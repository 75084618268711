import { Button } from '@mui/material'
import React from 'react'
const ClearLocalStorage = () => {
  const handleClearStorage = () => {
    localStorage.removeItem('kanbanData') // Run this once in your app or browser console to reset the storage
  }

  return <Button onClick={handleClearStorage}>Clear Kanban Local Storage</Button>
}

export default ClearLocalStorage
