import React, { useContext, createContext, useState, useEffect } from 'react'
import { Box, AppBar, Toolbar, useTheme } from '@mui/material'
import MoreTimeIcon from '@mui/icons-material/MoreTime'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import PersonIcon from '@mui/icons-material/Person'
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import PandaDocImageComponent from '../../components/AvatarImageComponents/PandaDocImageComponent'
import GridViewIcon from '@mui/icons-material/GridView'

export const renderHeaderContent = boardName => {
  switch (boardName) {
    case 'Dashboard':
      // return <CustomDateRender objectData={content} dateField={item.field} />
      return (
        <>
          <GridViewIcon
            sx={{
              color: 'rgb(5, 190, 113)',
            }}
          />
        </>
      )
    case 'Leads':
      // return <CustomDateRender objectData={content} dateField={item.field} />
      return (
        <>
          <OnlinePredictionIcon
            sx={{
              color: 'rgb(0, 134, 192)',
            }}
          />
        </>
      )
    case 'Prospects':
      return (
        <>
          <MoreTimeIcon
            sx={{
              color: 'rgb(224, 118, 82)',
            }}
          />
        </>
      )
    case 'Contacts':
      return (
        <>
          <PersonIcon
            sx={{
              color: 'rgb(255, 123, 187)',
            }}
          />
        </>
      )
    case 'Organizations':
      return (
        <>
          <CorporateFareIcon
            sx={{
              color: 'rgb(114, 229, 218)',
            }}
          />
        </>
      )
    case 'Organization Details':
      return (
        <>
          <CorporateFareIcon
            sx={{
              color: 'rgb(114, 229, 218)',
            }}
          />
        </>
      )
    case 'Deals':
      return (
        <>
          <AttachMoneyIcon
            sx={{
              color: '#fbc11e',
            }}
          />
        </>
      )
    case 'Agreements':
      return (
        <>
          <ThumbUpOffAltIcon
            sx={{
              color: '#fbc11e',
            }}
          />
        </>
      )
    case 'Deals':
      return (
        <>
          <AttachMoneyIcon
            sx={{
              color: '#fbc11e',
            }}
          />
        </>
      )
    case 'Contracts':
      return (
        <>
          <ThumbUpOffAltIcon
            sx={{
              color: '#fbc11e',
            }}
          />
        </>
      )
    case 'Edit Contracts':
      return (
        <>
          <ThumbUpOffAltIcon
            sx={{
              color: '#fbc11e',
            }}
          />
        </>
      )
    case 'PandaDoc Contracts':
      return (
        <>
          <PandaDocImageComponent
            sx={{
              color: 'rgba(255,255,255,0.3)',
            }}
          />
        </>
      )
    default:
      return null
  }
}

// Creating a Context for the container
// Creating a Context for the container
export const ContainerContext = createContext()

// A Provider Component to encapsulate the use of context
export const ContainerProvider = ({ children }) => {
  const [headerTitle, setHeaderTitle] = useState('Default Title') // State to manage header title, customizable
  const [headerContent, setHeaderContent] = useState()
  const [isCreateOrganizationOpen, setIsCreateOrganizationOpen] = useState(false)

  const handleOpenCreateOrganization = () => {
    setIsCreateOrganizationOpen(true)
  }

  const handleCloseCreateOrganization = () => {
    setIsCreateOrganizationOpen(false)
  }

  const value = {
    headerTitle,
    setHeaderTitle,
    headerContent,
    setHeaderContent,
    isCreateOrganizationOpen,
    handleOpenCreateOrganization,
    handleCloseCreateOrganization,
  }

  return <ContainerContext.Provider value={value}>{children}</ContainerContext.Provider>
}

// A component to display the header and content
export const ContainerWithHeader = () => {
  const theme = useTheme()
  const { headerContent } = useContext(ContainerContext) // Use context to get the header title

  return (
    <Box sx={{ position: 'sticky', zIndex: 1200, top: 64 }}>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <AppBar position='static'>
          <Toolbar sx={{ backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'white' }}>
            {typeof headerContent === 'function' ? headerContent() : headerContent}
          </Toolbar>
        </AppBar>
      </Box>
    </Box>
  )
}

// import React, { useContext, createContext, useState, useEffect } from 'react'
// import { Box, AppBar, Toolbar, useTheme } from '@mui/material'
// import { MyStyledComponent } from '../../style/styleElements'
// import TimelineIcon from '@mui/icons-material/Timeline'
// import SpeedIcon from '@mui/icons-material/Speed'
// import RefreshIcon from '@mui/icons-material/Refresh'
// import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
// import MoreTimeIcon from '@mui/icons-material/MoreTime'
// import CorporateFareIcon from '@mui/icons-material/CorporateFare'
// import PersonIcon from '@mui/icons-material/Person'
// import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction'
// import PivotTableChartIcon from '@mui/icons-material/PivotTableChart'
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
// import GroboticPngLight from '../../images/Grobotic png-lightmode.png'
// import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
// import PandaDocImageComponent from '../../components/AvatarImageComponents/PandaDocImageComponent'
// import GridViewIcon from '@mui/icons-material/GridView'

// export const renderHeaderContent = boardName => {
//   switch (boardName) {
//     case 'Dashboard':
//       // return <CustomDateRender objectData={content} dateField={item.field} />
//       return (
//         <>
//           <GridViewIcon
//             sx={{
//               color: 'rgb(5, 190, 113)',
//             }}
//           />
//         </>
//       )
//     case 'Leads':
//       // return <CustomDateRender objectData={content} dateField={item.field} />
//       return (
//         <>
//           <OnlinePredictionIcon
//             sx={{
//               color: 'rgb(0, 134, 192)',
//             }}
//           />
//         </>
//       )
//     case 'Prospects':
//       return (
//         <>
//           <MoreTimeIcon
//             sx={{
//               color: 'rgb(224, 118, 82)',
//             }}
//           />
//         </>
//       )
//     case 'Contacts':
//       return (
//         <>
//           <PersonIcon
//             sx={{
//               color: 'rgb(255, 123, 187)',
//             }}
//           />
//         </>
//       )
//     case 'Organizations':
//       return (
//         <>
//           <CorporateFareIcon
//             sx={{
//               color: 'rgb(114, 229, 218)',
//             }}
//           />
//         </>
//       )
//     case 'Organization Details':
//       return (
//         <>
//           <CorporateFareIcon
//             sx={{
//               color: 'rgb(114, 229, 218)',
//             }}
//           />
//         </>
//       )
//     case 'Deals':
//       return (
//         <>
//           <AttachMoneyIcon
//             sx={{
//               color: '#fbc11e',
//             }}
//           />
//         </>
//       )
//     case 'Agreements':
//       return (
//         <>
//           <ThumbUpOffAltIcon
//             sx={{
//               color: '#fbc11e',
//             }}
//           />
//         </>
//       )
//     case 'Deals':
//       return (
//         <>
//           <AttachMoneyIcon
//             sx={{
//               color: '#fbc11e',
//             }}
//           />
//         </>
//       )
//     case 'Contracts':
//       return (
//         <>
//           <ThumbUpOffAltIcon
//             sx={{
//               color: '#fbc11e',
//             }}
//           />
//         </>
//       )
//     case 'Edit Contracts':
//       return (
//         <>
//           <ThumbUpOffAltIcon
//             sx={{
//               color: '#fbc11e',
//             }}
//           />
//         </>
//       )
//     case 'PandaDoc Contracts':
//       return (
//         <>
//           <PandaDocImageComponent
//             sx={{
//               color: 'rgba(255,255,255,0.3)',
//             }}
//           />
//         </>
//       )
//     default:
//       return null
//   }
// }

// // Creating a Context for the container
// export const ContainerContext = createContext()

// // A Provider Component to encapsulate the use of context
// export const ContainerProvider = ({ children }) => {
//   const [headerTitle, setHeaderTitle] = useState('Default Title') // State to manage header title, customizable
//   const [headerContent, setHeaderContent] = useState()

//   const value = {
//     headerTitle,
//     setHeaderTitle,
//     headerContent,
//     setHeaderContent,
//   }

//   return <ContainerContext.Provider value={value}>{children}</ContainerContext.Provider>
// }

// // A component to display the header and content
// export const ContainerWithHeader = () => {
//   const theme = useTheme()
//   const { headerContent, headerTitle } = useContext(ContainerContext) // Use context to get the header title
//   console.log('Header Content: ', headerContent)
//   return (
//     <Box sx={{ position: 'sticky', zIndex: 1200, top: 64 }}>
//       <AppBar position='static'>
//         <Toolbar sx={{ backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'white' }}>
//           {typeof headerContent === 'function' ? headerContent() : headerContent}
//           {/* {renderHeaderContent(boardName)} */}
//         </Toolbar>
//       </AppBar>
//     </Box>
//   )
// }
