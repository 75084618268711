// src/api/customHooks/useUploadContactPhoto.js
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { baseUrl } from '../queryMutations/mutationFunctions'

const uploadPhoto = async ({ formData }) => {
  const url = `${baseUrl}/aws/upload-organization-photo`
  console.log('FormData in uploadPhoto:', formData)

  const response = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return response.data
}

const useUploadOrganizationPhoto = () => {
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbarContext()

  const mutation = useMutation({
    mutationFn: uploadPhoto,
    onMutate: async ({ orgId, formData }) => {
      await queryClient.cancelQueries(['organizations', orgId])
      const previousOrganization = queryClient.getQueryData(['organizations', orgId])

      queryClient.setQueryData(['organizations', orgId], old => ({
        ...old,
        photoUrl: URL.createObjectURL(formData.get('file')),
      }))

      return { previousOrganization }
    },
    onError: (err, { orgId }, context) => {
      queryClient.setQueryData(['organizations', orgId], context.previousOrganization)
      console.error('Mutation Error:', err)
      showSnackbar(err.response?.data?.message || err.message, 'error')
    },
    onSuccess: (_, { orgId }) => {
      queryClient.invalidateQueries(['organizations'])
      showSnackbar('Photo uploaded successfully', 'success')
    },
  })

  return mutation
}

export default useUploadOrganizationPhoto
