import React from 'react'
import LinkedinSvg from './icons/linkedin.svg'
import MetaSvg from './icons/meta.svg'
import MetaBlueSvg from './icons/meta (1).svg'
import SnapchatSvg from './icons/snapchat.svg'
import InstagramSvg from './icons/social.svg'
import TikTokSvg from './icons/tiktok.svg'
import SpotifySvg from './icons/spotify.svg'
import YouTubeSvg from './icons/youtube.svg'
import TwitterSvg from './icons/twitter.svg'
import { Box, Tooltip } from '@mui/material'

export const determineSocialIcons = icon => {
  switch (icon) {
    case 'facebook':
      return MetaBlueSvg
    case 'instagram':
      return InstagramSvg
    case 'linkedin':
      return LinkedinSvg
    case 'spotify':
      return SpotifySvg
    case 'twitter':
      return TwitterSvg
    case 'youtube':
      return YouTubeSvg
    case 'tiktok':
      return TikTokSvg
    case 'snapchat':
      return SnapchatSvg
    default:
      return null
  }
}

const SvgIconComponent = ({ socialProfiles }) => {
  return (
    <Box style={{ display: 'flex', gap: '8px' }}>
      {socialProfiles && socialProfiles.length > 0
        ? socialProfiles.map((profile, index) => (
            <Tooltip key={`${index}-${profile.profile}`} title={profile.profile}>
              <a
                key={`${index}-${profile.profile}`}
                href={profile.url.startsWith('http') ? profile.url : `https://${profile.url}`}
                onClick={e => e.stopPropagation()}
                target='_blank'
                rel='noopener noreferrer'
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <img
                  key={`${index}-${profile.profile}`}
                  src={determineSocialIcons(profile.network)}
                  alt={profile.network}
                  width='24'
                  height='24'
                />
              </a>
            </Tooltip>
          ))
        : null}
    </Box>
  )
}

export default SvgIconComponent
