import { useQueryClient, useMutation } from '@tanstack/react-query'
import { baseUrl } from '../queryMutations/mutationFunctions'
import axios from 'axios'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'

const notifyMessage = async payload => {
  const slackToken = localStorage.getItem('slack_access_token')
  if (!slackToken) {
    console.error('No authentication token available')
    return
  }

  try {
    const response = await axios.post(
      `${baseUrl}/aws/slack/notify`,
      payload, // Now directly sending JSON payload
      {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: slackToken,
        },
      }
    )
    return response.data
  } catch (error) {
    console.error('Error sending notification message:', error)
    throw error // Re-throw the error for useMutation's onError to catch
  }
}

export const useNotifyMessage = () => {
  const { showSnackbar } = useSnackbarContext()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    // Directly pass the object to the mutation function
    mutationFn: notifyMessage,
    onSuccess: data => {
      console.log('Message Sent Successfully: ', data)
      // queryClient.invalidateQueries('threadMessages')
      showSnackbar('Notification sent successfully', 'success')
    },
    onError: error => {
      console.error('[useNotifyMessage] Error sending message:', error)
      showSnackbar(error.response.data.message || error.message, 'error')
    },
  })

  return mutation
}
