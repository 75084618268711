import React from 'react'
import {
  List,
  ListItem,
  Tooltip,
  Typography,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  useTheme,
} from '@mui/material'
import TimelineIcon from '@mui/icons-material/Timeline'
import SpeedIcon from '@mui/icons-material/Speed'
import RefreshIcon from '@mui/icons-material/Refresh'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import MoreTimeIcon from '@mui/icons-material/MoreTime'
import ZohoImageComponent from '../../../images/ZohoImageComponent/ZohoImageComponent'
import PandaDocImageComponent from '../../AvatarImageComponents/PandaDocImageComponent'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction'
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart'
import ChecklistIcon from '@mui/icons-material/Checklist'
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'

const DevNavigationSection = ({ handleNavigation, open, selectedPath }) => {
  const theme = useTheme()
  const bgSelectColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'
  const bgHoverColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'
  return (
    <List>
      <Typography sx={{ fontSize: '10px' }}>Dev Section</Typography>
      {[
        // { label: 'Diagram', path: 'diagram' },
        { label: 'Sprints', path: 'sprints' },
        { label: 'Campaign List', path: 'campaignlist' },
        { label: 'Timeline', path: 'timeline' },
        { label: 'Slack User List', path: 'slackuserlist' },
        // { label: 'Speed Dial', path: 'speeddial' },
        // { label: 'Team Tasks Test', path: 'teamtaskstest' },
        // Add more items here if necessary
      ].map((item, index) => (
        <ListItem key={item.label} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              backgroundColor: item.path === selectedPath.replace(/^\//, '') ? bgSelectColor : 'transparent', // Adjust color as needed
              '&:hover': {
                backgroundColor: bgHoverColor, // Adjust hover color as needed
              },
            }}
            onClick={() => handleNavigation(item.path)}
          >
            <Tooltip enterDelay={500} leaveDelay={0} title={item.label} placement='right'>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {/* {index === 0 ? (
                  <PivotTableChartIcon
                    sx={{
                      color: 'rgba(255,255,255,0.3)',
                    }}
                  />
                ) : null} */}
                {index === 0 ? (
                  <DirectionsRunIcon
                    sx={{
                      color: 'rgba(45, 85, 255, 1)',
                    }}
                  />
                ) : null}
                {index === 1 ? (
                  <ChecklistIcon
                    sx={{
                      color: 'rgb(93, 63, 211)',
                    }}
                  />
                ) : null}

                {index === 2 ? (
                  <TimelineIcon
                    sx={{
                      color: 'rgba(255,255,255,0.3)',
                    }}
                  />
                ) : null}
                {index === 3 ? (
                  <InsertEmoticonIcon
                    sx={{
                      color: 'rgba(255,255,255,0.3)',
                    }}
                  />
                ) : null}
                {/* {index === 7 ? (
                  <SpeedIcon
                    sx={{
                      color: 'rgba(255,255,255,0.3)',
                    }}
                  />
                ) : null} */}
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              sx={{
                opacity: open ? 1 : 0,
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? 'white' : 'black',
                }}
              >
                {item.label}
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}

export default DevNavigationSection
