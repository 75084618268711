import React from 'react'
import { InfoLabel, InfoValueWrapper, InfoValue } from './style'

/**
 * Renders a field label and its corresponding value, with optional link formatting for phone numbers.
 *
 * @param {Object} props - The component props.
 * @param {string} props.label - The label to display for the field.
 * @param {string} props.fieldName - The name of the field, used to determine if the value should be linked (e.g., for phone numbers).
 * @param {string} [props.value] - The value to display for the field. If no value is provided, a placeholder is shown.
 * @returns {JSX.Element} The rendered field component.
 */

const RenderField = ({ label, fieldName, value }) => {
  return (
    <>
      <InfoLabel>{label}</InfoLabel>
      <InfoValueWrapper>
        {value ? (
          <InfoValue href={fieldName === 'phone' ? `tel:${value}` : ''}>{value}</InfoValue>
        ) : (
          <InfoValue sx={{ color: 'rgba(211,211,211, 0.7)' }}>{'...'}</InfoValue>
        )}
      </InfoValueWrapper>
    </>
  )
}
export default RenderField
