import { IconButton, Badge, Drawer, Tooltip } from '@mui/material'
import PublicIcon from '@mui/icons-material/Public'
import AddIcon from '@mui/icons-material/Add'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'

const IncompleteDiscoveryIcon = ({ missingFields }) => {
  return (
    <Tooltip title={`Missing fields: ${missingFields.join(', ')}`}>
      <IconButton color='inherit'>
        <Badge badgeContent={<PriorityHighIcon sx={{ fontSize: '18px', color: 'rgba(242, 38, 19, 1)' }} />}>
          <PublicIcon sx={{ fontSize: '30px', color: 'rgba(25, 117, 255, 0.5)' }} />
        </Badge>
      </IconButton>
    </Tooltip>
  )
}

export default IncompleteDiscoveryIcon
