// export const editFieldConfig = {
//   'General Information': [
//     {
//       name: 'platform',
//       label: 'Platform',
//       type: 'text',
//       required: true,
//       fullLabel: 'Platform',
//     },
//     {
//       name: 'demographicTargeting',
//       label: 'Demographic Targeting',
//       type: 'text',
//       required: true,
//       fullLabel: 'Search Demographic Targeting',
//     },
//     {
//       name: 'landingPageUrl',
//       label: 'Landing Page URL',
//       type: 'text',
//       required: true,
//       fullLabel: 'Search Landing Page URL',
//     },
//     {
//       name: 'targetingSpecifics',
//       label: 'Targeting Specifics',
//       type: 'text',
//       required: false,
//       fullLabel: 'Search Targeting Specifics',
//     },
//     {
//       name: 'name',
//       label: 'Campaign Name',
//       type: 'text',
//       required: true,
//       fullLabel: 'Campaign Name',
//     },
//     {
//       name: 'location',
//       label: 'Location',
//       type: 'text',
//       required: true,
//       fullLabel: 'Location (presence and interest)',
//     },
//     {
//       name: 'adGroup',
//       label: 'Ad Group',
//       type: 'text',
//       required: true,
//       fullLabel: 'Ad Group',
//     },
//     {
//       name: 'keywords',
//       label: 'Keywords',
//       type: 'text',
//       required: true,
//       fullLabel: 'Keywords',
//       placeholder: 'Enter keywords separated by commas',
//     },
//     {
//       name: 'negativeKeywords',
//       label: 'Negative Keywords',
//       type: 'text',
//       required: false,
//       fullLabel: 'Negative Keywords',
//       placeholder: 'Enter negative keywords separated by commas',
//     },
//     {
//       name: 'organizationName',
//       label: 'Organization Name',
//       type: 'text',
//       required: true,
//       fullLabel: 'Organization Name',
//       readOnly: true,
//     },
//     {
//       name: 'type',
//       label: 'Contract Type',
//       type: 'text',
//       required: true,
//       fullLabel: 'Contract Type',
//       readOnly: true,
//     },
//     {
//       name: 'groContact',
//       label: 'GRO Contact - Email',
//       type: 'email',
//       required: true,
//       fullLabel: 'GRO Contact - Email',
//     },
//     {
//       name: 'orgContact',
//       label: 'Organization Contact - Email',
//       type: 'email',
//       required: true,
//       fullLabel: 'Organization Contact - Email',
//     },
//     {
//       name: 'vertical',
//       label: "Organization's Vertical",
//       type: 'text',
//       required: true,
//       fullLabel: "Organization's Vertical",
//       readOnly: true,
//     },
//     {
//       name: 'website',
//       label: "Organization's Website",
//       type: 'url',
//       required: true,
//       fullLabel: "Organization's Website",
//       readOnly: true,
//     },
//   ],
//   Integration: [
//     {
//       name: 'busObjective',
//       label: 'Business Objective',
//       type: 'select',
//       options: ['Leads', 'Branding/awareness', 'In-Store Visits', 'Website traffic'],
//       required: true,
//       fullLabel: `Business Objective <br /> a. What is the client's overall marketing objective? Do they want Leads, Branding/awareness, In-Store Visits, Website traffic or a mix of these? <br /> i. Keep in mind that more than one objective should come with more budget`,
//       placeholder: 'Business Objective',
//     },
//     {
//       name: 'geoTargeting',
//       label: 'Organization Geographic Targeting',
//       type: 'text',
//       required: true,
//       fullLabel: 'Organization Geographic Targeting <br /> a. State, City, Zip codes, etc',
//     },
//   ],
//   'Paid Search': [
//     {
//       name: 'ownBillingConfig',
//       label: 'Are we using our own billing configuration for the client?',
//       type: 'text',
//       required: true,
//       fullLabel: 'Are we using our own billing configuration for the client?',
//     },
//     {
//       name: 'cmsUsed',
//       label: 'CMS Being Used',
//       type: 'text',
//       required: true,
//       fullLabel: 'e.g. Wordpress, entrata, squarespace',
//     },
//     // {
//     //   name: 'googleAdsCallExtension',
//     //   label: 'Number for Google ads call extensions?',
//     //   type: 'text',
//     //   required: false,
//     //   fullLabel: 'What is the number the client provided us for Google ads call extensions?',
//     // },
//     {
//       name: 'differentNumberFromWebsite',
//       label: 'Is the client using call tracking?',
//       type: 'radio',
//       options: ['Yes', 'No'],
//       required: false,
//       fullLabel: 'Is the number different from the website?',
//     },
//     {
//       name: 'searchKeywords',
//       label: 'Search Keywords',
//       type: 'text',
//       required: false,
//       fullLabel: 'Search Keywords',
//     },
//     {
//       name: 'name',
//       label: 'Campaign Name',
//       type: 'text',
//       required: true,
//       fullLabel: 'Campaign Name',
//     },
//     {
//       name: 'location',
//       label: 'Location',
//       type: 'text',
//       required: true,
//       fullLabel: 'Location (presence and interest)',
//     },
//     {
//       name: 'adGroup',
//       label: 'Ad Group',
//       type: 'text',
//       required: true,
//       fullLabel: 'Ad Group',
//     },
//     {
//       name: 'keywords',
//       label: 'Keywords',
//       type: 'text',
//       required: true,
//       fullLabel: 'Keywords',
//       placeholder: 'Enter keywords separated by commas',
//     },
//     {
//       name: 'negativeKeywords',
//       label: 'Negative Keywords',
//       type: 'text',
//       required: false,
//       fullLabel: 'Negative Keywords',
//       placeholder: 'Enter negative keywords separated by commas',
//     },
//   ],
//   'Paid Social': [
//     // {
//     //   name: 'facebookAccess',
//     //   label: 'Does the client have the ability to share access to the Facebook page?',
//     //   type: 'text',
//     //   required: false,
//     //   fullLabel: 'Does the client have access to their Facebook page?',
//     // },
//     {
//       name: 'googleBusinessProfile',
//       label: 'Does the client have a Google Business profile Setup?',
//       type: 'text',
//       required: false,
//       fullLabel: 'Does the client have a Google Business profile Setup?',
//     },
//   ],
//   Display: [
//     {
//       name: 'display1',
//       label: 'Display Section.....?',
//       type: 'text',
//       required: false,
//       fullLabel: 'Is their Google Business profile up to date?',
//     },
//   ],
//   SEO: [
//     {
//       name: 'street',
//       label: 'Street',
//       type: 'text',
//       required: true,
//       fullLabel: "Organization's Street Address",
//       readOnly: true,
//     },
//     {
//       name: 'city',
//       label: 'City',
//       type: 'text',
//       required: true,
//       fullLabel: "Organization's City",
//       readOnly: true,
//     },
//     {
//       name: 'state',
//       label: 'State',
//       type: 'text',
//       required: true,
//       fullLabel: "Organization's State",
//       readOnly: true,
//     },
//     {
//       name: 'zip',
//       label: 'Zip',
//       type: 'text',
//       required: true,
//       fullLabel: "Organization's Zip Code",
//       readOnly: true,
//     },
//   ],
//   SMM: [
//     {
//       name: 'SMM1',
//       label: 'Is their Google Business profile up to date?',
//       type: 'text',
//       required: false,
//       fullLabel: 'Is their Google Business profile up to date?',
//     },
//   ],
//   Campaigns: [
//     {
//       name: 'name',
//       label: 'Campaign Name',
//       type: 'text',
//       required: true,
//       fullLabel: 'Campaign Name',
//     },
//     {
//       name: 'location',
//       label: 'Location',
//       type: 'text',
//       required: true,
//       fullLabel: 'Location (presence and interest)',
//     },
//     {
//       name: 'adGroup',
//       label: 'Ad Group',
//       type: 'text',
//       required: true,
//       fullLabel: 'Ad Group',
//     },
//     {
//       name: 'keywords',
//       label: 'Keywords',
//       type: 'text',
//       required: true,
//       fullLabel: 'Keywords',
//       placeholder: 'Enter keywords separated by commas',
//     },
//     {
//       name: 'negativeKeywords',
//       label: 'Negative Keywords',
//       type: 'text',
//       required: false,
//       fullLabel: 'Negative Keywords',
//       placeholder: 'Enter negative keywords separated by commas',
//     },
//   ],
// }
export const editFieldConfig = {
  'General Information': [
    {
      name: 'platform',
      label: 'Platform',
      type: 'text',
      required: true,
      fullLabel: 'Platform',
    },
    {
      name: 'demographicTargeting',
      label: 'Demographic Targeting',
      type: 'text',
      required: true,
      fullLabel: 'Search Demographic Targeting',
    },
    {
      name: 'landingPageUrl',
      label: 'Landing Page URL',
      type: 'text',
      required: true,
      fullLabel: 'Search Landing Page URL',
    },
    {
      name: 'targetingSpecifics',
      label: 'Targeting Specifics',
      type: 'text',
      required: false,
      fullLabel: 'Search Targeting Specifics',
    },
    {
      name: 'name',
      label: 'Campaign Name',
      type: 'text',
      required: true,
      fullLabel: 'Campaign Name',
    },
    {
      name: 'location',
      label: 'Location',
      type: 'text',
      required: true,
      fullLabel: 'Location (presence and interest)',
    },
    {
      name: 'adGroup',
      label: 'Ad Group',
      type: 'text',
      required: true,
      fullLabel: 'Ad Group',
    },
    {
      name: 'keywords',
      label: 'Keywords',
      type: 'text',
      required: true,
      fullLabel: 'Keywords',
      placeholder: 'Enter keywords separated by commas',
    },
    {
      name: 'negativeKeywords',
      label: 'Negative Keywords',
      type: 'text',
      required: false,
      fullLabel: 'Negative Keywords',
      placeholder: 'Enter negative keywords separated by commas',
    },
    {
      name: 'organizationName',
      label: 'Organization Name',
      type: 'text',
      required: true,
      fullLabel: 'Organization Name',
      readOnly: true,
    },
    {
      name: 'type',
      label: 'Contract Type',
      type: 'text',
      required: true,
      fullLabel: 'Contract Type',
      readOnly: true,
    },
    {
      name: 'groContact',
      label: 'GRO Contact - Email',
      type: 'email',
      required: true,
      fullLabel: 'GRO Contact - Email',
    },
    {
      name: 'orgContact',
      label: 'Organization Contact - Email',
      type: 'email',
      required: true,
      fullLabel: 'Organization Contact - Email',
    },
    {
      name: 'vertical',
      label: "Organization's Vertical",
      type: 'text',
      required: true,
      fullLabel: "Organization's Vertical",
      readOnly: true,
    },
    {
      name: 'website',
      label: "Organization's Website",
      type: 'url',
      required: true,
      fullLabel: "Organization's Website",
      readOnly: true,
    },
  ],
  Integration: [
    {
      name: 'busObjective',
      label: 'Business Objective',
      type: 'select',
      options: ['Leads', 'Branding/awareness', 'In-Store Visits', 'Website traffic'],
      required: true,
      fullLabel: `Business Objective <br /> a. What is the client's overall marketing objective?`,
      placeholder: 'Business Objective',
    },
    {
      name: 'geoTargeting',
      label: 'Organization Geographic Targeting',
      type: 'text',
      required: true,
      fullLabel: 'Organization Geographic Targeting <br /> a. State, City, Zip codes, etc',
    },
  ],
  'Paid Search': [
    {
      name: 'ownBillingConfig',
      label: 'Are we using our own billing configuration for the client?',
      type: 'text',
      required: true,
      fullLabel: 'Are we using our own billing configuration for the client?',
    },
    {
      name: 'cmsUsed',
      label: 'CMS Being Used',
      type: 'text',
      required: true,
      fullLabel: 'e.g. Wordpress, entrata, squarespace',
    },
    {
      name: 'differentNumberFromWebsite',
      label: 'Is the client using call tracking?',
      type: 'radio',
      options: ['Yes', 'No'],
      required: false,
      fullLabel: 'Is the number different from the website?',
    },
    {
      name: 'searchKeywords',
      label: 'Search Keywords',
      type: 'text',
      required: false,
      fullLabel: 'Search Keywords',
    },
  ],
  'Paid Social': [
    {
      name: 'googleBusinessProfile',
      label: 'Does the client have a Google Business profile Setup?',
      type: 'text',
      required: false,
      fullLabel: 'Does the client have a Google Business profile Setup?',
    },
  ],
  Campaigns: [
    {
      name: 'name',
      label: 'Campaign Name',
      type: 'text',
      required: true,
      fullLabel: 'Campaign Name',
    },
    {
      name: 'location',
      label: 'Location',
      type: 'text',
      required: true,
      fullLabel: 'Location (presence and interest)',
    },
    {
      name: 'adGroup',
      label: 'Ad Group',
      type: 'text',
      required: true,
      fullLabel: 'Ad Group',
    },
    {
      name: 'keywords',
      label: 'Keywords',
      type: 'text',
      required: true,
      fullLabel: 'Keywords',
      placeholder: 'Enter keywords separated by commas',
    },
    {
      name: 'negativeKeywords',
      label: 'Negative Keywords',
      type: 'text',
      required: false,
      fullLabel: 'Negative Keywords',
      placeholder: 'Enter negative keywords separated by commas',
    },
  ],
  Ads: [
    {
      name: 'conversionLocation',
      label: 'Conversion Location',
      type: 'text',
      required: true,
      fullLabel: 'Where is the conversion happening?',
    },
    {
      name: 'performanceGoal',
      label: 'Performance Goal',
      type: 'text',
      required: true,
      fullLabel: 'What is the performance goal?',
    },
    {
      name: 'conversionEvent',
      label: 'Conversion Event',
      type: 'text',
      required: true,
      fullLabel: 'What is the conversion event?',
    },
    {
      name: 'audience',
      label: 'Target Audience',
      type: 'text',
      required: true,
      fullLabel: 'Who is the target audience?',
      placeholder: 'Enter audience separated by commas',
    },
  ],
}
