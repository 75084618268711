import React from 'react'
import { Box, Button, Typography, useTheme } from '@mui/material'

const AddRenderOptionButton = ({ option, handleOpenDialog, ...props }) => {
  const theme = useTheme()

  // Default to an empty object if props is undefined
  const { key, className, onClick, ...rest } = props || {}

  const buttonStyles = {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(48, 53, 66)' : 'rgba(250, 249, 246)',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
    },
    width: '100%',
    margin: 0.5,
  }

  const typographyStyles = {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'rgb(74, 211, 162)',
    margin: 0.5,
  }

  const handleClick = event => {
    event.stopPropagation() // Stop propagation to prevent Autocomplete behavior
    handleOpenDialog(option.inputValue)
  }

  return (
    <Box
      key={key || option.id || 'addOptionButton-key'}
      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', alignItems: 'center' }}
    >
      <Button onClick={handleClick} sx={buttonStyles} {...rest}>
        <Typography sx={typographyStyles}>ADD "{option.inputValue}"</Typography>
      </Button>
    </Box>
  )
}

export default AddRenderOptionButton
