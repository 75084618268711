import React, { useEffect, useState } from 'react'
import { Grid, Tooltip, IconButton, TextField, CircularProgress } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CheckIcon from '@mui/icons-material/Check'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CompleteDiscoveryIcon from '../Icons/CompletedDiscoveryIcon'
import IncompleteDiscoveryIcon from '../Icons/IncompleteDiscoveryIcon'
import PublicIcon from '@mui/icons-material/Public'
import ValidateDiscoveryIcon from '../Icons/ValidateDiscoveryIcon'

const ValidateDiscoveryIcons = ({ organization, missingFields, isLoading }) => {
  return (
    <>
      <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {isLoading && <CircularProgress />}
        {organization ? (
          missingFields.length > 0 ? (
            <IncompleteDiscoveryIcon missingFields={missingFields} />
          ) : (
            <CompleteDiscoveryIcon />
          )
        ) : (
          <ValidateDiscoveryIcon />
        )}
      </Grid>
    </>
  )
}

export default ValidateDiscoveryIcons
