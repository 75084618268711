import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const FlexBoxRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'center',
  gap: 9,
  '&:hover': {
    transition: 'transform 0.1s ease-in-out',
    transform: 'scale(1.02)',
    transition: 'ease-in-out 0.2s',
  },
}))
