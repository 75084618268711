import React, { useEffect, useRef, useState } from 'react'
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
  getGridStringOperators,
  getGridNumericOperators,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridSingleSelectOperators,
} from '@mui/x-data-grid-pro'
import { Badge, Box, Button, Tooltip, Typography, useTheme } from '@mui/material'
import { gridToolbarContainerStyles } from './style'
import CustomStatusFilter from '../DataGrid/createColumns/CustomStatusFilter'
import CustomTypeFilter from '../DataGrid/CustomTypeFilter'
import { StyledGridToolbarQuickFilter } from '../DataGrid/style'
import { useActiveUser } from '../../api/slack/useActiveUser'

const statusFilters = [
  { label: 'Prospect', value: 'Prospect', color: 'rgb(93, 79, 251)' },
  { label: 'Onboarding', value: 'Onboarding', color: 'rgb(92, 179, 253)' },
  { label: 'In-Progress', color: 'rgb(110, 156, 222)' },
  { label: 'Active', value: 'Active', color: 'rgb(152, 214, 129)' },
  { label: 'Active (SEO)', color: 'rgb(152, 98, 161)' },
  { label: 'Active (Creative)', color: 'rgb(147, 111, 218)' },
  { label: 'Active (Website)', color: 'rgb(102, 69, 169)' },
  { label: 'Active (Past Due)', color: 'rgb(255, 131, 88)' },
  { label: 'Active (LTP)', color: 'rgb(51, 158, 205)' },
  { label: '30 day notice', value: '30 day notice', color: 'rgb(235, 77, 77)' },
  { label: 'Cancelled', value: 'Cancelled', color: 'rgb(0,0,0)' },
  { label: 'Inactive / Paused', color: 'rgb(255, 145, 145)' },
]

export const organizationTypeOptions = [
  {
    label: 'PMC',
    value: 'PMC',
    color: 'rgb(93, 79, 251)',
  },
  { label: 'OG', value: 'OG', color: 'rgb(92, 179, 253)' },
  { label: 'HP', value: 'HP', color: 'rgb(152, 214, 129)' },
  { label: 'PC', value: 'PC', color: 'rgb(93, 79, 251)' },
  { label: 'AW', value: 'AW', color: 'rgb(92, 179, 253)' },
  {
    label: 'ORG',
    value: 'ORG',
    color: 'rgb(242, 140, 40)',
  },
]
const slotProps = theme => ({
  button: {
    sx: {
      borderRadius: 2,
      maxHeight: '100%',
      mt: 2,
      mb: 2,
      '&:hover': { backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0,0,0,0.1)' },
      color: theme.palette.text.primary,
      '& .MuiBadge-badge': {
        backgroundColor: 'rgb(114, 229, 218)', // Your custom background color
        color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)', // Change the text color if needed
        fontSize: '14px', // Optional: adjust the font size
        width: '20px', // Adjust the width/height to control the size of the badge
        height: '20px',
      },
    },
  },
})
const OrganizationsGridToolbar = ({ filterModel, setFilterModel, context }) => {
  const theme = useTheme()
  const quickFilterRef = useRef(null) // Create a ref for the quick filter
  const [filterStatus, setFilterStatus] = useState('')
  const [filterStage, setFilterStage] = useState('')
  const [filterType, setFilterType] = useState('')
  const [shouldClear, setShouldClear] = useState(false) // New state to trigger clearing
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const activeUserName =
    activeUser && activeUser.user && activeUser.user.profile ? activeUser.user.profile.real_name : null

  useEffect(() => {
    const handleKeyDown = event => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        event.preventDefault()
        if (quickFilterRef.current) {
          quickFilterRef.current.focus() // Focus the quick filter input
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const handleFilterMyData = () => {
    setFilterModel({
      ...filterModel,
      quickFilterValues: [activeUserName],
    })
  }

  const handleSetFilter = (fieldName, newValue) => {
    // Ensure filterModel.items is an array, even if it's undefined
    const updatedFilters = (filterModel.items || []).filter(item => item.field !== fieldName)

    // Add the new filter for the specific field
    if (newValue) {
      updatedFilters.push({
        field: fieldName,
        operator: 'contains',
        value: newValue,
      })
    }

    // Update the filter model with the combined filters
    setFilterModel({
      ...filterModel, // Keep existing properties in the filter model
      items: updatedFilters, // Update the items array
    })
  }

  const handleClearFilters = e => {
    e.preventDefault()
    setFilterModel({
      items: [],
      quickFilterValues: [],
    }) // Clear filter model
    setFilterStatus('') // Reset status filter
    setFilterStage('') // Reset stage filter
    setFilterType('') // Reset type filter
    setShouldClear(true) // Trigger the clearing in the custom filter components
  }

  return (
    <GridToolbarContainer sx={gridToolbarContainerStyles}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, ml: 3 }}>
          <GridToolbarDensitySelector slotProps={slotProps(theme)} />
          <GridToolbarColumnsButton slotProps={slotProps(theme)} />
          <GridToolbarFilterButton slotProps={slotProps(theme)} />
          <GridToolbarExport slotProps={slotProps(theme)} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <CustomTypeFilter
            context={'type'}
            options={organizationTypeOptions}
            setterFn={setFilterType}
            filterModel={filterModel}
            value={filterType}
            onChange={handleSetFilter}
            shouldClear={shouldClear} // Pass the clear trigger to the filter
            setShouldClear={setShouldClear} // Pass the function to reset the trigger
          />
          <CustomStatusFilter
            context={'status'}
            options={statusFilters}
            setterFn={setFilterStatus}
            filterModel={filterModel}
            value={filterStatus}
            onChange={handleSetFilter}
          />
          {filterModel && Array.isArray(filterModel.quickFilterValues) && filterModel.quickFilterValues.length > 0 ? (
            <Badge
              badgeContent={
                <Typography
                  sx={{
                    fontSize: 12,
                    bgcolor: 'rgb(114, 229, 218)',
                    p: 0.5,
                    height: '20px',
                    width: '20px',
                    borderRadius: '50%',
                    display: 'flex', // Flexbox centering
                    alignItems: 'center', // Vertically center
                    justifyContent: 'center', // Horizontally center
                    color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)',
                    fontWeight: 'bold',
                  }}
                >
                  {filterModel && filterModel.quickFilterValues && Array.isArray(filterModel.quickFilterValues)
                    ? filterModel.quickFilterValues.length
                    : null}
                </Typography>
              }
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Button
                sx={{
                  height: 40,
                  color: theme.palette.mode === 'dark' ? 'lightblue' : 'lightblue',
                }}
                onClick={handleFilterMyData}
              >
                My Organizations
              </Button>
            </Badge>
          ) : (
            <Button
              sx={{
                height: 40,
                color: theme.palette.mode === 'dark' ? 'lightblue' : 'lightblue',
              }}
              onClick={handleFilterMyData}
            >
              My Organizations
            </Button>
          )}
          <Button sx={{ height: 40, color: 'rgb(240,128,128)' }} onClick={handleClearFilters}>
            Clear Filters
          </Button>
          <StyledGridToolbarQuickFilter placeholder={'ctrl+f or cmd+f to search'} inputRef={quickFilterRef} />
        </Box>
      </Box>
    </GridToolbarContainer>
  )
}

export default OrganizationsGridToolbar
