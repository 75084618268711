// Import from '@tanstack/react-query' instead of 'react-query'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import {
  sendCliqMessage,
  sendCliqChannelMessage,
  sendCliqChannelComment,
  deleteCliqMessage,
  createCliqChannel,
  sendCliqChannelAttachment,
} from './mutationFunctions'
// import { useProject } from '../../context/ProjectProvider/ProjectProvider'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
export const baseUrl = process.env.REACT_APP_API_URL

// export const useCreateProject = dispatch => {
//   const queryClient = useQueryClient()
//   const url = `${baseUrl}/aws/creategroprojects`
//   const mutation = useMutation({
//     mutationFn: async newProjectData => {
//       console.log('NEW PROJECT DATA TO SUBMIT[MUTATION]: ', newProjectData)
//       const oauthToken = localStorage.getItem('zoho_access_token')
//       if (!oauthToken) {
//         console.error('No authentication token available')
//         return
//       }

//       console.log('AUTH TOKEN IN CREATE PROJECT MUTATION: ', oauthToken)
//       try {
//         for (const data of newProjectData) {
//           const response = await axios.post(url, data.Item, {
//             headers: { Authorization: `Bearer ${oauthToken}` },
//           })
//           // Parse the data from response.config
//           if (response.config && response.config.data) {
//             const parsedData = JSON.parse(response.config.data)
//             console.log('Parsed Data:', parsedData)
//             // You can now use parsedData as a JavaScript object
//           }
//         }
//       } catch (error) {
//         console.error('Mutation Error:', error)
//         throw error
//       }
//     },
//     onSuccess: data => {
//       console.log('Mutation Success:', data)
//       // if (typeof dispatch === 'function' && data) { *******************************
//       //   dispatch({ type: 'ADD_PROJECT_SUCCESS', payload: data })
//       // }
//       queryClient.invalidateQueries(['awsProjects'])
//     },
//     onError: error => {
//       console.error('Mutation Error:', error)
//       // Handle error appropriately
//     },
//   })
//   return mutation
// }
export const useCreateProject = () => {
  const { showSnackbar } = useSnackbarContext()
  const queryClient = useQueryClient()
  const url = `${baseUrl}/aws/creategroprojects`

  const mutation = useMutation({
    mutationFn: async projectData => {
      console.log('NEW PROJECT DATA TO SUBMIT[MUTATION]: ', projectData)
      // Use Slack Access Token here for creating new channel //
      const oauthToken = localStorage.getItem('slack_access_token')
      if (!oauthToken) {
        throw new Error('No authentication token available')
      }

      const response = await axios.post(url, ...projectData, {
        headers: { Authorization: `Bearer ${oauthToken}` },
      })

      // Assuming the API response includes the created project or relevant data
      return response.data // This allows the response data to be accessed in onSuccess
    },
    onSuccess: data => {
      console.log('Mutation Success:', data)
      // if (typeof dispatch === 'function') {
      //   // Example: dispatch an action to update global state with the new project
      //   dispatch({ type: 'ADD_PROJECT_SUCCESS', payload: data });
      // }
      queryClient.invalidateQueries(['awsProjects'])
      showSnackbar('Project Created successfully', 'success')
    },
    onError: error => {
      console.error('Mutation Error:', error)
      showSnackbar(error.response.data.message || error.message, 'error')
    },
  })

  return mutation
}

export const useDeleteProject = () => {
  // console.log('[MUTATION]: Update Project Called')
  const queryClient = useQueryClient()

  const { mutate, isSuccess, isError } = useMutation({
    mutationFn: async deleteProjectData => {
      console.log('PROJECT IN DELETE PROJECT MUTATION: ', deleteProjectData)
      const url = `${baseUrl}/aws/deletegroprojects`
      const response = await axios.post(url, deleteProjectData)
      return response.data
    },
    onSuccess: (data, variables, context) => {
      console.log('Deleted Project Data:', data)
      // Invalidate and refetch
      queryClient.invalidateQueries(['awsProjects'])
    },
    onError: (error, variables, context) => {
      // Handle error
      console.error('Error deleting project:', error)
    },
  })

  return {
    deleteProject: mutate,
    deleteProjectSuccess: isSuccess,
    deleteProjectError: isError,
  }
}

export const useSendCliqMessage = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    // Directly pass the object to the mutation function
    mutationFn: sendCliqMessage,
    onSuccess: data => {
      console.log('Updated Project Data:', data)
      queryClient.invalidateQueries(['awsProjects'])
    },
    onError: error => {
      console.error('[useSendCliqMessage] Error sending message:', error)
    },
  })

  return mutation
}
export const useCliqChannelMessage = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    // Directly pass the object to the mutation function
    mutationFn: sendCliqChannelMessage,
    onSuccess: data => {
      console.log('useCliqChannelMessage Data:', data)
      // queryClient.invalidateQueries(['threadMessages'])
      queryClient.invalidateQueries(['chatMessages'])
    },
    onError: error => {
      console.error('[useCliqChannelMessage] Error sending message:', error)
    },
  })

  return mutation
}
export const useCliqChannelAttachment = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    // Directly pass the object to the mutation function
    mutationFn: sendCliqChannelAttachment,
    onSuccess: data => {
      console.log('useCliqChannelAttachment Data:', data)
      // queryClient.invalidateQueries(['threadMessages'])
      queryClient.invalidateQueries(['chatMessages'])
    },
    onError: error => {
      console.error('[useCliqChannelAttachment] Error sending message:', error)
    },
  })

  return mutation
}
export const useCliqChannelComment = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    // Directly pass the object to the mutation function
    mutationFn: sendCliqChannelComment,
    onSuccess: data => {
      console.log('useCliqChannelComment Data:', data)
      queryClient.invalidateQueries(['chatMessages'])
    },
    onError: error => {
      console.error('[useCliqChannelComment] Error sending message:', error)
    },
  })

  return mutation
}

export const useDeleteCliqMessage = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    // Directly pass the object to the mutation function
    mutationFn: deleteCliqMessage,
    onSuccess: data => {
      console.log('Delete Cliq Message Data:', data)
      queryClient.invalidateQueries(['chatMessages'])
    },
    onError: error => {
      console.error('[useDeleteCliqMessage] Error deleting message::', error)
    },
  })

  return mutation
}
export const useCreateCliqChannel = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    // Directly pass the object to the mutation function
    mutationFn: createCliqChannel,
    onSuccess: data => {
      console.log('Updated Project Data:', data)
      queryClient.invalidateQueries(['awsProjects'])
    },
    onError: error => {
      console.error('Error creating cliq channel:', error)
    },
  })

  return mutation
}
