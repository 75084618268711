import React, { useState } from 'react'
import { Box, useTheme, Button, Typography, Tooltip, Chip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { deleteButtonStyles } from './style'
import ContactsPopover from './ContactsPopover'
import useUploadContactPhoto from '../../api/customHooks/useUploadContactPhoto'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'

const tooltipGridFooter = title => {
  return <Typography>{title}</Typography>
}

export const ContactsGridFooter = ({
  massUpdateData,
  selectionModel = [],
  rowCount,
  onChange,
  onUpdate,
  contacts = [],
  anchorEl,
  handleDeleteClick,
  handleConfirmDelete,
  handleClosePopover,
  selectedData,
  setSelectedData,
  setSelectionModel,
  apiRef,
}) => {
  const theme = useTheme()
  const { mutate: uploadMutation } = useUploadContactPhoto()
  const { showSnackbar } = useSnackbarContext()
  const [file, setFile] = useState(null)

  const handleFileChange = event => {
    const selectedFile = event.target.files[0]
    setFile(selectedFile)

    console.log('[handleFileChange] selectedFile:', selectedFile)
    console.log('[handleFileChange] selectedData:', selectedData)

    if (selectedFile && selectedData) {
      const formData = new FormData()
      formData.append('file', selectedFile)
      formData.append('contactId', selectedData.contacts_uuid)

      console.log('[handleFileChange] FormData:', formData.get('file'), formData.get('contactId'))

      uploadMutation(
        { contactId: selectedData.contacts_uuid, formData },
        {
          onSuccess: () => {
            console.log('Photo uploaded successfully!')
            showSnackbar('Photo uploaded successfully!', 'success')
            apiRef.current.setRowSelectionModel([])
            setSelectionModel([])
            setSelectedData(null)
          },
          onError: error => {
            console.error('Error uploading photo:', error)
            showSnackbar('Failed to upload photo. Please try again.', 'error') // Handle errors gracefully
          },
        }
      )
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'contacts-grid-footer-popover' : undefined

  return (
    <>
      {selectionModel.length > 0 ? (
        <Box
          sx={{
            maxHeight: 60,
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: theme.palette.mode === 'dark' ? 'black' : 'white',
            p: 3,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant='h8'>{'Update'}</Typography>
            <Chip label={selectionModel.length} sx={{ color: 'rgb(24, 144, 255)', fontSize: 18 }} />
            <Typography variant='h8'> {selectionModel.length === 1 ? 'Contact' : 'Contacts'}</Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {selectionModel.length === 1 && (
              <>
                <input type='file' onChange={handleFileChange} style={{ display: 'none' }} id='contact-photo-upload' />
                <label htmlFor='contact-photo-upload' style={{ cursor: 'pointer' }}>
                  <Tooltip title={tooltipGridFooter('Upload Photo')} placement='top'>
                    <Button color='primary' variant='outlined' component='span'>
                      Upload Photo
                    </Button>
                  </Tooltip>
                </label>
                <Tooltip title={tooltipGridFooter('Delete')} placement='top'>
                  <Button
                    sx={deleteButtonStyles(theme)}
                    variant='outlined'
                    onClick={handleDeleteClick}
                    color='secondary'
                  >
                    Delete
                  </Button>
                </Tooltip>
              </>
            )}
            {/* <Tooltip title={selectionModel.length === 1 ? 'Apply Update' : 'Apply Mass Update'} placement='top'> */}
            <Tooltip
              title={selectionModel.length === 1 ? 'Under Development...' : 'Under Development...'}
              placement='top'
            >
              <Box>
                <Button
                  color='secondary'
                  variant='contained'
                  onClick={onUpdate}
                  // disabled={selectionModel.length === 0}
                  disabled
                  sx={{ height: 40 }}
                >
                  {selectionModel.length === 1 ? 'Update' : 'Mass Update'}
                </Button>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            padding: 1,
            alignItems: 'center',
            minHeight: 50,
            bgcolor: theme.palette.mode === 'dark' ? 'black' : 'white',
            gap: 1,
          }}
        >
          <Typography variant='body2' mr={2}>
            Total Rows:
          </Typography>
          <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(89, 224, 158,0.8)' : 'rgba(0,0,0,0.6)' }}>
            {rowCount}
          </Typography>
          <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)' }}>
            /
          </Typography>
          <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)' }}>
            {contacts.length}
          </Typography>
        </Box>
      )}
      <ContactsPopover
        id={id}
        open={open}
        onClose={handleClosePopover}
        handleConfirmDelete={handleConfirmDelete}
        selectedData={selectedData}
      />
    </>
  )
}
