import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { baseUrl } from '../queryMutations/queryMutations'

const createOrganization = async orgData => {
  const url = `${baseUrl}/aws/organizations`
  console.log('New Organization Data: ', orgData)
  const response = await axios.post(url, orgData)
  return response.data // Assumes the response data includes the new contact with `id`
}

export const useCreateOrganization = () => {
  const { showSnackbar } = useSnackbarContext()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: createOrganization,
    onMutate: async newOrg => {
      await queryClient.cancelQueries(['organizations'])
      const previousOrganizations = queryClient.getQueryData(['organizations'])
      return { previousOrganizations }
    },
    onError: (err, newOrg, context) => {
      queryClient.setQueryData(['organizations'], context.previousOrganizations)
      console.error('Mutation Error:', err)
      showSnackbar(err.response?.data?.message || err.message, 'error')
    },
    onSuccess: data => {
      queryClient.setQueryData(['organizations'], old => {
        if (!old) {
          return { data: [{ ...data, id: data.org_uuid }], total: 1 }
        }
        if (Array.isArray(old.data)) {
          return { ...old, data: [...old.data, { ...data, id: data.org_uuid }], total: old.total + 1 }
        }
        console.error('Unexpected query data format:', old)
        return { data: [{ ...data, id: data.org_uuid }], total: 1 }
      })
      showSnackbar('Organization created successfully', 'success')
    },
    onSettled: (data, error) => {
      console.log('data: ', data, 'error: ', error)
      queryClient.invalidateQueries(['organizations'])
    },
  })

  return mutation
}

// export const useCreateOrganization = () => {
//   const { showSnackbar } = useSnackbarContext()
//   const queryClient = useQueryClient()

//   const mutation = useMutation({
//     mutationFn: createOrganization,
//     onMutate: async newOrg => {
//       await queryClient.cancelQueries(['organizations'])

//       // Snapshot the previous value
//       const previousOrganizations = queryClient.getQueryData(['organizations'])

//       // Do not optimistically update here because we don't have the new `id` yet
//       return { previousOrganizations }
//     },
//     onError: (err, newOrg, context) => {
//       queryClient.setQueryData(['organizations'], context.previousOrganizations)
//       console.error('Mutation Error:', err)
//       showSnackbar(err.response?.data?.message || err.message, 'error')
//     },
//     onSuccess: data => {
//       queryClient.setQueryData(['organizations'], old => [...old, { ...data, id: data.org_uuid }]) // Assuming the new contact is returned with `gcontacts_uuid`
//       showSnackbar('Organization created successfully', 'success')
//     },
//     onSettled: () => {
//       queryClient.invalidateQueries(['organizations'])
//     },
//   })

//   return mutation
// }
