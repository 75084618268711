import React, { useState } from 'react'
import RenderOrgTeam from './RenderOrgTeam'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { useQueryClient } from '@tanstack/react-query'
import { Box } from '@mui/material'

const TeamMembers = ({ organization }) => {
  const [isUpdating, setIsUpdating] = useState(false)
  const { mutate: updateItems } = useUpdateItems()
  const queryClient = useQueryClient()
  const handleUpdateOrganization = (role, newUsers) => {
    setIsUpdating(true) // Set updating to true as the process starts

    const newUserIds = newUsers.map((user) => user.id || user.user_uuid)
    console.log('newUserIds: ', newUserIds)

    if (!Array.isArray(organization[role])) {
      organization[role] = []
    }

    const updates = [
      {
        primaryKey: 'org_uuid',
        tableName: 'organizations',
        itemId: organization.org_uuid,
        fieldName: role,
        newValue: newUserIds,
      },
    ]

    updateItems(
      { updates: updates },
      {
        onSuccess: (response) => {
          console.log('Update successful', response)

          if (response && Array.isArray(response.updates)) {
            response.updates.forEach((update) => {
              const { fieldName, updatedAttributes } = update

              if (updatedAttributes && updatedAttributes[fieldName]) {
                console.log(
                  `Updated ${fieldName}: `,
                  updatedAttributes[fieldName]
                )
              }
            })
          } else {
            console.warn('No updates found in the response:', response)
          }

          // queryClient.invalidateQueries(['/aws/organizations', organization.org_uuid])
          setIsUpdating(false)
        },
        onError: (err) => {
          console.error('Update failed', err)
          setIsUpdating(false)
        },
        onSettled: () => {
          setIsUpdating(false)
        },
      }
    )
  }
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <RenderOrgTeam
        key={organization ? organization.org_uuid : []}
        organization={organization}
        onUpdate={handleUpdateOrganization}
        isUpdating={isUpdating}
      />
    </Box>
  )
}

export default TeamMembers
