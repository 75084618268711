export const statusFilters = [
  { label: 'Prospect', value: 'Prospect', color: 'rgb(93, 79, 251)' },
  { label: 'Onboarding', value: 'Onboarding', color: 'rgb(92, 179, 253)' },
  { label: 'In-Progress', color: 'rgb(110, 156, 222)' },
  { label: 'Active', value: 'Active', color: 'rgb(152, 214, 129)' },
  { label: 'Active (SEO)', color: 'rgb(152, 98, 161)' },
  { label: 'Active (Creative)', color: 'rgb(147, 111, 218)' },
  { label: 'Active (Website)', color: 'rgb(102, 69, 169)' },
  { label: 'Active (Past Due)', color: 'rgb(255, 131, 88)' },
  { label: 'Active (LTP)', color: 'rgb(51, 158, 205)' },
  { label: '30 day notice', value: '30 day notice', color: 'rgb(235, 77, 77)' },
  { label: 'Cancelled', value: 'Cancelled', color: 'rgb(0,0,0)' },
  { label: 'Inactive / Paused', color: 'rgb(255, 145, 145)' },
]
export const contractStatusFilters = [
  { label: 'Active', value: 'Active', color: 'rgb(75, 185, 114)' },
  // { label: 'Approved', value: 'Approved', color: 'rgb(152, 251, 152)' },
  { label: 'Completed', value: 'Completed', color: 'rgb(80, 200, 120)' },
  {
    label: 'Contract Approved',
    value: 'Contract Approved',
    color: 'rgb(255, 144, 69)',
  },
  { label: 'Created', value: 'Created', color: 'rgb(112, 224, 213)' },
  { label: 'Completed Internally', value: 'Completed Internally', color: 'rgba(22, 160, 133, 1)' },
  {
    label: 'Month to Month',
    value: 'Month to Month',
    color: 'rgb(23, 127, 217)',
  },
  {
    label: 'Needs Approval',
    value: 'Needs Approval',
    color: 'rgb(251, 193, 30)',
  },
  {
    label: 'Sent For Approval',
    value: 'Sent For Approval',
    color: 'rgb(163, 96, 205)',
  },
  { label: 'Saved', value: 'Saved', color: 'rgb(34, 121, 145)' },
  { label: 'Sent', value: 'Sent', color: 'rgb(76, 68, 198)' },
  { label: 'Set Status', value: 'Set Status', color: 'rgb(178, 190, 181)' },
  {
    label: 'Strategy Approved',
    value: 'Strategy Approved',
    color: 'rgb(6, 255, 167)',
  },
  { label: 'Viewed', value: 'Viewed', color: 'rgb(213, 102, 201)' },
]

export const dealStageFilters = [
  { label: 'Discovery', value: 'Discovery', color: 'rgb(25, 117, 255)' },
  {
    label: 'Pending Approval',
    value: 'Pending Approval',
    color: 'rgba(241, 130, 141, 1)',
  },
  { label: 'Proposal', value: 'Proposal', color: 'rgba(178, 94, 178, 1)' },
  {
    label: 'Contract Created',
    value: 'Contract Created',
    color: 'rgb(11, 127, 171, 1)',
  },
  {
    label: 'Contract Sent',
    value: 'Contract Sent',
    color: 'rgb(217, 169, 102, 1)',
  },
  { label: 'Deal Won', value: 'Deal Won', color: 'rgba(80, 200, 120, 1)' },
  { label: 'Deal Lost', value: 'Deal Lost', color: '#ffb5ac' },
  { label: 'Purgatory', value: 'Purgatory', color: '#ffdc85' },
]
