import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton } from '@mui/material'
const EditIconButton = ({ onEdit }) => {
  return (
    <IconButton onClick={onEdit} sx={{ backgroundColor: 'rgb(42, 128, 200)' }}>
      <EditIcon sx={{ height: 25, width: 25 }} />
    </IconButton>
  )
}

export default EditIconButton
