import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/queryMutations'

// export const useUpdateDeal = () => {
//   const queryClient = useQueryClient()

//   const mutation = useMutation({
//     mutationFn: async ({ dealId, dealData }) => {
//       const url = `${baseUrl}/aws/updatedeals/${dealId}`
//       const response = await axios.put(url, dealData)
//       return response.data
//     },

//     onMutate: async ({ dealId, dealData }) => {
//       await queryClient.cancelQueries(['deals', dealId])
//       const previousDeal = queryClient.getQueryData(['deals', dealId])

//       queryClient.setQueryData(['deals', dealId], old => ({
//         ...old,
//         ...dealData,
//       }))

//       return { previousDeal }
//     },

//     onError: (err, { dealId }, context) => {
//       queryClient.setQueryData(['deals', dealId], context.previousDeal)
//     },

//     onSuccess: (_, { dealId }) => {
//       queryClient.invalidateQueries(['deals', dealId])
//     },
//   })

//   return mutation
// }
export const useUpdateDeal = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async ({ dealId, dealData }) => {
      const url = `${baseUrl}/aws/updatedeals/${dealId}`
      const response = await axios.put(url, dealData)
      return response.data
    },

    onMutate: async ({ dealId, dealData }) => {
      await queryClient.cancelQueries(['/aws/deals', dealId])
      const previousDeal = queryClient.getQueryData(['/aws/deals', dealId])

      queryClient.setQueryData(['/aws/deals', dealId], old => ({
        ...old,
        ...dealData,
      }))

      return { previousDeal }
    },

    onError: (err, { dealId }, context) => {
      queryClient.setQueryData(['/aws/deals', dealId], context.previousDeal)
    },

    onSuccess: (_, { dealId }) => {
      // queryClient.invalidateQueries(['deals']) // Invalidate all deals to ensure the grid rerenders
      // queryClient.invalidateQueries(['organizations']) // Invalidate organizations if they are related to the deals grid
    },
  })

  return mutation
}
