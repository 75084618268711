import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import { useOrganizationById } from '../../api/aws/useOrganization'
import RenderCompanyChip from '../RenderChipComponents/RenderCompanyChip'

const RenderOrganizationLinkName = ({ organizationId }) => {
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(organizationId)

  if (isOrganizationError) return <div>Organization Error...</div>
  if (isOrganizationLoading) return <CircularProgress />
  const renderChip = organization => {
    // Normalize type to be an array
    const typeArray = Array.isArray(organization.type) ? organization.type : [organization.type]
    const type = typeArray[0] || 'NT' // Assuming the first element is the main type
    // console.log('TYPE: ', type)
    switch (type) {
      case 'Housing Property':
      case 'Agency Whitelabel':
      case 'Property Management Company':
      case 'Ownership Group':
      case 'Parent Client':
      case 'Student':
      case 'Housing':
      case 'Organization':
        return <RenderCompanyChip option={organization} />
      default:
        return <RenderCompanyChip option={{ ...organization, type: 'NT' }} />
    }
  }

  return <>{organization && renderChip(organization)}</>
}

export default RenderOrganizationLinkName

// import { Box, CircularProgress, Typography } from '@mui/material'
// import React from 'react'
// import { useOrganizationById } from '../../api/aws/useOrganization'
// import RenderCompanyChip from '../CustomCompanyComponents/RenderCompanyChip'

// const RenderOrganizationLinkName = ({ organizationId }) => {
//   const { data: organization, isLoading: isOrganizationLoading, isError: isOrganizationError } = useOrganizationById(
//     organizationId
//   )
//   if (isOrganizationError) return <div>Organization Error...</div>
//   if (organizationLoading) return <CircularProgress />
//   return (
//     <>
//       {organization && organization.type && organization.type === 'Housing Property' ? (
//         <>
//           <Box>{<RenderCompanyChip option={organization} />}</Box>
//         </>
//       ) : null}
//       {organization && organization.type && organization.type === 'Agency Whitelabel' ? (
//         <>
//           <Box>{<RenderCompanyChip option={organization} />}</Box>
//         </>
//       ) : null}
//       {organization && organization.type && organization.type === 'Property Management Company' ? (
//         <>
//           <Box>{<RenderCompanyChip option={organization} />}</Box>
//         </>
//       ) : null}
//       {organization && organization.type && organization.type === 'Ownership Group' ? (
//         <>
//           <Box>{<RenderCompanyChip option={organization} />}</Box>
//         </>
//       ) : null}
//       {organization && organization.type && organization.type === 'Parent Client' ? (
//         <>
//           <Box>{<RenderCompanyChip option={organization} />}</Box>
//         </>
//       ) : null}
//     </>
//   )
// }

// export default RenderOrganizationLinkName
