import React, { createContext, useState, useCallback, useContext, useReducer, useEffect } from 'react'
import { Box } from '@mui/material'
import { useFetchNotifications } from '../../api/aws/useFetchNotifications'
import { useUserProvider } from '../UsersProvider/UsersProvider'
import { useUsers } from '../../api/aws/useUsers'

const initialState = {
  groNotification: {
    userId: '',
    taskId: '',
    message: {},
    isRead: false,
  },
  groNotifications: [],
  isLoading: true,
  isError: false,
}

export const NotificationContext = createContext()

export const notificationReducer = (state, action) => {
  switch (action.type) {
    case 'SET_NOTIFICATIONS':
      return {
        ...state,
        // Assuming each project has its own individual state structure
        groNotifications: action.payload, // This will replace the entire projects array
      }
    case 'SET_USER_NOTIFICATIONS':
      return {
        ...state,
        userNotifications: action.payload,
      }
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      }
    case 'SET_ERROR':
      return {
        ...state,
        isError: true,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}

export const NotificationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(notificationReducer, initialState)
  const [notifications, setNotifications] = useState([])
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0)
  const { users, isUsersLoading, isUsersError } = useUsers()
  const { awsNotifications, awsNotificationsLoading, awsNotificationsError } = useFetchNotifications()
  const addNotification = useCallback((message, severity) => {
    setNotifications(prev => [...prev, { message, severity }])
    setUnreadNotificationCount(prev => prev + 1)
  }, [])

  const resetNotificationCount = useCallback(() => {
    setUnreadNotificationCount(0)
  }, [])

  useEffect(() => {
    if (!awsNotificationsLoading) {
      dispatch({ type: 'SET_LOADING', payload: false })
      if (awsNotifications) {
        const usersAndNotifications = awsNotifications.map(notification => {
          const slackUser = users.find(sUser => sUser.slack_users_uuid === notification.userId)
          return {
            ...notification,
            slackUserId: slackUser ? slackUser.id : undefined,
          }
        })

        dispatch({ type: 'SET_NOTIFICATIONS', payload: usersAndNotifications })
      } else if (awsNotificationsError) {
        dispatch({ type: 'SET_ERROR', payload: 'Error loading user data' })
      }
    }
  }, [awsNotifications])

  // if (state.isLoading) {
  //   return <Loading componentName={'Notifications'} />
  // }

  // if (state.isError) {
  //   return <Box>Error loading data: {state.errorMessage}</Box>
  // }
  // console.log('[NotificationProvider] state: ', state)
  const value = {
    ...state,
    dispatch,
    addNotification,
    notifications,
    unreadNotificationCount,
    resetNotificationCount,
  }

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>
}

export const useNotify = () => {
  const context = useContext(NotificationContext)
  if (!context) {
    throw new Error('useNotify must be used within a NotificationProvider')
  }
  return context
}
