import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Badge, Typography } from '@mui/material'
import { version } from '../../components/Version'

const WelcomeHeaderThemedImage = ({ srcLight, srcDark, alt }) => {
  const theme = useTheme()
  const src = theme.palette.mode === 'dark' ? srcDark : srcLight
  const versionTextColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'

  return (
    <Badge
      badgeContent={
        <>
          <Badge
            badgeContent={
              <Typography sx={{ color: versionTextColor, fontSize: 10, mr: 2, mb: 0.5 }}>{`v${version}`}</Typography>
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <Typography sx={{ fontWeight: 'bold', mr: 1, color: 'rgba(255, 148, 112, 1)' }}>BETA</Typography>
          </Badge>
          {/* <Typography sx={{ fontSize: 10 }}>{version}</Typography> */}
        </>
      }
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      overlap='rectangular'
      sx={{
        '& .MuiBadge-badge': {
          marginTop: '3px',
          zIndex: 20,
        },
      }}
    >
      <img style={{ width: 175, zIndex: 10 }} src={src} alt={alt} />
    </Badge>
  )
}

export default WelcomeHeaderThemedImage
