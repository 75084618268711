import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import Loading from '../Loading/Loading'
import { MyStyledComponent } from '../../style/styleElements'
import { useParams } from 'react-router-dom'
import { useOwnershipGroup } from '../../api/aws/useOwnershipGroup'

const OwnershipGroup = () => {
  const { ownershipGroupId } = useParams()
  console.log('Id: ', ownershipGroupId)
  const {
    data: ownershipGroup,
    isLoading: ownershipGroupLoading,
    isError: ownershipGroupError,
    error: ownershipGroupDetailsError,
  } = useOwnershipGroup(ownershipGroupId)

  if (ownershipGroupLoading) {
    return <Loading />
  }

  if (ownershipGroupDetailsError) {
    return <Typography>Error loading ownership group details: {ownershipGroupDetailsError.message}</Typography>
  }

  if (!ownershipGroup) return <Typography>ownershipGroup data is not available.</Typography>

  const detailItems = [
    { label: 'Ownership Group', value: ownershipGroup.name },
    { label: 'Industry', value: ownershipGroup.industry },
    // { label: 'Contact Name', value: organization.contactName },
    // { label: 'Contact Email', value: organization.contactEmail },
    // { label: 'Website', value: organization.website },
    // { label: 'Vertical', value: organization.vertical },
  ]

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 4 }}>
      <MyStyledComponent sx={{ p: 4, width: '100%', maxWidth: 800 }}>
        <Typography variant='h4' gutterBottom align='center'>
          Ownership Group Information
        </Typography>
        <Grid container spacing={2}>
          {detailItems.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Typography variant='subtitle1'>{item.label}</Typography>
              <Typography variant='body1'>{item.value}</Typography>
            </Grid>
          ))}
        </Grid>
      </MyStyledComponent>
    </Box>
  )
}

export default OwnershipGroup
