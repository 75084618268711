import React from 'react'
import { Box, DialogTitle, Typography, useTheme } from '@mui/material'
import { DialogIcon, boxStyles, dialogTitleStyles } from './style'
import GroboticPng from '../../images/Grobotic png.png'
import GroboticLight from '../../images/Grobotic png-lightmode.png'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

const CreateDealHeader = ({ context }) => {
  const theme = useTheme()
  return (
    <Box sx={boxStyles}>
      <DialogIcon src={theme.palette.mode === 'dark' ? GroboticPng : GroboticLight} />
      <Box sx={boxStyles}>
        <DialogTitle sx={dialogTitleStyles(theme)}>{context}</DialogTitle>
        <AttachMoneyIcon
          sx={{
            color: '#fbc11e',
            width: 35,
            height: 35,
          }}
        />
      </Box>
    </Box>
  )
}

export default CreateDealHeader
