import axios from 'axios'
import { baseUrl } from '../queryMutations/queryMutations'
import { useQuery } from '@tanstack/react-query'
import api from '../../authentication/axiosInstance'

export const getGoogleDocs = async driveId => {
  const apiUrl = `${baseUrl}/aws/googledocs${driveId ? `?driveId=${driveId}` : ''}`
  const googleToken = localStorage.getItem('google_access_token')
  try {
    const response = await api.get(apiUrl, {
      headers: { Authorization: `Bearer ${googleToken}` },
    })
    if (response.data) {
      // console.log('RESPONSE GOOGLE DOCS: ', response.data)
      return response.data
    } else {
      console.warn('No docs data found in response')
      return []
    }
  } catch (error) {
    console.error('Error fetching docs: ', error)
    return [] // Return an empty array in case of an error
  }
}

export const useGoogleDocs = (driveId, options = {}) => {
  return useQuery({
    queryKey: ['googleDocs', driveId],
    queryFn: () => getGoogleDocs(driveId),
    staleTime: Infinity, // Data is never considered stale
    refetchOnWindowFocus: false, // Do not refetch on window focus
    ...options,
  })
}

// import axios from 'axios'
// import { baseUrl } from '../queryMutations/queryMutations'
// import { useQuery } from '@tanstack/react-query'
// import { simpleFetch } from '../databaseQueries/databaseFunctions'
// import api from '../../authentication/axiosInstance'

// export const getGoogleDocs = async driveId => {
//   const apiUrl = `${baseUrl}/aws/googledocs${driveId ? `?driveId=${driveId}` : ''}`
//   const googleToken = localStorage.getItem('google_access_token')
//   console.log('googleToken: ', googleToken)
//   if (!googleToken) {
//     console.error('No authentication token available')
//     return []
//   }
//   try {
// const response = await api.get(apiUrl, {
//   headers: { Authorization: `Bearer ${googleToken}` },
// })
//     if (response.data) {
//       console.log('RESPONSE GOOGLE DOCS: ', response ? response.data : response)
//       return response.data ? response.data : []
//     } else {
//       console.warn('No docs data found in response')
//       return []
//     }
//   } catch (error) {
//     console.error('Error fetching docs: ', error)
//     return [] // Return an empty array in case of an error
//   }
// }

// export const useGoogleDocs = (driveId, options = {}) => {
//   return useQuery({
//     queryKey: ['googleDocs', driveId],
//     queryFn: () => getGoogleDocs(driveId),
//     staleTime: Infinity, // Data is never considered stale
//     refetchOnWindowFocus: false, // Do not refetch on window focus
//     ...options,
//   })
// }
