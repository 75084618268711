import React from 'react'
import { Typography, Box, Chip, TextField } from '@mui/material'
import { setStageStyle } from './DealsKanban'
import { renderTypeColor } from '../DataGrid/createColumns/renderDealsColumns'
import { styled } from '@mui/material/styles'
import { Link, useParams } from 'react-router-dom'
import { useDeal } from '../../api/aws/useDeal'
import Loading from '../Loading/Loading'

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  padding: theme.spacing(0),
}))

const formatTypeStyle = type => {
  switch (type) {
    case 'IO':
      return 'Insertion Order'
    case 'MSA':
      return 'Marketing Services Agreement'
    case 'RMSA':
      return 'Revise Marketing Services Agreement'
    case 'MSA - Housing':
      return 'Marketing Services Agreement - Housing'
    case 'RMSA - Housing':
      return 'Revised Marketing Services Agreement - Housing'
    case 'CSA':
      return 'Creative Services Agreement'
    case 'RCSA':
      return 'Revised Creative Services Agreement'
  }
}

const formatCurrency = amount => {
  const formattedAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount)

  return formattedAmount
}

const DealItemDetails = ({ deal }) => {
  // if (deal && deal.organizations) {
  //   console.log('deal.organizations.name: ', deal.organizations.name)
  // }
  return (
    <Box>
      <Link
        to={`/deals/${deal.deal_uuid}`}
        style={{ textDecoration: 'none', color: 'inherit', backgroundColor: 'transparent' }}
      >
        <Typography
          key={deal.id || deal.deal_uuid}
          sx={{
            color: setStageStyle(deal.stage),
            fontWeight: 'bold',
            '&:hover': {
              opacity: '0.8',
            },
          }}
          variant='subtitle2'
        >
          {deal.primary}
        </Typography>
      </Link>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* <Typography variant='body2' color='textSecondary'>
          {deal.secondary}
        </Typography> */}
        {deal && deal.organizations && (
          <Typography variant='body2' color='textSecondary' sx={{ fontStyle: 'italic' }}>
            {deal.organizations.name}
          </Typography>
        )}
        {deal && deal.recipients && deal.recipients.authorizedRecipient
          ? deal.recipients.authorizedRecipient.map(authRec => {
              const authRecFullName = `${authRec.first_name} ` + `${authRec.last_name}`
              return (
                <Typography variant='body2' color='textSecondary' sx={{ fontStyle: 'italic' }}>
                  {authRecFullName}
                </Typography>
              )
            })
          : null}
        {deal && deal.type && (
          <Div variant='body2' sx={{ color: renderTypeColor(deal.type) }}>
            {deal.type}
          </Div>
        )}
        {/* {deal && deal.stage && (
          <Div variant='body2' sx={{ color: setStageStyle(deal.stage) }}>
            {deal.stage}
          </Div>
        )} */}
        {deal && deal.status && (
          <Div
            sx={{ color: renderTypeColor(deal.status) }}
            // onChange={e => handleProductChange(index, column.id, e.target.value)}
            // sx={{ width: column.width }}
          >
            {deal.status || ''}
          </Div>
        )}
        {deal && deal.grossProfit && (
          <Typography variant='body2' color='textSecondary'>
            {`Gross Profit: ${formatCurrency(deal.grossProfit)}`}
          </Typography>
        )}
        {deal && deal.total && (
          <Typography variant='body2' color='textSecondary'>
            {`Contract Total: ${formatCurrency(deal.total)}`}
          </Typography>
        )}
        {/* {deal && deal.organizations && (
          <Typography variant='body2' color='textSecondary' sx={{ fontStyle: 'italic' }}>
            {displayOrganizations(deal.organizations)}
          </Typography>
        )} */}
        {deal.mediaSpend && (
          <Typography
            sx={{ color: 'lightgreen' }}
            label={`Media Spend: ${deal.mediaSpend ? deal.mediaSpend : null}`}
            variant='caption'
          ></Typography>
        )}
        {deal.contact && (
          <Typography variant='caption'>{`Contact: ${
            deal.contact && deal.contact.name ? deal.contact.name : null
          }`}</Typography>
        )}
        {deal.websiteUrl && <Typography variant='caption'>{`Website: ${deal.websiteUrl}`}</Typography>}
        {deal.contactEmail && <Typography variant='caption'>{`Contact: ${deal.contactEmail}`}</Typography>}
        {deal.contractAmount && (
          <Typography sx={{ color: 'lightgreen' }} variant='caption'>{`Contract: ${formatCurrency(
            deal.contractAmount
          )}`}</Typography>
        )}
        {deal && deal.contractDetails
          ? deal.contractDetails.map(contract => (
              <Box key={deal.id}>
                <Typography>{contract.name}</Typography>
                <Typography>{contract.type}</Typography>
              </Box>
            ))
          : null}
        {/* Add more details as required */}
      </Box>
    </Box>
  )
}

export default DealItemDetails
