import { Chip, Typography, Box, useTheme } from '@mui/material'

const RenderChip = ({ type, height, width }) => {
  const theme = useTheme()
  const themeColor = theme.palette.mode === 'dark' ? 'white' : 'black'
  let label = ''
  let color = ''

  switch (type) {
    case 'Property Management Company':
      label = 'PMC'
      color = 'rgb(93, 79, 251)'
      break
    case 'Ownership Group':
      label = 'OG'
      color = 'rgb(92, 179, 253)'
      break
    case 'Housing Property':
      label = 'HP'
      color = 'rgb(152, 214, 129)'
      break
    case 'Parent Client':
      label = 'PC'
      color = 'rgb(93, 79, 251)'
      break
    case 'Agency Whitelabel':
      label = 'AW'
      color = 'rgb(92, 179, 253)'
      break
    case 'Organization':
      label = 'ORG'
      color = 'rgb(242, 140, 40)'
      break
    case 'PMC':
      label = 'PMC'
      color = 'rgb(93, 79, 251)'
      break
    case 'OG':
      label = 'OG'
      color = 'rgb(92, 179, 253)'
      break
    case 'HP':
      label = 'HP'
      color = 'rgb(152, 214, 129)'
      break
    case 'PC':
      label = 'PC'
      color = 'rgb(93, 79, 251)'
      break
    case 'AW':
      label = 'AW'
      color = 'rgb(92, 179, 253)'
      break
    case 'ORG':
      label = 'ORG'
      color = 'rgb(242, 140, 40)'
      break
    case 'NT':
      label = 'NT'
      color = 'lightgrey'
      break
    default:
      label = 'NT'
      color = 'grey'
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Chip
        size='small'
        label={label}
        style={{
          height: height ? height : 30,
          width: width ? width : 70,
          borderColor: color,
          borderWidth: '1px',
          borderStyle: 'solid',
          color: themeColor,
          borderRadius: '4px',
          //   marginRight: '8px',
        }}
      />
    </Box>
  )
}

export default RenderChip
