import { IconButton, Badge, Drawer } from '@mui/material'
import { useNotify } from '../../context/NotificationProvider/NotificationProvider'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import UserImage from '../AvatarImageComponents/UserImage'

const BellIconWithCounter = ({ handleClick, imageUrl, data }) => {
  // console.log('BellIcon data: ', data)
  const badgeCount = data ? data.length : 0
  return (
    <IconButton color='inherit' onClick={handleClick}>
      {/* <Badge badgeContent={badgeCount} color='error'> */}
      {/* <NotificationsNoneIcon sx={{ fontSize: '30px' }} /> */}
      <UserImage imageUrl={imageUrl ? imageUrl : null} />
      {/* </Badge> */}
    </IconButton>
  )
}

export default BellIconWithCounter
