import { useGetQuery } from './useGetQuery'

export const useOrganizations = () => {
  const { data, isLoading: isOrganizationsLoading, isError: isOrganizationsError } = useGetQuery(
    '/aws/organizations',
    'organizations'
  )

  const organizations = data?.data || []

  return { organizations, isOrganizationsLoading, isOrganizationsError }
}
