import React from 'react'
import { Autocomplete, ListItem, Box, Typography, TextField, Grid, IconButton, Link } from '@mui/material'
import { StyledGridTypography, StyledGridDefaultTypography } from '../../style/styleElements'
import { format, parseISO, isValid, isToday, isTomorrow, differenceInCalendarDays } from 'date-fns'
import ContentPasteGoOutlinedIcon from '@mui/icons-material/ContentPasteGoOutlined'
import { Link as ReactLink } from 'react-router-dom'
import { slugify } from '../../helperFunctions/slugify'
import { formatDates } from '../../helperFunctions/formatDates'

export const formatDateAndTime = date => {
  if (!date) return 'No DateTime'

  const parsedDate = parseISO(date)
  if (!isValid(parsedDate)) {
    console.error('Invalid date:', date)
    return 'Invalid Date'
  }

  return format(parsedDate, 'MM/dd/yyyy, hh:mm a')
}

const getDocStatus = status => {
  switch (status) {
    case 'document.viewed':
      return <StyledGridTypography sx={{ color: 'rgb(157, 73, 171)' }}>{'Viewed'}</StyledGridTypography>
    case 'document.draft':
      return <StyledGridTypography sx={{ color: 'rgb(229, 229, 229)' }}>{'Draft'}</StyledGridTypography>
    case 'document.sent':
      return <StyledGridTypography sx={{ color: 'rgb(38, 85, 178)' }}>{'Sent'}</StyledGridTypography>
    case 'document.completed':
      return <StyledGridTypography sx={{ color: 'rgb(71, 185, 114)' }}>{'Completed'}</StyledGridTypography>
    case 'document.waiting_approval':
      return <StyledGridTypography sx={{ color: 'rgb(99, 220, 232)' }}>{'Awaiting Approval'}</StyledGridTypography>
    case 'document.voided':
      return <StyledGridTypography sx={{ color: 'rgb(255, 97, 116)' }}>{'Voided'}</StyledGridTypography>
    case 'document.approved':
      return <StyledGridTypography sx={{ color: 'rgb(34, 104, 162)' }}>{'Approved'}</StyledGridTypography>
    default:
      return status
  }
}

const DocumentList = ({ document, previewUrl, rowBackgroundColor }) => {
  return (
    // <ReactLink to={`/pandadocs/${slugify(document.name)}`} state={{ document }} style={{ textDecoration: 'none' }}>
    <Grid
      container
      sx={{
        margin: '0 auto',
        marginTop: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', // This helps space out the items evenly
        backgroundColor: rowBackgroundColor,
        borderRadius: '15px',
        padding: '10px',
        '& > .MuiGrid-item': {
          padding: '10px',
        },
        transition: 'ease-in background-color 0.2s',
      }}
    >
      <Grid item xs={12 / 8}>
        <StyledGridDefaultTypography>Name</StyledGridDefaultTypography>
        <StyledGridTypography>{document.name}</StyledGridTypography>
      </Grid>
      <Grid item xs={12 / 10}>
        <StyledGridDefaultTypography>Doc Link</StyledGridDefaultTypography>
        <StyledGridTypography>
          {/* <IconButton> */}
          {previewUrl && (
            <Link
              href={`${previewUrl}/${document.id}`}
              onClick={e => e.stopPropagation()}
              target='_blank'
              rel='noopener noreferrer'
            >
              <>
                <IconButton>
                  <ContentPasteGoOutlinedIcon style={{ fontSize: 30 }} />
                </IconButton>
              </>
            </Link>
          )}
          {!previewUrl && <p>No preview available.</p>}
          {/* </IconButton> */}
        </StyledGridTypography>
      </Grid>
      <Grid item xs={12 / 10}>
        <StyledGridDefaultTypography>Status</StyledGridDefaultTypography>
        {/* <StyledGridTypography> */}
        {getDocStatus(document.status)}
        {/* </StyledGridTypography> */}
      </Grid>
      <Grid item xs={12 / 10}>
        <StyledGridDefaultTypography>Date Created</StyledGridDefaultTypography>
        <StyledGridTypography>{formatDateAndTime(document.date_created)}</StyledGridTypography>
      </Grid>
      <Grid item xs={12 / 10}>
        <StyledGridDefaultTypography>Date Modified</StyledGridDefaultTypography>
        <StyledGridTypography>{formatDateAndTime(document.date_modified)}</StyledGridTypography>
      </Grid>
      <Grid item xs={12 / 10}>
        <StyledGridDefaultTypography>Expiration Date</StyledGridDefaultTypography>
        <StyledGridTypography>{formatDateAndTime(document.expiration_date)}</StyledGridTypography>
      </Grid>
      <Grid item xs={12 / 10}>
        <StyledGridDefaultTypography>Date Completed</StyledGridDefaultTypography>
        <StyledGridTypography>{formatDateAndTime(document.date_completed)}</StyledGridTypography>
      </Grid>
    </Grid>
    // </ReactLink>
  )
}

export default DocumentList
