export const dialogStyles = theme => ({
  width: '100%',
  maxWidth: '800px',
  // backgroundColor: theme.palette.mode === 'dark' ? '' : 'rgb(232, 246, 252)',
  backgroundColor: theme.palette.mode === 'dark' ? '' : 'rgb(255, 255, 255)',
  color: '#fff',
  minHeight: '50vh',
  maxHeight: '95vh',
  padding: '30px',
  '& .MuiDialogContent-root': {
    width: '100%',
    padding: '20px',
  },
  '& .MuiTextField-root': {
    margin: '0px 2px 3px 5px 5px',
    // flex: '1 1 45%', // Allows text fields to be 45% of the container width
    minWidth: '150px', // Ensures a minimum width for text fields
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(67, 70, 75)' : 'white',
    minWidth: '150px', // Ensures a minimum width for input base
    flex: '1 1 45%', // Allows select components to be 45% of the container width
  },
  '& .MuiSelect-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(67, 70, 75)' : 'white',
    flex: '1 1 45%', // Allows select components to be 45% of the container width
    minWidth: '150px', // Ensures a minimum width for select components
  },
})

export const dialogContentStyles = {
  width: 'auto',
  maxWidth: '700px',
  margin: '5px auto',
}

export const boxStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 1,
}
export const buttonContainerStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginTop: 1,
  marginBottom: 1,
  gap: 1,
}

export const buttonContainerFocusedStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginTop: 1,
  marginBottom: 1,
  gap: 1,
}
export const collapseStyleFocsed = {
  borderColor: 'rgb(89, 224, 158)', // Custom border color for the outline when focused
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '4px',
  mt: 2,
  mb: 1,
}
export const collapseStyle = {
  mt: 2,
  mb: 1,
}
