export const verticalsRequiringApproval = [
  'Construction',
  'Tourism',
  'Marketing Agency',
  'Manufacturing',
  'Entertainment',
  'Restaurant (Food & Beverage)',
  'City/Government',
  'Financial Institution',
  'Technology',
  'Medical',
  'Lawyer',
  'Education',
  'Senior',
  'Homebuilding',
  'Build to rent',
]
