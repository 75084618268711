import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/queryMutations'

const deleteOrganization = async orgId => {
  const url = `${baseUrl}/aws/organizations/${orgId}`
  try {
    const response = await axios.delete(url)
    return response.data
  } catch (error) {
    console.error('Error deleting organization:', error)
    throw error
  }
}

export const useDeleteOrganization = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: deleteOrganization,
    onSuccess: () => {
      queryClient.invalidateQueries('deals')
      queryClient.invalidateQueries('organizations') // Invalidate organizations to refresh updated data
      console.log('Successfully deleted organization')
    },
    onError: error => {
      console.error('[useDeleteOrganization] Error deleting organization:', error)
    },
  })
  return mutation
}
