export const getTeamTasksTabColor = label => {
  switch (label) {
    case 'Waiting on SEO':
      return 'rgb(255, 21, 138)' // Color when not selected
    case 'Waiting on MI':
      return 'rgb(128, 128, 198)' // Color when not selected
    case 'Waiting on DMS':
      return 'rgb(255, 100, 46)'
    case 'Strategy Requests':
      return 'rgb(102, 204, 255)'
    case 'Waiting on AM':
      return 'rgb(0, 134, 192)'
    case 'Stuck - Need Manager Attn':
      return 'rgb(187, 51, 84)'
    case 'Waiting on Client':
      return 'rgb(226, 68, 92)'
    case 'Done':
      return 'rgb(0, 200, 117)'
    default:
      return 'grey' // Default unselected color
  }
}
