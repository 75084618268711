import React, { useState } from 'react'
import { Autocomplete, TextField, Chip, Typography, ListItem, Box } from '@mui/material'
import { contactChipStyle } from '../ContractDialog/style'

const ContactRecipientAutocomplete = ({ recipients, value, label, onChange, error }) => {
  const [inputValue, setInputValue] = useState('')
  const [selectedUsers, setSelectedUsers] = useState(value || [])

  const handleSelectUser = (event, newValue) => {
    console.log('newValue: ', newValue)

    setSelectedUsers(newValue)
    onChange(newValue) // This will trigger the update in the parent component
  }

  const handleRemoveUser = contactId => {
    console.log('removing: ', contactId)
    const newSelectedContacts = selectedUsers.filter(
      contact => contact.id !== contactId && contact.contacts_uuid !== contactId
    )
    console.log('newSelectedContacts: ', newSelectedContacts)
    setSelectedUsers(newSelectedContacts)
    onChange(newSelectedContacts)
  }

  return (
    <Autocomplete
      multiple
      id='contact-recipients-autocomplete'
      options={recipients || []}
      getOptionLabel={option => `${option.firstName} ${option.lastName} (${option.email})`}
      value={selectedUsers}
      onChange={handleSelectUser}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id
      }}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      filterOptions={(options, { inputValue }) => {
        let filtered = options.filter(option =>
          `${option.firstName} ${option.lastName}`.toLowerCase().includes(inputValue.toLowerCase())
        )
        return filtered
      }}
      renderOption={(props, option) => {
        const firstName = option.firstName || option.first_name
        const lastName = option.lastName || option.last_name
        return (
          <Box
            {...props}
            key={option.id || option.contacts_uuid}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px',
            }}
          >
            <Typography>{`${firstName} ${lastName}`}</Typography>
            <Typography
              sx={{ fontSize: '12px', fontStyle: 'italic', marginLeft: '8px' }}
            >{`(${option.email})`}</Typography>
          </Box>
        )
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, onDelete, ...rest } = getTagProps({ index })
          const firstName = option.firstName || option.first_name
          const lastName = option.lastName || option.last_name
          return (
            <Chip
              key={key}
              label={`${firstName} ${lastName}`}
              {...rest}
              onDelete={() => handleRemoveUser(option.id || option.contacts_uuid)}
              sx={contactChipStyle}
            />
          )
        })
      }
      renderInput={params => <TextField {...params} label={label} error={error} />}
      fullWidth
    />
  )
}

export default ContactRecipientAutocomplete

// import React, { useState } from 'react'
// import { Autocomplete, TextField, Chip, Typography, ListItem, Box } from '@mui/material'
// import { contactChipStyle } from '../ContractDialog/style'

// const ContactRecipientAutocomplete = ({ recipients, label, onChange, error }) => {
//   const [inputValue, setInputValue] = useState('')
//   const [selectedUsers, setSelectedUsers] = useState([])

//   const handleSelectUser = (event, newValue) => {
//     console.log('newValue: ', newValue)

//     setSelectedUsers(newValue)
//     onChange(newValue) // This will trigger the update in the parent component
//   }

//   const handleRemoveUser = contactId => {
//     const newSelectedContacts = selectedUsers.filter(
//       contact => contact.id !== contactId || contact.contacts_uuid !== contactId
//     )
//     console.log('newSelectedContacts: ', newSelectedContacts)
//     setSelectedUsers(newSelectedContacts)
//     onChange(newSelectedContacts)
//   }

//   return (
//     <Autocomplete
//       multiple
//       id='contact-recipients-autocomplete'
//       options={recipients || []}
//       getOptionLabel={option => `${option.firstName} ${option.lastName} (${option.email})`}
//       value={selectedUsers}
//       onChange={handleSelectUser}
//       inputValue={inputValue}
//       onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
//       filterOptions={(options, { inputValue }) => {
//         let filtered = options.filter(option =>
//           `${option.firstName} ${option.lastName}`.toLowerCase().includes(inputValue.toLowerCase())
//         )
//         return filtered
//       }}
//       renderOption={(props, option) => (
//         <Box
//           {...props}
//           key={option.id || option.contacts_uuid}
//           sx={{
//             display: 'flex',
//             flexDirection: 'row',
//             justifyContent: 'space-between',
//             alignItems: 'center',
//             padding: '8px',
//           }}
//         >
//           <Typography>{`${option.firstName} ${option.lastName}`}</Typography>
//           <Typography
//             sx={{ fontSize: '12px', fontStyle: 'italic', marginLeft: '8px' }}
//           >{`(${option.email})`}</Typography>
//         </Box>
//       )}
//       renderTags={(tagValue, getTagProps) =>
//         tagValue.map((option, index) => (
//           <Chip
//             label={`${option.firstName} ${option.lastName}`}
//             {...getTagProps({ index })}
//             onDelete={() => handleRemoveUser(option.id || option.contacts_uuid)}
//             sx={contactChipStyle}
//           />
//         ))
//       }
//       renderInput={params => <TextField {...params} label={label} error={error} />}
//       fullWidth
//     />
//   )
// }

// export default ContactRecipientAutocomplete
