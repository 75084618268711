import { Box, Link } from '@mui/material'
import { slugify } from '../../../helperFunctions/slugify'
import { StyledGridTypography } from '../../../style/styleElements'
import { formatDateAndTime } from '../../PandaDoc/DocumentList'

export const renderWebhookColumns = () => [
  {
    field: 'name',
    headerName: 'Name',
    flex: 0.2,
    minWidth: 150,
  },
  {
    field: 'event',
    headerName: 'Event',
    flex: 0.1,
    minWidth: 150,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.1,
    minWidth: 100,
  },
  {
    field: 'recipient1',
    headerName: 'Recipient 1',
    flex: 0.2,
    minWidth: 150,
    renderCell: params => {
      const recipientFirstName =
        params.row.recipients && params.row.recipients[0] ? params.row.recipients[0].first_name : 'No Recipient'
      const recipientLastName =
        params.row.recipients && params.row.recipients[0] ? params.row.recipients[0].last_name : ''
      const recipientEmail =
        params.row.recipients && params.row.recipients[0] ? `(${params.row.recipients[0].email})` : ''
      const fullName = `${recipientFirstName} ${recipientLastName} ${recipientEmail}`
      return <Box>{fullName || ''}</Box>
    },
  },
  {
    field: 'recipient2',
    headerName: 'Recipient 2',
    flex: 0.2,
    minWidth: 150,
    renderCell: params => {
      const recipientFirstName =
        params.row.recipients && params.row.recipients[1] ? params.row.recipients[1].first_name : 'No Recipient'
      const recipientLastName =
        params.row.recipients && params.row.recipients[1] ? params.row.recipients[1].last_name : ''
      const recipientEmail =
        params.row.recipients && params.row.recipients[1] ? `(${params.row.recipients[1].email})` : ''
      const fullName = `${recipientFirstName} ${recipientLastName} ${recipientEmail}`
      return <Box>{fullName || ''}</Box>
    },
  },
  {
    field: 'document_id',
    headerName: 'Contract Id',
    flex: 0.1,
    minWidth: 150,
    renderCell: params => {
      return <Box>{params.value}</Box>
    },
  },
  {
    field: 'date_created',
    headerName: 'Date Created',
    flex: 0.1,
    minWidth: 100,
    renderCell: params => {
      return <Box>{formatDateAndTime(params.value)}</Box>
    },
  },
  {
    field: 'date_modified',
    headerName: 'Date Modified',
    flex: 0.1,
    minWidth: 100,
    renderCell: params => {
      return <Box>{formatDateAndTime(params.value)}</Box>
    },
  },
]
