import React, { useEffect, useState } from 'react'
import { Box, Grid, useTheme } from '@mui/material'
import { useParams } from 'react-router-dom'
import DealInfoCard from './DealInfoCard'
import { useUpdateItem } from '../../api/aws/useUpdateItem'
import RenderContract from './RenderContract'
import { gridStyles } from './style'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import useSaveDeal from '../../api/customHooks/useSaveDeal'
import DetailsLoading from '../Loading/DetailsLoading'
import { useNewContactsById } from '../../api/customHooks/useNewContactsById'
import { useOrganizations } from '../../api/aws/useOrganizations'
import { determineApprovalState } from '../DealApproval/determineApprovalState'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import { useDeal } from '../../api/aws/useDeal'

const DealsDetails = () => {
  const { dealId } = useParams()
  const { deal, isDealLoading, isDealError } = useDeal(dealId)
  const theme = useTheme()
  // Access the deal context
  const { newDeal, setNewDeal } = useDealDetailsContext()
  const [isError, setIsError] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isApproving, setIsApproving] = useState(false)
  const gridBgColor = theme.palette.mode === 'dark' ? '#1e2735' : 'rgba(255,255,255)'
  const { mutate: saveDeal } = useSaveDeal()
  const { showSnackbar } = useSnackbarContext()
  const { mutate: updateItem } = useUpdateItem()
  // Retrieve contacts associated with the deal
  const newContactIds = newDeal && newDeal.contacts && Array.isArray(newDeal.contacts) ? newDeal.contacts : []
  const newContacts = useNewContactsById(newContactIds)
  // Function to update deal data
  const handleUpdate = (fieldName, newValue) => {
    if (newDeal[fieldName] !== newValue) {
      updateItem(
        {
          primaryKey: 'deal_uuid',
          tableName: 'deals',
          itemId: dealId,
          fieldName,
          newValue,
        },
        {
          onSuccess: () => {
            console.log('Update successful')
            showSnackbar('Successfully Updated', 'success')
          },
          onError: err => {
            console.error('Update failed', err)
            showSnackbar('Update Failed', 'error')
          },
        }
      )
    }
  }
  // Save updated deal
  const handleSave = (deal, organization) => {
    const updatedApprovalState = determineApprovalState(deal, organization)

    saveDeal(
      {
        dealId: deal.deal_uuid,
        dealData: {
          ...deal,
          status: deal.noSignature ? 'Completed Internally' : updatedApprovalState.status,
          stage: deal.noSignature ? 'Deal Won' : updatedApprovalState.stage,
          needsApproval: updatedApprovalState.needsApproval,
          hasProducts: updatedApprovalState.hasProducts,
          lastModified: new Date(),
        },
      },
      {
        onSuccess: () => {
          setIsEditMode(false)
          setIsOpen(false)
        },
        onError: () => {
          setIsEditMode(false)
          setIsOpen(false)
          setIsError(true)
        },
      }
    )
  }

  const handleEdit = () => {
    setIsEditMode(true)
    setIsOpen(true)
  }

  const handleCloseEditMode = () => {
    setIsEditMode(false)
    setIsOpen(false)
  }
  if (isDealLoading) {
    return <DetailsLoading />
  }
  return (
    <>
      <Grid container spacing={2} justifyContent='center' sx={gridStyles(theme)}>
        {/* {detailsLoading ? (
          <DetailsLoading />
        ) : ( */}
        <>
          {!isEditMode ? (
            <>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <DealInfoCard
                  key={dealId}
                  deal={deal} // Use the deal from the context
                  onEdit={handleEdit}
                  newContacts={newContacts}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        padding: 2,
                        backgroundColor: gridBgColor,
                        height: 200,
                        borderRadius: '25px',
                        width: '100%',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        padding: 2,
                        backgroundColor: gridBgColor,
                        height: 130,
                        borderRadius: '25px',
                        width: '100%',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Box
                sx={{
                  width: '100%',
                  backgroundColor: gridBgColor,
                  padding: 4,
                  borderRadius: '25px',
                  mt: 2,
                }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid item xs={12} sm={12}>
                    <RenderContract
                      dealId={dealId}
                      onSaveDeal={handleSave}
                      onClose={handleCloseEditMode}
                      open={isOpen}
                      onUpdate={handleUpdate}
                      isEditMode={isEditMode}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </>
        {/* )} */}
      </Grid>
    </>
  )
}

export default DealsDetails
