import React, { createContext, useContext, useState } from 'react'

const ValidationContext = createContext()

export const ValidationProvider = ({ children }) => {
  const [errors, setErrors] = useState({})
  const hasErrors = Object.values(errors).some(error => error)
  const errorStyle = {
    borderColor: 'red',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'red',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  }
  const isValidWebsite = url => {
    // console.log('Validating: ', url)
    const pattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]{1,63}\.[a-zA-Z]{2,6})(\/.*)?$/
    // console.log('isValidUrl? : ', pattern.test(url))
    return pattern.test(url)
  }
  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case 'name':
        return value ? '' : 'Name is required'
      case 'email':
        return /\S+@\S+\.\S+/.test(value) ? '' : 'Email is invalid'
      case 'website': {
        if (!value) return ''
        const urlPattern = new RegExp(
          '^(https?:\\/\\/)?' + // protocol
          "((([a-zA-Z0-9$_.+!*'(),;:&=~-]+)@)?" + // authentication
          '(([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}|localhost|' + // domain name and extension
          '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|\\[?[a-fA-F0-9:.]+\\]?))' + // OR ip (v4 & v6)
          '(\\:\\d+)?' + // port
          '(\\/[-a-zA-Z0-9%_.~+]*)*' + // path
          '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' + // query string
            '(\\#[-a-zA-Z0-9_]*)?$'
        )
        return urlPattern.test(value) ? '' : 'Please enter a valid URL'
      }
      // Add more validations as needed
      case 'vertical':
        return value ? '' : 'Vertical is required'
      default:
        return ''
    }
  }

  return (
    <ValidationContext.Provider value={{ validateField, isValidWebsite, errors, setErrors, hasErrors, errorStyle }}>
      {children}
    </ValidationContext.Provider>
  )
}

export const useValidationContext = () => useContext(ValidationContext)
