import { IconButton, Badge, Drawer, Tooltip } from '@mui/material'
import PublicIcon from '@mui/icons-material/Public'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'

const ValidateDiscoveryIcon = () => {
  return (
    <Tooltip title='Discovery Validation Check'>
      <IconButton>
        <Badge badgeContent={<QuestionMarkIcon sx={{ fontSize: '18px', color: 'rgba(232, 232, 232, 1)' }} />}>
          <PublicIcon sx={{ fontSize: '30px', color: 'rgba(25, 117, 255, 0.5)' }} />
        </Badge>
      </IconButton>
    </Tooltip>
  )
}

export default ValidateDiscoveryIcon
