import React from 'react'
import { Box } from '@mui/material'

const UserImageComponent = ({ imageUrl, width, height }) => {
  const userImageStyles = {
    width: width ? width : '50px', // Set a fixed width
    height: height ? height : '50px', // Set a fixed height
    // borderRadius: '50%', // Make the image circular
    objectFit: 'cover', // Ensure the image covers the box while maintaining aspect ratio
    // padding: 0.3,
  }
  return (
    <Box
      component='img'
      src={imageUrl}
      alt='User'
      sx={{
        width: width ? width : '40px', // Set a fixed width
        height: height ? height : '40px', // Set a fixed height
        // ...userImageStyles,
        objectFit: 'cover',
        borderRadius: '50%',
      }}
    />
  )
}

export default UserImageComponent

// import React from 'react'
// import { Avatar } from '@mui/material'
// import { styled } from '@mui/material/styles'
// import { Box } from '@mui/material'

// const UserImageComponent = ({ imageUrl, style }) => {
//   console.log('STYLE: ', style)
//   const CustomAvatar = styled(Avatar)({
//     width: style ? style.width : '40px',
//     height: style ? style.height : '40px',
//     flex: style ? style.flex : '0',
//     backgroundColor: 'transparent',
//   })

//   // Placeholder image URL
//   const placeholderImageUrl = '../images/gro-logo.png' // Replace with your placeholder image path

//   // Function to check if the image URL is valid
//   const isValidImageUrl = url => {
//     return url && url.startsWith('https://')
//   }

//   // Determine the image URL to use
//   const imageSrc = isValidImageUrl(imageUrl) ? imageUrl : placeholderImageUrl

//   return (
//     <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//       <CustomAvatar src={imageSrc} alt='User' />
//     </Box>
//   )
// }

// export default UserImageComponent
