import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions'

const deleteMutation = async params => {
  const { endpoint, table } = params
  console.log('endpoint: ', endpoint, 'table: ', table)
  console.log(baseUrl, '/', endpoint)
  try {
    const { data } = await axios.delete(`${baseUrl}${endpoint}`)
    console.log('DATA FROM DELETE: ', data)
    return data.message // Ensure this is a string
  } catch (error) {
    console.error(error)
    throw new Error(`Error deleting ${table}: ${error.response?.data?.message || error.message}`)
  }
}

export const useDeleteMutation = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: deleteMutation,
    onSuccess: (message, variables) => {
      // console.log('VARIABLES: ', message, variables)
      // const { table } = variables
      // queryClient.invalidateQueries([table]) // Invalidate queries for the deleted item
      // console.log(`Successfully deleted item from ${table}`)
      return message // Ensure this is a string
    },
    onError: error => {
      console.error('Error deleting item:', error)
      return error.message // Ensure this is a string
    },
  })
  return mutation
}
