import React from 'react'
import { useWebsocketContext } from './WebsocketProvider'
import { TextField, Button } from '@mui/material'
const SendBroadcast = () => {
  const { customMessage, setCustomMessage, broadcastAnnouncement } =
    useWebsocketContext()
  return (
    <>
      <TextField
        label="Custom Message"
        variant="outlined"
        value={customMessage}
        onChange={(e) => setCustomMessage(e.target.value, 'broadcast')}
        fullWidth
        margin="normal"
      />
      <Button
        onClick={broadcastAnnouncement}
        variant="contained"
        color="primary"
      >
        Broadcast Grobotic Message
      </Button>
    </>
  )
}

export default SendBroadcast

/*

ok now i am trying to finalize a few things.  Inside my organizations details page when clicking into an organization, you can edit most things but I need to add the functionality of being able to change the contacts attached to an organization.

*/
