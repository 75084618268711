import React from 'react'
import { Avatar, Box, Tooltip, IconButton, Chip, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { Link } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person'
import GroupsIcon from '@mui/icons-material/Groups'
import { GridStatusChip } from '../../../style/styleElements'
import RenderVertical from '../../Organizations/RenderVertical'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import RenderOrganizationChip from '../../RenderChipComponents/RenderOrganizationChip'
import RenderCompanyChip from '../../RenderChipComponents/RenderCompanyChip'
import { formatDayjsDate } from './renderDealsColumns'
import RenderChip from '../../RenderChipComponents/RenderChip'
import DiscoveryProgress from '../../Icons/DiscoveryProgress'
import { userImageStyles, getDensityStyles } from '../style'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import UserImageComponent from '../../Users/UserImageComponent'
import RenderGridTeamMembers from '../../TeamMembers/RenderGridTeamMembers'
import { getGridStringOperators } from '@mui/x-data-grid-pro'

export const getStatusColor = status => {
  switch (status) {
    case 'Prospect':
      return '#b25eb2'
    case 'Onboarding':
      return 'rgb(92, 179, 253)'
    case 'In-Progress':
      return 'rgb(110, 156, 222)'
    case 'Active':
      return 'rgb(80, 200, 120)'
    case 'Active (SEO)':
      return 'rgb(152, 98, 161)'
    case 'Active (Creative)':
      return 'rgb(147, 111, 218)'
    case 'Active (Website)':
      return 'rgb(102, 69, 169)'
    case 'Active (Past Due)':
      return 'rgb(255, 131, 88)'
    case 'Active (LTP)':
      return 'rgb(51, 158, 205)'
    case 'Active - 30 Day Notice':
      return 'rgb(255, 123, 208)'
    case 'Inactive / Paused':
      return 'rgb(255, 145, 145)'
    case '30 day notice':
      return 'rgb(235, 77, 77)'
    case 'Cancelled':
      return 'rgb(0,0,0)'
    case 'Student':
      return 'rgb(255, 214, 10)'
    case 'Senior':
      return 'rgb(210, 180, 140)'
    case 'MF':
      return 'rgb(212, 113, 91)'
    case 'Multifamily':
      return 'rgb(212, 113, 91)'
    case 'Homebuilding':
      return 'rgb(212, 113, 91)'
    case 'No Vertical Assigned':
      return '#899499'
    case 'Education':
      return '#899499'
    case 'Medical':
      return '#899499'
    case 'Financial Institution':
      return '#899499'
    case 'Lawyer':
      return '#899499'
    case 'No Vertical Assigned':
      return '#899499'
    case 'Housing Property':
      return '#899499'
    case 'Property Management Company':
      return '#899499'
    case 'Ownership Group':
      return '#899499'
    default:
      return '#899499'
  }
}
const filterTypeNames = type => {
  switch (type) {
    case 'Property Management Company':
      return 'PMC'
    case 'Ownership Group':
      return 'OG'
    case 'Housing Property':
      return 'HP'
    case 'Parent Client':
      return 'PC'
    case 'Agency Whitelabel':
      return 'AW'
    case 'Organization':
      return 'ORG'
  }
}

export const formatWebsiteUrl = url => {
  return url
    ? url
        .replace(/^https?:\/\//, '')
        .replace(/^www\./, '')
        .replace(/\/$/, '')
    : url
}

const analyzeDiscovery = params => {
  const hasValue = []
  if (params.website) hasValue.push('Website')
  if (params.street) hasValue.push('Street')
  if (params.city) hasValue.push('City')
  if (params.state) hasValue.push('State')
  if (params.zip) hasValue.push('Zip')
  if (params.cmsUsed) hasValue.push('CMS Used')
  if (params.socialProfiles) hasValue.push('Active Social Profiles')
  if (params.marketingObjective) hasValue.push('Marketing Objective')
  if (params.geoTargeting) hasValue.push('Geographic Targeting')
  const decimalProgress = hasValue.length / 9
  const percentage = parseFloat((decimalProgress * 100).toFixed(0)) // Convert to integer percentage and ensure it's a number
  const fieldsCompleted = hasValue.length
  return { progress: percentage, completed: fieldsCompleted }
}

export const renderOrganizationColumns = (organizations = [], contacts = [], users, density = 'standard') => {
  const containsOperator = getGridStringOperators().find(op => op.value === 'contains')

  // Custom operator that wraps the 'contains' logic to invert it
  const customNotContainsOperator = {
    ...containsOperator,
    label: 'does not contain',
    value: 'notContains',
    getApplyFilterFn: filterItem => {
      const containsFn = containsOperator.getApplyFilterFn(filterItem)
      if (!containsFn) {
        return null
      }
      return cellValue => !containsFn(cellValue)
    },
  }
  const densityStyles = getDensityStyles(density)
  const stringAvatar = name => {
    const nameParts = name.split(' ')
    if (nameParts.length < 2) {
      return {
        children: (
          <Typography
            sx={{
              fontSize: densityStyles.fontSize,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            {nameParts[0][0]}
          </Typography>
        ),
      }
    }
    return {
      children: (
        <Typography
          sx={{
            fontSize: densityStyles.fontSize,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {`${nameParts[0][0]}${nameParts[1][0]}`}
        </Typography>
      ),
    }
  }
  const combineTeamMemberNames = row => {
    if (!row) return ''
    const { AM, DMS, PM, SEO, BD } = row
    const combinedIds = [...(AM || []), ...(DMS || []), ...(PM || []), ...(SEO || []), ...(BD || [])]
    const combinedNames = combinedIds
      .map(id => users.find(user => user.id === id)?.name)
      .filter(name => name)
      .join(', ')
    return combinedNames || 'No Team Members'
  }
  return [
    {
      field: 'org_uuid',
      headerName: 'Id',
      flex: 0.2,
      minWidth: 100,
      hide: true,
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return <Box>{params && params.value ? params.value : ''}</Box>
      },
    },
    {
      field: 'name',
      headerName: 'Organization Name',
      flex: 0.5,
      minWidth: 150,
      headerAlign: 'left',
      valueGetter: params => params, // Use params directly
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const orgId = params.id || params.row.id || params.row.org_uuid
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            <Link to={`/organizations/${orgId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'left',
                  height: '100%',
                  width: '100%',
                  fontWeight: 'bold',
                }}
              >
                {params.row.photoUrl ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'left',
                      alignItems: 'center',
                      height: '100%',
                      gap: densityStyles.gap,
                    }}
                  >
                    <Avatar
                      src={params.row.photoUrl}
                      sx={{
                        width: densityStyles.avatarSize,
                        height: densityStyles.avatarSize,
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                      alt={params.value}
                    />

                    <Typography
                      sx={{
                        fontSize: densityStyles.fontSize,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      {params.value}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'left',
                      alignItems: 'center',
                      height: '100%',
                      gap: densityStyles.gap,
                    }}
                  >
                    <Avatar
                      {...stringAvatar(params.value)}
                      sx={{
                        backgroundColor: 'rgb(114, 229, 218)',
                        width: densityStyles.avatarSize,
                        height: densityStyles.avatarSize,
                      }}
                      alt={params.value}
                    />
                    <Typography
                      sx={{
                        fontSize: densityStyles.fontSize,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      {params.value}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Link>
          </Box>
        )
      },
    },
    {
      field: 'id',
      headerName: 'Discovery',
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      valueGetter: params => {
        const organization = organizations.find(org => org.id === params || org.org_uuid === params)
        if (!organization) return 0 // Return 0 if organization is not found
        const discoveryFields = [
          organization.website,
          organization.street,
          organization.city,
          organization.state,
          organization.zip,
          organization.cmsUsed,
          organization.socialProfiles,
          organization.marketingObjective,
          organization.geoTargeting,
        ]
        const filledFieldsCount = discoveryFields.filter(field => !!field).length
        return filledFieldsCount
      },
      renderCell: params => {
        const orgId = params.id || params.row.id || params.row.org_uuid
        const { progress, completed } = analyzeDiscovery(params.row)
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <Link to={`/organizations/${orgId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <DiscoveryProgress progress={progress} completed={completed} size={densityStyles.globeSize} />
            </Link>
          </Box>
        )
      },
    },
    {
      field: 'website',
      headerName: 'Website',
      flex: 0.2,
      headerAlign: 'center',
      valueGetter: params => params,
      renderCell: params => {
        // Function to remove 'https://', 'http://', and 'www.' for display
        const formatWebsite = url => {
          return url
            .replace(/^https?:\/\//, '')
            .replace(/^www\./, '')
            .replace(/\/$/, '')
        }

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {params && params.value ? (
              <a
                href={params.value.startsWith('http') ? params.value : `https://${params.value}`}
                onClick={e => e.stopPropagation()}
                target='_blank'
                rel='noopener noreferrer'
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'left', gap: 1 }}>
                  <Typography sx={{ fontSize: densityStyles.fontSize }}>{formatWebsite(params.value)}</Typography>
                </Box>
              </a>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'left', gap: 1 }}>
                <Typography
                  sx={{
                    fontSize: densityStyles.fontSize,
                    color: 'rgb(84, 81, 81)',
                  }}
                >
                  {'No Website'}
                </Typography>
              </Box>
            )}
          </Box>
        )
      },
    },

    {
      field: 'status',
      headerName: 'Status',
      flex: 0.2,
      headerAlign: 'center',
      valueGetter: params => params,
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const type = params && params.row && params.row.type ? params.row.type : null
        const parentTypes = ['Housing Property', 'Organization']
        return (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                alignItems: 'center',
                height: '100%',
                gap: densityStyles.gap,
              }}
            >
              <GridStatusChip
                size='small'
                style={{
                  color: params.value ? getStatusColor(params.value) : '#899499',
                  fontWeight: 'bold',
                }}
                // label={params.value || params.row.type || 'No Status'}
                label={params.value || 'Parent Client'}
              />
            </Box>
          </>
        )
      },
    },
    {
      field: 'contractedBy',
      headerName: 'Contracted By',
      flex: 0.3,
      headerAlign: 'left',
      valueGetter: params => {
        // console.log('VG[contractedBy] params:', params)
        const contractedById = params && Array.isArray(params) ? params[0] : null
        const contractedBy = organizations.find(p => p.org_uuid === contractedById)
        return contractedBy ? contractedBy.name : null
        // return contractedBy ? contractedBy.name : 'N/A'
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const contractedById = params.row.contractedBy?.[0]
        const contractedBy = organizations.find(p => p.org_uuid === contractedById)
        return contractedById && contractedBy ? (
          <Link to={`/organizations/${contractedById}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                alignItems: 'center',
                height: '100%',
                padding: densityStyles.padding,
                gap: densityStyles.gap,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'left', gap: 1 }}>
                <Typography sx={{ fontSize: densityStyles.fontSize }}>{contractedBy.name}</Typography>
              </Box>
            </Box>
          </Link>
        ) : null
      },
    },
    {
      field: 'affiliated',
      headerName: 'Affiliated',
      flex: 0.3,
      headerAlign: 'left',
      valueGetter: params => {
        const affiliatedIds = params // Use params directly
        if (!Array.isArray(affiliatedIds)) return null
        // if (!Array.isArray(affiliatedIds)) return 'N/A'
        const affiliatedNames = affiliatedIds
          .map(id => organizations.find(org => org.org_uuid === id)?.name)
          .filter(name => name) // Remove undefined names
          .join(', ')
        return affiliatedNames || null
        // return affiliatedNames || 'N/A'
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const affiliatedIds = params.row.affiliated || []
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {affiliatedIds.map(id => {
              const affiliated = organizations.find(p => p.org_uuid === id)
              return (
                <Link key={id} to={`/organizations/${id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Tooltip title={affiliated?.name || 'No name available'}>
                    <Box sx={{ display: 'flex', alignItems: 'left', gap: 1 }}>
                      <RenderCompanyChip option={affiliated} />
                    </Box>
                  </Tooltip>
                </Link>
              )
            })}
          </Box>
        )
      },
    },
    {
      field: 'owner',
      headerName: 'Owner',
      flex: 0.1,
      minWidth: 100,
      headerAlign: 'center',
      valueGetter: params => {
        const userId = params && params[0] ? params[0] : null
        const user = users.find(u => u.id === userId)
        return user && user.name ? user.name : 'No User Assigned'
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const userId = params && params.row && params.row.owner ? params.row.owner[0] : null
        const user = users.find(u => u.id === userId)
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {user ? (
              <Avatar
                alt={user.real_name}
                src={user.image_512}
                sx={{
                  height: densityStyles.avatarSize,
                  width: densityStyles.avatarSize,
                }}
              />
            ) : (
              <Box
                sx={{
                  width: densityStyles.avatarSize,
                  height: densityStyles.avatarSize,
                  borderRadius: '50%',
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontSize: densityStyles.fontSize,
                }}
              >
                <Typography sx={{ fontSize: densityStyles.fontSize }}>?</Typography>
              </Box>
            )}
          </Box>
        )
      },
    },
    {
      field: 'primaryContact',
      headerName: 'Primary Contact',
      flex: 0.3,
      headerAlign: 'left',
      valueGetter: params => {
        const primaryContact = params?.[0]
        const primaryContactName = primaryContact
          ? contacts.find(contact => contact.contacts_uuid === primaryContact)?.name
          : ''
        return primaryContactName
      },
      renderCell: params => {
        const primaryContactId =
          params && params.row && Array.isArray(params.row.primaryContact) ? params.row.primaryContact[0] : []
        const primaryContactName = params.value

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {primaryContactId && primaryContactName ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 3,
                }}
              >
                <Tooltip title={`Contact Details`}>
                  <Box sx={{ display: 'flex' }}>
                    <PermIdentityIcon sx={{ color: 'rgb(255, 123, 187)' }} />
                  </Box>
                </Tooltip>

                <Link to={`/contacts/${primaryContactId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Typography sx={{ fontSize: densityStyles.fontSize }}>{primaryContactName}</Typography>
                </Link>
              </Box>
            ) : (
              <Box sx={{ display: 'flex' }}>
                <PermIdentityIcon sx={{ color: 'rgba(255, 123, 187, 0.3)' }} />
              </Box>
            )}
          </Box>
        )
      },
      sortComparator: (a, b) => {
        if (!a && !b) return 0 // Both values are empty
        if (!a) return 1 // Treat empty as larger
        if (!b) return -1 // Treat empty as larger
        return a.localeCompare(b) // Standard string comparison for non-empty values
      },
    },

    {
      field: 'contacts',
      headerName: '',
      flex: 0.13,
      headerAlign: 'center',
      valueGetter: params => {
        const contactUUIDs = Array.isArray(params) ? params : []
        const filteredContacts = contacts.filter(contact => contactUUIDs.includes(contact.contacts_uuid))
        return filteredContacts.map(contact => contact.name).join(', ') || 'No Contacts'
      },
      renderCell: params => {
        const contactUUIDs = Array.isArray(params.row.contacts) ? params.row.contacts : []
        const filteredContacts = contacts.filter(contact => contactUUIDs.includes(contact.contacts_uuid))
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            {filteredContacts && filteredContacts.length > 0 && (
              <Tooltip
                title={
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {filteredContacts.map(contact => (
                      <Link
                        key={contact.contacts_uuid}
                        to={`/contacts/${contact.id || contact.contacts_uuid}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        <Chip
                          label={contact.name}
                          variant='outlined'
                          sx={{
                            margin: '5px',
                            width: '150px',
                            minWidth: '150px',
                          }}
                        />
                      </Link>
                    ))}
                  </Box>
                }
                placement='top'
                arrow
                PopperProps={{
                  modifiers: [{ name: 'preventOverflow', options: { padding: 8 } }],
                }}
                sx={{
                  '& .MuiTooltip-tooltip': {
                    maxWidth: 'none',
                    minWidth: '200px',
                    backgroundColor: 'rgba(97,97,97,0.9)',
                  },
                  '& .MuiTooltip-arrow': { color: 'rgba(97,97,97,0.9)' },
                }}
              >
                <IconButton size='small'>
                  <GroupsIcon sx={{ height: 30, width: 30 }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )
      },
    },
    {
      field: 'vertical',
      headerName: 'Vertical',
      flex: 0.28,
      headerAlign: 'center',
      sortable: false,
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const verticals = Array.isArray(params.row.vertical) ? params.row.vertical : [params.row.vertical]
        const firstVertical = verticals.length > 0 ? verticals[0] : 'No Vertical Assigned'

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              // padding: densityStyles.padding,
              // gap: densityStyles.gap,
            }}
          >
            <RenderVertical
              vertical={firstVertical}
              width={densityStyles.verticalWidth}
              height={densityStyles.verticalHeight}
              size={densityStyles.verticalSize}
            />
          </Box>
        )
      },
    },
    {
      field: 'dealIds',
      headerName: 'Deals',
      flex: 0.28,
      headerAlign: 'center',
      valueGetter: params => {
        return params && Array.isArray(params) ? params.length : []
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              // padding: densityStyles.padding,
              // gap: densityStyles.gap,
            }}
          >
            {params.value}
          </Box>
        )
      },
    },

    {
      field: 'type',
      headerName: 'Type',
      flex: 0.2,
      headerAlign: 'center',
      valueGetter: params => {
        return filterTypeNames(params)
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: densityStyles.padding,
            gap: densityStyles.gap,
          }}
        >
          <RenderChip
            type={Array.isArray(params.value) ? params.value[0] : params.value}
            height={densityStyles.chipHeight}
            width={densityStyles.chipWidth}
          />
          {/* {Array.isArray(params.value) ? params.value[0] : params.value} */}
        </Box>
      ),
    },

    {
      field: 'street',
      headerName: 'Street',
      flex: 0.2,
      minWidth: 100,
      hide: true,
      valueGetter: params => {
        return params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        return <Box>{params && params.value ? params.value : 'No Street Assigned'}</Box>
      },
    },
    {
      field: 'city',
      headerName: 'City',
      flex: 0.1,
      minWidth: 150,
      hide: true,
      valueGetter: params => {
        return params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        return <Box>{params && params.value ? params.value : 'No City Assigned'}</Box>
      },
    },
    {
      field: 'state',
      headerName: 'State',
      flex: 0.1,
      minWidth: 50,
      hide: true,
      valueGetter: params => {
        return params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        return <Box>{params && params.value ? params.value : 'No State Assigned'}</Box>
      },
    },
    {
      field: 'cmsUsed',
      headerName: 'CMS',
      flex: 0.1,
      minWidth: 50,
      hide: true,
      valueGetter: params => {
        return params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        return <Box>{params && params.value ? params.value : 'No CMS Assigned'}</Box>
      },
    },
    {
      field: 'social',
      headerName: 'Social',
      flex: 0.5,
      minWidth: 50,
      hide: true,
      valueGetter: params => {
        return params && params.length > 0 ? params.map(params => params.network).join(',') : []
      },
      renderCell: params => {
        return (
          <>
            <Box>{params.value}</Box>
            {/* {params && Array.isArray(params.value)
              ? params.value.map(social => {
                  return <Box>{social.network}</Box>
                })
              : null} */}
          </>
        )
      },
    },
    {
      field: 'zip',
      headerName: 'Zip',
      flex: 0.1,
      minWidth: 50,
      hide: true,
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return <Box>{params && params.value ? params.value : 'No Zip Assigned'}</Box>
      },
    },
    {
      field: 'source',
      headerName: 'Source',
      flex: 0.1,
      minWidth: 50,
      hide: true,
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return <Box>{params && params.value ? params.value : 'No Source Assigned'}</Box>
      },
    },
    {
      field: 'team',
      headerName: 'Team',
      flex: 0.4,
      minWidth: 100,
      headerAlign: 'center',
      sortable: false,
      valueGetter: params => {},
      renderCell: params => {
        const organization = params && params.row ? params.row : null
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            <RenderGridTeamMembers organization={organization} densityStyles={densityStyles} />
          </Box>
        )
      },
    },
    {
      field: 'creation_timestamp',
      headerName: 'Created Time',
      flex: 0.3,
      headerAlign: 'center',
      valueGetter: params => (Array.isArray(params) ? params[0] : params),
      renderCell: params => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'center',
            height: '100%',
            padding: densityStyles.padding,
            gap: densityStyles.gap,
          }}
        >
          {Array.isArray(params.value) ? formatDayjsDate(params.value[0]) : formatDayjsDate(params.value)}
        </Box>
      ),
    },
  ]
}
