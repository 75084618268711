// import React from 'react'
// import { Box, LinearProgress, Chip } from '@mui/material'
// import { Draggable } from 'react-beautiful-dnd'
// import { styled } from '@mui/material/styles'
// import ListItem from '@mui/material/ListItem'
// import ListItemAvatar from '@mui/material/ListItemAvatar'
// import ListItemText from '@mui/material/ListItemText'
// import Avatar from '@mui/material/Avatar'
// import InboxIcon from '@mui/icons-material/Inbox'
// import PersonSearchIcon from '@mui/icons-material/PersonSearch'
// import SearchIcon from '@mui/icons-material/Search'
// import DescriptionIcon from '@mui/icons-material/Description'
// import CheckCircleIcon from '@mui/icons-material/CheckCircle'
// import CancelIcon from '@mui/icons-material/Cancel'
// import AcUnitIcon from '@mui/icons-material/AcUnit'
// import HelpIcon from '@mui/icons-material/Help'
// import DealItemDetails from './DealItemDetails'

// const StyledListItem = styled(ListItem)(({ theme, isDragging }) => ({
//   backgroundColor: isDragging
//     ? theme.palette.mode === 'dark'
//       ? 'rgba(0,0,0,0.4)'
//       : 'rgba(255,255,255,0.4)'
//     : theme.palette.mode === 'dark'
//     ? '#1a1616'
//     : 'white',

//   color: theme.palette.mode === 'dark' ? 'white' : 'black',
//   margin: '5px',
//   width: 'calc(100% - 10px)', // Adjust width to accommodate margin
//   borderRadius: '5px',
//   transition: 'background-color 0.2s ease', // Smooth transition for background color change
// }))
// const DraggableListItem = ({ item, index }) => {
//   const getIcon = stage => {
//     switch (stage) {
//       case 'Prospect':
//         return <PersonSearchIcon />
//       case 'Discovery':
//         return <SearchIcon />
//       case 'Proposal':
//         return <DescriptionIcon />
//       case 'Deal Won':
//         return <CheckCircleIcon />
//       case 'Deal Lost':
//         return <CancelIcon />
//       case 'Cold':
//         return <AcUnitIcon />
//       case 'Purgatory':
//         return <HelpIcon />
//       default:
//         return <InboxIcon />
//     }
//   }

//   return (
//     <Draggable draggableId={item.id} index={index}>
//       {(provided, snapshot) => (
//         <StyledListItem
//           ref={provided.innerRef}
//           {...provided.draggableProps}
//           {...provided.dragHandleProps}
//           isDragging={snapshot.isDragging}
//         >
//           <ListItemAvatar>
//             <Avatar>{getIcon(item.stage)}</Avatar>
//           </ListItemAvatar>
//           {/* <ListItemText primary={item.primary} secondary={`${item.secondary} - ${item.details}`} /> */}
//           <DealItemDetails deal={item} />
//         </StyledListItem>
//       )}
//     </Draggable>
//   )
// }

// export default DraggableListItem
import React from 'react'
import { Box, LinearProgress, Chip } from '@mui/material'
import { Draggable } from 'react-beautiful-dnd'
import { styled } from '@mui/material/styles'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import InboxIcon from '@mui/icons-material/Inbox'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import SearchIcon from '@mui/icons-material/Search'
import DescriptionIcon from '@mui/icons-material/Description'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import HelpIcon from '@mui/icons-material/Help'
import DealItemDetails from './DealItemDetails'

const StyledListItem = styled(ListItem)(({ theme, isDragging }) => ({
  backgroundColor:
    isDragging && theme.palette.mode === 'dark'
      ? 'rgba(0,0,0,0.4)'
      : isDragging && theme.palette.mode === 'light'
      ? 'rgba(255,255,255,0.4)'
      : theme.palette.mode === 'dark'
      ? 'rgb(26, 22, 22)'
      : 'rgb(255,255,255)',
  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'black',
  margin: '5px',
  width: 'calc(100% - 10px)', // Adjust width to accommodate margin
  borderRadius: '5px',
  transition: 'background-color 0.2s ease', // Smooth transition for background color change
}))

const DraggableListItem = ({ item, index }) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <StyledListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
        >
          <ListItemAvatar>
            <Avatar>{/* Customize your icon based on the stage or other attributes */}</Avatar>
          </ListItemAvatar>
          <DealItemDetails deal={item} />
        </StyledListItem>
      )}
    </Draggable>
  )
}

export default DraggableListItem
