import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../queryMutations/mutationFunctions'
import axios from 'axios'

const getQueryById = async (endpoint, tableName, id) => {
  try {
    const url = id ? `${baseUrl}${endpoint}/${id}` : `${baseUrl}${endpoint}`
    const { data } = await axios.get(url, {
      params: id ? {} : { table: tableName }, // Params are only needed for fetching all items
    })
    return data || []
  } catch (error) {
    console.error('Error fetching data:', error)
    return [] // Return an empty array in case of an error
  }
}

export const useGetQueryById = (endpoint, tableName, id = null) => {
  return useQuery({
    queryKey: [endpoint, id], // Use endpoint and id as the query key
    queryFn: () => getQueryById(endpoint, tableName, id),
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 10, // Cache data for 10 minutes
    enabled: !!id,
  })
}
