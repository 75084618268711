import React from 'react'
import { Drawer, Box, Typography, useTheme, InputLabel } from '@mui/material'

const LeadDrawer = ({ lead, open, onClose }) => {
  console.log('LEAD: ', lead)
  const theme = useTheme()
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box
        p={2}
        width="600px"
        sx={{ marginTop: theme.spacing(9), textAlign: 'left' }}
      >
        {lead ? (
          // lead.map((lead) => {
          <>
            <React.Fragment key={lead.id || lead.lead_uuid}>
              <InputLabel>Name</InputLabel>
              <Typography sx={{ margin: 1 }} variant="h6">
                {lead.name}
              </Typography>
              <InputLabel>Email</InputLabel>
              <Typography sx={{ margin: 1 }}>{lead.email}</Typography>
              <InputLabel>Phone</InputLabel>
              <Typography sx={{ margin: 1 }}>{lead.phone}</Typography>
              <InputLabel>Submitted Date</InputLabel>
              <Typography sx={{ margin: 1 }}>{lead.submittedDate}</Typography>
              <InputLabel>Submitted Time</InputLabel>
              <Typography sx={{ margin: 1 }}>{lead.submittedTime}</Typography>
              <InputLabel>Company</InputLabel>
              <Typography sx={{ margin: 1 }}>{lead.company}</Typography>
              <InputLabel>Message</InputLabel>
              <Typography sx={{ margin: 1 }}>{lead.message}</Typography>
            </React.Fragment>
          </>
        ) : (
          // })
          <Typography>No Lead Selected</Typography>
        )}
      </Box>
    </Drawer>
  )
}

export default LeadDrawer
