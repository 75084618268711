import dayjs from 'dayjs'

/**
 * Formats a date into a human-readable string.
 * @param {Date|string|Dayjs} date - The date to format.
 * @return {string} - The formatted date string.
 */
export const formatDate = date => {
  return dayjs(date).format('MM/DD/YYYY') // Adjust the format as needed
}

export const formatDates = date => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
  return date.toLocaleDateString('en-US', options)
}

export const formatDateForMonday = date => {
  return dayjs(date).format('YYYY-DD-MM') // Adjust the format as needed
}
