import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/queryMutations'

// export const useUpdateOrganization = () => {
//   const queryClient = useQueryClient()

//   const mutation = useMutation({
//     mutationFn: async ({ orgId, deal_id }) => {
//       console.log('deal_id: ', deal_id)
//       const url = `${baseUrl}/aws/updateorganizations/${orgId}`
//       const response = await axios.put(url, { deal_id }, { params: { removeUndefinedValues: true } })
//       return response.data
//     },
//     onMutate: async ({ orgId, deal_id }) => {
//       await queryClient.cancelQueries(['organizations', orgId])
//       const previousOrg = queryClient.getQueryData(['organizations', orgId])

//       queryClient.setQueryData(['organizations', orgId], old => ({
//         ...old,
//         dealIds: old.dealIds ? [...old.dealIds, deal_id] : [deal_id],
//       }))

//       return { previousOrg }
//     },
//     onError: (err, { orgId }, context) => {
//       queryClient.setQueryData(['organizations', orgId], context.previousOrg)
//     },
//     onSuccess: (_, { orgId }) => {
//       queryClient.invalidateQueries(['organizations', orgId])
//     },
//   })

//   return mutation
// }

export const useUpdateOrganization = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async ({ orgId, deal_id }) => {
      console.log('deal_id: ', deal_id)
      const url = `${baseUrl}/aws/updateorganizations/${orgId}`
      const response = await axios.put(url, { deal_id }, { params: { removeUndefinedValues: true } })
      return response.data
    },
    onMutate: async ({ orgId, deal_id }) => {
      await queryClient.cancelQueries(['organizations', orgId])
      const previousOrg = queryClient.getQueryData(['organizations', orgId])

      queryClient.setQueryData(['organizations', orgId], old => ({
        ...old,
        dealIds: old.dealIds ? [...old.dealIds, deal_id] : [deal_id],
      }))

      return { previousOrg }
    },
    onError: (err, { orgId }, context) => {
      queryClient.setQueryData(['organizations', orgId], context.previousOrg)
    },
    onSuccess: (_, { orgId }) => {
      // queryClient.invalidateQueries(['organizations']) // Invalidate all organizations to ensure the related data updates
      // queryClient.invalidateQueries(['deals']) // Invalidate deals if they are related to the organizations
    },
  })

  return mutation
}

// import { useMutation, useQueryClient } from '@tanstack/react-query'
// import axios from 'axios'
// import { baseUrl } from '../queryMutations/queryMutations'

// export const useUpdateOrganization = () => {
//   const queryClient = useQueryClient()

//   const mutation = useMutation({
//     // Use async/await directly in the mutation function
//     mutationFn: async ({ orgId, dealId }) => {
//       const url = `${baseUrl}/aws/updateorganizations/${orgId}`
//       const response = await axios.put(url, { deal_id: dealId })
//       return response.data // Directly return the response data
//     },

//     // Handle the optimistic update
//     onMutate: async ({ orgId, dealId }) => {
//       await queryClient.cancelQueries(['organizations', orgId])
//       const previousOrg = queryClient.getQueryData(['organizations', orgId])

//       // Optimistically update to the new value
//       queryClient.setQueryData(['organizations', orgId], old => ({
//         ...old,
//         dealIds: old?.dealIds ? [...old.dealIds, dealId] : [dealId],
//       }))

//       return { previousOrg }
//     },

//     // Handle errors by rolling back
//     onError: (err, { orgId }, context) => {
//       queryClient.setQueryData(['organizations', orgId], context.previousOrg)
//     },

//     // After mutation, invalidate to refresh data
//     onSuccess: (_, { orgId }) => {
//       queryClient.invalidateQueries(['organizations', orgId])
//     },
//   })
//   return mutation
// }
