import React from 'react'
import { Navigate } from 'react-router-dom'
import Loading from '../components/Loading/Loading'

const ProtectedRoute = ({ children }) => {
  const googleAccessToken = localStorage.getItem('google_access_token')
  const slackAccessToken = localStorage.getItem('slack_access_token')
  const pandaAccessToken =
    process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'laptop'
      ? '44c682867cd90875b3ba53db7280c56e1a4d6068'
      : localStorage.getItem('panda_access_token')

  const allTokensPresent = googleAccessToken && slackAccessToken && pandaAccessToken

  // console.log('Google Access Token in ProtectedRoute:', googleAccessToken)
  // console.log('Slack Access Token in ProtectedRoute:', slackAccessToken)
  // console.log('Panda Access Token in ProtectedRoute:', pandaAccessToken)
  // console.log('All Tokens Present:', allTokensPresent)
  if (allTokensPresent) {
    console.log('*** Fully Authenticated - All Tokens Present ***')

    if (!allTokensPresent) {
      return <Loading /> // Show loading while checking tokens
    }
    // console.log(
    //   `All Tokens Present...\n[Google Access Token]: ${googleAccessToken}\n[Slack Access Token]: ${slackAccessToken}\n[Panda Access Token]: ${pandaAccessToken}`
    // )
  }

  return allTokensPresent ? children : <Navigate to='/' />
}

export default ProtectedRoute
