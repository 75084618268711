import { IconButton, Badge, Drawer, Tooltip } from '@mui/material'
import PublicIcon from '@mui/icons-material/Public'
import AddIcon from '@mui/icons-material/Add'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import CheckIcon from '@mui/icons-material/Check'

const CompleteDiscoveryIcon = () => {
  return (
    <Tooltip title='Discovery Complete'>
      <IconButton>
        <Badge badgeContent={<CheckIcon sx={{ fontSize: '18px', color: 'rgba(147, 250, 165, 1)' }} />}>
          <PublicIcon sx={{ fontSize: '30px', color: 'rgb(25, 117, 255)' }} />
        </Badge>
      </IconButton>
    </Tooltip>
  )
}

export default CompleteDiscoveryIcon
