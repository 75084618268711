import React from 'react'
import { Avatar, Box, Chip, DialogTitle, Grid, Typography, useTheme } from '@mui/material'
import GrboboticLight from '../../images/Grobotic png-lightmode.png'
import GrboboticDark from '../../images/Grobotic png.png'
import { renderHeaderContent } from '../../context/ContainerProvider/BoardHeaders'
import ThemedImage from '../../images/ThemedImage/ThemedImage'
import ContractInfoHeader from '../ContractDialog/ContractInfoHeader'
import { capitalizeFirstLetter, renderTypeColor } from '../DataGrid/createColumns/renderDealsColumns'
import { useOrganizationById } from '../../api/aws/useOrganization'
import DetailsLoading from '../Loading/DetailsLoading'
import EditContractInfoHeader from '../EditContractDialog/EditContractInfoHeader'

const EditDialogHeaderComponent = ({ name, newDeal, setNewDeal, handleChange, validationErrors, approvalState }) => {
  const theme = useTheme()
  const titleColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'
  const orgId = newDeal && Array.isArray(newDeal.organizations) ? newDeal.organizations[0] : null
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId)

  if (isOrganizationLoading) {
    return <DetailsLoading />
  }

  return (
    <Box>
      {name === 'Edit Contracts' ? (
        <>
          <Box
            key={newDeal && newDeal.id ? newDeal.id : newDeal && newDeal.deal_uuid ? newDeal.deal_uuid : ''}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '30px',
              marginBottom: 7,
            }}
          >
            {organization && organization.photoUrl ? (
              <Avatar
                src={organization.photoUrl}
                sx={{
                  width: 70,
                  height: 70,
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
                alt={organization.name ?? ''}
              />
            ) : (
              <ThemedImage srcLight={GrboboticLight} srcDark={GrboboticDark} />
            )}
            {organization ? (
              <DialogTitle
                sx={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: titleColor,
                  fontSize: '4rem',
                }}
              >
                {organization.name}
              </DialogTitle>
            ) : (
              <DialogTitle
                sx={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: titleColor,
                  fontSize: '2rem',
                }}
              >
                {'No Organization Found'}
              </DialogTitle>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Chip
                sx={{
                  marginRight: 2,
                  color: newDeal && newDeal.status && newDeal.status === 'Approved' ? 'black' : '',
                  fontWeight: 'bold',
                  backgroundColor: renderTypeColor(newDeal && newDeal.status ? newDeal.status : ''),
                }}
                size='lg'
                label={
                  <Typography>
                    {newDeal && newDeal.status ? capitalizeFirstLetter(newDeal.status) : 'Set Status'}
                  </Typography>
                }
              />
            </Box>
          </Box>
          <EditContractInfoHeader
            newDeal={newDeal}
            setNewDeal={setNewDeal}
            handleChange={handleChange}
            validationErrors={validationErrors}
            approvalState={approvalState}
            organization={organization}
          />
        </>
      ) : name === 'Deal Contract Details' ? (
        <Grid container>
          <Grid item xs={12} sm={12}>
            <ContractInfoHeader
              newDeal={newDeal}
              handleChange={handleChange}
              validationErrors={validationErrors}
              approvalState={approvalState}
              organization={organization}
            />
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '30px',
            marginBottom: 3,
          }}
        >
          <ThemedImage srcLight={GrboboticLight} srcDark={GrboboticDark} />
          <DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center', color: titleColor }}>
            {`Create ${name}`}
          </DialogTitle>
        </Box>
      )}
    </Box>
  )
}

export default EditDialogHeaderComponent
