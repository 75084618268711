import React, { useState, useEffect } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Stack,
  TextField,
  styled,
  useTheme,
} from '@mui/material'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import { BoxScroller, CreateProjectTextField } from '../../style/styleElements'
import CustomVerticalAutocomplete from '../CustomCompanyComponents/CustomVerticalAutocomplete'
import CustomTypeSelect from '../Reusable/CustomTypeSelect'
import { housingTypeOptions, typeOptions } from '../CreateOrganization/CreateOrganization'
import GroboticPng from '../../images/Grobotic png.png'
import GroboticLight from '../../images/Grobotic png-lightmode.png'
import { dialogStyles, dialogContentStyles } from './style'
import CustomParentCompanyAutocomplete from '../CustomCompanyComponents/CustomParentCompanyAutocomplete'
import PrimaryContactAutocomplete from '../Contacts/PrimaryContactAutocomplete'
import DialogHeaderComponent from '../DialogHeader/DialogHeader'
import AdditionalContactsAutocomplete from '../Contacts/AdditionalContactsAutocomplete'
import { useGetQuery } from '../../api/aws/useGetQuery'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { useContacts } from '../../api/aws/useContacts'
const initialState = {
  name: '',
  dba: '',
  type: '',
  billingTerms: '',
  status: 'Prospect',
  primaryContact: [],
  contacts: [],
  teamMemberIds: [],
  parentClient: [],
  contractedBy: [],
  affiliated: [],
  dealIds: [],
  website: '',
  vertical: [],
  billingPhone: '',
  businessPhone: '',
  email: '',
  street: '',
  city: '',
  state: '',
  zip: '',
  closeDate: '',
  cmsUsed: '',
  socialProfiles: '',
  marketingObjective: '',
  geoTargeting: '',
  summary: '',
  owner: [],
}

const OrganizationDialog = ({ open, onClose, tempInput, onSave }) => {
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const [selectedParentClient, setSelectedParentClient] = useState([])
  const { contacts, isContactsLoading, isContactsError } = useContacts()

  const theme = useTheme()
  const dialogIcon = theme.palette.mode === 'dark' ? GroboticPng : GroboticLight

  const [newOrganization, setNewOrganization] = useState(initialState)

  useEffect(() => {
    if (tempInput) {
      setNewOrganization(prev => ({ ...prev, name: tempInput }))
    }
  }, [tempInput])

  useEffect(() => {
    if (!open) {
      setNewOrganization(initialState)
    }
  }, [open])

  const handleChange = (key, eventOrValue) => {
    const value = eventOrValue.target ? eventOrValue.target.value : eventOrValue.value
    const name = eventOrValue.target ? eventOrValue.target.name : key
    setNewOrganization(prev => ({ ...prev, [name]: value }))
  }

  const handleCompanyChange = (context, value) => {
    setNewOrganization(prev => ({
      ...prev,
      [context]: value,
    }))
  }

  const handleSave = () => {
    console.log('New org: ', newOrganization)
    onSave({ ...newOrganization, owner: activeUser && activeUser.user ? [activeUser.user.id] : [] })
    setNewOrganization({
      ...initialState,
    })
  }

  const handleSelectingContacts = newValue => {
    setNewOrganization(prev => ({
      ...prev,
      contacts: Array.isArray(newValue)
        ? newValue.map(item => (item ? item.contacts_uuid : item.id))
        : newValue
        ? [newValue ? newValue.contacts_uuid : newValue.id]
        : [],
    }))
  }

  const handleSelectPrimaryContact = newValue => {
    setNewOrganization(prev => ({
      ...prev,
      primaryContact: Array.isArray(newValue)
        ? newValue.map(item => (item ? item.contacts_uuid : item.id))
        : newValue
        ? [newValue ? newValue.contacts_uuid : newValue.id]
        : [],
    }))
  }

  const handleVerticalChange = event => {
    const { value } = event.target
    setNewOrganization(prev => ({
      ...prev,
      vertical: value,
      contractedBy: [],
      affiliated: [],
    }))
  }

  const addParentClient = newValue => {
    setNewOrganization(prev => ({
      ...prev,
      parentClient: Array.isArray(newValue) ? newValue.map(item => item.pc_uuid) : newValue ? [newValue.pc_uuid] : [],
    }))
    setSelectedParentClient(prev => [...prev, newValue])
  }

  const handleCompanyBlur = (context, value) => {
    console.log('[CreateOrganization] handleCompanyBlur: value', value)
  }

  const isVerticalSelected =
    newOrganization &&
    newOrganization.vertical &&
    Array.isArray(newOrganization.vertical) &&
    newOrganization.vertical.length > 0
  const isHousingSelected =
    newOrganization && newOrganization.vertical && Array.isArray(newOrganization.vertical)
      ? newOrganization.vertical.some(option =>
          [
            'Real Estate - Student',
            'Real Estate - MF',
            'Real Estate - Senior',
            'Real Estate - Homebuilding',
            'Student',
            'Senior',
            'Multifamily',
            'Homebuilding',
            'Build to rent',
          ].includes(option)
        )
      : []

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
      PaperProps={{
        sx: dialogStyles(theme),
      }}
    >
      <DialogHeaderComponent name='Organizations' />
      <BoxScroller>
        <DialogContent sx={dialogContentStyles}>
          <Stack spacing={2}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <CreateProjectTextField
                label='Name'
                name='name'
                value={newOrganization.name}
                onChange={e => handleChange('name', e)}
                fullWidth
              />
              <CreateProjectTextField
                label='DBA'
                name='dba'
                value={newOrganization.dba}
                onChange={e => handleChange('dba', e)}
                fullWidth
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <PrimaryContactAutocomplete
                contacts={contacts}
                label='Primary Contact'
                onChange={handleSelectPrimaryContact}
              />
              <AdditionalContactsAutocomplete
                contacts={contacts}
                label='Additional Contacts'
                onChange={handleSelectingContacts}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
              <CustomVerticalAutocomplete label={'Vertical'} onChange={handleVerticalChange} />
            </Box>
            {isVerticalSelected && (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                  {isHousingSelected ? (
                    <Box sx={{ flex: 1, height: '100%', minWidth: 0 }}>
                      <CustomTypeSelect
                        stateKey='type'
                        value={newOrganization.type}
                        options={housingTypeOptions}
                        displayLabel='Organization Type'
                        setterFn={setNewOrganization}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ flex: 1, height: '100%', minWidth: 0 }}>
                      <CustomTypeSelect
                        stateKey='type'
                        value={newOrganization.type}
                        options={typeOptions}
                        displayLabel='Organization Type'
                        setterFn={setNewOrganization}
                      />
                    </Box>
                  )}
                </Box>
                {newOrganization.type && newOrganization.type === 'Housing Property' ? (
                  <>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <CustomParentCompanyAutocomplete
                        label='Contracted By'
                        value={newOrganization.contractedBy}
                        onChange={handleCompanyChange}
                        context='contractedBy'
                        onBlur={handleCompanyBlur}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <CustomParentCompanyAutocomplete
                        label='Affiliated'
                        value={newOrganization.affiliated}
                        onChange={handleCompanyChange}
                        context='affiliated'
                        type={newOrganization.type}
                        onBlur={handleCompanyBlur}
                      />
                    </Box>
                  </>
                ) : (
                  <Box sx={{ flex: 1, height: '100%', minWidth: 0 }}>
                    <CustomParentCompanyAutocomplete
                      label='Parent Client(s)'
                      value={newOrganization.parentClient}
                      onChange={handleCompanyChange}
                      context='parentClient'
                      type={newOrganization.type}
                      onBlur={handleCompanyBlur}
                    />
                  </Box>
                )}
              </>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <CreateProjectTextField
                label='Website'
                name='website'
                value={newOrganization.website}
                onChange={e => handleChange('website', e)}
                fullWidth
              />
              <CreateProjectTextField
                label='Street'
                name='street'
                value={newOrganization.street}
                onChange={e => handleChange('street', e)}
                fullWidth
              />
              <Box sx={{ display: 'flex', gap: 1 }}>
                <CreateProjectTextField
                  label='City'
                  name='city'
                  value={newOrganization.city}
                  onChange={e => handleChange('city', e)}
                  fullWidth
                />
                <CreateProjectTextField
                  label='State'
                  name='state'
                  value={newOrganization.state}
                  onChange={e => handleChange('state', e)}
                  fullWidth
                />
                <CreateProjectTextField
                  label='Zip'
                  name='zip'
                  value={newOrganization.zip}
                  onChange={e => handleChange('zip', e)}
                  fullWidth
                />
              </Box>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary' variant='outlined'>
            Cancel
          </Button>
          <Button onClick={handleSave} color='primary' variant='contained'>
            Add
          </Button>
        </DialogActions>
      </BoxScroller>
    </Dialog>
  )
}

export default OrganizationDialog
