import React from 'react'
import { Button } from '@mui/material'
const CreativeApproveButton = ({ needsApproval, isApproved, isApproving, onApprove }) => {
  return (
    <>
      <Button
        sx={{
          width: '10%',
          backgroundColor: 'rgb(93, 63, 211)',
        }}
        disabled={isApproving}
        onClick={onApprove}
        name='isApproved'
        variant='contained'
      >
        Approve
      </Button>
    </>
  )
}

export default CreativeApproveButton
