import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions'
import { refreshPandaToken } from './refreshPandaToken'

export const pandaAccessToken =
  process.env.REACT_APP_ENV === 'local'
    ? '44c682867cd90875b3ba53db7280c56e1a4d6068'
    : localStorage.getItem('panda_access_token')

const getDocuments = async documentId => {
  if (!pandaAccessToken) {
    throw new Error('Panda authentication token is missing or expired. Please log in again.')
  }

  let url = `${baseUrl}/aws/pandadocuments`
  if (documentId) {
    // console.log('Document Id added: ', documentId)
    url += `/${documentId}` // Assuming this is the correct path for document details
  }
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `${pandaAccessToken}` },
    })
    // console.log('Response PandaDoc: ', response)
    return response.data
  } catch (error) {
    // Here you can handle specific errors and rethrow them
    throw new Error(error.response?.data?.message || error.message || 'An unexpected error occurred.')
  }
}
// const getDocuments = async documentId => {
//   let accessToken =
//     process.env.REACT_APP_ENV === 'local'
//       ? '44c682867cd90875b3ba53db7280c56e1a4d6068'
//       : localStorage.getItem('panda_access_token')
//   if (!accessToken) {
//     throw new Error('Panda authentication token is missing or expired. Please log in again.')
//   }

//   let url = `${baseUrl}/aws/pandadocuments`
//   if (documentId) {
//     url += `/${documentId}`
//   }

//   try {
//     const response = await axios.get(url, {
//       headers: { Authorization: `Bearer ${accessToken}` },
//     })
//     return response.data
//   } catch (error) {
//     if (error.response && error.response.status === 401) {
//       // Token expired, attempt to refresh
//       try {
//         accessToken = await refreshPandaToken()
//         // Retry the request with the new token
//         const response = await axios.get(url, {
//           headers: { Authorization: `Bearer ${accessToken}` },
//         })
//         return response.data
//       } catch (refreshError) {
//         throw new Error('Failed to refresh PandaDoc token. Please log in again.')
//       }
//     }
//     throw new Error(error.response?.data?.message || error.message || 'An unexpected error occurred.')
//   }
// }

export const useGetDocuments = documentId => {
  return useQuery({
    queryKey: ['pandaDocuments', documentId],
    queryFn: () => getDocuments(documentId),
    onError: error => {
      // Handle error state here, useful for setting error messages in UI
      console.error('Error fetching panda documents', error)
    },
  })
}
