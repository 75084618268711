import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/queryMutations'

const fetchOwnershipGroupById = async ownershipGroupId => {
  const { data } = await axios.get(`${baseUrl}/aws/ownershipgroups/${ownershipGroupId}`)
  console.log('Data: ', data)
  return data
}

export const useOwnershipGroup = ownershipGroupId => {
  return useQuery({
    queryKey: ['ownershipGroup', ownershipGroupId],
    queryFn: () => fetchOwnershipGroupById(ownershipGroupId),
    staleTime: 1000 * 60 * 5, // Data is considered fresh for 5 minutes
    enabled: !!ownershipGroupId, // This query will not run until both ownershipGroupId and prospectName are available
  })
}
