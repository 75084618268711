import React, { useState } from 'react'
import {
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  useTheme,
  TableContainer,
  Button,
  CircularProgress,
} from '@mui/material'
import MyDeals from './MyDeals'
import MyOrganizations from './MyOrganizations'
import { useGetQuery } from '../../api/aws/useGetQuery'
import { useOrganizations } from '../../api/aws/useOrganizations'
import DetailsLoading from '../Loading/DetailsLoading'
import { useActiveUser } from '../../api/slack/useActiveUser'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const DashboardPage = () => {
  const theme = useTheme()
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const { user: { profile: { real_name: username } = {}, id: userId } = {} } = activeUser || {}
  const [selectedUserId, setSelectedUserId] = useState(userId)
  const [selectedMonth, setSelectedMonth] = useState('')
  const { data: deals, isLoading: dealsLoading, isError: dealsError } = useGetQuery('/aws/deals', 'deals')
  const { organizations, isOrganizationsLoading, isOrganizationsError } = useOrganizations()

  const isLoading = dealsLoading || isOrganizationsLoading
  const isError = dealsError || isOrganizationsError
  const gridBorderColors = theme.palette.mode === 'dark' ? 'white' : 'black'
  const myOrgs =
    organizations && Array.isArray(organizations)
      ? organizations.filter(org => {
          // Exclude organizations with missing essential data
          if (!org || !org.name || !org.status) return false

          // Check if the current user is in the owner array
          return org.owner && Array.isArray(org.owner) && org.owner.includes(activeUser?.user?.id)
        })
      : []
  if (isLoading) {
    return <DetailsLoading />
  }

  const handleUserChange = event => {
    setSelectedUserId(event.target.value)
  }

  const handleMonthChange = event => {
    setSelectedMonth(event.target.value)
  }
  const dealsByOwner =
    deals && Array.isArray(deals)
      ? deals.filter(deal => {
          // Exclude deals with missing essential data
          if (!deal || !deal.name || !deal.status) return false

          // Check if the current user is in the owner array
          return deal.owner && Array.isArray(deal.owner) && deal.owner.includes(activeUser?.user?.id)
        })
      : []

  const dealsByTeamMember =
    deals && Array.isArray(deals)
      ? deals.filter(deal => {
          // Exclude deals with missing essential data
          if (!deal || !deal.name || !deal.status) return false

          const isPM = deal.PM && Array.isArray(deal.PM) && deal.PM.includes(activeUser?.user?.id)
          const isAM = deal.AM && Array.isArray(deal.AM) && deal.AM.includes(activeUser?.user?.id)
          const isDMS = deal.DMS && Array.isArray(deal.DMS) && deal.DMS.includes(activeUser?.user?.id)
          const isSEO = deal.SEO && Array.isArray(deal.SEO) && deal.SEO.includes(activeUser?.user?.id)
          const isBD = deal.BD && Array.isArray(deal.BD) && deal.BD.includes(activeUser?.user?.id)

          // Return true if the user is associated with any of these roles
          return isPM || isAM || isDMS || isSEO || isBD
        })
      : []

  // Combine and deduplicate the deals
  const uniqueDeals = new Set([...dealsByOwner, ...dealsByTeamMember])
  const myDeals = [...uniqueDeals]
  const filteredDeals =
    deals && Array.isArray(deals)
      ? selectedUserId
        ? deals.filter(deal => deal.owner && deal.owner.includes(selectedUserId))
        : deals
      : []

  const dealsForSelectedMonth = selectedMonth
    ? filteredDeals.filter(deal => {
        const dealMonth = new Date(deal.startDate).getMonth()
        return dealMonth === months.indexOf(selectedMonth)
      })
    : filteredDeals

  const activeDealsCount = dealsForSelectedMonth.filter(deal => deal.status === 'Active').length
  const contractsSentCount = dealsForSelectedMonth.filter(deal => deal.stage === 'Contract Sent').length
  const dealsWonData = dealsForSelectedMonth.filter(deal => deal.stage === 'Deal Won').length
  const dealsInDraft = dealsForSelectedMonth.filter(deal => deal.status === 'draft').length

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 260px)',
        width: '100%',
        marginTop: 1,
      }}
    >
      {isLoading && <CircularProgress />}
      <Grid container spacing={0} sx={{ width: '98%', height: '100%' }}>
        {/* User and Month selectors */}
        {/* <Grid
          item
          xs={6}
          sx={{
            border: '1px solid',
            borderColor: 'gray',
            height: '12%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Select value={selectedUserId} onChange={handleUserChange} sx={{ width: '95%' }}>
              <MenuItem value=''>All Users</MenuItem>
              {users.map(user => (
                <MenuItem key={user.id || user.user_uuid} value={user.id}>
                  {user && user.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            border: '1px solid',
            borderColor: 'gray',
            height: '12%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Select value={selectedMonth} onChange={handleMonthChange} sx={{ width: '95%' }}>
              <MenuItem value=''>All Months</MenuItem>
              {months.map((month, index) => (
                <MenuItem key={index} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid> */}

        {/* Scrollable MyDeals Section */}
        <Grid
          item
          xs={6}
          sx={{
            height: 'calc(100% - 25%)', // Adjust to make room for the other elements
            overflowY: 'auto',
            // padding: '10px',
            border: '1px solid',
            borderColor: gridBorderColors,
            marginTop: '10px',
            maxHeight: '75vh', // Set maximum height for the box
            zIndex: 100,
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 2,
              backgroundColor: theme.palette.background.paper, // Ensure this is a solid color
              padding: '10px',
              width: '100%', // Ensure it spans the full width
            }}
          >
            <Typography variant='h6' align='center'>
              {`Deals [${myDeals.length}]`}
            </Typography>
          </Box>

          {myDeals && myDeals.length > 0 ? (
            myDeals.map(deal => (
              <Box key={deal.id} sx={{ marginBottom: 2 }}>
                <MyDeals deal={deal} />
              </Box>
            ))
          ) : (
            <Typography variant='body1'>No deals available.</Typography>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            height: 'calc(100% - 25%)', // Adjust to make room for the other elements
            overflowY: 'auto',
            border: '1px solid',
            borderColor: gridBorderColors,
            marginTop: '10px',
            maxHeight: '75vh', // Set maximum height for the box
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 2,
              backgroundColor: theme.palette.background.paper, // Ensure this is a solid color
              padding: '10px',
              width: '100%', // Ensure it spans the full width
            }}
          >
            <Typography variant='h6' align='center'>
              {`Organizations [${myOrgs.length}]`}
            </Typography>
          </Box>
          {myOrgs && myOrgs.length > 0 ? (
            myOrgs.map(org => (
              <Box key={org.org_uuid} sx={{ marginBottom: 2 }}>
                <MyOrganizations org={org} />
              </Box>
            ))
          ) : (
            <Typography variant='body1'>No Organizations available.</Typography>
          )}
        </Grid>

        {/* Stats Display */}
        <Grid
          item
          xs={3}
          sx={{
            border: '1px solid',
            borderColor: gridBorderColors,
            height: '25%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant='h6'>Active Deals: {activeDealsCount}</Typography>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            border: '1px solid',
            borderColor: gridBorderColors,
            height: '25%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant='h6'>Contracts Sent: {contractsSentCount}</Typography>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            border: '1px solid',
            borderColor: gridBorderColors,
            height: '25%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant='h6'>Deals Won: {dealsWonData}</Typography>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            border: '1px solid',
            borderColor: gridBorderColors,
            height: '25%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant='h6'>Deals in Draft: {dealsInDraft}</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DashboardPage
