import { Box } from '@mui/material'
import React from 'react'
import RenderCompanyChip from '../RenderChipComponents/RenderCompanyChip'

const RenderParentOrganizationLink = ({ organization }) => {
  if (!organization) return null

  const parentTypes = ['Property Management Company', 'Ownership Group', 'Parent Client']

  // Normalize type to be an array
  const typeArray = Array.isArray(organization.type) ? organization.type : [organization.type]
  const type = typeArray[0] || 'NT' // Assuming the first element is the main type

  if (!parentTypes.includes(type) && type !== 'NT') return null

  return (
    <Box>
      <RenderCompanyChip option={{ ...organization, type }} />
    </Box>
  )
}

export default RenderParentOrganizationLink

// import { Box } from '@mui/material'
// import React from 'react'
// import RenderCompanyChip from '../CustomCompanyComponents/RenderCompanyChip'

// const RenderParentOrganizationLink = ({ organization }) => {
//   if (!organization) return null

//   const parentTypes = ['Property Management Company', 'Ownership Group', 'Parent Client']
//   const type = organization.type && organization.type.length > 0 ? organization.type : null

//   if (!parentTypes.includes(type) && type !== 'NT') return null

//   return (
//     <Box>
//       <RenderCompanyChip option={{ ...organization, type }} />
//     </Box>
//   )
// }

// export default RenderParentOrganizationLink
