import React, { useState, useEffect } from 'react'
import { Box, FormControl, InputLabel, Select, MenuItem, Chip, Typography, useTheme } from '@mui/material'

const CustomOptionSelect = ({ stateKey, value, options, displayLabel, setterFn }) => {
  const theme = useTheme()
  const [selectedValue, setSelectedValue] = useState(value)

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  const handleChange = event => {
    const newValue = event.target.value
    setSelectedValue(newValue)
    setterFn(newValue)
  }

  return (
    <Box sx={{ minWidth: 150 }}>
      <FormControl fullWidth variant='outlined' sx={{ display: 'flex', alignItems: 'center' }}>
        <InputLabel
          id={`${stateKey}-label`}
          sx={{
            color: theme.palette.mode === 'dark' ? 'rgb(255,255,255,0.5)' : 'rgb(0,0,0,0.5)',
            fontSize: '16px',
            transform: 'translate(9px, 11px) scale(1)',
            '&.MuiInputLabel-shrink': {
              transform: 'translate(14px, -10px) scale(0.75)',
            },
            '&.Mui-focused': {
              color: theme.palette.mode === 'dark' ? 'rgb(121 212 255)' : 'black',
            },
          }}
        >
          {displayLabel}
        </InputLabel>
        <Select
          labelId={`${stateKey}-label`}
          id={`${stateKey}-select`}
          value={selectedValue || ''}
          onChange={handleChange}
          label={displayLabel}
          renderValue={selected => (
            <Box sx={{ display: 'flex', alignItems: 'center' }} gap={1}>
              {selected ? (
                <Chip
                  size='small'
                  style={{
                    backgroundColor: options.find(opt => opt.label === selected)?.color,
                    width: 14,
                    height: 14,
                    borderRadius: '50%',
                  }}
                />
              ) : null}
              <Typography variant='subtitle1' noWrap>
                {selected}
              </Typography>
            </Box>
          )}
          sx={{ minWidth: 150 }}
        >
          {options.map(option => (
            <MenuItem key={option.label} value={option.label}>
              <Chip
                size='small'
                style={{
                  backgroundColor: option.color,
                  width: 14,
                  height: 14,
                  borderRadius: '50%',
                  marginRight: 8,
                }}
              />
              <Typography variant='subtitle1'>{option.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default CustomOptionSelect

// import React, { useState, useEffect } from 'react'
// import { Box, FormControl, InputLabel, Select, MenuItem, Chip, Typography, useTheme } from '@mui/material'

// const CustomOptionSelect = ({ stateKey, value, options, displayLabel, setterFn }) => {
//   const theme = useTheme()
//   const [selectedValue, setSelectedValue] = useState(value)

//   useEffect(() => {
//     setSelectedValue(value)
//   }, [value])

//   const handleChange = event => {
//     const newValue = event.target.value
//     setSelectedValue(newValue)
//     setterFn(newValue)
//   }

//   return (
//     <Box sx={{ width: '100%' }}>
//       <FormControl fullWidth variant='outlined' sx={{ display: 'flex', alignItems: 'center' }}>
//         <InputLabel
//           id={`${stateKey}-label`}
//           sx={{
//             color: theme.palette.mode === 'dark' ? 'rgb(255,255,255,0.5)' : 'rgb(0,0,0,0.5)',
//             fontSize: '16px',
//             transform: 'translate(9px, 11px) scale(1)',
//             '&.MuiInputLabel-shrink': {
//               transform: 'translate(14px, -10px) scale(0.75)',
//             },
//             '&.Mui-focused': {
//               color: theme.palette.mode === 'dark' ? 'rgb(121 212 255)' : 'black',
//             },
//           }}
//         >
//           {displayLabel}
//         </InputLabel>
//         <Select
//           labelId={`${stateKey}-label`}
//           id={`${stateKey}-select`}
//           value={selectedValue || ''}
//           onChange={handleChange}
//           label={displayLabel}
//           renderValue={selected => (
//             <Box sx={{ display: 'flex', alignItems: 'center' }} gap={1}>
//               {selected ? (
//                 <Chip
//                   size='small'
//                   style={{
//                     backgroundColor: options.find(opt => opt.label === selected)?.color,
//                     width: 14,
//                     height: 14,
//                     borderRadius: '50%',
//                   }}
//                 />
//               ) : null}
//               <Typography variant='subtitle1'>{selected}</Typography>
//             </Box>
//           )}
//         >
//           {options.map(option => (
//             <MenuItem key={option.label} value={option.label}>
//               <Chip
//                 size='small'
//                 style={{
//                   backgroundColor: option.color,
//                   width: 14,
//                   height: 14,
//                   borderRadius: '50%',
//                   marginRight: 8,
//                 }}
//               />
//               <Typography variant='subtitle1'>{option.label}</Typography>
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     </Box>
//   )
// }

// export default CustomOptionSelect

// // import React, { useState, useEffect } from 'react'
// // import { Box, Chip, Typography } from '@mui/material'
// // import InputLabel from '@mui/material/InputLabel'
// // import MenuItem from '@mui/material/MenuItem'
// // import FormControl from '@mui/material/FormControl'
// // import Select from '@mui/material/Select'

// // /**
// //  *
// //  * @param {string} stateKey string value to use for property changed
// //  * @param {string} value initial value to set for select
// //  * @param {array} options array of options to be displayed in list
// //  * @param {function} displayLabel custom label to be passed to select component
// //  * @param {function} setterFn set function for value
// //  * @returns
// //  */
// // // const CustomOptionSelect = ({ stateKey, value, options, displayLabel, setterFn }) => {
// // //   const [selectedValue, setSelectedValue] = useState('')

// // //   const handleChange = event => {
// // //     const newValue = event.target.value
// // //     const option = options.find(option => option.label === newValue)
// // //     console.log('[CustomOptionSelect] Handling newValue change:', newValue, option)

// // //     setSelectedValue(option ? option.label : '')
// // //     setterFn(prev => ({
// // //       ...prev,
// // //       [stateKey]: option ? option.label : '',
// // //     }))
// // //   }
// // const CustomOptionSelect = ({ stateKey, value, options, displayLabel, setterFn }) => {
// //   const [selectedValue, setSelectedValue] = useState(value)

// //   useEffect(() => {
// //     setSelectedValue(value)
// //   }, [value])

// //   const handleChange = event => {
// //     const newValue = event.target.value
// //     setSelectedValue(newValue)
// //     setterFn(newValue)
// //   }

// //   return (
// //     <Box>
// //       <FormControl sx={{ display: 'flex', alignItems: 'center' }} fullWidth>
// //         <InputLabel id={`${stateKey}-label`}>{displayLabel}</InputLabel>
// //         <Select
// //           labelId={`${stateKey}-label`}
// //           label={displayLabel || ''}
// //           id={`${stateKey}-select`}
// //           value={selectedValue ? selectedValue : value}
// //           onChange={handleChange}
// //           renderValue={selected => (
// //             <Box sx={{ display: 'flex', alignItems: 'center' }} gap={1}>
// //               {selected ? (
// //                 <Chip
// //                   size='small'
// //                   style={{
// //                     backgroundColor: options.find(opt => opt.label === selected)?.color,
// //                     width: 14,
// //                     height: 14,
// //                     borderRadius: '50%',
// //                   }}
// //                 />
// //               ) : null}
// //               <Typography variant='subtitle1'>{selected}</Typography>
// //             </Box>
// //           )}
// //         >
// //           {options.map(option => (
// //             <MenuItem key={option.label} value={option.label}>
// //               <Chip
// //                 size='small'
// //                 style={{
// //                   backgroundColor: option.color,
// //                   width: 14,
// //                   height: 14,
// //                   borderRadius: '50%',
// //                   margin: '5px',
// //                 }}
// //               />
// //               <Typography variant='subtitle1'>{option.label}</Typography>
// //             </MenuItem>
// //           ))}
// //         </Select>
// //       </FormControl>
// //     </Box>
// //   )
// // }

// // export default CustomOptionSelect
