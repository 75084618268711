import React, { useState, useEffect } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  TextField,
  styled,
  useTheme,
} from '@mui/material'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import { CreateProjectTextField } from '../../style/styleElements'
import { dialogStyles } from './style'
import CustomVerticalAutocomplete from './CustomVerticalAutocomplete'
import CustomTypeSelect from '../Reusable/CustomTypeSelect'
import { housingTypeOptions, typeOptions } from '../CreateOrganization/CreateOrganization'
import GroboticPng from '../../images/Grobotic png.png'
import GroboticLight from '../../images/Grobotic png-lightmode.png'
import { useActiveUser } from '../../api/slack/useActiveUser'

const initialState = {
  name: '',
  dba: '',
  type: '',
  billingTerms: '',
  status: 'Prospect',
  primaryContact: [],
  contacts: [],
  teamMemberIds: [],
  parentClient: [],
  contractedBy: [],
  affiliated: [],
  dealIds: [],
  website: '',
  vertical: [],
  billingPhone: '',
  businessPhone: '',
  email: '',
  street: '',
  city: '',
  state: '',
  zip: '',
  closeDate: '',
  cmsUsed: '',
  socialProfiles: '',
  marketingObjective: '',
  geoTargeting: '',
  summary: '',
  owner: [],
}

const DialogIcon = styled('img')({
  width: 50,
  height: 50,
  borderRadius: '50%',
})

const CustomCompanyDialog = ({ open, onClose, tempInput, onSave }) => {
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const theme = useTheme()
  const dialogIcon = theme.palette.mode === 'dark' ? GroboticPng : GroboticLight
  const [newOrganization, setNewOrganization] = useState(initialState)

  useEffect(() => {
    if (tempInput) {
      setNewOrganization(prev => ({ ...prev, name: tempInput }))
    }
  }, [tempInput])

  useEffect(() => {
    if (!open) {
      setNewOrganization(initialState)
    }
  }, [open])

  const handleChange = (key, eventOrValue) => {
    // console.log(key, eventOrValue)
    const value = eventOrValue.target ? eventOrValue.target.value : eventOrValue.value
    const name = eventOrValue.target ? eventOrValue.target.name : key
    // console.log('name: ', name, 'value: ', value)
    setNewOrganization(prev => ({ ...prev, [name]: value }))
  }

  const handleSave = () => {
    onSave({ ...newOrganization, owner: activeUser && activeUser.user ? [activeUser.user.id] : [] })
    setNewOrganization({ ...initialState })
  }

  const isHousingSelected = newOrganization.vertical.some(option =>
    ['Student', 'Multifamily', 'Senior', 'Homebuilding'].includes(option)
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
      PaperProps={{
        sx: dialogStyles(theme),
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <DialogIcon src={dialogIcon} />
        <DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center' }}>New Organization</DialogTitle>
        <CorporateFareIcon sx={{ color: 'rgb(114, 229, 218)' }} />
      </Box>
      <DialogContent sx={{ width: 'auto', maxWidth: '700px', margin: '0 auto' }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <CreateProjectTextField
            label='Name'
            name='name'
            value={newOrganization.name}
            onChange={e => handleChange('name', e)}
            fullWidth
          />
        </Box>
        <CustomVerticalAutocomplete label={'Vertical'} onChange={value => handleChange('vertical', value)} />
        {isHousingSelected && (
          <Box sx={{ flex: 1, height: '60px', minWidth: 0 }}>
            <CustomTypeSelect
              stateKey='type'
              value={newOrganization.type}
              options={housingTypeOptions}
              displayLabel='Organization Type'
              setterFn={setNewOrganization}
            />
          </Box>
        )}
        {!isHousingSelected && (
          <CustomTypeSelect
            stateKey='type'
            value={newOrganization.type}
            options={typeOptions}
            displayLabel='Organization Type'
            setterFn={setNewOrganization}
          />
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <CreateProjectTextField
            label='Website'
            name='website'
            value={newOrganization.website}
            onChange={e => handleChange('website', e)}
            fullWidth
          />
          <CreateProjectTextField
            label='Street'
            name='street'
            value={newOrganization.street}
            onChange={e => handleChange('street', e)}
            fullWidth
          />
          <Box sx={{ display: 'flex', gap: 1 }}>
            <CreateProjectTextField
              label='City'
              name='city'
              value={newOrganization.city}
              onChange={e => handleChange('city', e)}
              fullWidth
            />
            <CreateProjectTextField
              label='State'
              name='state'
              value={newOrganization.state}
              onChange={e => handleChange('state', e)}
              fullWidth
            />
            <CreateProjectTextField
              label='Zip'
              name='zip'
              value={newOrganization.zip}
              onChange={e => handleChange('zip', e)}
              fullWidth
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary' variant='outlined'>
          Cancel
        </Button>
        <Button onClick={handleSave} color='primary' variant='contained'>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CustomCompanyDialog

// import React, { useState, useEffect } from 'react'
// import {
//   Box,
//   Chip,
//   Dialog,
//   DialogContent,
//   DialogActions,
//   DialogTitle,
//   Button,
//   TextField,
//   styled,
//   useTheme,
// } from '@mui/material'
// import CorporateFareIcon from '@mui/icons-material/CorporateFare'
// import { useCreatePropertyMgmt } from '../../api/aws/useCreatePropertyMgmt'
// import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
// import { CreateProjectTextField } from '../../style/styleElements'
// import { dialogStyles } from './style'
// import CustomVerticalAutocomplete from './CustomVerticalAutocomplete'
// import CustomTypeSelect from '../Reusable/CustomTypeSelect'
// import { housingTypeOptions, typeOptions } from '../CreateOrganization/CreateOrganization'
// import GroboticPng from '../../images/Grobotic png.png'
// import GroboticLight from '../../images/Grobotic png-lightmode.png'

// const DialogIcon = styled('img')({
//   width: 50,
//   height: 50,
//   borderRadius: '50%',
// })

// const CustomCompanyDialog = ({ open, onClose, tempInput, onSave }) => {
//   const theme = useTheme()
//   const dialogIcon = theme.palette.mode === 'dark' ? GroboticPng : GroboticLight
//   const initialState = {
//     name: '',
//     industry: '',
//     vertical: [],
//     type: '',
//     website: '',
//     street: '',
//     city: '',
//     state: '',
//   }
//   const [newOrganization, setNewOrganization] = useState(initialState)
//   const { mutate: createPropertyMgmt } = useCreatePropertyMgmt()
//   const { showSnackbar } = useSnackbarContext()

//   useEffect(() => {
//     setNewOrganization(prev => ({ ...prev, name: tempInput }))
//   }, [tempInput])

//   const handleChange = (key, eventOrValue) => {
//     const value = eventOrValue.target ? eventOrValue.target.value : eventOrValue.value
//     const name = eventOrValue.target ? eventOrValue.target.name : key
//     setNewOrganization(prev => ({ ...prev, [name]: value }))
//   }

//   const handleSave = () => {
//     console.log(`Saving new ${newOrganization ? newOrganization.type : 'Organization'} :`, newOrganization)
//     onSave(newOrganization)
//     setNewOrganization({ ...initialState })
//     // onClose()
//   }

//   const isHousingSelected = newOrganization.vertical.some(option =>
//     ['Real Estate - Student', 'Real Estate - MF', 'Real Estate - Senior', 'Real Estate - Homebuilding'].includes(option)
//   )

//   return (
//     <Dialog
//       open={open}
//       onClose={onClose}
//       fullWidth
//       maxWidth='sm' // You can change 'sm' to 'md' or 'lg' for larger sizes
//       PaperProps={{
//         sx: dialogStyles(theme),
//       }}
//     >
//       <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
//         <DialogIcon src={dialogIcon} />
//         <DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center' }}>New Organization</DialogTitle>
//         <CorporateFareIcon sx={{ color: 'rgb(114, 229, 218)' }} />
//       </Box>
//       <DialogContent sx={{ width: 'auto', maxWidth: '700px', margin: '0 auto' }}>
//         <Box sx={{ display: 'flex', gap: 1 }}>
//           <CreateProjectTextField
//             label='Name'
//             name='name'
//             value={newOrganization.name}
//             onChange={e => handleChange('name', e)}
//             fullWidth
//           />
//         </Box>
//         <CustomVerticalAutocomplete label={'Vertical'} onChange={value => handleChange('vertical', value)} />
//         {isHousingSelected && (
//           <Box sx={{ flex: 1, height: '60px', minWidth: 0 }}>
//             <CustomTypeSelect
//               stateKey='type'
//               value={newOrganization.type}
//               options={housingTypeOptions}
//               displayLabel='Organization Type'
//               setterFn={setNewOrganization}
//             />
//           </Box>
//         )}
//         {!isHousingSelected && (
//           <CustomTypeSelect
//             stateKey='type '
//             value={newOrganization.type}
//             options={typeOptions}
//             displayLabel='Organization Type'
//             setterFn={setNewOrganization}
//           />
//         )}
//         <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
//           <CreateProjectTextField
//             label='Website'
//             name='website'
//             value={newOrganization.website}
//             onChange={e => handleChange('website', e)}
//             fullWidth
//           />
//           <CreateProjectTextField
//             label='Street'
//             name='street'
//             value={newOrganization.street}
//             onChange={e => handleChange('street', e)}
//             fullWidth
//           />
//           <Box sx={{ display: 'flex', gap: 1 }}>
//             <CreateProjectTextField
//               label='City'
//               name='city'
//               value={newOrganization.city}
//               onChange={e => handleChange('city', e)}
//               fullWidth
//             />
//             <CreateProjectTextField
//               label='State'
//               name='state'
//               value={newOrganization.state}
//               onChange={e => handleChange('state', e)}
//               fullWidth
//             />
//             <CreateProjectTextField
//               label='Zip'
//               name='zip'
//               value={newOrganization.zip}
//               onChange={e => handleChange('zip', e)}
//               fullWidth
//             />
//           </Box>
//         </Box>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose} color='primary' variant='outlined'>
//           Cancel
//         </Button>
//         <Button onClick={handleSave} color='primary' variant='contained'>
//           Add
//         </Button>
//       </DialogActions>
//     </Dialog>
//   )
// }

// export default CustomCompanyDialog
