import React, { createContext, useContext, useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { useCreateDeal } from '../../api/aws/useCreateDeal'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { useCreateDocument } from '../../api/PandaDoc/useCreateDocument'
import { useUpdateDeal } from '../../api/aws/useUpdateDeal'
import { useSnackbarContext } from '../SnackbarProvider/SnackbarProvider'
import { calculateTotalMonthsForSingleProduct, calculateMonthsForDeal } from '../../utility/calculateTotalMonths'

// Create a context for the deal
const DealContext = createContext()

export const useDealDetailsContext = () => useContext(DealContext)

export const DealDetailsProvider = ({ children, dealData }) => {
  const [originalDeal, setOriginalDeal] = useState({ ...dealData, dealStatus: 'Active' })
  const [newDeal, setNewDeal] = useState({ ...dealData, dealStatus: 'Active' })
  const [productRows, setProductRows] = useState([])
  const [approvalState, setApprovalState] = useState({
    hasProducts: newDeal?.products?.length > 0,
    needsApproval: newDeal?.needsApproval ?? false,
    status: newDeal?.status ?? 'Draft',
    stage: newDeal?.stage ?? 'Discovery',
    canCreateContract: newDeal?.canCreateContract ?? false,
  })

  const [originalApprovalState, setOriginalApprovalState] = useState({
    hasProducts: originalDeal?.products?.length > 0,
    needsApproval: originalDeal?.needsApproval ?? false,
    status: originalDeal?.status ?? 'Draft',
    stage: originalDeal?.stage ?? 'Discovery',
    isApproved: originalDeal?.isApproved ?? false,
    canCreateContract: originalDeal?.canCreateContract ?? false,
  })

  const { mutate: createDeal } = useCreateDeal()
  const { mutate: updateItems } = useUpdateItems()
  const { mutate: createDocument } = useCreateDocument()
  const { mutate: updateDeal } = useUpdateDeal()
  const { showSnackbar } = useSnackbarContext()
  const [isCreating, setIsCreating] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [validationErrors, setValidationErrors] = useState({
    targetLaunchDate: false,
    dependenciesDueDate: false,
    authorizedRecipient: false,
    contactRecipient: false,
    products: false,
  })
  // calculate totals
  const calculateMediaSpend = (price, marginPercentage) => {
    const marginValue = parseFloat(price) * (parseFloat(marginPercentage) / 100)
    const newMediaSpend = (parseFloat(price) - marginValue).toFixed(2)
    return newMediaSpend
  }

  const calculateTotalForRow = row => {
    return parseFloat(row.price || 0) * parseFloat(row.quantity || 1)
  }
  const computeTotals = (deal, productRows) => {
    const grossProfit = productRows.reduce((acc, row) => acc + parseFloat(row.grossProfit || 0), 0)
    const monthlyTotal = productRows.reduce((acc, row) => acc + parseFloat(row.total || 0), 0)
    const implementationFee = parseFloat(deal?.implementationFee || 0)
    const total = monthlyTotal

    return { grossProfit, monthlyTotal, implementationFee, total }
  }
  // Validation
  const validateFields = deal => {
    // Ensure recipients object exists with default empty arrays
    const recipients = deal?.recipients || { authorizedRecipient: [], contactRecipient: [] }

    const errors = {
      targetLaunchDate: !deal?.targetLaunchDate,
      dependenciesDueDate: !deal?.dependenciesDueDate,
      authorizedRecipient: !(
        Array.isArray(recipients.authorizedRecipient) && recipients.authorizedRecipient.length > 0
      ), // Check if array exists and has length
      contactRecipient: !(Array.isArray(recipients.contactRecipient) && recipients.contactRecipient.length > 0), // Check if array exists and has length
      products:
        (Array.isArray(deal?.products) && deal.products.length === 0) ||
        (Array.isArray(deal?.products) && deal.products.some(product => !product.productName)),
    }

    setValidationErrors(errors)
    return !Object.values(errors).some(Boolean) // Return whether any of the errors are true
  }

  const handleSaveDeal = async (deal, onSuccess, onError) => {
    setIsCreating(true)
    createDeal(deal, {
      onSuccess: data => {
        setIsCreating(false)
        showSnackbar('Deal saved successfully', 'success')
        onSuccess && onSuccess(data)
      },
      onError: error => {
        setIsCreating(false)
        setIsError(true)
        showSnackbar('Error saving deal', 'error')
        onError && onError(error)
      },
    })
  }

  const handleUpdateDeal = async (dealId, updatedDeal, onSuccess, onError) => {
    setIsCreating(true)
    updateDeal(
      {
        dealId,
        dealData: updatedDeal,
      },
      {
        onSuccess: data => {
          setIsCreating(false)
          showSnackbar('Deal updated successfully', 'success')
          onSuccess && onSuccess(data)
        },
        onError: error => {
          setIsCreating(false)
          setIsError(true)
          showSnackbar('Error updating deal', 'error')
          onError && onError(error)
        },
      }
    )
  }
  // Product Row Functions
  const addRow = async () => {
    const startDate = newDeal.startDate ? dayjs(newDeal.startDate) : null
    const endDate = newDeal.endDate ? dayjs(newDeal.endDate) : null
    const totalMonthsForDeal = calculateMonthsForDeal(startDate, endDate)
    const newRow = {
      rowId: `${productRows.length + 1}`,
      productName: '',
      description: '',
      addtlDescriptor: '',
      qty: totalMonthsForDeal ? totalMonthsForDeal : '1.00',
      price: '0.00',
      margin: '0.00',
      mediaSpend: '0.00',
      total: '0.00',
      startDate: startDate,
      endDate: endDate,
      status: '',
      grossProfit: '0.00',
      selectedProduct: null,
    }
    setProductRows(prevRows => {
      const updatedRows = [...prevRows, newRow]
      setNewDeal(prevDetails => ({
        ...prevDetails,
        products: [...updatedRows],
      }))

      const isValid = validateFields({ ...newDeal, products: updatedRows })
      setIsValid(isValid)
      console.log('UPDATED ROWS: ', updatedRows)
      return updatedRows
    })
  }
  const removeRow = async index => {
    const updatedRows = productRows.filter((_, i) => i !== index)
    setProductRows(updatedRows)
    const isValid = validateFields({ ...newDeal, products: updatedRows })
    setIsValid(isValid)
    setNewDeal(prevDetails => ({
      ...prevDetails,
      products: updatedRows,
    }))
  }
  ///////////////////////////// handleProductChange ////////////////////////////
  /////////////////////////////////////////////////////////////////////////////
  const handleProductChange = (index, field, value, additionalUpdates = {}) => {
    const paidProductsList = [
      'Meta Advertising',
      'Linkedin Advertising',
      'Paid Search Advertising',
      'Google Display Advertising',
      'Spotify Advertising',
      'Youtube Advertising',
    ]
    const updatedRows = productRows.map((row, i) => {
      if (i === index) {
        const updatedRow = {
          ...row,
          [field]: value,
          ...additionalUpdates,
        }
        if (field === 'dateRange') {
          const startDate = value[0]
          const endDate = value[1]
          // Calculate total months for this product
          const totalMonthsForProduct = calculateTotalMonthsForSingleProduct(startDate, endDate)
          // Update the start and end date and the quantity (total months)
          updatedRow.startDate = startDate
          updatedRow.endDate = endDate
          updatedRow.qty = totalMonthsForProduct // Update quantity to the total months
        }

        if (field === 'selectedProduct') {
          updatedRow.productName = value ? value.title : ''
          updatedRow.price = value ? value.price : 0 // Reset price when product changes
          updatedRow.margin = additionalUpdates.margin || '0.00' // Set new margin if provided
          // Add this logic to handle specific product titles
          if (paidProductsList.includes(value && value.title)) {
            updatedRow.payer = 'GRO'
            updatedRow.accountUsed = 'GRO'
          }
        }

        if (['price', 'margin', 'grossProfit', 'selectedProduct'].includes(field)) {
          updatedRow.mediaSpend = calculateMediaSpend(updatedRow.price, updatedRow.margin)
          updatedRow.total = calculateTotalForRow(updatedRow)
          updatedRow.grossProfit = (parseFloat(updatedRow.price) - parseFloat(updatedRow.mediaSpend)).toFixed(2)
        }

        return updatedRow
      }
      return row
    })

    setProductRows(updatedRows)
    setNewDeal(prevDetails => {
      const updatedDeal = {
        ...prevDetails,
        products: updatedRows,
      }
      const isValid = validateFields(updatedDeal)
      setIsValid(isValid)
      return updatedDeal
    })
  }
  /////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////

  ////////////////////////// handleChange //////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////
  const handleChange = (field, newValue) => {
    setNewDeal(prevDetails => {
      const updatedDeal = { ...prevDetails }

      if (field === 'implementationFee') {
        updatedDeal[field] = parseFloat(newValue) || 0
      } else if (field === 'authorizedRecipient' || field === 'contactRecipient') {
        const recipients = newValue.map(recipient => ({
          id: recipient.id || recipient.contacts_uuid || recipient.user_uuid,
          email: recipient['Employee Email'] || recipient.email,
          first_name: recipient['First Name'] || recipient.firstName || recipient.first_name,
          last_name: recipient['Last Name'] || recipient.lastName || recipient.last_name,
          role: field === 'authorizedRecipient' ? 'Authorized Personnel' : 'Client',
          signing_order: field === 'authorizedRecipient' ? 1 : 2,
        }))
        updatedDeal.recipients = {
          ...updatedDeal.recipients,
          [field]: recipients,
        }
      } else if (field === 'numberOfPayments') {
        updatedDeal[field] = parseFloat(newValue) || 0
      } else {
        updatedDeal[field] = newValue
      }
      console.log('UpdatedDeal: ', updatedDeal)
      const isValid = validateFields(updatedDeal)
      setIsValid(isValid)
      return updatedDeal
    })
  }
  /////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////

  // Initialize the originalApprovalState based on dealData
  useEffect(() => {
    if (!dealData) return // Early return if dealData is not defined
    setNewDeal(dealData)
    setOriginalDeal(dealData)
    // Ensure that organizations are part of dealData and not cleared
    if (dealData.organizations) {
      setNewDeal(prevDeal => ({ ...prevDeal, organizations: dealData.organizations }))
    }

    if (dealData.products) {
      const updatedContractProducts = dealData.products.map((row, index) => {
        const startDate = row && row.startDate ? row.startDate : null
        const endDate = row && row.endDate ? row.endDate : null
        const totalMonthsForProduct = calculateTotalMonthsForSingleProduct(startDate, endDate)
        console.log('[DealDetailsProvider] startDate: ', startDate, 'endDate: ', endDate, totalMonthsForProduct)
        return {
          rowId: index.toString(),
          productName: row.productName || row.selectedProduct?.title,
          description: row.description || '',
          addtlDescriptor: row.addtlDescriptor || '',
          qty: totalMonthsForProduct || row.qty || '1.00',
          price: row.price || '0.00',
          margin: row.margin || '0.00',
          mediaSpend: row.mediaSpend || '0.00',
          total: row.total || '0.00',
          startDate: row.startDate ? dayjs(row.startDate) : null,
          endDate: row.endDate ? dayjs(row.endDate) : null,
          status: row.status || '',
          grossProfit: row.grossProfit || '0.00',
          payer: row.payer || 'GRO',
          accountUsed: row.accountUsed || 'GRO',
        }
      })
      setProductRows(updatedContractProducts)
    }
  }, [dealData])

  return (
    <DealContext.Provider
      value={{
        newDeal,
        setNewDeal,
        productRows,
        setProductRows,
        originalApprovalState,
        approvalState,
        setApprovalState,
        isCreating,
        isError,
        handleSaveDeal,
        handleUpdateDeal,
        createDocument,
        updateItems,
        validateFields,
        validationErrors,
        setValidationErrors,
        isValid,
        setIsValid,
        calculateMediaSpend,
        calculateTotalForRow,
        computeTotals,
        addRow,
        removeRow,
        handleProductChange,
        handleChange,
      }}
    >
      {children}
    </DealContext.Provider>
  )
}
