import React, { useState, useEffect } from 'react'
import { Button, Table, TableBody, TableContainer } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useTheme } from '@mui/material/styles'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import useTotals from '../../api/customHooks/useTotals'
import EditDialogHeaderComponent from '../DialogHeader/EditDialogHeader'
import ContractTotals from '../ContractDialog/ContractTotals'
import { usePandaContext } from '../../context/PandaProvider/PandaProvider'
import { useCreateDocument } from '../../api/PandaDoc/useCreateDocument'
import { useUpdateDeal } from '../../api/aws/useUpdateDeal'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import RenderRow from '../ContractDialog/RenderRow'
import EditDealInfo from './EditDealInfo'
import { InfoBoxContainer } from './style'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { useActiveUser } from '../../api/slack/useActiveUser'
import DetailsLoading from '../Loading/DetailsLoading'
import { handleEditDealState } from '../DealApproval/determineApprovalState'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import isEqual from 'lodash.isequal'
import { useDeal } from '../../api/aws/useDeal'
import { useParams } from 'react-router-dom'
import { verticalsRequiringApproval } from './verticalsRequiringApproval'
import {
  calculateMonthsForDeal,
  calculateTotalMonthsForProducts,
  calculateTotalMonthsForSingleProduct,
} from '../../utility/calculateTotalMonths'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(isSameOrBefore)
const calculateTotalForRow = row => parseFloat(row.price || 0) * parseFloat(row.quantity || 1)

const calculateMediaSpend = (price, marginPercentage) => {
  const marginValue = parseFloat(price) * (parseFloat(marginPercentage) / 100)
  return (parseFloat(price) - marginValue).toFixed(2)
}

const RenderContract = ({ onSaveDeal, onClose, open, onUpdate, isEditMode }) => {
  const theme = useTheme()
  const { dealId } = useParams()
  const { deal, isDealLoading, isDealError } = useDeal(dealId)
  const [newDeal, setNewDeal] = useState(deal)
  const {
    productRows,
    setProductRows,
    originalDeal,
    originalApprovalState,
    approvalState,
    setApprovalState,
  } = useDealDetailsContext()
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(
    newDeal && Array.isArray(newDeal.organizations) ? newDeal.organizations[0] : null
  )
  const [isCreating, setIsCreating] = useState(false)
  const [isError, setIsError] = useState(false)
  const [validationErrors, setValidationErrors] = useState({
    targetLaunchDate: false,
    dependenciesDueDate: false,
    authorizedRecipient: false,
    contactRecipient: false,
    products: false,
  })
  useEffect(() => {
    if (newDeal) {
      console.log('New Deal: ', newDeal)
    }
  }, [newDeal])
  const [isValid, setIsValid] = useState(false)
  // const [productRows, setProductRows] = useProductRows(newDeal)
  const totals = useTotals(productRows, newDeal?.implementationFee || 0, newDeal.type)
  const { mutate: createDocument } = useCreateDocument()
  const { mutate: updateDeal } = useUpdateDeal()
  const { products, productsLoading, productsError } = usePandaContext()
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const { showSnackbar } = useSnackbarContext()
  const {
    organization: contractedBy,
    isOrganizationLoading: isContractedByLoading,
    isOrganizationError: isContractedByError,
  } = useOrganizationById(organization && Array.isArray(organization.contractedBy) ? organization.contractedBy[0] : [])

  useEffect(() => {
    if (newDeal && organization) {
      const updatedApprovalState = handleEditDealState(newDeal, organization, originalApprovalState)

      if (!isEqual(updatedApprovalState, originalApprovalState)) {
        setApprovalState(updatedApprovalState)
        setNewDeal(prevDeal => ({
          ...prevDeal,
          ...updatedApprovalState,
          dealStatus: prevDeal?.dealStatus || 'Active',
          name: organization ? `${organization.name} - ${newDeal.type}` : '', // Ensure name updates when type changes
        }))
      }
    }
  }, [newDeal, organization, newDeal.type, originalApprovalState]) // Add newDeal.type as a dependency

  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////

  const handleChange = (field, newValue) => {
    setNewDeal(prevDetails => {
      const updatedDeal = { ...prevDetails }

      // Ensure originalDeal exists before comparing values
      if (field === 'implementationFee') {
        updatedDeal[field] = parseFloat(newValue) || 0
      } else if (field === 'authorizedRecipient' || field === 'contactRecipient') {
        const recipients = newValue.map(recipient => ({
          id: recipient.id || recipient.contacts_uuid || recipient.user_uuid,
          email: recipient.email,
          first_name: recipient.firstName || recipient.first_name,
          last_name: recipient.lastName || recipient.last_name,
          role: field === 'authorizedRecipient' ? 'Authorized Personnel' : 'Client',
          signing_order: field === 'authorizedRecipient' ? 1 : 2,
        }))
        updatedDeal.recipients = {
          ...updatedDeal.recipients,
          [field]: recipients,
        }
      } else if (field === 'type') {
        updatedDeal[field] = newValue
        const isCSAType = ['CSA', 'RCSA'].includes(newValue)
        const isTypeMSA = newValue === 'MSA'
        updatedDeal.name = organization ? `${organization.name} - ${newValue}` : updatedDeal.name
        let needsApproval = updatedDeal.needsApproval
        if (isTypeMSA && organization) {
          const requiresApproval =
            organization.vertical &&
            Array.isArray(organization.vertical) &&
            organization.vertical.some(v => verticalsRequiringApproval.includes(v)) &&
            !['Student', 'Multifamily'].includes(organization.vertical[0])
          needsApproval = requiresApproval
        }
        updatedDeal.needsApproval = isCSAType ? true : needsApproval
      } else {
        updatedDeal[field] = newValue
      }

      const isValid = validateFields(updatedDeal)
      setIsValid(isValid)
      return updatedDeal
    })
  }

  const validateFields = deal => {
    // Ensure recipients object exists with default empty arrays
    const recipients = deal?.recipients || { authorizedRecipient: [], contactRecipient: [] }

    const errors = {
      targetLaunchDate: !deal?.targetLaunchDate,
      dependenciesDueDate: !deal?.dependenciesDueDate,
      authorizedRecipient: !(
        Array.isArray(recipients.authorizedRecipient) && recipients.authorizedRecipient.length > 0
      ), // Check if array exists and has length
      contactRecipient: !(Array.isArray(recipients.contactRecipient) && recipients.contactRecipient.length > 0), // Check if array exists and has length
      products:
        (Array.isArray(deal?.products) && deal.products.length === 0) ||
        (Array.isArray(deal?.products) && deal.products.some(product => !product.productName)),
    }

    setValidationErrors(errors)
    return !Object.values(errors).some(Boolean) // Return whether any of the errors are true
  }

  useEffect(() => {
    if (open) {
      const isValid = validateFields(newDeal)
      setIsValid(isValid)
    }
  }, [open, newDeal])
  const handleSave = async e => {
    e.preventDefault()
    setIsCreating(true)

    const updatedDeal = {
      ...newDeal,
      ...totals,
    }
    try {
      await onSaveDeal(updatedDeal, organization)
      setIsCreating(false)
    } catch (error) {
      setIsCreating(false)
      console.error(error)
    }
  }

  const submitContract = (cleanedDeal, organization, activeUser) => {
    try {
      const documentData = {
        deal: cleanedDeal,
        organization,
        activeUser,
      }

      createDocument(documentData, {
        onSuccess: data => {
          const contractId = data.id
          const dealWithContractId = {
            ...cleanedDeal,
            contractId: contractId,
            status: 'Created',
            stage: 'Contract Created',
            lastModified: new Date(),
          }
          updateDeal(
            {
              dealId: dealWithContractId.deal_uuid,
              dealData: dealWithContractId,
            },
            {
              onSuccess: () => {
                setIsCreating(false)
                onClose()
              },
              onError: error => {
                console.error('Error in updateDeal:', error)
                setIsCreating(false)
              },
            }
          )
          showSnackbar('Document successfully created', 'success')
        },
        onError: error => {
          console.error('Error in createDocument:', error)
          setIsCreating(false)
          setIsError(true)
        },
      })
    } catch (error) {
      console.error('Error in submitContract try-catch:', error)
      setIsCreating(false)
      setIsError(true)
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    setIsCreating(true)
    try {
      submitContract({ ...newDeal, creation_timestamp: new Date(), isCreated: true }, organization, activeUser)
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      setIsCreating(false)
      setIsError(true)
    }
    setIsCreating(false)
  }
  const onDragEnd = result => {
    if (!result.destination) return
    const items = Array.from(productRows)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setProductRows(items)
  }
  productRows.map((row, i) => {
    const startDate = row.startDate
    const endDate = row.endDate
  })
  const handleProductChange = (index, field, value, additionalUpdates = {}) => {
    const paidProductsList = [
      'Meta Advertising',
      'Linkedin Advertising',
      'Paid Search Advertising',
      'Google Display Advertising',
      'Spotify Advertising',
      'Youtube Advertising',
      'TikTok Advertising',
    ]

    const updatedRows = productRows.map((row, i) => {
      if (i === index) {
        const updatedRow = {
          ...row,
          [field]: value,
          ...additionalUpdates,
        }

        if (field === 'dateRange') {
          const startDate = value[0]
          const endDate = value[1]

          // Calculate total months for this product
          const totalMonthsForProduct = calculateTotalMonthsForSingleProduct(startDate, endDate)

          // Update the start and end date and the quantity (total months)
          updatedRow.startDate = startDate
          updatedRow.endDate = endDate
          updatedRow.qty = totalMonthsForProduct // Update quantity to the total months
        }

        if (field === 'selectedProduct') {
          updatedRow.productName = value ? value.title : ''
          updatedRow.price = value ? value.price : 0 // Reset price when product changes
          updatedRow.margin = additionalUpdates.margin || '0.00' // Set new margin if provided
          if (paidProductsList.includes(value && value.title)) {
            updatedRow.payer = value.payer || 'GRO'
            updatedRow.accountUsed = value.accountUsed || 'GRO'
          }
        }

        if (['price', 'margin', 'grossProfit', 'selectedProduct'].includes(field)) {
          updatedRow.mediaSpend = calculateMediaSpend(updatedRow.price, updatedRow.margin)
          updatedRow.total = calculateTotalForRow(updatedRow)
          updatedRow.grossProfit = (parseFloat(updatedRow.price) - parseFloat(updatedRow.mediaSpend)).toFixed(2)
        }

        return updatedRow
      }
      return row
    })

    setProductRows(updatedRows)
    setNewDeal(prevDetails => {
      const updatedDeal = {
        ...prevDetails,
        products: updatedRows,
      }

      const isValid = validateFields(updatedDeal)
      setIsValid(isValid)
      return updatedDeal
    })
  }

  const handleAddRow = () => {
    const startDate = newDeal.startDate ? dayjs(newDeal.startDate) : null
    const endDate = newDeal.endDate ? dayjs(newDeal.endDate) : null
    const totalMonthsForDeal = calculateMonthsForDeal(newDeal.startDate, newDeal.endDate)

    const newRow = {
      rowId: `${productRows.length + 1}`,
      productName: '',
      description: '',
      addtlDescriptor: '',
      qty: totalMonthsForDeal ? totalMonthsForDeal : '1.00',
      price: '0.00',
      margin: '0.00',
      mediaSpend: '0.00',
      total: '0.00',
      startDate,
      endDate,
      dateRange: [startDate, endDate],
      status: '',
      grossProfit: '0.00',
      selectedProduct: null,
    }

    setProductRows(prevRows => {
      const updatedRows = [...prevRows, newRow]
      setNewDeal(prevDetails => ({
        ...prevDetails,
        products: updatedRows,
      }))
      const isValid = validateFields({ ...newDeal, products: updatedRows })
      setIsValid(isValid)
      return updatedRows
    })
  }

  const handleRemoveRow = index => {
    const updatedRows = productRows.filter((_, i) => i !== index)
    setProductRows(updatedRows)
    const isValid = validateFields({ ...newDeal, products: updatedRows })
    setIsValid(isValid)
    setNewDeal(prevDetails => ({
      ...prevDetails,
      products: updatedRows,
    }))
  }

  const isLoading = isDealLoading || isOrganizationLoading || isContractedByLoading
  if (isLoading) {
    return <DetailsLoading />
  }
  return (
    <InfoBoxContainer sx={{ width: '100%' }}>
      <EditDealInfo
        key={dealId}
        selectedDeal={newDeal}
        setSelectedDeal={setNewDeal}
        organization={organization}
        approvalState={approvalState}
        contractedBy={contractedBy}
        onUpdate={onUpdate}
        isCreating={isCreating}
        onClose={onClose}
        onSave={handleSave}
        isValid={isValid}
        onCreate={handleSubmit}
        validationErrors={validationErrors}
      />
      <EditDialogHeaderComponent
        name='Deal Contract Details'
        newDeal={newDeal}
        setNewDeal={setNewDeal}
        handleChange={handleChange}
        validationErrors={validationErrors}
      />
      <TableContainer sx={{ width: '100%', marginTop: '25px' }}>
        <Table sx={{ width: '100%' }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable'>
              {provided => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {productRows.map((row, index) => (
                    <Draggable key={row.rowId} draggableId={row.rowId} index={index}>
                      {(provided, snapshot) => (
                        <RenderRow
                          key={row.rowId}
                          row={row}
                          index={index}
                          handleProductChange={handleProductChange}
                          provided={provided}
                          snapshot={snapshot}
                          handleRemoveRow={handleRemoveRow}
                          validationErrors={validationErrors}
                          type={newDeal ? newDeal.type : ''}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      <Button
        sx={{
          display: 'flex',
          marginTop: 2,
          marginBottom: 4,
          '&:hover': {
            opacity: '80%',
          },
        }}
        startIcon={<AddCircleOutlineIcon />}
        onClick={handleAddRow}
      >
        Add Service
      </Button>
      <ContractTotals newDeal={newDeal} setNewDeal={setNewDeal} totals={totals} onChange={handleChange} />
    </InfoBoxContainer>
  )
}

export default RenderContract

// import React, { useEffect } from 'react'
// import { Button, Table, TableBody, TableContainer } from '@mui/material'
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
// import { useTheme } from '@mui/material/styles'
// import dayjs from 'dayjs'
// import { useQueryClient, useMutation } from '@tanstack/react-query'
// import EditDialogHeaderComponent from '../DialogHeader/EditDialogHeader'
// import ContractTotals from '../ContractDialog/ContractTotals'
// import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
// import RenderRow from '../ContractDialog/RenderRow'
// import EditDealInfo from './EditDealInfo'
// import { InfoBoxContainer } from './style'
// import DetailsLoading from '../Loading/DetailsLoading'
// import { useDeal } from '../../api/aws/useDeal'
// import { useUpdateDeal } from '../../api/aws/useUpdateDeal'
// import { useParams } from 'react-router-dom'

// const calculateTotalForRow = row => parseFloat(row.price || 0) * parseFloat(row.quantity || 1)
// const calculateMediaSpend = (price, marginPercentage) => {
//   const marginValue = parseFloat(price) * (parseFloat(marginPercentage) / 100)
//   return (parseFloat(price) - marginValue).toFixed(2)
// }

// const RenderContract = ({ onSaveDeal, onClose, open, onUpdate, isEditMode }) => {
//   const theme = useTheme()
//   const queryClient = useQueryClient()
//   const { dealId } = useParams()
//   const { deal, isDealLoading, isDealError } = useDeal(dealId) // Fetch the deal using React Query
//   const { showSnackbar } = useSnackbarContext()
//   const { mutate: updateDeal } = useUpdateDeal() // Mutation to update deal

//   // Set deal in the React Query cache to act as a local state
//   useEffect(() => {
//     if (deal) {
//       queryClient.setQueryData(['deal', dealId], deal)
//     }
//   }, [deal, dealId, queryClient])

//   // Get the deal from the cache (acting as local state)
//   const newDeal = queryClient.getQueryData(['deal', dealId])

//   // Handle updating the deal's fields and saving the changes locally in the cache
//   const handleChange = (field, newValue) => {
//     queryClient.setQueryData(['deal', dealId], prevDeal => {
//       const updatedDeal = { ...prevDeal }

//       if (field === 'authorizedRecipient' || field === 'contactRecipient') {
//         const recipients = newValue.map(recipient => ({
//           id: recipient.id || recipient.contacts_uuid || recipient.user_uuid,
//           email: recipient.email,
//           first_name: recipient.firstName || recipient.first_name,
//           last_name: recipient.lastName || recipient.last_name,
//           role: field === 'authorizedRecipient' ? 'Authorized Personnel' : 'Client',
//           signing_order: field === 'authorizedRecipient' ? 1 : 2,
//         }))
//         updatedDeal.recipients = { ...updatedDeal.recipients, [field]: recipients }
//       } else {
//         updatedDeal[field] = newValue
//       }
//       return updatedDeal
//     })
//   }

//   // Handle saving the deal (using useMutation to send the changes to the backend)
//   const handleSave = async () => {
//     const updatedDeal = queryClient.getQueryData(['deal', dealId]) // Get updated deal from cache
//     try {
//       await onSaveDeal(updatedDeal)
//       showSnackbar('Deal saved successfully', 'success')
//     } catch (error) {
//       console.error(error)
//       showSnackbar('Error saving deal', 'error')
//     }
//   }

//   // Product row management
//   const handleProductChange = (index, field, value, additionalUpdates = {}) => {
//     queryClient.setQueryData(['deal', dealId], prevDeal => {
//       const updatedDeal = { ...prevDeal }
//       const updatedRows = updatedDeal.products.map((row, i) => {
//         if (i === index) {
//           const updatedRow = {
//             ...row,
//             [field]: value,
//             ...additionalUpdates,
//           }

//           if (field === 'dateRange') {
//             updatedRow.startDate = value[0]
//             updatedRow.endDate = value[1]
//           }

//           if (['price', 'margin', 'grossProfit', 'selectedProduct'].includes(field)) {
//             updatedRow.mediaSpend = calculateMediaSpend(updatedRow.price, updatedRow.margin)
//             updatedRow.total = calculateTotalForRow(updatedRow)
//           }

//           return updatedRow
//         }
//         return row
//       })

//       updatedDeal.products = updatedRows
//       return updatedDeal
//     })
//   }

//   const handleAddRow = () => {
//     const newRow = {
//       rowId: `id-${Math.random().toString(36).substr(2, 16)}`,
//       productName: '',
//       description: '',
//       price: '0.00',
//       margin: '0.00',
//       mediaSpend: '0.00',
//       total: '0.00',
//     }

//     queryClient.setQueryData(['deal', dealId], prevDeal => {
//       const updatedDeal = { ...prevDeal, products: [...prevDeal.products, newRow] }
//       return updatedDeal
//     })
//   }

//   const handleRemoveRow = index => {
//     queryClient.setQueryData(['deal', dealId], prevDeal => {
//       const updatedDeal = { ...prevDeal }
//       updatedDeal.products = updatedDeal.products.filter((_, i) => i !== index)
//       return updatedDeal
//     })
//   }

//   if (isDealLoading) {
//     return <DetailsLoading />
//   }

//   return (
//     <InfoBoxContainer sx={{ width: '100%' }}>
//       <EditDealInfo
//         selectedDeal={newDeal}
//         setSelectedDeal={deal => queryClient.setQueryData(['deal', dealId], deal)}
//         onSave={handleSave}
//         onClose={onClose}
//         handleChange={handleChange}
//       />
//       <EditDialogHeaderComponent name='Deal Contract Details' newDeal={newDeal} handleChange={handleChange} />
//       <TableContainer sx={{ width: '100%', marginTop: '25px' }}>
//         <Table sx={{ width: '100%' }}>
//           <DragDropContext>
//             <Droppable droppableId='droppable'>
//               {provided => (
//                 <TableBody {...provided.droppableProps} ref={provided.innerRef}>
//                   {newDeal.products.map((row, index) => (
//                     <Draggable key={row.rowId} draggableId={row.rowId} index={index}>
//                       {(provided, snapshot) => (
//                         <RenderRow
//                           key={row.rowId}
//                           row={row}
//                           index={index}
//                           handleProductChange={handleProductChange}
//                           provided={provided}
//                           snapshot={snapshot}
//                           handleRemoveRow={handleRemoveRow}
//                         />
//                       )}
//                     </Draggable>
//                   ))}
//                   {provided.placeholder}
//                 </TableBody>
//               )}
//             </Droppable>
//           </DragDropContext>
//         </Table>
//       </TableContainer>
//       <Button
//         sx={{ display: 'flex', marginTop: 2, marginBottom: 4 }}
//         startIcon={<AddCircleOutlineIcon />}
//         onClick={handleAddRow}
//       >
//         Add Service
//       </Button>
//       <ContractTotals newDeal={newDeal} setNewDeal={deal => queryClient.setQueryData(['deal', dealId], deal)} />
//     </InfoBoxContainer>
//   )
// }

// export default RenderContract
//
