import { Chip, Typography, Box, useTheme } from '@mui/material'

const RenderOrganizationChip = ({ option }) => {
  console.log('[RenderOrganizationChip] option: ', option)
  const theme = useTheme()
  const themeColor = theme.palette.mode === 'dark' ? 'white' : 'black'
  let label = ''
  let color = ''

  // Check if the type is an array and handle the first type if available
  const type = Array.isArray(option.type) && option.type.length > 0 ? option.type[0] : 'NT'

  switch (type) {
    case 'Property Management Company':
      label = 'PMC'
      color = 'rgb(93, 79, 251)'
      break
    case 'Ownership Group':
      label = 'OG'
      color = 'rgb(92, 179, 253)'
      break
    case 'Housing Property':
      label = 'HP'
      color = 'rgb(152, 214, 129)'
      break
    case 'Parent Client':
      label = 'PC'
      color = 'rgb(93, 79, 251)'
      break
    case 'Agency Whitelabel':
      label = 'AW'
      color = 'rgb(92, 179, 253)'
      break
    case 'Organization':
      label = 'ORG'
      color = 'rgb(242, 140, 40)'
      break
    case 'NT':
      label = 'NT'
      color = 'lightgrey'
      break
    default:
      label = 'NT'
      color = 'grey'
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Chip
        size='small'
        label={label}
        style={{
          height: 20,
          width: 50,
          borderColor: color,
          borderWidth: '1px',
          borderStyle: 'solid',
          color: themeColor,
          borderRadius: '4px',
          marginRight: '8px',
        }}
      />
      <Typography sx={{ color: themeColor }} variant='subtitle1'>
        {option.name}
      </Typography>
    </Box>
  )
}

export default RenderOrganizationChip
