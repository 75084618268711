import React, { useState, useEffect } from 'react'
import { Box, FormControl, Select, MenuItem, Chip, Typography } from '@mui/material'

const EditTypeSelect = ({ name, value, options, displayLabel, onChange, onBlur, hasDeals }) => {
  const [selectedValue, setSelectedValue] = useState(value ? value : '')

  useEffect(() => {
    setSelectedValue(value || '')
  }, [value])

  const handleChange = event => {
    const newValue = event.target.value
    setSelectedValue(newValue)
    onChange({ target: { name, value: newValue } })
    onBlur({ target: { name, value: newValue } }) // Trigger blur immediately after change
  }

  return (
    <Box sx={{ width: 400 }}>
      <FormControl
        fullWidth
        variant='outlined'
        //  disabled={hasDeals}
      >
        <Select
          labelId={`${name}-label`}
          id={`${name}-select`}
          value={selectedValue || ''}
          label={displayLabel}
          onChange={handleChange}
        >
          {options.map(option => (
            <MenuItem key={option.label} value={option.label}>
              <Chip
                size='small'
                label={option.type}
                style={{
                  height: 20,
                  width: 50,
                  borderColor: option.color,
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  color: 'black',
                  borderRadius: '4px',
                  marginRight: '8px',
                }}
              />
              <Typography variant='subtitle1'>{option.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default EditTypeSelect
