import { useQueries } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions'

const fetchById = async (endpoint, id) => {
  const { data } = await axios.get(`${baseUrl}${endpoint}/${id}`)
  return data
}

export const useGetQueryByIds = (endpoint, tableName, ids = [], options = {}) => {
  // Ensure ids is an array
  const validIds = Array.isArray(ids) ? ids.filter(id => !!id) : []
  // console.log('Valid IDs:', validIds) // This should show only valid IDs

  // Generate query configurations for useQueries
  const queries = validIds.map(id => ({
    queryKey: [endpoint, id],
    queryFn: () => fetchById(endpoint, id),
    enabled: !!id, // Ensure the query only runs if the ID is valid
    staleTime: options.staleTime || Infinity, // Default to Infinity if not provided
    cacheTime: options.cacheTime || 1000 * 60 * 10, // Default to 10 minutes if not provided
    onSuccess: options.onSuccess || (data => console.log('Query success:', [endpoint, id], data)),
    onError: options.onError || (error => console.error('Query error:', [endpoint, id], error)),
    onSettled: options.onSettled || (() => console.log('Query settled:', [endpoint, id])),
  }))

  return useQueries({ queries })
}
