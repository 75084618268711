// context/HeaderProvider.js

import React, { createContext, useState } from 'react'

export const HeaderContext = createContext()

export const HeaderProvider = ({ children }) => {
  const [organizationInfo, setOrganizationInfo] = useState(null)
  const [dealInfo, setDealInfo] = useState(null)
  const [contactInfo, setContactInfo] = useState(null)

  return (
    <HeaderContext.Provider
      value={{
        organizationInfo,
        setOrganizationInfo,
        dealInfo,
        setDealInfo,
        contactInfo,
        setContactInfo,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}
