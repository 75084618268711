import React, { useEffect, useState } from 'react'
import { Autocomplete, TextField, Button, Chip, Typography, Box, useTheme } from '@mui/material'
import { useCreateContact } from '../../api/aws/useCreateContact'
import OrganizationsDialog from './OrgDialog'
import { renderOption, renderTags } from '../CustomCompanyComponents/renderOptions'
import { useCreateOrganization } from '../../api/aws/useCreateOrganization'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import RenderCompanyChip from '../RenderChipComponents/RenderCompanyChip'
import { AutocompleteButton } from '../../style/styleElements'
import { useActiveUser } from '../../api/slack/useActiveUser'

const OrganizationsAutocomplete = ({ organizations, multiple = true, label, value, addOrganization, newProspect }) => {
  const theme = useTheme()
  const { showSnackbar } = useSnackbarContext()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [tempInput, setTempInput] = useState('')
  const themeColor = theme.palette.mode === 'dark' ? 'white' : 'black'
  const { data: activeSlackUser } = useActiveUser()

  // const [selectedOrganizations, setSelectedOrganizations] = useState(value || [])

  const [selectedOrganizations, setSelectedOrganizations] = useState(Array.isArray(value) ? value : [])
  const { mutate: createOrganization } = useCreateOrganization()

  const handleOpenDialog = inputValue => {
    setTempInput(inputValue)
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setTempInput('')
  }

  const handleAddOrganization = (event, newOrganization) => {
    console.log('New Organization Added: ', newOrganization)
    if (newOrganization) {
      addOrganization(newOrganization)
    } else {
      addOrganization(null)
    }
    handleCloseDialog()
  }
  const handleRemoveOrganization = orgId => {
    const newSelectedOrganizations = selectedOrganizations.filter(org => org.org_uuid !== orgId)
    setSelectedOrganizations(newSelectedOrganizations)
  }
  const handleSave = newValue => {
    console.log('[OrgAutocomplete handleSave] newValue: ', newValue)
    createOrganization(newValue, {
      onSuccess: data => {
        console.log('data: ', data)
        const newOrg = {
          ...data,
          id: data.org_uuid,
          owner: activeSlackUser && activeSlackUser.user ? [activeSlackUser.user.id] : [],
        }
        const updatedOrganizations = [...selectedOrganizations, newOrg]
        setSelectedOrganizations(updatedOrganizations)
        addOrganization(newOrg) // Ensure the newly created organization is selected
        handleCloseDialog()
        showSnackbar('Organization added successfully', 'success')
      },
    })
  }

  const filterOptions = (options, { inputValue }) => {
    let filtered = options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()))
    if (inputValue !== '' && !filtered.some(option => option.name.toLowerCase() === inputValue.toLowerCase())) {
      filtered.unshift({
        inputValue,
        specialOption: true,
        id: `add-new-${inputValue}`,
      })
    }
    return filtered
  }

  useEffect(() => {
    if (selectedOrganizations) {
      console.log('[OrgAutocomplete] selectedOrganizations: ', selectedOrganizations)
    }
  }, [selectedOrganizations])

  return (
    <>
      <Autocomplete
        multiple={multiple}
        // value={selectedOrganizations}
        id='organizations-autocomplete'
        options={organizations}
        onChange={(event, newValue) => handleAddOrganization(event, newValue)}
        getOptionLabel={option => option.name || ''}
        filterOptions={filterOptions}
        // renderOption={renderOption}
        renderOption={(props, option) => {
          const { key, id, ...rest } = props
          return option.specialOption ? (
            <AutocompleteButton key={`${key}-${id}`} {...rest} onClick={() => handleOpenDialog(option.inputValue)}>
              <Typography>Add "{option.inputValue}"</Typography>
            </AutocompleteButton>
          ) : (
            <Box key={`${key}-${id}`} {...rest}>
              <RenderCompanyChip option={option} />
            </Box>
          )
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const { key, ...rest } = getTagProps({ index })
            if (!option) {
              return null
            }
            return (
              <Box key={option.org_uuid}>
                <Chip
                  key={key}
                  label={<RenderCompanyChip option={option} />}
                  {...rest}
                  onDelete={() => handleRemoveOrganization(option.org_uuid)}
                  size='small'
                  style={{
                    height: 'auto',
                    width: 'auto',
                    borderColor: themeColor,
                    borderWidth: '0.5px',
                    borderStyle: 'solid',
                    backgroundColor: 'transparent',
                    color: themeColor,
                    borderRadius: '4px',
                    marginRight: '8px',
                  }}
                />
              </Box>
            )
          })
        }
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            sx={{
              '& .MuiInputBase-input': {
                color: selectedOrganizations ? 'lightblue' : 'inherit',
              },
            }}
          />
        )}
        // renderInput={params => <TextField {...params} label={label} />}
        fullWidth
        freeSolo
      />
      <OrganizationsDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        tempInput={tempInput}
        onSave={handleSave}
        newProspect={newProspect}
      />
    </>
  )
}

export default OrganizationsAutocomplete
