import React, { useState, useEffect, useMemo } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableContainer,
  Paper,
  Box,
  Tooltip,
} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useTheme } from '@mui/material/styles'
import dayjs from 'dayjs'

import useSaveDeal from '../../api/customHooks/useSaveDeal'
import useProductRows from '../../api/customHooks/useProductRows'
import useTotals from '../../api/customHooks/useTotals'
import EditDialogHeaderComponent from '../DialogHeader/EditDialogHeader'
import EditContractRenderRow from './EditContractRenderRow'
import ContractTotals from '../ContractDialog/ContractTotals'
import { SaveButton, CreateButton } from './style'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { editFormatForPanda } from '../PandaDoc/formatForPanda'
import { dialogStyles } from './style'
import { useCreateDocument } from '../../api/PandaDoc/useCreateDocument'
import { useUpdateDeal } from '../../api/aws/useUpdateDeal'
import { BoxScroller } from '../../style/styleElements'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import WarningIcon from '@mui/icons-material/Warning'
import PandaDocImageComponent from '../AvatarImageComponents/PandaDocImageComponent'
import RenderRow from '../ContractDialog/RenderRow'
import DetailsLoading from '../Loading/DetailsLoading'
import GridLoading from '../Loading/GridLoading'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { determineApprovalState, handleEditDealState } from '../DealApproval/determineApprovalState'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import isEqual from 'lodash.isequal'
import { calculateMonthsForDeal } from '../../utility/calculateTotalMonths'

const calculateTotalForRow = row => parseFloat(row.price || 0) * parseFloat(row.quantity || 1)

const calculateMediaSpend = (price, marginPercentage) => {
  const marginValue = parseFloat(price) * (parseFloat(marginPercentage) / 100)
  return (parseFloat(price) - marginValue).toFixed(2)
}
const verticalsRequiringApproval = [
  'Construction',
  'Tourism',
  'Marketing Agency',
  'Manufacturing',
  'Entertainment',
  'Restaurant (Food & Beverage)',
  'City/Government',
  'Financial Institution',
  'Technology',
  'Medical',
  'Lawyer',
  'Education',
  'Senior',
  'Homebuilding',
  'Build to rent',
]

const EditContractDialog = ({ open, onClose }) => {
  const theme = useTheme()
  const {
    newDeal,
    setNewDeal,
    productRows,
    setProductRows,
    originalDeal,
    originalApprovalState,
    approvalState,
    setApprovalState,
  } = useDealDetailsContext()
  const [editedDeal, setEditedDeal] = useState(newDeal)
  const [testLoading, setTestLoading] = useState(true)
  const [isCreating, setIsCreating] = useState(false)
  const [isError, setIsError] = useState(false)
  const [validationErrors, setValidationErrors] = useState({
    targetLaunchDate: false,
    dependenciesDueDate: false,
    authorizedRecipient: false,
    contactRecipient: false,
    products: false,
  })
  const csaTypes = ['CSA', 'RCSA']
  const [isValid, setIsValid] = useState(false)
  const totals = useTotals(productRows, newDeal?.implementationFee || 0, newDeal?.type)
  const { showSnackbar } = useSnackbarContext()
  const { mutate: saveDeal } = useSaveDeal()
  const { mutate: createDocument } = useCreateDocument()
  const { mutate: updateDeal } = useUpdateDeal()
  const orgId = newDeal && Array.isArray(newDeal.organizations) ? newDeal.organizations[0] : []
  console.log('ORGID: ', orgId)
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId)
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()

  useEffect(() => {
    if (newDeal && organization) {
      const updatedApprovalState = handleEditDealState(newDeal, organization, originalApprovalState)

      // Only update if the approval state has actually changed
      if (!isEqual(updatedApprovalState, originalApprovalState)) {
        setApprovalState(updatedApprovalState)

        setNewDeal(prevDeal => ({
          ...prevDeal,
          ...updatedApprovalState,
          dealStatus: prevDeal?.dealStatus || 'Active',
          name: organization ? `${organization.name} - ${newDeal.type}` : '',
        }))
      } else {
        setApprovalState(updatedApprovalState)
      }
    }
  }, [newDeal, organization, originalApprovalState])
  // Re-run when deal type or noSignature changes

  const handleSave = event => {
    event.preventDefault()
    setIsCreating(true)

    // Use handleEditDealState to calculate the deal state before saving
    const updatedApprovalState = determineApprovalState(newDeal, organization)

    saveDeal(
      {
        dealId: newDeal.deal_uuid,
        dealData: {
          ...newDeal,
          status: newDeal.noSignature ? 'Completed Internally' : updatedApprovalState.status,
          stage: newDeal.noSignature ? 'Deal Won' : updatedApprovalState.stage,
          needsApproval: updatedApprovalState.needsApproval,
          hasProducts: updatedApprovalState.hasProducts, // Ensuring hasProducts is updated
          lastModified: new Date(),
        },
      },
      {
        onSuccess: () => {
          setIsCreating(false)
          onClose()
        },
        onError: () => {
          setIsCreating(false)
          setIsError(true)
        },
      }
    )
  }

  const submitContract = (newDeal, organization, activeUser) => {
    try {
      const documentData = {
        deal: newDeal,
        organization,
        activeUser,
      }

      createDocument(documentData, {
        onSuccess: data => {
          const contractId = data.id
          const dealWithContractId = {
            ...newDeal,
            contractId: contractId,
            status: 'Created',
            stage: 'Contract Created',
            lastModified: new Date(),
            isCreated: true,
          }
          updateDeal(
            {
              dealId: dealWithContractId.deal_uuid,
              dealData: dealWithContractId,
            },
            {
              onSuccess: () => {
                setIsCreating(false)
                onClose()
              },
              onError: error => {
                console.error('Error in updateDeal:', error)
                setIsCreating(false)
              },
            }
          )
          showSnackbar('Document successfully created', 'success')
        },
        onError: error => {
          console.error('Error in createDocument:', error)
          setIsCreating(false)
          setIsError(true)
        },
      })
    } catch (error) {
      console.error('Error in submitContract try-catch:', error)
      setIsCreating(false)
      setIsError(true)
    }
  }
  const handleSubmit = event => {
    event.preventDefault()
    setIsCreating(true)
    try {
      submitContract({ ...newDeal, creation_timestamp: new Date() }, organization, activeUser)
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      setIsCreating(false)
      setIsError(true)
    }
    setIsCreating(false)
  }
  useEffect(() => {
    if (newDeal) {
      console.log('Organizations before change:', newDeal.organizations)
    }
  }, [newDeal])
  const handleChange = (field, newValue) => {
    setNewDeal(prevDetails => {
      const updatedDeal = { ...prevDetails }

      // Preserve the organizations array
      if (!updatedDeal.organizations) {
        updatedDeal.organizations = prevDetails.organizations
      }

      // Update field-specific logic
      if (field === 'implementationFee') {
        updatedDeal[field] = parseFloat(newValue) || 0
      } else if (field === 'authorizedRecipient' || field === 'contactRecipient') {
        const recipients = newValue.map(recipient => ({
          id: recipient.id || recipient.contacts_uuid || recipient.user_uuid,
          email: recipient['Employee Email'] || recipient.email,
          first_name: recipient['First Name'] || recipient.firstName || recipient.first_name,
          last_name: recipient['Last Name'] || recipient.lastName || recipient.last_name,
          role: field === 'authorizedRecipient' ? 'Authorized Personnel' : 'Client',
          signing_order: field === 'authorizedRecipient' ? 1 : 2,
        }))
        updatedDeal.recipients = {
          ...updatedDeal.recipients,
          [field]: recipients,
        }
      } else if (field === 'type') {
        updatedDeal[field] = newValue
        const isCSAType = ['CSA', 'RCSA'].includes(newValue)
        const isTypeMSA = newValue === 'MSA'
        updatedDeal.name = organization ? `${organization.name} - ${newValue}` : updatedDeal.name

        let needsApproval = updatedDeal.needsApproval
        if (isTypeMSA && organization) {
          const requiresApproval =
            organization.vertical &&
            Array.isArray(organization.vertical) &&
            organization.vertical.some(v => verticalsRequiringApproval.includes(v)) &&
            !['Student', 'Multifamily', 'Student Housing'].includes(organization.vertical[0])

          needsApproval = requiresApproval
        }

        // Update the deal with recalculated approval state
        updatedDeal.needsApproval = isCSAType ? true : needsApproval
      } else {
        updatedDeal[field] = newValue
      }

      // Recalculate deal state using determineApprovalState
      // const recalculatedDeal = determineApprovalState(updatedDeal, organization)
      const isValid = validateFields(updatedDeal)
      // const isValid = validateFields(recalculatedDeal)
      setIsValid(isValid)

      // return recalculatedDeal
      return updatedDeal
    })
  }

  const validateFields = deal => {
    // Ensure recipients object exists with default empty arrays
    const recipients = deal?.recipients || { authorizedRecipient: [], contactRecipient: [] }

    const errors = {
      targetLaunchDate: !deal?.targetLaunchDate,
      dependenciesDueDate: !deal?.dependenciesDueDate,
      authorizedRecipient: !(
        Array.isArray(recipients.authorizedRecipient) && recipients.authorizedRecipient.length > 0
      ), // Check if array exists and has length
      contactRecipient: !(Array.isArray(recipients.contactRecipient) && recipients.contactRecipient.length > 0), // Check if array exists and has length
      products:
        (Array.isArray(deal?.products) && deal.products.length === 0) ||
        (Array.isArray(deal?.products) && deal.products.some(product => !product.productName)),
    }

    setValidationErrors(errors)
    return !Object.values(errors).some(Boolean) // Return whether any of the errors are true
  }
  useEffect(() => {
    if (open) {
      const isValid = validateFields(newDeal)
      setIsValid(isValid)
    }
  }, [open, newDeal])

  const handleProductChange = (index, field, value, additionalUpdates = {}) => {
    const paidProductsList = [
      'Meta Advertising',
      'Linkedin Advertising',
      'Paid Search Advertising',
      'Google Display Advertising',
      'Spotify Advertising',
      'Youtube Advertising',
    ]
    console.log('field: ', field, 'value: ', value)
    const updatedRows = productRows.map((row, i) => {
      if (i === index) {
        const updatedRow = { ...row, [field]: value, ...additionalUpdates }
        if (['price', 'margin', 'grossProfit'].includes(field)) {
          updatedRow.mediaSpend = calculateMediaSpend(updatedRow.price, updatedRow.margin)
          updatedRow.total = calculateTotalForRow(updatedRow)
          updatedRow.grossProfit = updatedRow.price - updatedRow.mediaSpend
        }
        if (field === 'selectedProduct') {
          updatedRow.productName = value ? value.title : ''
          updatedRow.price = value ? value.price : 0 // Reset price when product changes
          updatedRow.margin = additionalUpdates.margin || '0.00' // Set new margin if provided
          // Add this logic to handle specific product titles
          if (paidProductsList.includes(value && value.title)) {
            updatedRow.payer = 'GRO'
            updatedRow.accountUsed = 'GRO'
          }
        }
        if (['dateRange'].includes(field)) {
          updatedRow.startDate = value[0]
          updatedRow.endDate = value[1]
        }
        return updatedRow
      }
      return row
    })

    setProductRows(updatedRows)
    setNewDeal(prevDetails => {
      const updatedDeal = {
        ...prevDetails,
        products: updatedRows,
        ...totals,
      }

      const isValid = validateFields(updatedDeal)
      setIsValid(isValid)
      return updatedDeal
    })
  }
  useEffect(() => {
    setNewDeal(prevDetails => ({
      ...prevDetails,
      totals,
    }))
  }, [totals])
  const handleAddRow = () => {
    const startDate = newDeal.startDate ? dayjs(newDeal.startDate) : null
    const endDate = newDeal.endDate ? dayjs(newDeal.endDate) : null
    const totalMonthsForDeal = calculateMonthsForDeal(newDeal.startDate, newDeal.endDate)

    const newRow = {
      rowId: `${productRows.length + 1}`,
      productName: '',
      description: '',
      addtlDescriptor: '',
      qty: totalMonthsForDeal ? totalMonthsForDeal : '1.00',
      price: '0.00',
      margin: '0.00',
      mediaSpend: '0.00',
      total: '0.00',
      startDate,
      endDate,
      dateRange: [startDate, endDate],
      status: '',
      grossProfit: '0.00',
      selectedProduct: null,
    }

    setProductRows(prevRows => {
      const updatedRows = [...prevRows, newRow]
      setNewDeal(prevDetails => ({
        ...prevDetails,
        products: updatedRows,
      }))
      const isValid = validateFields({ ...newDeal, products: updatedRows })
      setIsValid(isValid)
      return updatedRows
    })
  }

  const handleRemoveRow = index => {
    const updatedRows = productRows.filter((_, i) => i !== index)
    setProductRows(updatedRows)
    setNewDeal(prevDetails => ({
      ...prevDetails,
      products: updatedRows,
    }))
  }

  const onDragEnd = result => {
    if (!result.destination) return
    const items = Array.from(productRows)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setProductRows(items)
  }

  if (isOrganizationLoading) {
    return <DetailsLoading />
  }

  return (
    <>
      {/* {isOrganizationLoading ? (
        <GridLoading />
      ) : ( */}
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth='xl'
        PaperProps={{
          sx: dialogStyles(theme),
        }}
      >
        <EditDialogHeaderComponent
          name='Edit Contracts'
          newDeal={newDeal}
          setNewDeal={setNewDeal}
          handleChange={handleChange}
          validationErrors={validationErrors}
        />
        <BoxScroller
          sx={{
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.mode === 'dark' ? 'rgba(167,51,51,0.65)' : 'rgba(167,51,51,0.65)',
              borderRadius: '6px',
            },
          }}
        >
          <DialogContent>
            <TableContainer component={Paper}>
              <Table>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='droppable'>
                    {provided => (
                      <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                        {productRows.map((row, index) => {
                          return (
                            <Draggable key={row.rowId} draggableId={row.rowId} index={index}>
                              {(provided, snapshot) => (
                                <RenderRow
                                  key={row.rowId}
                                  row={row}
                                  index={index}
                                  handleProductChange={handleProductChange}
                                  provided={provided}
                                  snapshot={snapshot}
                                  handleRemoveRow={handleRemoveRow}
                                  validationErrors={validationErrors}
                                  type={newDeal ? newDeal.type : ''}
                                />
                              )}
                            </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
            <Button
              sx={{
                display: 'flex',
                '&:hover': {
                  opactity: 0.8,
                },
              }}
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleAddRow}
            >
              Add Service
            </Button>
          </DialogContent>
        </BoxScroller>
        <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <ContractTotals
            newDeal={newDeal}
            setNewDeal={setNewDeal}
            totals={totals}
            onChange={handleChange}
            organization={organization}
          />
          <Box sx={{ display: 'flex', flexDirection: 'row', marginRight: -4 }} gap={1}>
            <Box sx={{ display: 'flex', paddingTop: 10 }} gap={1}>
              {isCreating && <CircularProgress sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }} />}
              {isError && <Typography color='error'>Error creating deal</Typography>}
              <Button onClick={onClose} color='primary' variant='outlined'>
                Cancel
              </Button>
              <SaveButton onClick={handleSave} color='secondary' variant='contained'>
                Save
              </SaveButton>
              {newDeal && !newDeal.noSignature && (
                <Tooltip title={newDeal && !newDeal.isApproved ? 'Deal needs approval before creating contract' : ''}>
                  <Box>
                    <CreateButton
                      onClick={handleSubmit}
                      disabled={
                        (newDeal.needsApproval && !newDeal.isApproved) ||
                        csaTypes.includes(newDeal.type) ||
                        !isValid ||
                        isCreating
                      }
                      color='primary'
                      variant='contained'
                      startIcon={
                        (newDeal.needsApproval && !newDeal.isApproved) ||
                        (csaTypes.includes(newDeal.type) && newDeal.needsApproval && !newDeal.isApproved) ? (
                          <WarningIcon sx={{ color: 'crimson' }} />
                        ) : (
                          <PandaDocImageComponent size={30} />
                        )
                      } // Include the icon here
                    >
                      Create Contract
                    </CreateButton>
                  </Box>
                </Tooltip>
              )}

              {/* <CreateButton onClick={handleSubmit} color='primary' variant='contained'>
              Create Contract
            </CreateButton> */}
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      {/* )} */}
    </>
  )
}

export default EditContractDialog
