import React, { useState, useEffect } from 'react'
import { Box, Typography, Button, List, ListItem, ListItemText, IconButton, Avatar, Paper, Grid } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { v4 as uuidv4 } from 'uuid' // For generating unique note IDs
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useParams } from 'react-router-dom'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { useUsersById } from '../../api/customHooks/useUsersById'
import DetailsLoading from '../Loading/DetailsLoading'
import { useActiveUser } from '../../api/slack/useActiveUser'
import RichTextField from '../ContractDialog/MuiRichTextField'

const OrganizationNotes = () => {
  const { orgId } = useParams()
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId)
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const [newNoteContent, setNewNoteContent] = useState('')
  const [notes, setNotes] = useState([])
  const { mutate: updateItems } = useUpdateItems()
  const { showSnackbar } = useSnackbarContext()

  // Extract user IDs from notes
  const userIds = notes.flatMap(note => note.owner || [])
  const { users, isLoading: isUsersLoading } = useUsersById(userIds)

  useEffect(() => {
    if (organization && organization.notes) {
      const sortedNotes = [...organization.notes].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      setNotes(sortedNotes)
    }
  }, [organization])

  const handleClearNoteContent = () => {
    setNewNoteContent('')
  }
  const handleAddNote = () => {
    if (newNoteContent) {
      const newNote = {
        note_id: uuidv4(),
        contents: newNoteContent,
        owner: activeUser && activeUser.user ? [activeUser.user.id] : [],
        createdAt: new Date().toISOString(),
      }

      const updatedNotes = [...notes, newNote]
      setNotes(updatedNotes)

      updateItems(
        {
          updates: [
            {
              primaryKey: 'org_uuid',
              tableName: 'organizations',
              itemId: organization.org_uuid,
              fieldName: 'notes',
              newValue: updatedNotes,
            },
          ],
        },
        {
          onSuccess: () => {
            showSnackbar('Note added successfully', 'success')
            setNewNoteContent('') // Clear the input after successful addition
          },
          onError: err => {
            console.error('Failed to add note:', err)
            showSnackbar('Failed to add note', 'error')
          },
        }
      )
    }
  }

  const handleDeleteNote = noteId => {
    const updatedNotes = notes.filter(note => note.note_id !== noteId)
    setNotes(updatedNotes)

    updateItems(
      {
        updates: [
          {
            primaryKey: 'org_uuid',
            tableName: 'organizations',
            itemId: organization.org_uuid,
            fieldName: 'notes',
            newValue: updatedNotes,
          },
        ],
      },
      {
        onSuccess: () => {
          showSnackbar('Note deleted successfully', 'success')
        },
        onError: err => {
          console.error('Failed to delete note:', err)
          showSnackbar('Failed to delete note', 'error')
        },
      }
    )
  }

  if (isOrganizationLoading || isUsersLoading) {
    return <DetailsLoading />
  }

  if (isOrganizationError || !organization) {
    return <Typography>Error loading organization details or no organization data available.</Typography>
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h6' gutterBottom>
          Notes
        </Typography>

        {/* Note addition form */}
        <Box component={Paper} elevation={2} sx={{ p: 2, mb: 4 }}>
          <RichTextField content={newNoteContent} onContentChange={setNewNoteContent} justify={'left'} />
          <Button
            variant='contained'
            startIcon={<AddIcon />}
            onClick={handleAddNote}
            sx={{ alignSelf: 'flex-start', mt: 2 }}
          >
            Add Note
          </Button>
        </Box>
      </Grid>

      {/* List of notes */}
      <Grid item xs={12}>
        <List>
          {notes.map(note => {
            const noteOwner = users.find(user => user.id === note.owner?.[0])
            return (
              <ListItem
                key={note.note_id}
                component={Paper}
                elevation={1}
                sx={{ mb: 2, p: 2, display: 'flex', alignItems: 'flex-start' }}
              >
                {noteOwner && (
                  <Avatar alt={noteOwner.name} src={noteOwner.image_512} sx={{ width: 48, height: 48, mr: 2 }} />
                )}
                <ListItemText
                  primary={
                    <>
                      {noteOwner && (
                        <Typography variant='subtitle2' fontWeight='bold'>
                          {noteOwner.name} - {new Date(note.createdAt).toLocaleString()}
                        </Typography>
                      )}
                    </>
                  }
                  secondary={<Typography variant='body2' dangerouslySetInnerHTML={{ __html: note.contents }} />}
                />
                <IconButton edge='end' aria-label='delete' onClick={() => handleDeleteNote(note.note_id)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            )
          })}
        </List>
      </Grid>
    </Grid>
  )
}

export default OrganizationNotes
