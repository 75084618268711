import React, { useState } from 'react'
import {
  Box,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  useTheme,
  Grid,
  IconButton,
} from '@mui/material'
import { calculateAccountingSchedule } from './calculateAccountingSchedule'
import { useParams } from 'react-router-dom'
import { useDeal } from '../../api/aws/useDeal'
import { formatCurrency } from '../DealsKanban/aggregateStageTotals'
import { tableCellStyles, tableHeaderStyles } from '../DealApproval/style'
import RenderServiceIcon from '../RenderServiceIcons/RenderServiceIcon'
import IOTotalSchedule from './IOTotalSchedule'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import DownloadIcon from '@mui/icons-material/Download'
import { downloadContract } from '../Deals/downloadContract'
import PandaDocDownload from './PandaDocDownload'

const AccountingSchedule = () => {
  const { dealId } = useParams()
  const { deal, isDealLoading, isDealError } = useDeal(dealId)
  const [isDownloading, setIsDownloading] = useState(false)
  const theme = useTheme()

  if (isDealLoading) return <Typography>Loading...</Typography>
  if (isDealError) return <Typography>Error loading payment schedule</Typography>

  let paymentSchedule = calculateAccountingSchedule(deal)

  // Sort the paymentSchedule array by startDate (only for non-CSA)
  if (!['CSA', 'RCSA'].includes(deal.type)) {
    paymentSchedule = paymentSchedule.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
  }
  const handleDownloadContract = async () => {
    setIsDownloading(true)
    try {
      await downloadContract(deal ? deal.contractId : null)
      setIsDownloading(false)
    } catch (e) {
      setIsDownloading(false)
      console.log('Error: ', e)
    }
  }
  return (
    <Box
      sx={{
        marginTop: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {deal && deal.type !== 'IO' ? (
        <Grid container spacing={1} sx={{ maxWidth: '80vw' }}>
          {paymentSchedule.map((item, index) => (
            <Grid item xs={12} key={`${index} - ${item.paymentNumber || item.month}`}>
              <Table sx={{ marginBottom: 2 }}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} sx={tableHeaderStyles}>
                      {item.startDate && item.endDate
                        ? `${item.startDate} - ${item.endDate}`
                        : `Payment ${item.paymentNumber}`}
                      {deal && deal.status === 'Completed' && (
                        <PandaDocDownload onDownload={handleDownloadContract} isDownloading={isDownloading} />
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.products.map((product, idx) => (
                    <TableRow key={`${index}-${idx}-${product.productName}`}>
                      <TableCell sx={tableCellStyles(theme)}>
                        {<RenderServiceIcon service={product.productName} />}
                      </TableCell>
                      <TableCell sx={tableCellStyles(theme)}>{formatCurrency(product.paymentAmount)}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell
                      sx={{
                        ...tableCellStyles(theme),
                        textAlign: 'right',
                        borderRight: 'none',
                      }}
                      colSpan={1}
                    >
                      Total:
                    </TableCell>
                    <TableCell
                      sx={{
                        ...tableCellStyles(theme),
                        color: 'rgb(50, 187, 130)',
                        borderLeft: 'none',
                      }}
                      colSpan={1}
                    >
                      {formatCurrency(item.totalAmount)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          ))}
        </Grid>
      ) : (
        <IOTotalSchedule deal={deal} isDownloading={isDownloading} onDownload={handleDownloadContract} />
      )}
    </Box>
  )
}

export default AccountingSchedule
