import isEqual from 'lodash.isequal'

export const determineApprovalState = (selectedDeal, organization) => {
  const csaTypes = ['CSA', 'RCSA']
  const msaTypes = ['MSA', 'RMSA', 'MSA - Housing', 'RMSA - Housing']
  const typesToSkipApproval = ['IO']
  const verticalsToSkipApproval = ['Student', 'Multifamily', 'Student Housing']

  let approvalState = {
    hasProducts: selectedDeal && Array.isArray(selectedDeal.products) && selectedDeal.products.length > 0,
    needsApproval: false,
    status: selectedDeal?.status || 'Draft', // Preserve existing status if present
    stage: selectedDeal?.stage || 'Discovery', // Preserve existing stage if present
  }

  if (csaTypes.includes(selectedDeal?.type)) {
    approvalState.needsApproval = true
    approvalState.stage = approvalState.hasProducts ? 'Pending Approval' : 'Discovery'
    approvalState.status = approvalState.hasProducts ? 'Needs Approval' : 'Draft'
  } else if (msaTypes.includes(selectedDeal?.type)) {
    const needsApproval = !verticalsToSkipApproval.includes(
      organization && organization.vertical && Array.isArray(organization.vertical) ? organization.vertical[0] : ''
    )
    approvalState.needsApproval = needsApproval
    if (needsApproval) {
      approvalState.stage = approvalState.hasProducts ? 'Pending Approval' : 'Discovery'
      approvalState.status = approvalState.hasProducts ? 'Needs Approval' : 'Draft'
    } else {
      approvalState.stage = approvalState.hasProducts ? 'Proposal' : 'Discovery'
      approvalState.status = approvalState.hasProducts ? 'Saved' : 'Draft'
    }
  } else if (typesToSkipApproval.includes(selectedDeal?.type)) {
    approvalState.needsApproval = false
    approvalState.stage = approvalState.hasProducts ? 'Proposal' : 'Discovery'
    approvalState.status = approvalState.hasProducts ? 'Saved' : 'Draft'
  }

  if (selectedDeal?.noSignature) {
    approvalState.needsApproval = false
    approvalState.stage = 'Deal Won'
    approvalState.status = 'Completed Internally'
  }

  return approvalState
}

export const handleEditDealState = (existingDeal, organization, originalApprovalState) => {
  const csaTypes = ['CSA', 'RCSA']
  const msaTypes = ['MSA', 'RMSA', 'MSA - Housing', 'RMSA - Housing']
  const typesToSkipApproval = ['IO']
  const verticalsToSkipApproval = ['Student', 'Multifamily', 'Student Housing']

  // Initialize the original state and the updated state
  const originalState = {
    hasProducts: existingDeal?.products?.length > 0,
    needsApproval: existingDeal?.needsApproval ?? false,
    status: existingDeal?.status ?? 'Draft',
    stage: existingDeal?.stage ?? 'Discovery',
  }

  let updatedDealState = { ...originalState }

  // Apply your logic to update the deal state
  if (csaTypes.includes(existingDeal?.type)) {
    updatedDealState.needsApproval = true
    if (updatedDealState.hasProducts) {
      updatedDealState.status = 'Needs Approval'
      updatedDealState.stage = 'Pending Approval'
    }
  } else if (msaTypes.includes(existingDeal?.type)) {
    const needsApproval = !verticalsToSkipApproval.includes(organization?.vertical?.[0] || '')
    updatedDealState.needsApproval = needsApproval

    if (needsApproval && updatedDealState.hasProducts) {
      updatedDealState.status = 'Needs Approval'
      updatedDealState.stage = 'Pending Approval'
    } else if (!needsApproval && updatedDealState.hasProducts) {
      updatedDealState.status = 'Saved'
      updatedDealState.stage = 'Proposal'
    }
  } else if (typesToSkipApproval.includes(existingDeal?.type)) {
    updatedDealState.needsApproval = false
    if (updatedDealState.hasProducts) {
      updatedDealState.status = 'Saved'
      updatedDealState.stage = 'Proposal'
    }
  }

  if (existingDeal?.noSignature) {
    updatedDealState.needsApproval = false
    updatedDealState.status = 'Completed Internally'
    updatedDealState.stage = 'Deal Won'
  }

  // Use lodash's isEqual to deeply compare originalState and updatedDealState
  if (isEqual(updatedDealState, originalState)) {
    return originalApprovalState
  } else {
    return updatedDealState
  }
}
