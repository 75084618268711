import { createTheme } from '@mui/material/styles'
import { grey, blueGrey, red } from '@mui/material/colors'

export const lightTheme = createTheme({
  palette: {
    type: 'light',
    mode: 'light',
    primary: {
      lighter: blueGrey[100],
      light: blueGrey[300],
      main: 'rgba(114, 112, 114, 0.7)',
      dark: blueGrey[700],
      darker: blueGrey[900],
      darkest: 'black',
    },
    background: {
      default: 'rgb(232, 246, 252)',
      main: blueGrey[300],
      paper: 'rgba(255,255,255,1)',
      tableRow: 'rgba(255,255,255,1)',
    },
    secondary: {
      light: '#293742',
      main: grey[50],
      dark: grey[50],
      darker: red[900],
    },
    tertiary: {
      lighter: grey[50],
      light: grey[100],
      main: grey[400],
      dark: grey[700],
      darker: grey[900],
    },
  },
  typography: {
    fontFamily: '"Muli", sans-serif',
    // fontFamily: ''
    fontSize: 14,
    h1: {
      fontSize: '2.5rem',
    },
  },
  spacing: 8,
  hover: 'rgba(0,0,0, 0.7)',
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
    myComponentBackground: 'rgba(255,255,255,1)',
  },
  shadows: Array(25)
    .fill('none')
    .map((_, index) =>
      index === 0
        ? 'none'
        : `0px ${index}px ${index * 2}px rgba(0, 0, 0, 0.14), 
    0px ${index / 2}px ${index}px rgba(0, 0, 0, 0.12), 
    0px ${index}px ${index * 3}px rgba(0, 0, 0, 0.2)`
    ),

  components: {
    // MuiCssBaseline: {
    //   styleOverrides: `
    //     * {
    //       scrollbar-width: thin; // For Firefox
    //       scrollbar-color: rgba(255,255,255,0.5) #2c2f33; // For Firefox
    //     }
    //     *::-webkit-scrollbar {
    //       width: 6px; // For WebKit browsers
    //       height: 6px;
    //     }
    //     *::-webkit-scrollbar-track {
    //       background: #2c2f33;
    //     }
    //     *::-webkit-scrollbar-thumb {
    //       background-color: rgba(255,255,255,0.5);
    //       border-radius: 3px;
    //     }
    //   `,
    // },
    MyCustomQuill: {
      light: {
        height: '150px',
        borderColor: 'rgba(0,0,0,0.22)',
        color: 'black',
      },
      dark: {
        height: '100%',
        borderColor: 'rgba(255, 255, 255, 0.12)',
        color: 'rgba(255,255,255,1)',
        backgroundColor: 'rgb(30 43 59)',
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // backgroundColor: '#eeeeee', // Adjust as needed
          color: grey[500], // Text color for Paper
          alignContent: 'center',
          textAlign: 'center',
          display: 'flexbox',
          width: 'auto',
          borderRadius: '5px', // Rounded corners for Paper components
          // '&:hover': {
          //   // backgroundColor: '#eeeeee',
          //   borderWidth: 0,
          //   // boxShadow: 'none',
          // },
          boxShadow: 'none',
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          marginRight: '30px',
          marginLeft: '30px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'black',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderColor: 'black',
          color: 'black',
          '&:hover': {
            backgroundColor: grey[400],
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: 'none',
          // elevation: 3,
          // boxShadow:
          //   '0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12), 0px 7px 8px -4px rgba(0, 0, 0, 0.2)',
        },
      },
    },

    // Style for TableHead
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: blueGrey[100], // Light blueGrey background for the table head
        },
      },
    },
    // Style for TableBody
    MuiTableBody: {
      styleOverrides: {
        root: {
          // backgroundColor: blueGrey[500], // Lighter blueGrey background for the table body
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          width: '20%',
          // maxWidth: '400px',
          // maxHeight: '600px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'rgb(0,0,0,0.5)',
          // Adjust these styles as needed to align your labels
          // left: 'initial',
          // paddingLeft: '5px', // or any value that aligns it correctly
          '&.Mui-focused': {
            color: 'black',
          },
        },
        outlined: {
          fontSize: '16px',
          transform: 'translate(9px, 11px) scale(1)', // Adjust the first value as needed
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -10px) scale(0.75)', // Adjust the first value as needed
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        // Override styles for the root of the Autocomplete component if needed
        option: {
          height: 'auto',
          '&:hover': {
            backgroundColor: 'f5f5f5',
            cursor: 'pointer', // Add a pointer cursor on hover
          },
        },
        input: {
          width: '100%',
          // height: '1.1876em', // Set a consistent height, align with MuiInputBase if needed
          // padding: '10px 14px', // Adjust padding to control the overall height
        },
        root: {
          width: '100%',
          height: '100%',
        },
        popper: {
          // overflow: 'clip',
          // width: 'auto',
          width: '100%',
        },
        paper: {
          textAlign: 'left',
          width: 'auto',
        },
        inputRoot: {
          // Apply styles to the root of the input component inside Autocomplete
          '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-notchedOutline': {
              // borderColor: '#6cd3e7', // Default border color
              // borderColor: 'red', // Default border color
              borderWidth: '1px', // Ensure border width is enough to be visible
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'blue', // Hover border color
              borderWidth: '2px', // Optional: Change border width on hover
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(97, 229, 155, 0.5)',
              borderWidth: '2px', // Optional: Change border width on focus
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(97, 229, 155, 0.5)', // Custom border color for the outline when focused
              },
            },
            // input: {
            //   '&:focus': {
            //     color: 'red', // Custom text color when focused
            //   },
            // },
          },
          color: 'rgba(97, 229, 155)',
        },
        // inputRoot: {
        //   padding: '0px',
        //   height: '50px',
        //   '& .MuiAutocomplete-input': {
        //     height: 'auto',
        //   }, // Set the height to auto for the input element itself
        //   // Remove border, outline, and padding
        //   '& .MuiOutlinedInput-notchedOutline': {
        //     borderWidth: '0', // Remove border
        //   },
        //   '& .MuiInputLabel-root': {
        //     // Override label styles
        //     fontSize: '1rem', // Example: change font size
        //     whiteSpace: 'nowrap', // Example: prevent wrapping
        //     // overflow: 'visible', // Example: make sure it's not hidden
        //   },
        //   '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root': {
        //     padding: '0px !important', // Override padding right when both icons are present
        //   },
        // },
        // Overrides for the end adornment (icons on the end)
        endAdornment: {
          // Hide the dropdown arrow icon
          display: 'none',
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          // borderBottom: '1px solid black',
          border: 'none',
          backgroundColor: 'rgba(255,255,255,1)',
          paddingBottom: '16px', // Adjust the value as needed
          marginBottom: '20px',
          borderBottom: '8px solid transparent',
        },
        head: {
          // color: grey[50], // Lighter text for better contrast
          fontWeight: 'bold', // Bold font weight for table head cells
          textAlign: 'center',
        },
        body: {
          textAlign: 'center',
          margin: '5px',
          wordWrap: 'break-word',
          whiteSpace: 'normal',
          textOverflow: 'hidden',
          height: '2px',
          lineHeight: '1.25',
          fontSize: '14px',
          justifyContent: 'center', // Center content horizontally
          alignItems: 'center', // Center content vertically
          // color: grey[200], // Adjust as needed
        },
      },
    },
    // Style for TableRow
    // MuiTableRow: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: '#f5f5f5',
    //       // borderBottom: '1px solid black',
    //     },
    //   },
    // },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: '#eeeeee',
          // Resetting transform properties for TableRow
          // transform: 'none !important',
          // transition: 'none !important', // You may also need to reset transitions
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {},
        // input: {
        //   color: blueGrey[900],
        //   // height: '60px',
        // },
        input: {
          fontSize: '14px',
          color: 'black',
          // '&:focus': {
          //   color: 'red', // Custom text && cursor color when focused
          // },
        },
        head: {
          color: blueGrey[900], // Dark blueGrey text for table head cells
          fontWeight: 'bold', // Bold font weight for table head cells
          textAlign: 'center',
        },
        body: {
          textAlign: 'center',
          margin: '5px',
          wordWrap: 'break-word',
          whiteSpace: 'normal',
          textOverflow: 'hidden',
          height: '2px',
          lineHeight: '1.25',
          fontSize: '14px',
          justifyContent: 'center', // Center content horizontally
          alignItems: 'center', // Center content vertically
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          justifyContent: 'center',
          // minWidth: '75%',
          // minHeight: '100%',
          '&.global-shadow': {
            // Apply shadow and elevation to the global FormControl
            // elevation: 3,
            // boxShadow:
            //   '0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12), 0px 7px 8px -4px rgba(0, 0, 0, 0.2)',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          // color: 'rgb(0,0,0,0.5)',
          // color: 'red',
          fontSize: '18px',
          // position: 'relative',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {},
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          // height: '52.7px',
          backgroundColor: 'transparent',
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          color: 'black',
          //       textAlign: 'center',
          //       fontSize: '14px',
          // height: '20px',
          //       margin: '5px',
          //       width: 'auto',
          //       minWidth: '150px', // Reduced minWidth for better control
          //       '&:hover': {
          //         borderColor: grey[800],
          //       },
          '& .MuiSelect-select': {
            textAlign: 'center',
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center',
          },
          //       inputRoot: {
          //         '& .MuiOutlinedInput-notchedOutline': {
          //           padding: 0,
          //           borderWidth: '0', // Remove border
          //         },
          //       },
          //       endAdornment: {
          //         display: 'none',
          //       },
          '& .MuiSvgIcon-root': {
            display: 'none',
          },
          //       '& .services-select-cprojects .MuiSvgIcon-root': {
          //         display: 'inline-block',
          //       },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          // Add a condition to apply styles only when inside your custom Select
          [`&.selectMenu .MuiMenuItem-root`]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          '&:hover': {
            // backgroundColor: 'rgb(98, 102, 110, 0.1)',
            backgroundColor: 'rgb(0, 0, 0, 0.1)',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          // backgroundColor: grey[200],
          backgroundColor: '#ECEFF8',
          color: grey[200],
          fontSize: '14px',
          marginBottom: '8px',
          width: '100%',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        gutters: {
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'none',
            // marginBottom: '-5px',
          },
        },
        root: {
          // backgroundColor: '#ECEFF8',
          margin: '2px',
          backgroundColor: 'rgba(255,255,255,1)',
          color: 'rgba(255,255,255,1)',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Apply transition to summary
          // transform: 'scale(1.025)',
          '&:hover': {
            transform: 'scale(1.01)', // Apply transform only to summary
            // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', // Corrected boxShadow value
          },
          // transition: 'box-shadow 0.3s ease, transform 0.3s ease',
          // '&:hover': {
          //   boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.2)', // Adjust shadow to your preference
          //   transform: 'translateY(-3px)', // Slight vertical lift
          // },
        },
      },
    },

    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255,255,255,1)',
          // color: 'rgba(255,255,255,1)',
          // margin: 10,
          padding: 0,
        },
      },
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          // backgroundColor: grey[200],
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(255,255,255,0.9)', // Lighter background color
          color: 'black', // Text color
          fontSize: '0.75rem', // Larger font size
          padding: '10px', // More padding
          borderRadius: '4px', // Rounded corners
          // maxWidth: 100, // Wider tooltip
          // width: 420,
          textAlign: 'center',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxHeight: '100vh',
          overflowY: 'auto',
          borderRadius: '10px', // Rounded corners for the modal
          boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.3)', // Shadow for modal
        },
      },
    },

    MuiGrid: {
      styleOverrides: {
        item: {
          item: {
            paddingTop: '0px !important', // Set to '0px' or another value as per your requirement
            paddingLeft: '0px !important', // Adjust this value as well
          },
        },
        root: {
          paddingTop: 0,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          // backgroundColor: grey[50],
          backgroundColor: 'transparent',
          color: '#fafafa',
          // transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          borderRadius: '4px',
          // boxShadow:
          // '0px 1px 2px rgba(0, 0, 0, 0.14), 0px 0.5px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
          alignContent: 'center',
          textAlign: 'center',
          display: 'flexbox',
          width: 'auto',
          overflow: 'hidden',
          // padding: '8px',
          // marginTop: '10px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          // Set the color of SVG icons to black
          color: '#000', // Or use grey[900] for a softer black if preferred
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: 'black',
          borderColor: 'black',
          '& .MuiChip-deleteIconSmall': {
            color: 'black',
          },
        },
      },
    },
    // MuiDataGrid: {
    //   styleOverrides: {
    //     root: {
    //       // backgroundColor: 'red',
    //       backgroundColor: 'rgba(255,255,255,1)',
    //       borderColor: 'rgba(0,0,0,0.4)',
    //       marginTop: '10px',
    //       // Add your DataGrid specific styles here for the dark theme
    //       '& .MuiDataGrid-cell': {
    //         borderColor: 'rgba(0,0,0,0.4)',
    //         color: 'rgba(0, 0, 0)', // Lighter text for better readability
    //       },
    //       '& .MuiDataGrid-columnHeaders': {
    //         borderColor: 'rgba(0,0,0,0.4)',
    //         fontSize: '14px',
    //         // backgroundColor: 'rgba(255,255,255,0.5)', // Darker background for column headers
    //         // backgroundColor: 'rgb(255,255,255, 0.7)', // Darker background for column headers
    //         color: 'rgb(0,0,0)', // Light text color for contrast
    //       },
    //       '& .MuiDataGrid-row': {
    //         fontSize: '14px',
    //         // Apply any row styling if needed
    //         '&:hover': {
    //           backgroundColor: 'rgba(0, 0, 0, 0.5)', // Slight highlight on hover
    //           // transform: 'scale(1.005)',
    //           // transition: 'transform 0.1s ease-in-out',
    //         },
    //       },
    //       '.MuiDataGrid-row.Mui-selected': {
    //         '&:hover': {
    //           backgroundColor: 'rgba(0, 0, 0, 0.1)',
    //           // borderColor: 'rgba(255,255,255,0.4)',
    //         },
    //         backgroundColor: 'rgba(0, 0, 0, 0.1)',
    //       },
    //     },
    //     // This targets the pagination controls specifically
    //     pagination: {
    //       color: 'rgba(0, 0, 0, 0.7)', // Ensures pagination controls match the dark theme
    //     },
    //     footerContainer: {
    //       '&.MuiDataGrid-footerContainer': {
    //         borderColor: 'rgba(0,0,0,0.4)', // Example background color
    //         color: 'rgba(0, 0, 0, 0.7)', // Example text color
    //         // backgroundColor: 'rgba(255,255,255,0.5)',
    //       },
    //     },
    //   },
    // },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 0,
          // maxHeight: '100vh',
          maxWidth: '95vw',
          fontFamily: '"Muli", sans-serif',
          // boxShadow: Array(25)
          //   .fill('none')
          //   .map((_, index) =>
          //     index === 0
          //       ? 'none'
          //       : `0px ${index}px ${index * 2}px rgba(0, 0, 0, 0.14),
          // 0px ${index / 2}px ${index}px rgba(0, 0, 0, 0.12),
          // 0px ${index}px ${index * 3}px rgba(0, 0, 0, 0.2)`
          //   ),
          // backgroundColor: 'rgb(235, 247, 255)',
          backgroundColor: 'rgb(255, 255, 255)',
          borderColor: 'rgba(0,0,0,0.4)',
          marginTop: '10px',
          '& .MuiDataGrid-cell': {
            // border: '1px solid',
            // borderColor: 'transparent',
            color: 'rgba(0, 0, 0)', // Darker text for better readability
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            '&:hover': {
              // backgroundColor: 'rgba(87, 125, 173, 0.2)',
              transform: 'scale(1.03)',
              // border: '1px solid',
              // borderColor: 'rgba(0,0,0,0.2)',
              backgroundColor: 'rgb(232, 246, 252)',
              color: 'rgba(0, 0, 0)', // Lighter text for better readability
            },
          },
          '& .MuiDataGrid-columnHeaders': {
            borderColor: 'rgba(0,0,0,0.4)',
            fontSize: '14px',
            backgroundColor: 'rgba(255, 255, 255, 0.7)', // Light background for column headers
            color: 'rgba(0, 0, 0)', // Dark text color for contrast
          },
          '& .MuiDataGrid-row': {
            fontSize: '12px',
            '&:hover': {
              // backgroundColor: 'rgba(0, 0, 0, 0.05)', // Slight highlight on hover
              backgroundColor: 'transparent',
            },
          },
          '& .MuiDataGrid-row.Mui-selected': {
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
          '& .MuiCheckbox-root svg': {
            width: 16,
            height: 16,
            backgroundColor: 'transparent',
            border: `1px solid #d9d9d9`,
            borderRadius: 2,
          },
          '& .MuiCheckbox-root svg path': {
            display: 'none',
          },
          '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
            backgroundColor: '#1890ff',
            borderColor: '#1890ff',
          },
          '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
            position: 'absolute',
            display: 'table',
            border: '2px solid #fff',
            borderTop: 0,
            borderLeft: 0,
            transform: 'rotate(45deg) translate(-50%,-50%)',
            opacity: 1,
            transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
            content: '""',
            top: '50%',
            left: '39%',
            width: 5.71428571,
            height: 9.14285714,
          },
          '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
            width: 8,
            height: 8,
            backgroundColor: '#1890ff',
            transform: 'none',
            top: '39%',
            border: 0,
          },
          '& .MuiDataGrid-toolbarContainer': {
            // border: '1px solid',
            // borderColor: 'red',
            border: 0,
            backgroundColor: 'rgba(255,255,255,1)',
          },
          '& .MuiDataGrid-container--top [role=row]': {
            backgroundColor: 'rgba(255,255,255,1)',
          },
          '& ::-webkit-scrollbar': {
            // width: '12px',
            // height: '12px',
          },
          '& ::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(167,51,51,1)',
            borderRadius: '6px',
          },
          '& ::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
          '& ::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        pagination: {
          color: 'rgba(0, 0, 0, 0.7)', // Ensures pagination controls match the light theme
        },
        footerContainer: {
          '&.MuiDataGrid-footerContainer': {
            border: '1px solid',
            borderColor: 'rgba(0,0,0,0.4)', // Example border color
            color: 'rgba(0, 0, 0, 0.7)', // Example text color
            backgroundColor: 'rgba(255,255,255,1)', // Light background for footer
          },
          '& .MuiTablePagination-toolbar': {
            backgroundColor: 'rgba(255,255,255,1)', // Example: Setting toolbar background color
            // You can add other styling here as neede
          },
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255, 255, 255)',
        },
      },
    },
  },
})
