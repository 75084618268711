import React from 'react'
import { Box, Drawer, Alert, useTheme } from '@mui/material'

const NotificationDrawer = ({ drawerOpen, showDrawer, closeDrawer, drawerWidth, notifications }) => {
  const theme = useTheme()
  const alertBgColor = theme.palette.mode === 'dark' ? 'rgb(23, 25, 30)' : 'rgba(0,0,0,0.2)'
  const alertBorderColor = theme.palette.mode === 'dark' ? 'white' : 'black'
  const alertTextColor = theme.palette.mode === 'dark' ? 'white' : 'black'

  return (
    <Drawer
      onClose={closeDrawer}
      anchor='right'
      open={drawerOpen}
      // key={vertical + horizontal}
      sx={{
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'center',
          alignItems: 'center',
        },
      }}
    >
      <Box
        sx={{
          // display: 'flex',
          // flexDirection: 'column',
          marginTop: 8,
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {notifications &&
          notifications.map(notification => (
            <Box key={notification.notify_uuid}>
              {!notification.message ? (
                <Alert
                  onClose={closeDrawer}
                  severity={'success'}
                  sx={{
                    border: '1px solid',
                    borderColor: alertBorderColor,
                    pr: 2,
                    pl: 2,
                    color: alertTextColor,
                    backgroundColor: alertBgColor,
                  }}
                >
                  {'No Notifications Currently'}
                </Alert>
              ) : (
                <Alert
                  onClose={closeDrawer}
                  severity={notification.severity}
                  sx={{
                    border: '1px solid',
                    borderColor: alertBorderColor,
                    pr: 2,
                    pl: 2,
                    color: alertTextColor,
                    backgroundColor: alertBgColor,
                  }}
                >
                  {notification.message}
                </Alert>
              )}
            </Box>
          ))}
      </Box>
    </Drawer>
  )
}

export default NotificationDrawer
