import React from 'react'
import { Tooltip, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'

const RichTextTooltip = ({ content, onClick }) => {
  return (
    <Tooltip
      title={
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          style={{
            padding: '10px',
            maxWidth: '300px',
            color: 'inherit',
            fontSize: '0.875rem',
            lineHeight: '1.4em',
          }}
        />
      }
      arrow
      interactive={'true'}
      placement='right'
    >
      <IconButton onClick={onClick} sx={{ padding: 1.5 }} size='small'>
        <EditIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  )
}

export default RichTextTooltip
