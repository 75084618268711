import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from './AuthContext'
import Loading from '../components/Loading/Loading'

const Token = () => {
  const { redirectToSSOAuth } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    const googleAccessToken = localStorage.getItem('google_access_token')
    const slackAccessToken = localStorage.getItem('slack_access_token')

    const pandaAccessToken =
      process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'laptop'
        ? '44c682867cd90875b3ba53db7280c56e1a4d6068'
        : localStorage.getItem('panda_access_token')

    console.log('Google Access Token:', googleAccessToken)
    console.log('Slack Access Token:', slackAccessToken)
    console.log('Panda Access Token:', pandaAccessToken)

    if (googleAccessToken && slackAccessToken && pandaAccessToken) {
      navigate('/dashboard')
    } else {
      redirectToSSOAuth()
    }
  }, [navigate, redirectToSSOAuth])

  return <Loading />
}

export default Token
