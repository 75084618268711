import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../queryFunctions/queryFunctions'
import axios from 'axios'

const fetchSlackUserProfile = async () => {
  const apiUrl = `${baseUrl}/aws/userprofile`
  const slackToken = localStorage.getItem('slack_access_token')
  if (!slackToken) {
    console.error('No authentication token available')
    return
  }
  try {
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `${slackToken}` },
    })
    return response.data
  } catch (error) {
    console.error('Error fetching slack users: ', error)
    return [] // Return an empty array in case of an error
  }
}

export const useSlackUserProfile = () => {
  return useQuery({
    queryKey: ['slackUserProfile'],
    queryFn: fetchSlackUserProfile,
  })
}
