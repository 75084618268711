import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import ConstructionIcon from '@mui/icons-material/Construction'
const DealServicesInfo = () => {
  const theme = useTheme()
  const iconColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Typography>Deal Services Here.</Typography>
      <Box sx={{ mt: 20 }}>
        <Typography sx={{ color: iconColor }}>Under Construction</Typography>
        <ConstructionIcon sx={{ height: 150, width: 150, color: iconColor }} />
      </Box>
    </Box>
  )
}

export default DealServicesInfo
