import React, { useState, useEffect } from 'react'
import { Autocomplete, TextField, Chip } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useUsers } from '../../api/aws/useUsers'

const UsersAutocomplete = ({ userId, label, onChange, onBlur, multiple = true, defaultOwner }) => {
  const { users, isUsersLoading, isUsersError } = useUsers()

  const [inputValue, setInputValue] = useState('')
  const [selectedUsers, setSelectedUsers] = useState(() => {
    const currentUser = users.find(u => u.id === defaultOwner)
    return multiple ? (currentUser ? [currentUser] : []) : currentUser || null
  })

  const handleSelectUser = (event, newValue) => {
    setSelectedUsers(newValue)
    onChange(newValue)
  }

  const handleRemoveUser = userId => {
    const newSelectedUsers = selectedUsers.filter(user => user && user.id !== userId)
    setSelectedUsers(newSelectedUsers)
    onChange(newSelectedUsers)
  }

  return (
    <Autocomplete
      multiple={multiple}
      id='users-autocomplete'
      options={users || []}
      getOptionLabel={option => {
        if (!option) return ''
        return option.real_name
          ? option.real_name
          : `${option.firstName || option.first_name || ''} ${option.lastName || option.last_name || ''} (${
              option.email
            })`
      }}
      value={selectedUsers}
      onChange={handleSelectUser}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      isOptionEqualToValue={(option, value) => {
        if (!option || !value) return false
        return option.id === value.id
      }}
      filterOptions={(options, { inputValue }) =>
        options.filter(option =>
          `${option.firstName || option.first_name || ''} ${option.lastName || option.last_name || ''}`
            .toLowerCase()
            .includes(inputValue.toLowerCase())
        )
      }
      renderOption={(props, option) => (
        <li {...props}>
          {option.firstName || option.first_name || ''} {option.lastName || option.last_name || ''} ({option.email})
        </li>
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, onDelete, ...rest } = getTagProps({ index })
          return (
            <Chip
              key={option.id}
              label={`${option.firstName || option.first_name || ''} ${option.lastName || option.last_name || ''}`}
              {...rest}
              onDelete={() => handleRemoveUser(option.id)}
              deleteIcon={<CancelIcon />}
            />
          )
        })
      }
      renderInput={params => <TextField {...params} label={label} />}
      fullWidth
    />
  )
}

export default UsersAutocomplete
