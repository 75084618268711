import { Route, Routes } from 'react-router-dom'
import AppLayout from './AppLayout'
import { SnackbarProvider } from './context/SnackbarProvider/SnackbarProvider'
import OrganizationsPage from './components/Organizations/OrganizationsPage'
import OrganizationPage from './components/Organizations/OrganizationPage'
import Leads from './components/Leads/Leads'
import PropertyMgmtCompany from './components/PropertyManagementCompanies/PropertyMgmtCompany'
import OwnershipGroup from './components/OwnershipGroups/OwnershipGroup'
import Documents from './components/PandaDoc/Documents'
import DocumentDetails from './components/PandaDoc/DocumentDetails'
import DealsPage from './components/Deals/DealsPage'
import UpdateComponents from './components/UpdateComponents/UpdateComponents'
import ContactDetails from './components/Contacts/ContactDetails'
import WebhookData from './components/WebhookData/WebhookData'
import SlackUserList from './components/SlackUserList/SlackUserList'
import { HeaderProvider } from './context/HeaderProvider/HeaderProvider'
import DashboardPage from './components/Dashboard/DashboardPage'
import { LoadingProvider } from './context/LoadingProvider/LoadingProvider'
import { PandaProvider } from './context/PandaProvider/PandaProvider'
import DealsDetails from './components/Deals/DealsDetails'
import OrganizationDetails from './components/Organizations/OrganizationDetails'
import OrgDealInfo from './components/Organizations/OrgDealInfo'
import OrgBillingInfo from './components/Organizations/OrgBillingInfo'
import OrgTaskInfo from './components/Organizations/OrgTaskInfo'
import OrganizationNotes from './components/Organizations/OrganizationNotes'
import DealsKanban from './components/DealsKanban/DealsKanban'
import DealTaskInfo from './components/Deals/DealTaskInfo'
import DealServicesInfo from './components/Deals/DealServicesInfo'
import DealPage from './components/Deals/DealPage'
import CampaignList from './components/CampaignList/CampaignList'
import ContactsPage from './components/Contacts/ContactsPage'
import { ValidationProvider } from './context/ValidationProvider/ValidationProvider'
import PaymentSchedule from './components/PaymentSchedule/PaymentSchedule'
import Sprints from './components/Sprints/Sprints.tsx'
import { LeadsProvider } from './context/LeadsProvider/LeadsProvider.js'
import { WebsocketProvider } from './context/Websocket/WebsocketProvider.js'
import Strategy from './components/Strategy/Strategy.js'
import ReviewDeal from './components/DealApproval/ReviewDeal.js'
import ContactPage from './components/Contacts/ContactPage.js'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import AccountingSchedule from './components/PaymentSchedule/AccountingSchedule.js'
import RenderInvoicing from './components/PaymentSchedule/RenderInvoicing.js'

const ProtectedApp = () => {
  return (
    <ValidationProvider>
      <LeadsProvider>
        <SnackbarProvider>
          <PandaProvider>
            <LoadingProvider>
              <HeaderProvider>
                <AppLayout>
                  <ReactQueryDevtools initialIsOpen={false} />
                  <Routes>
                    <Route path='/dashboard' element={<DashboardPage />} />
                    <Route path='/leads' element={<Leads />} />
                    <Route path='/deals' element={<DealsPage />} />
                    <Route path='/deals/kanban' element={<DealsKanban />} />
                    <Route path='/deals/:dealId/*' element={<DealPage />}>
                      <Route path='' element={<DealsDetails />} />
                      <Route path='tasks' element={<DealTaskInfo />} />
                      <Route path='services' element={<DealServicesInfo />} />
                      <Route path='strategy' element={<Strategy />} />
                      <Route path='reviewdeal' element={<ReviewDeal />} />
                      <Route path='paymentschedule' element={<PaymentSchedule />} />
                      <Route path='invoicing' element={<AccountingSchedule />} />
                    </Route>
                    <Route path='/contacts' element={<ContactsPage />} />
                    <Route path='/contacts/:contactId/*' element={<ContactPage />}>
                      <Route path='' element={<ContactDetails />} />
                      <Route path='additionalinfo' element={<div>additionalInfo</div>} />
                    </Route>
                    <Route path='/organizations' element={<OrganizationsPage />} />
                    <Route path='/organizations/:orgId/*' element={<OrganizationPage />}>
                      <Route path='' element={<OrganizationDetails />} />
                      <Route path='deals' element={<OrgDealInfo />} />
                      <Route path='tasks' element={<OrgTaskInfo />} />
                      <Route path='billing' element={<OrgBillingInfo />} />
                      <Route path='notes' element={<OrganizationNotes />} />
                    </Route>
                    <Route path='/propertymgmt/:propertyMgmtId' element={<PropertyMgmtCompany />} />
                    <Route path='/ownershipGroup/:ownershipGroupId' element={<OwnershipGroup />} />
                    <Route path='/sprints' element={<Sprints />} />
                    <Route path='/campaignlist' element={<CampaignList />} />
                    <Route path='/updatecomponents' element={<UpdateComponents />} />
                    <Route path='/pandadocs' element={<Documents />} />
                    <Route path='/pandadocs/:name' element={<DocumentDetails />} />
                    <Route path='/slackuserlist' element={<SlackUserList />} />
                    <Route path='/timeline' element={<WebhookData />} />
                  </Routes>
                </AppLayout>
              </HeaderProvider>
            </LoadingProvider>
          </PandaProvider>
        </SnackbarProvider>
      </LeadsProvider>
    </ValidationProvider>
  )
}

export default ProtectedApp
