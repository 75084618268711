import React from 'react'
import { Grid, Box, Stack, Typography, TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { editFieldConfig } from '../../context/DealsProvider/initialFormState'

const ViewStrategyForm = ({ formState, isEditMode, onChange, onBlur, campaigns = [], ads = [] }) => {
  const theme = useTheme()
  const notIncludePageNames = ['General Information']
  return (
    <Grid container sx={{ padding: '20px', maxWidth: '1400px', margin: 'auto' }}>
      <Box p={3}>
        <Stack spacing={2} width='100%'>
          {Object.keys(editFieldConfig).map((pageName, pageIndex) => (
            <Box key={pageIndex} mb={4}>
              <Typography sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black', mb: 2 }} variant='h6'>
                {pageName}
              </Typography>
              <Grid
                container
                spacing={2}
                p={3}
                justifyContent='flex-start'
                sx={{
                  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgb(255, 255, 255)',
                  borderRadius: 1,
                }}
              >
                {editFieldConfig[pageName].map((field, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Box>
                      {!isEditMode ? (
                        <>
                          <Typography>{field.label}</Typography>
                          <Typography variant='subtitle2' color={'rgb(181, 191, 205)'}>
                            {formState[field.name] || ''}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography>{field.label}</Typography>
                          <TextField
                            name={field.name}
                            type={field.type || 'text'}
                            value={formState[field.name] || ''}
                            onChange={onChange}
                            onBlur={onBlur}
                            variant='standard'
                            fullWidth
                            disabled={field.readOnly}
                            InputProps={{
                              readOnly: field.readOnly,
                            }}
                          />
                        </>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}

          {/* Dynamically render campaigns */}
          <Grid
            container
            spacing={2}
            p={3}
            justifyContent='flex-start'
            sx={{
              backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgb(255, 255, 255)',
              borderRadius: 1,
            }}
          >
            {campaigns.length > 0 &&
              campaigns.map((campaign, index) => (
                <Grid key={index} item xs={12} sm={12} mt={4} sx={{ borderRadius: 1 }}>
                  <Typography variant='h6'>
                    Campaign {index + 1}: {campaign.name}
                  </Typography>

                  {isEditMode ? (
                    <>
                      <TextField
                        fullWidth
                        label='Location'
                        name={`campaign_${index}_location`}
                        value={campaign.location}
                        variant='outlined'
                        margin='normal'
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                      <TextField
                        fullWidth
                        label='Ad Group'
                        name={`campaign_${index}_adGroup`}
                        value={campaign.adGroup}
                        variant='outlined'
                        margin='normal'
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                      <TextField
                        fullWidth
                        label='Keywords'
                        name={`campaign_${index}_keywords`}
                        value={campaign.keywords ? campaign.keywords.join('\n') : ''} // Default to empty string if undefined
                        variant='outlined'
                        margin='normal'
                        multiline
                        rows={5}
                        placeholder='Enter each keyword on a new line'
                        onChange={e => {
                          const updatedKeywords = e.target.value.split('\n')
                          onChange({
                            target: {
                              name: `campaign_${index}_keywords`,
                              value: updatedKeywords,
                            },
                          })
                        }}
                        onBlur={onBlur}
                      />
                      <TextField
                        fullWidth
                        label='Negative Keywords'
                        name={`campaign_${index}_negativeKeywords`}
                        value={campaign.negativeKeywords ? campaign.negativeKeywords.join('\n') : ''}
                        variant='outlined'
                        margin='normal'
                        multiline
                        rows={5}
                        placeholder='Enter each negative keyword on a new line'
                        onChange={e => {
                          const updatedNegativeKeywords = e.target.value.split('\n')
                          onChange({
                            target: {
                              name: `campaign_${index}_negativeKeywords`,
                              value: updatedNegativeKeywords,
                            },
                          })
                        }}
                        onBlur={onBlur}
                      />
                      {ads.length > 0 &&
                        ads.map((adSet, adIndex) => (
                          <>
                            {Object.keys(adSet).map((set, setIndex) => (
                              <Box key={adIndex} mt={2}>
                                <Typography variant='subtitle1'>{adSet[set].name}</Typography>

                                <TextField
                                  fullWidth
                                  label='Conversion Location'
                                  name={`adSet_${adIndex}_conversionLocation`}
                                  value={adSet[set].conversionLocation || ''}
                                  variant='outlined'
                                  margin='normal'
                                  onChange={e => {
                                    const updatedAds = [...ads]
                                    updatedAds[adIndex][set].conversionLocation = e.target.value
                                    onChange({
                                      target: {
                                        name: `adSet_${adIndex}_conversionLocation`,
                                        value: updatedAds,
                                      },
                                    })
                                  }}
                                  onBlur={onBlur}
                                />

                                <TextField
                                  fullWidth
                                  label='Performance Goal'
                                  name={`adSet_${adIndex}_performanceGoal`}
                                  value={adSet[set].performanceGoal || ''}
                                  variant='outlined'
                                  margin='normal'
                                  onChange={e => {
                                    const updatedAds = [...ads]
                                    updatedAds[adIndex][set].performanceGoal = e.target.value
                                    onChange({
                                      target: {
                                        name: `adSet_${adIndex}_performanceGoal`,
                                        value: updatedAds,
                                      },
                                    })
                                  }}
                                  onBlur={onBlur}
                                />

                                <TextField
                                  fullWidth
                                  label='Conversion Event'
                                  name={`adSet_${adIndex}_conversionEvent`}
                                  value={adSet[set].conversionEvent || ''}
                                  variant='outlined'
                                  margin='normal'
                                  onChange={e => {
                                    const updatedAds = [...ads]
                                    updatedAds[adIndex][set].conversionEvent = e.target.value
                                    onChange({
                                      target: {
                                        name: `adSet_${adIndex}_conversionEvent`,
                                        value: updatedAds,
                                      },
                                    })
                                  }}
                                  onBlur={onBlur}
                                />

                                <TextField
                                  fullWidth
                                  label='Audience'
                                  name={`adSet_${adIndex}_audience`}
                                  value={adSet[set].audience ? adSet[set].audience.join(', ') : ''}
                                  variant='outlined'
                                  margin='normal'
                                  multiline
                                  rows={3}
                                  onChange={e => {
                                    const updatedAds = [...ads]
                                    updatedAds[adIndex][set].audience = e.target.value.split(', ')
                                    onChange({
                                      target: {
                                        name: `adSet_${adIndex}_audience`,
                                        value: updatedAds,
                                      },
                                    })
                                  }}
                                  onBlur={onBlur}
                                />
                              </Box>
                            ))}
                          </>
                        ))}
                    </>
                  ) : (
                    <>
                      <Typography>Location: {campaign.location}</Typography>
                      <Typography>Ad Group: {campaign.adGroup}</Typography>
                      <Typography>Keywords: </Typography>
                      <ul>
                        {campaign.keywords &&
                          campaign.keywords.map((keyword, keywordIndex) => <li key={keywordIndex}>{keyword}</li>)}
                      </ul>
                      {ads.length > 0 &&
                        ads.map((adSet, adIndex) => (
                          <Box key={adIndex} mt={4} sx={{ borderRadius: 1 }}>
                            <Typography variant='h6'>Ad Set {adIndex + 1}</Typography>
                            {Object.keys(adSet).map((set, setIndex) => (
                              <Box key={setIndex} mt={2}>
                                <Typography variant='subtitle1'>{adSet[set].name}</Typography>
                                <Typography variant='body2'>
                                  Conversion Location: {adSet[set].conversionLocation || ''}
                                </Typography>
                                <Typography variant='body2'>
                                  Performance Goal: {adSet[set].performanceGoal || ''}
                                </Typography>
                                <Typography variant='body2'>
                                  Conversion Event: {adSet[set].conversionEvent || ''}
                                </Typography>
                                <Typography variant='body2'>
                                  Audience: {adSet[set].audience ? adSet[set].audience.join(', ') : ''}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        ))}
                    </>
                  )}
                </Grid>
              ))}
          </Grid>
        </Stack>
      </Box>
    </Grid>
  )
}

export default ViewStrategyForm
