const generateUniqueId = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  for (let i = 0; i < 5; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return result
}

export const getUniqueIdentifier = existingNames => {
  let uniqueId = generateUniqueId()
  const existingIds = existingNames.map(name => name.split('#').pop())
  while (existingIds.includes(uniqueId)) {
    uniqueId = generateUniqueId()
  }
  return uniqueId
}
