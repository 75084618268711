import React, { useState, useEffect } from 'react'
import { Box, Typography, Select, MenuItem, InputLabel, useTheme, FormControl, IconButton } from '@mui/material'
import GroMarketingIcon from '../Icons/GroMarketingIcon'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import OrganizationsIconComponent from '../Icons/OrganizationsIconComponent'

const PaidSelect = ({ context, displayLabel, value, setterFn, disabled }) => {
  const theme = useTheme()
  const [selectedValue, setSelectedValue] = useState(value)

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  const handleChange = event => {
    const newValue = event.target.value
    setSelectedValue(newValue)
    setterFn(newValue)
  }

  const paidOptions = [
    { label: 'GRO', value: 'GRO' },
    { label: 'Client', value: 'Client' },
  ]

  return (
    <Box sx={{ minWidth: 75 }}>
      <FormControl fullWidth variant='outlined'>
        <InputLabel
          id={`${context ? context : 'paidOptions'}-label`}
          sx={{
            color: theme.palette.mode === 'dark' ? 'rgb(255,255,255,0.5)' : 'rgb(0,0,0,0.5)',
            fontSize: '16px',
          }}
        >
          {displayLabel ? displayLabel : ''}
        </InputLabel>
        <Select
          labelId={`${context ? context : 'paidOptions'}-label`}
          id={`${context}-select`}
          value={selectedValue || 'GRO'}
          disabled={context === 'payer' ? disabled : disabled}
          onChange={handleChange}
          label={displayLabel}
          renderValue={selected => (
            <Box sx={{ display: 'flex', alignItems: 'center' }} gap={1}>
              {selected === 'GRO' ? (
                <>
                  <GroMarketingIcon disabled={disabled} />
                </>
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', aligntItems: 'center' }}>
                  {/* <CorporateFareIcon /> */}
                  <Typography>Client</Typography>
                </Box>
              )}
            </Box>
          )}
          sx={{
            minWidth: 75,
          }}
        >
          {paidOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 3,
                }}
              >
                <Typography variant='subtitle1'>{option.label}</Typography>
                {option.value === 'GRO' && <GroMarketingIcon width={50} radius={'25px'} bgColor={'transparent'} />}
                {option.value === 'Client' && (
                  <CorporateFareIcon
                    sx={{
                      color: 'rgb(114, 229, 218)',
                    }}
                  />
                )}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default PaidSelect
