import React from 'react'
import PandaDocLogo from '../../images/pandadoc.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import LaunchIcon from '@mui/icons-material/Launch'
import LinkIcon from '@mui/icons-material/Link'
import { Tooltip, Badge, Box } from '@mui/material'
import { InfoValue } from '../Contacts/style'

const PandaDocLinkIcon = ({ size }) => {
  return (
    <>
      <Tooltip title='Open Contract'>
        {size ? (
          <img style={{ width: size, borderRadius: 25 }} src={PandaDocLogo} />
        ) : (
          <img style={{ width: 40, borderRadius: 25 }} src={PandaDocLogo} />
        )}
      </Tooltip>
    </>
  )
}

export default PandaDocLinkIcon
