import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/queryMutations'
import { useGetQueryByIds } from '../aws/useGetQueryByIds'

const fetchLeadsById = async leadId => {
  const { data } = await axios.get(`${baseUrl}/aws/leads/${leadId}`)
  return data
}

export const useLeadsById = leadIds => {
  const validLeadIds = Array.isArray(leadIds) ? leadIds.filter(id => !!id) : []
  // console.log('validNewContactIds:', validNewContactIds)
  const newLeads = useGetQueryByIds('/aws/leads', 'leads', validLeadIds || [])
    .filter(result => result.isSuccess && result.data) // Only include successful results with data
    .map(result => result.data) // Extract the `data` property
  // console.log('New Contacts: ', newLeads)
  return newLeads
}
// export const useLeadsById = leadIds => {
//   const validLeadIds = Array.isArray(leadIds) ? leadIds.filter(id => !!id) : []
//   console.log('validLeadIds:', validLeadIds)
//   const newLeads = useGetQueryByIds('/aws/leads', 'leads', validLeadIds || [])
//     .filter(result => result.isSuccess && result.data) // Only include successful results with data
//     .map(result => result.data) // Extract the `data` property
//   return newLeads
// }
