import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions'

const updateWebsite = async ({ updates }) => {
  console.log('[UpdateWebsite] called with updates: ', updates)
  const url = `${baseUrl}/aws/updatewebsite`
  try {
    const response = await axios.put(url, { updates })
    if (response.status !== 200) throw new Error('Network response was not ok')
    return response.data
  } catch (error) {
    console.error('Failed to update items:', error)
    throw error
  }
}

export const useUpdateWebsite = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: updateWebsite,
    onSuccess: (data, variables) => {
      console.log('[useUpdateWebsite] Updated items data:', data, 'VARIABLES: ', variables)
      variables.updates.forEach(update => {
        // queryClient.invalidateQueries(['awsItems', update.itemId])
        // queryClient.invalidateQueries([update.tableName, update.itemId])
      })
    },
    onError: error => {
      console.error('[useUpdateWebsite] Error updating items:', error)
    },
  })

  return mutation
}
