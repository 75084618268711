import { Grid, Stack, Typography, IconButton } from '@mui/material'
import React from 'react'
import GroboticIcon from '../../components/Icons/GroboticIcon'
import GroboticLight from '../../images/Grobotic png-lightmode.png'

const RenderError = () => {
  return (
    <Grid
      container
      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 6 }}
    >
      <Stack sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 6 }}>
        <Grid item xs={12}>
          <IconButton>
            <img src={GroboticLight} height={400} width={400} />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h3'>We're sorry, error loading data..</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h3'>Please try reloading...</Typography>
        </Grid>
      </Stack>
    </Grid>
  )
}

export default RenderError
