import React, { useContext, useEffect, useState } from 'react'
import { Tabs, Tab, Box, Typography, useTheme } from '@mui/material'
import { useParams, Link, Outlet, useLocation } from 'react-router-dom'
import OrganizationDetailsHeader from './OrganizationDetailsHeader'

const OrganizationPage = () => {
  const { orgId } = useParams()
  // const { data: organization, isLoading: organizationLoading, isError: organizationError } = useOrganizationById(orgId)
  const theme = useTheme()
  const location = useLocation()
  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    const path = location.pathname.split('/').pop()
    switch (path) {
      case 'deals':
        setTabIndex(1)
        break
      case 'tasks':
        setTabIndex(2)
        break
      case 'billing':
        setTabIndex(3)
        break
      case 'notes':
        setTabIndex(4)
        break
      default:
        setTabIndex(0)
        break
    }
  }, [location.pathname])

  // if (organizationLoading) {
  //   return <Loading />
  // }

  // if (organizationError) {
  //   return <Typography>Error loading organization details</Typography>
  // }

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue)
  }

  return (
    <Box>
      <OrganizationDetailsHeader />
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label='organization tabs'
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgb(255, 255, 255)',
        }}
      >
        <Tab label='Details' component={Link} to={`/organizations/${orgId}`} />
        <Tab label='Deals' component={Link} to={`/organizations/${orgId}/deals`} />
        <Tab label='Tasks' component={Link} to={`/organizations/${orgId}/tasks`} />
        <Tab label='Billing' component={Link} to={`/organizations/${orgId}/billing`} />
        <Tab label='Notes' component={Link} to={`/organizations/${orgId}/notes`} />
      </Tabs>
      <Box sx={{ p: 3 }}>
        <Outlet />
      </Box>
    </Box>
  )
}

export default OrganizationPage
