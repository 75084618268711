import React, { useState } from 'react'
import { Select, MenuItem } from '@mui/material'
const SelectStrategyTemplate = ({ templates, selectedTemplate, onChange }) => {
  const handleChange = event => {
    onChange(event)
  }
  return (
    <Select value={selectedTemplate} onChange={event => handleChange(event)} displayEmpty>
      <MenuItem value='' disabled>
        Select Template
      </MenuItem>
      {Object.keys(templates).map((templateName, index) => (
        <MenuItem key={index} value={templateName}>
          {templateName}
        </MenuItem>
      ))}
    </Select>
  )
}

export default SelectStrategyTemplate
