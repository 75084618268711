// Loading.js
import React from 'react'
import { Box, CircularProgress, LinearProgress, Stack, Typography, useTheme, Backdrop } from '@mui/material'
import { styled, keyframes } from '@mui/material/styles'
import GroboticPng from '../../images/Grobotic png.png'
import GroboticLight from '../../images/Grobotic png-lightmode.png'

const fadeInOut = keyframes`
  20%, 80% {
    opacity: .2;
  }
  50% {
    opacity: 1;
  }
`

const StyledGroLoadingComponent = styled('div')(({ theme }) => ({
  position: 'fixed',
  zIndex: 800,
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgb(232, 246, 252)',
}))

const LoadingLogoContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const LoadingLogo = styled('img')({
  width: 400,
  height: 400,
  borderRadius: '50%',
  animation: `${fadeInOut} 5s ease-in-out infinite`,
})

const Loading = ({ componentName, progress }) => {
  const theme = useTheme()
  return (
    <StyledGroLoadingComponent>
      <Stack direction='column' alignItems='center' justifyContent='center'>
        <LoadingLogoContainer>
          <LoadingLogo src={theme.palette.mode === 'dark' ? GroboticPng : GroboticLight} alt='Loading...' />
          <CircularProgress
            size={450}
            style={{ position: 'absolute', color: 'rgba(83, 228, 169, 0.5)' }}
            thickness={0.5}
          />
        </LoadingLogoContainer>
        <Box sx={{ width: '100%', mt: 8 }}>
          <LinearProgress variant='determinate' value={progress || 0} />
        </Box>
      </Stack>
    </StyledGroLoadingComponent>
  )
}

export default Loading
