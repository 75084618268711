import React from 'react'
import { Typography } from '@mui/material'
const RichTextDescription = ({ description }) => {
  return (
    <Typography
      sx={{
        fontSize: 13,
        color: 'rgb(135, 131, 131)',
        pt: 1,
      }}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  )
}

export default RichTextDescription
