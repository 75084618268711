import React from 'react'
import { Grid, Chip, useTheme, Box, Tooltip, Typography } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import { Link as RouterLink } from 'react-router-dom'
import Loading from '../Loading/Loading'

const RenderDealContacts = ({ contacts }) => {
  const theme = useTheme()
  const maxVisibleContacts = 2
  const extraCount = contacts ? contacts.length - maxVisibleContacts : 0
  const textColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
  const gridItemStyles = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  }

  const chipStyles = {
    height: 'auto',
    width: 'auto',
    borderColor: 'transparent',
    borderWidth: '0.5px',
    borderStyle: 'solid',
    backgroundColor: 'transparent',
    color: theme.palette.mode === 'dark' ? 'rgba(255,255,255)' : 'rgba(0,0,0)',
    borderRadius: '4px',
  }

  const renderContactChip = contact => (
    <Box
      key={contact.contacts_uuid}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        maxWidth: '100%',
        overflow: 'hidden',
        margin: 0,
        padding: 0.5,
      }}
    >
      <PersonIcon sx={{ color: 'rgb(255, 123, 187)' }} />
      <Chip
        label={
          <RouterLink to={`/contacts/${contact.contacts_uuid}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            {contact.name}
          </RouterLink>
        }
        size='small'
        style={chipStyles}
      />
    </Box>
  )

  const renderExtraContactsTooltip = () => (
    <Tooltip
      title={
        <Box>
          {contacts.slice(maxVisibleContacts).map(contact => (
            <Box
              key={contact.contacts_uuid}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                maxWidth: '100%',
                overflow: 'hidden',
                margin: 0,
                padding: 0.5,
              }}
            >
              <PersonIcon sx={{ color: 'rgb(255, 123, 187)' }} />
              <Chip
                label={
                  <RouterLink
                    to={`/contacts/${contact.contacts_uuid}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {contact.name}
                  </RouterLink>
                }
                size='small'
                style={chipStyles}
              />
            </Box>
          ))}
        </Box>
      }
      arrow
    >
      <Chip label={`+${extraCount}`} size='small' style={chipStyles} />
    </Tooltip>
  )

  if (!contacts || contacts.length === 0) {
    return <Typography sx={{ color: textColor }}>No Contacts Assigned</Typography> // Handle empty state
  }

  return (
    <Grid item xs={12} sx={gridItemStyles}>
      {contacts.length > 0 && (
        <>
          {contacts.slice(0, maxVisibleContacts).map(renderContactChip)}
          {extraCount > 0 && renderExtraContactsTooltip()}
        </>
      )}
    </Grid>
  )
}

export default RenderDealContacts
