export const chipStyle = (theme, color, height, width) => ({
  height: height ? height : 20,
  width: width ? width : 50,
  minHeight: height ? height : 20,
  minWidth: width ? width : 50,
  borderColor: color,
  borderWidth: '1px',
  borderStyle: 'solid',
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  borderRadius: '4px',
  marginRight: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
