import React from 'react'
import { Grid, Typography, useTheme, Box, Tooltip } from '@mui/material'
import { tableCellStyles, tableHeaderStyles, tableSectionStyles } from './style'
import RichTextDescription from './RichTextDescription'

const GenerateContractTable = ({ options, section }) => {
  const theme = useTheme()
  const headers = options.length > 0 ? Object.keys(options[0]) : []

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      {section && (
        <Box sx={{ bgcolor: theme.palette.background.default, p: 1 }}>
          <Typography variant='h6' sx={tableSectionStyles(theme)}>
            {section}
          </Typography>
        </Box>
      )}
      <Grid container sx={{ bgcolor: theme.palette.background.paper }}>
        {/* Render Headers */}
        {headers.map((header, index) => (
          <Grid
            item
            xs={12 / headers.length}
            key={`${header}-${index}`}
            sx={{
              ...tableHeaderStyles(theme),
              p: 1,
              textAlign: 'center',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {header}
          </Grid>
        ))}
        {/* Render Data Rows */}
        {options.map((entry, rowIndex) => (
          <Grid container key={`row-${rowIndex}`}>
            {headers.map((header, headerIndex) => (
              <Tooltip
                title={entry[header].type === 'number' ? null : entry[header].value}
                key={`tooltip-${rowIndex}-${headerIndex}`} // Adding a unique key for Tooltip
              >
                <Grid
                  item
                  xs={entry && entry[header].xs ? entry[header].xs : 12 / headers.length}
                  key={`cell-${rowIndex}-${headerIndex}`} // Adding a unique key for Grid
                  sx={{
                    ...tableCellStyles(theme, entry[header].color, entry.width),
                    p: 1,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {entry[header].type === 'number'
                    ? `$${entry[header].value}`
                    : entry[header].value
                    ? entry[header].value
                    : 'N/A'}
                </Grid>
              </Tooltip>
            ))}
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default GenerateContractTable
