import React, { createContext, useContext, useState, useCallback, useEffect } from 'react'
import { Alert, Box, Snackbar, IconButton, useTheme, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'
import CloseIcon from '@mui/icons-material/Close'
import GroboticIcon from '../../components/Icons/GroboticIcon'
import { FlexBoxRow } from '../../components/ui/flex-box-row'

const iconMapping = {
  info: <InfoIcon fontSize='inherit' />,
  grobotic: <GroboticIcon />,
  success: <CheckCircleIcon fontSize='inherit' />,
  error: <ErrorIcon fontSize='inherit' />,
  warning: <WarningIcon fontSize='inherit' />,
}

const SnackbarAlert = ({ open, message, onClose, severity }) => {
  const theme = useTheme()
  const backgroundColorDark = severity === 'grobotic' ? 'rgba(103, 128, 159, 0.5)' : ''
  const backgroundColorLight = severity === 'grobotic' ? 'rgba(255, 255, 255, 0.5)' : ''
  const backgroundColor = theme.palette.mode === 'dark' ? backgroundColorDark : backgroundColorLight
  const broadcastColor = theme.palette.mode === 'dark' ? 'rgba(241, 130, 141, 1)' : 'rgba(241, 130, 141, 1)'
  const darkBgBroadcast = severity === 'broadcast' ? 'rgba(103, 128, 159, 0.5)' : ''
  const lightBgBroadcast = severity === 'broadcast' ? 'rgba(255, 255, 255, 0.5)' : ''
  const broadcastBgColor = theme.palette.mode === 'dark' ? darkBgBroadcast : lightBgBroadcast
  const closingColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255, 1)' : 'rgba(0,0,0, 1)'

  const anchorOrigin =
    severity === 'grobotic'
      ? { vertical: 'bottom', horizontal: 'right' }
      : severity === 'broadcast'
      ? { vertical: 'top', horizontal: 'center' }
      : { vertical: 'bottom', horizontal: 'left' }

  const autoHideDuration = severity === 'broadcast' ? null : 6000

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      anchorOrigin={anchorOrigin}
      onClose={severity === 'broadcast' ? undefined : onClose} // Use undefined to prevent closing on backdrop click or timeout for broadcasts
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between', // Ensure space for the X button
          color: severity === 'broadcast' ? broadcastColor : '',
          backgroundColor: severity === 'broadcast' ? broadcastBgColor : backgroundColor,
          borderRadius: '25px',
          pl: 1,
          pr: 2,
          width: '100%', // Adjust to make sure the box takes the full width
        }}
      >
        {severity === 'grobotic' ? (
          <>
            <GroboticIcon />
            <Box sx={{ ml: 1, flexGrow: 1 }}>{typeof message === 'string' ? message : 'An error occurred'}</Box>
          </>
        ) : severity === 'broadcast' ? (
          <>
            <GroboticIcon />
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', ml: 1 }}>
              {typeof message === 'string' ? <Typography>{message}</Typography> : 'An error occurred'}{' '}
            </Box>
            <IconButton color='inherit' onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </>
        ) : (
          <Alert severity={severity} icon={iconMapping[severity]}>
            {typeof message === 'string' ? message : 'An error occurred'}
          </Alert>
        )}
      </Box>
    </Snackbar>
  )
}

const SnackbarContext = createContext({
  showSnackbar: (message, severity) => {},
})

export const useSnackbarContext = () => useContext(SnackbarContext)

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  })

  const showSnackbar = useCallback((message, severity = 'info') => {
    setSnackbar({
      open: true,
      message: typeof message === 'string' ? message : JSON.stringify(message),
      severity,
    })
  }, [])

  const closeSnackbar = useCallback(() => {
    setSnackbar({ open: false, message: '' })
  }, [])

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        onClose={closeSnackbar}
        severity={snackbar.severity}
      />
    </SnackbarContext.Provider>
  )
}
