export const getCreativeTasksTabColor = label => {
  switch (label) {
    case 'In Progress':
      return 'rgb(253, 188, 100)'
    case 'Needs AM Review':
      return 'rgb(255, 131, 88)'
    case 'Needs Revision':
      return 'rgb(213, 197, 103)'
    case 'Sent for Approval':
      return 'rgb(147, 111, 218)'
    case 'Ready to Launch':
      return 'rgb(121, 175, 253)'
    case 'Done':
      return 'rgb(53, 153, 112)'
    case 'Blocked':
      return 'rgb(133, 214, 255)'
    case 'On Hold':
      return 'rgb(232, 105, 125)'
    case 'Needs Copy':
      return 'rgb(113, 214, 209)'
    case 'Needs CD Review':
      return 'rgb(51, 211, 145)'
    case 'CD Approved':
      return 'rgb(255, 68, 161)'

    case 'Need Creative Attention':
      return 'rgb(51, 158, 205)'
    default:
      return 'grey' // Default unselected color
  }
}
