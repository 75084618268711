export const patchNotes = {
  "1.24.5": {
    "Features": [],
    "Bug Fixes": [
      "fixed vertical type error for deals)",
      "monthly totals should now be adding up correctly)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.24.4": {
    "Features": [],
    "Bug Fixes": [
      "fixed calculations for totals inside created deals and during creation)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.24.3": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "grid search bar filter count added)"
    ],
    "Refactors": []
  },
  "1.24.2": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "small border around filter menu and aligned contents)"
    ],
    "Refactors": []
  },
  "1.24.1": {
    "Features": [],
    "Bug Fixes": [
      "added does not contain to the filters list)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.24.0": {
    "Features": [
      "filters no longer clear on refresh or navigation to another page, the clear filters button will clear the current filters on the active grid)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.23.0": {
    "Features": [
      "strategy tab added with campaign selection for auto filling values in select fields)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.22.0": {
    "Features": [
      "Insertion orders now have a quantity column that will autofill based on the range of dates input into the contract.  The contract in pandadoc will accurately display the total for the insertion order whether products are separated by month or set with a range of dates)"
    ],
    "Bug Fixes": [
      "key error fixes and interactive components)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.21.0": {
    "Features": [
      "IO types now display the correct total and set the correct total for contracts and rendering)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.20.2": {
    "Features": [],
    "Bug Fixes": [
      "fix payment schedule data in contract details for PandaDoc section)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.20.1": {
    "Features": [],
    "Bug Fixes": [
      "fixed bug with request approval button  and added product: Website - Add On to products list)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.20.0": {
    "Features": [
      "deal status for month to month 30 day notice and active is now available)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.19.1": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "organization images added to deals grid)"
    ],
    "Refactors": []
  },
  "1.19.0": {
    "Features": [
      "deals will dynamically update based on the deal type selected or the signature checkbox state even after the deal has been created.)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.18.2": {
    "Features": [],
    "Bug Fixes": [
      "deal and organization filters are now working correctly and can be used together ie. filter by status and also type)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.18.1": {
    "Features": [],
    "Bug Fixes": [
      "when changing deal type the code will correctly determine the stage and status to set and will also add or remove the approval process)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.18.0": {
    "Features": [
      "deal type can now be edited from all locations in the app)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.17.0": {
    "Features": [
      "if organization does not have a vertical assigned, can update org's vertical from create deal screen)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.16.3": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "removed lines in details pages and aligned fields more efficiently)"
    ],
    "Refactors": []
  },
  "1.16.2": {
    "Features": [],
    "Bug Fixes": [
      "product prices are correctly auto filling the price column)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.16.1": {
    "Features": [],
    "Bug Fixes": [
      "added validation to organizations for vertical)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.16.0": {
    "Features": [
      "notes section added to organizations)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.15.3": {
    "Features": [],
    "Bug Fixes": [
      "_dev data_ fixed timeline for webhooks)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.15.2": {
    "Features": [],
    "Bug Fixes": [
      "deal owner now properly assigns to deal when creating contract directly through the create deal screen)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.15.1": {
    "Features": [],
    "Bug Fixes": [
      "fixed prepared by not showing up the correct name, and fixed needing to refresh deals grid after creating new deal)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.15.0": {
    "Features": [
      "invoicing for CSA now properly calculates the separate payments based on the number of payments selected)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.14.0": {
    "Features": [
      "contact photos and emails are now displayed when selecting contacts while creating organizations)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.13.0": {
    "Features": [
      "deals and contract now show contracted by organization)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.12.0": {
    "Features": [
      "deals kanban now contains links to deal by clicking on deal name)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.11.7": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "adjusted review deal page for responsiveness to smaller screens)"
    ],
    "Refactors": []
  },
  "1.11.6": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "added smaller loader for details pages)"
    ],
    "Refactors": []
  },
  "1.11.5": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": [
      "further improved performance with optimized queries)"
    ]
  },
  "1.11.4": {
    "Features": [],
    "Bug Fixes": [
      "approval process should update now if hitting back in create deal to change the deal type)",
      "contracted by is now displayed in the contract for each deal when editing)"
    ],
    "Style": [],
    "Refactors": [
      "improve fetching performance by optimizing data fetching and query handling)"
    ]
  },
  "1.11.3": {
    "Features": [],
    "Bug Fixes": [
      "approval process should update now if hitting back in create deal to change the deal type)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.11.2": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": [
      "improve fetching performance by optimizing data fetching and query handling)"
    ]
  },
  "1.11.1": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": [
      "**api:** optimize data fetching and query handling)"
    ]
  },
  "1.11.0": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.10.0": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "can access rich text field in light and dark mode)",
      "formatted reviewdeal descriptions with rich text)"
    ],
    "Refactors": []
  },
  "1.9.0": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "can access rich text field in light and dark mode)"
    ],
    "Refactors": []
  },
  "1.8.2": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "added description to review deal rendered products)"
    ],
    "Refactors": []
  },
  "1.8.1": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [
      "new broadcast message for deal won notifications)"
    ],
    "Refactors": []
  },
  "1.8.0": {
    "Features": [
      "notifications for deal won)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.7.1": {
    "Features": [],
    "Bug Fixes": [
      "organization names can now be edited)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.7.0": {
    "Features": [
      "new tab in deals for invoicing to display cost per month)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.6.2": {
    "Features": [],
    "Bug Fixes": [
      "can create insertion orders without approval and removed approval tooltip when deal type is IO)",
      "csa contracts now correctly using the number of payments to calculate payment schedule used for contract)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.6.1": {
    "Features": [],
    "Bug Fixes": [
      "link to pandadoc contract now available inside deal via pandadoc icon)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.6.0": {
    "Features": [
      "updating websites will now not only update the website, but add the current organizations active social profiles and cms used automatically)"
    ],
    "Bug Fixes": [
      "active days removed from contract template)",
      "new format for website urls)",
      "no longer will get white screen when editing organizations that do not contain a vertical)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.5.0": {
    "Features": [
      "ctrl+f or cmd+f to focus grid toolbar quick filter search component)"
    ],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  },
  "1.4.6": {
    "Features": [],
    "Bug Fixes": [
      "fixed websites not upating when editing organizations information)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.4.5": {
    "Features": [],
    "Bug Fixes": [
      "payer and accountUsed will now correctly stay as the values selected)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.4.4": {
    "Features": [],
    "Bug Fixes": [
      "added version control automation to changelog and display)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.4.3": {
    "Features": [],
    "Bug Fixes": [
      "youtube advertising now enables payer and account fields upon selection)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.4.2": {
    "Features": [],
    "Bug Fixes": [
      "broadcast message displayed for information)"
    ],
    "Style": [],
    "Refactors": []
  },
  "1.4.1": {
    "Features": [],
    "Bug Fixes": [],
    "Style": [],
    "Refactors": []
  }
};
