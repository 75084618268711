import React, { useState } from 'react'
import UpdateZohoDeals from './UpdateZohoDeals'
import RefreshZohoContacts from './RefreshZohoContacts'
import GetSlackUsers from './GetSlackUsers'
import ClearLocalStorage from './ClearLocalStorage'
import RefreshZohoAccounts from './RefreshZohoAccounts'
import FetchGoogleDocComponent from '../CreateGoogleDoc/FetchingGoogleDocComponent'
import GooglePicker from '../CreateGoogleDoc/GooglePickerComponent.tsx'
import GoogleDocs from '../CreateGoogleDoc/GoogleDocs.js'
import { Typography, CircularProgress, Box, useTheme, Button } from '@mui/material'
import { useGoogleDocs } from '../../api/google/useGoogleDocs.js'
import GroboticPickerComponent from '../Google/GroboticPickerComponent.tsx'
import CreateMondayItem from './CreateMondayItem.js'
import ReadExcel from './ReadExcel.js'
import SendBroadcast from '../../context/Websocket/SendBroadcast.js'
import Budget from './Budget.js'
import NotifyTestComponent from '../TEST/NotifyTestComponent.js'
import ConnectedUsersList from './ConnectedUsersList.js'

const UpdateComponents = () => {
  const { data: googleDocs, isLoading: googleDocsLoading, isError: googleDocsError } = useGoogleDocs()
  const theme = useTheme()

  if (googleDocsLoading) {
    return <CircularProgress />
  }

  if (googleDocsError) {
    return <Typography color='error'>Error loading Google Docs</Typography>
  }

  if (!googleDocs || googleDocs.length === 0) {
    return <Typography>No Google Docs found</Typography>
  }

  return (
    <Box sx={{ marginTop: theme.spacing(5) }}>
      {/* <ConnectedUsersList /> */}
      {/* <UpdateZohoDeals /> */}
      {/* <RefreshZohoContacts /> */}
      {/* <GetSlackUsers /> */}
      <ClearLocalStorage />
      {/* <RefreshZohoAccounts /> */}
      {/* <CreateMondayItem /> */}
      {/* <Box sx={{ marginTop: theme.spacing(2) }}>
        <SendBroadcast />
      </Box> */}
      {/* <CreateGoogleDocComponent /> */}
      {/* <FetchGoogleDocComponent /> */}
      {/* <GooglePicker /> */}
      {/* <GroboticPickerComponent /> */}
      {/* <ReadExcel /> */}
      {/* <NotifyTestComponent /> */}
      {/* <GoogleDocs googleDocs={googleDocs} /> */}
      {/* <Budget /> */}
    </Box>
  )
}

export default UpdateComponents
