import React, { useState, useEffect } from 'react'
import { Box, FormControl, InputLabel, Select, MenuItem, Chip, Typography } from '@mui/material'

const CustomTypeSelect = ({ stateKey, value, options, displayLabel, setterFn }) => {
  const [selectedValue, setSelectedValue] = useState(value)
  useEffect(() => {
    setSelectedValue(value)
    console.log('selectedValue changed: ', selectedValue)
  }, [value])

  const handleChange = event => {
    const newValue = event.target.value
    console.log('newValue: ', newValue)
    setSelectedValue(newValue)
    setterFn(prev => ({
      ...prev,
      [stateKey]: newValue,
    }))
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <FormControl fullWidth variant='outlined' sx={{ height: '100%' }}>
        <InputLabel
          sx={{
            '&.MuiInputLabel-shrink': {
              transform: 'translate(10px, -8px) scale(0.75)',
            },
          }}
          id={`${stateKey}-label`}
        >
          {displayLabel}
        </InputLabel>
        <Select
          labelId={`${stateKey}-label`}
          id={`${stateKey}-select`}
          value={selectedValue}
          label={displayLabel}
          onChange={handleChange}
          sx={{ height: '100%' }}
        >
          {options.map(option => (
            <MenuItem key={option.label} value={option.label}>
              <Chip
                size='small'
                label={option.type}
                style={{
                  height: 20,
                  width: 50,
                  borderColor: option.color,
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  color: 'black',
                  borderRadius: '4px',
                  marginRight: '8px',
                }}
              />
              <Typography variant='subtitle1'>{option.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default CustomTypeSelect
