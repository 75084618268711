import React, { useState, useContext, useEffect } from 'react'
import BoardHeaders from '../../context/ContainerProvider/BoardHeaders'
import CustomDataGrid from '../DataGrid/CustomDataGrid'
import { renderWebhookColumns } from '../DataGrid/createColumns/renderWebhookColumns'
import { usePandaContext } from '../../context/PandaProvider/PandaProvider'
import { useGetQuery } from '../../api/aws/useGetQuery'
import DetailsLoading from '../Loading/DetailsLoading'
import { useFetchWebhookData } from '../../api/aws/useFetchWebhookData'

const WebhookData = () => {
  const { webhookData, isWebhookDataLoading, isWebhookDataError } = useFetchWebhookData()
  const [selectedOrganization, setSelectedOrganization] = useState(null)
  const [selectionModel, setSelectionModel] = useState([])
  const [filterModel, setFilterModel] = useState({ items: [] })
  const rows = webhookData.map(webhook => ({
    ...webhook,
    id: webhook.pdchook_uuid,
    Contact: webhook.Contact ? webhook.Contact.name : 'No Contact Assigned',
    Owner: webhook.Owner ? webhook.Owner.name : 'No Owner Assigned',
  }))
  useEffect(() => {
    const newOrganization = webhookData.find(org => org.id === selectionModel[0])
    setSelectedOrganization(newOrganization)
  }, [selectionModel, webhookData])
  if (isWebhookDataLoading) {
    return <DetailsLoading />
  }

  const columns = renderWebhookColumns(webhookData)
  return (
    <CustomDataGrid
      data={rows}
      columns={columns}
      fieldId='id'
      selectedDataIds={selectionModel}
      setSelectedDataIds={setSelectionModel}
      filterModel={filterModel}
      setFilterModel={setFilterModel}
    />
  )
}

export default WebhookData
