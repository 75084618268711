export const initialFormState = {
  // id: '',
  // name: '',
  platform: '',
  organizationName: '',
  type: '',
  groContact: '',
  orgContact: '',
  street: '',
  city: '',
  zip: '',
  vertical: '',
  website: '',
  busObjective: '',
  geoTargeting: '',
  demographic: '',
  competitors: '',
  requests: '',
  productsAndServices: '',
  adCopy: '',
  creativeProvided: '',
  promotionsOffered: '',
  timeFrame: '',
  businessAge: '',
  needMoreAwareness: '',
  leasingGoal: '',
  unitsLeased: '',
  targetOccupancyDate: '',
  websiteInfo: '',
  ownBillingConfig: '',
  retainer: '',
  cmsUsed: '',
  usingOwnAccounts: '',
  clientCreatingCampaigns: '',
  clientPausingCampaigns: '',
  clientModifyingCampaigns: '',
  clientModifyingConversions: '',
  googleAdsCallExtension: '',
  differentNumberFromWebsite: '',
  willingToAddGscTag: '',
  facebookAccess: '',
  facebookPageOwner: '',
  facebookPageAdmin: '',
  facebookBusinessManager: '',
  gtm: '',
  needGtm: '',
  gtmAccess: '',
  conversionsToTrack: '',
  conversionsPriority: '',
  googleBusinessProfile: '',
  googleBusinessProfileUpdated: '',
  dealId: '',
  organizationId: '',
}
export const editFieldConfig = {
  'General Information': [
    {
      name: 'platform',
      label: 'Platform',
      type: 'text',
      required: true,
      fullLabel: 'Platform',
    },
    {
      name: 'demographicTargeting',
      label: 'Demographic Targeting',
      type: 'text',
      required: true,
      fullLabel: 'Search Demographic Targeting',
    },
    {
      name: 'landingPageUrl',
      label: 'Landing Page URL',
      type: 'text',
      required: true,
      fullLabel: 'Search Landing Page URL',
    },
    {
      name: 'targetingSpecifics',
      label: 'Targeting Specifics',
      type: 'text',
      required: false,
      fullLabel: 'Search Targeting Specifics',
    },
    // {
    //   name: 'name',
    //   label: 'Campaign Name',
    //   type: 'text',
    //   required: true,
    //   fullLabel: 'Campaign Name',
    // },
    // {
    //   name: 'location',
    //   label: 'Location',
    //   type: 'text',
    //   required: true,
    //   fullLabel: 'Location (presence and interest)',
    // },
    // {
    //   name: 'adGroup',
    //   label: 'Ad Group',
    //   type: 'text',
    //   required: true,
    //   fullLabel: 'Ad Group',
    // },
    // {
    //   name: 'keywords',
    //   label: 'Keywords',
    //   type: 'text',
    //   required: true,
    //   fullLabel: 'Keywords',
    //   placeholder: 'Enter keywords separated by commas',
    // },
    // {
    //   name: 'negativeKeywords',
    //   label: 'Negative Keywords',
    //   type: 'text',
    //   required: false,
    //   fullLabel: 'Negative Keywords',
    //   placeholder: 'Enter negative keywords separated by commas',
    // },
    {
      name: 'organizationName',
      label: 'Organization Name',
      type: 'text',
      required: true,
      fullLabel: 'Organization Name',
      readOnly: true,
    },
    {
      name: 'type',
      label: 'Contract Type',
      type: 'text',
      required: true,
      fullLabel: 'Contract Type',
      readOnly: true,
    },
    {
      name: 'groContact',
      label: 'GRO Contact - Email',
      type: 'email',
      required: true,
      fullLabel: 'GRO Contact - Email',
    },
    {
      name: 'orgContact',
      label: 'Organization Contact - Email',
      type: 'email',
      required: true,
      fullLabel: 'Organization Contact - Email',
    },
    {
      name: 'vertical',
      label: "Organization's Vertical",
      type: 'text',
      required: true,
      fullLabel: "Organization's Vertical",
      readOnly: true,
    },
    {
      name: 'website',
      label: "Organization's Website",
      type: 'url',
      required: true,
      fullLabel: "Organization's Website",
      readOnly: true,
    },
  ],
  Integration: [
    {
      name: 'busObjective',
      label: 'Business Objective',
      type: 'select',
      options: ['Leads', 'Branding/awareness', 'In-Store Visits', 'Website traffic'],
      required: true,
      fullLabel: `Business Objective <br /> a. What is the client's overall marketing objective? Do they want Leads, Branding/awareness, In-Store Visits, Website traffic or a mix of these? <br /> i. Keep in mind that more than one objective should come with more budget`,
      placeholder: 'Business Objective',
    },
    {
      name: 'geoTargeting',
      label: 'Organization Geographic Targeting',
      type: 'text',
      required: true,
      fullLabel: 'Organization Geographic Targeting <br /> a. State, City, Zip codes, etc',
    },
  ],
  'Paid Search': [
    {
      name: 'ownBillingConfig',
      label: 'Are we using our own billing configuration for the client?',
      type: 'text',
      required: true,
      fullLabel: 'Are we using our own billing configuration for the client?',
    },
    {
      name: 'cmsUsed',
      label: 'CMS Being Used',
      type: 'text',
      required: true,
      fullLabel: 'e.g. Wordpress, entrata, squarespace',
    },
    // {
    //   name: 'googleAdsCallExtension',
    //   label: 'Number for Google ads call extensions?',
    //   type: 'text',
    //   required: false,
    //   fullLabel: 'What is the number the client provided us for Google ads call extensions?',
    // },
    {
      name: 'differentNumberFromWebsite',
      label: 'Is the client using call tracking?',
      type: 'radio',
      options: ['Yes', 'No'],
      required: false,
      fullLabel: 'Is the number different from the website?',
    },
    {
      name: 'searchKeywords',
      label: 'Search Keywords',
      type: 'text',
      required: false,
      fullLabel: 'Search Keywords',
    },
  ],
  'Paid Social': [
    // {
    //   name: 'facebookAccess',
    //   label: 'Does the client have the ability to share access to the Facebook page?',
    //   type: 'text',
    //   required: false,
    //   fullLabel: 'Does the client have access to their Facebook page?',
    // },
    {
      name: 'googleBusinessProfile',
      label: 'Does the client have a Google Business profile Setup?',
      type: 'text',
      required: false,
      fullLabel: 'Does the client have a Google Business profile Setup?',
    },
  ],
  Display: [
    {
      name: 'display1',
      label: 'Display Section.....?',
      type: 'text',
      required: false,
      fullLabel: 'Is their Google Business profile up to date?',
    },
  ],
  SEO: [
    {
      name: 'street',
      label: 'Street',
      type: 'text',
      required: true,
      fullLabel: "Organization's Street Address",
      readOnly: true,
    },
    {
      name: 'city',
      label: 'City',
      type: 'text',
      required: true,
      fullLabel: "Organization's City",
      readOnly: true,
    },
    {
      name: 'state',
      label: 'State',
      type: 'text',
      required: true,
      fullLabel: "Organization's State",
      readOnly: true,
    },
    {
      name: 'zip',
      label: 'Zip',
      type: 'text',
      required: true,
      fullLabel: "Organization's Zip Code",
      readOnly: true,
    },
  ],
  SMM: [
    {
      name: 'SMM1',
      label: 'Is their Google Business profile up to date?',
      type: 'text',
      required: false,
      fullLabel: 'Is their Google Business profile up to date?',
    },
  ],
  Campaigns: [
    {
      name: 'platform',
      label: 'Platform',
      type: 'text',
      required: true,
      fullLabel: 'Platform',
    },
    {
      name: 'name',
      label: 'Campaign Name',
      type: 'text',
      required: true,
      fullLabel: 'Campaign Name',
    },
    {
      name: 'location',
      label: 'Location',
      type: 'text',
      required: true,
      fullLabel: 'Location (presence and interest)',
    },
    {
      name: 'adGroup',
      label: 'Ad Group',
      type: 'text',
      required: true,
      fullLabel: 'Ad Group',
    },
    {
      name: 'keywords',
      label: 'Keywords',
      type: 'text',
      required: true,
      fullLabel: 'Keywords',
      placeholder: 'Enter keywords separated by commas',
    },
    {
      name: 'negativeKeywords',
      label: 'Negative Keywords',
      type: 'text',
      required: false,
      fullLabel: 'Negative Keywords',
      placeholder: 'Enter negative keywords separated by commas',
    },
  ],
}

// export const editFieldConfig = {
//   'General Information': [
//     {
//       name: 'name',
//       label: 'Name',
//       type: 'text',
//       required: true,
//       fullLabel: 'Discovery Form Name',
//     },
//     {
//       name: 'organizationName',
//       label: 'Organization Name',
//       type: 'text',
//       required: true,
//       fullLabel: 'Organization Name',
//       readOnly: true,
//     },
//     {
//       name: 'type',
//       label: 'Contract Type',
//       type: 'text',
//       required: true,
//       fullLabel: 'Contract Type',
//       readOnly: true,
//     },
//     {
//       name: 'groContact',
//       label: 'GRO Contact - Email',
//       type: 'email',
//       required: true,
//       fullLabel: 'GRO Contact - Email <br /> a. example@gromarketing.com',
//     },
//     {
//       name: 'orgContact',
//       label: 'Organization Contact - Email',
//       type: 'email',
//       required: true,
//       fullLabel: 'Organization Contact - Email <br /> a. example@example.com',
//     },
//     {
//       name: 'vertical',
//       label: "Organization's Vertical",
//       type: 'text',
//       required: true,
//       fullLabel: "Organization's Vertical",
//       readOnly: true,
//     },
//     {
//       name: 'website',
//       label: "Organization's Website",
//       type: 'url',
//       required: true,
//       fullLabel: "Organization's Website <br /> a. Please provide client's URL",
//       readOnly: true,
//     },
//     {
//       name: 'requests',
//       label: "Organization Requests, Do's/Don'ts & Other Notes",
//       type: 'text',
//       required: true,
//       fullLabel: "Organization Requests, Do's/Don'ts & Other Notes",
//     },
//     {
//       name: 'productsAndServices',
//       label: 'Products and Services To Focus On',
//       type: 'text',
//       required: true,
//       fullLabel: 'e.g. specific floorplans, areas, budget allocations',
//       placeholder: 'e.g. specific floorplans, areas, budget allocations',
//     },
//     // {
//     //   name: 'creativeProvided',
//     //   label: 'Will the client be providing any creative?',
//     //   type: 'text',
//     //   required: true,
//     //   fullLabel: 'Will the client be providing any creative?',
//     // },
//     {
//       name: 'leasingGoal',
//       label: 'What is their leasing goal?',
//       type: 'text',
//       required: false,
//       fullLabel:
//         'What is their leasing goal? <br /> a. How many units do they want leased? <br /> b. Target date for 100% occupancy',
//     },
//     // {
//     //   name: 'promotionsOffered',
//     //   label: 'Are promotions offered?',
//     //   type: 'radio',
//     //   options: ['Yes', 'No'],
//     //   required: false,
//     //   fullLabel: 'Are promotions offered?',
//     // },
//     // {
//     //   name: 'promotionDetails',
//     //   label: 'Promotion Details',
//     //   type: 'text',
//     //   required: false,
//     //   fullLabel: 'Will the promotion be changing? \nWhat is the time frame of the promotion(s)?',
//     //   conditional: true,
//     //   helperText: 'Will the promotion be changing? \nWhat is the time frame of the promotion(s)?',
//     //   parent: 'promotionsOffered',
//     //   value: 'Yes',
//     // },
//     {
//       name: 'gtm',
//       label: 'Has the client requested we used their GTM account?',
//       type: 'radio',
//       options: ['Yes', 'No'],
//       required: false,
//       fullLabel: 'Has the client requested we used their GTM account',
//     },
//     // {
//     //   name: 'demographic',
//     //   label: 'Customer Demographic',
//     //   type: 'text',
//     //   required: false,
//     //   fullLabel: 'Customer Demographic',
//     // },
//     // {
//     //   name: 'adCopy',
//     //   label: 'Does the client want specific Ad Copy?',
//     //   type: 'text',
//     //   required: true,
//     //   fullLabel: 'Does the client want specific Ad Copy?',
//     // },
//     {
//       name: 'conversionsToTrack',
//       label: 'Conversions to Track',
//       type: 'text',
//       required: true,
//       fullLabel: 'Conversions to Track',
//     },
//     {
//       name: 'street',
//       label: 'Street',
//       type: 'text',
//       required: true,
//       fullLabel: "Organization's Street Address",
//       readOnly: true,
//     },
//     {
//       name: 'city',
//       label: 'City',
//       type: 'text',
//       required: true,
//       fullLabel: "Organization's City",
//       readOnly: true,
//     },
//     {
//       name: 'state',
//       label: 'State',
//       type: 'text',
//       required: true,
//       fullLabel: "Organization's State",
//       readOnly: true,
//     },
//     {
//       name: 'zip',
//       label: 'Zip',
//       type: 'text',
//       required: true,
//       fullLabel: "Organization's Zip Code",
//       readOnly: true,
//     },
//     {
//       name: 'marketingObjective',
//       label: 'Business Objective',
//       type: 'select',
//       options: ['Leads', 'Branding/awareness', 'In-Store Visits', 'Website traffic'],
//       required: true,
//       fullLabel: `Business Objective <br /> a. What is the client's overall marketing objective? Do they want Leads, Branding/awareness, In-Store Visits, Website traffic or a mix of these? <br /> i. Keep in mind that more than one objective should come with more budget`,
//       placeholder: 'Business Objective',
//     },
//   ],
//   Integration: [
//     {
//       name: 'busObjective',
//       label: 'Business Objective',
//       type: 'select',
//       options: ['Leads', 'Branding/awareness', 'In-Store Visits', 'Website traffic'],
//       required: true,
//       fullLabel: `Business Objective <br /> a. What is the client's overall marketing objective? Do they want Leads, Branding/awareness, In-Store Visits, Website traffic or a mix of these? <br /> i. Keep in mind that more than one objective should come with more budget`,
//       placeholder: 'Business Objective',
//     },
//     {
//       name: 'geoTargeting',
//       label: 'Organization Geographic Targeting',
//       type: 'text',
//       required: true,
//       fullLabel: 'Organization Geographic Targeting <br /> a. State, City, Zip codes, etc',
//     },
//   ],
//   'Paid Search': [
//     {
//       name: 'ownBillingConfig',
//       label: 'Are we using our own billing configuration for the client?',
//       type: 'text',
//       required: true,
//       fullLabel: 'Are we using our own billing configuration for the client?',
//     },
//     {
//       name: 'cmsUsed',
//       label: 'CMS Being Used',
//       type: 'text',
//       required: true,
//       fullLabel: 'e.g. Wordpress, entrata, squarespace',
//     },
//     // {
//     //   name: 'googleAdsCallExtension',
//     //   label: 'Number for Google ads call extensions?',
//     //   type: 'text',
//     //   required: false,
//     //   fullLabel: 'What is the number the client provided us for Google ads call extensions?',
//     // },
//     {
//       name: 'differentNumberFromWebsite',
//       label: 'Is the client using call tracking?',
//       type: 'radio',
//       options: ['Yes', 'No'],
//       required: false,
//       fullLabel: 'Is the number different from the website?',
//     },
//   ],
//   'Paid Social': [
//     // {
//     //   name: 'facebookAccess',
//     //   label: 'Does the client have the ability to share access to the Facebook page?',
//     //   type: 'text',
//     //   required: false,
//     //   fullLabel: 'Does the client have access to their Facebook page?',
//     // },
//     {
//       name: 'googleBusinessProfile',
//       label: 'Does the client have a Google Business profile Setup?',
//       type: 'text',
//       required: false,
//       fullLabel: 'Does the client have a Google Business profile Setup?',
//     },
//   ],
//   Display: [
//     {
//       name: 'display1',
//       label: 'Display Section.....?',
//       type: 'text',
//       required: false,
//       fullLabel: 'Is their Google Business profile up to date?',
//     },
//   ],
//   SEO: [
//     {
//       name: 'street',
//       label: 'Street',
//       type: 'text',
//       required: true,
//       fullLabel: "Organization's Street Address",
//       readOnly: true,
//     },
//     {
//       name: 'city',
//       label: 'City',
//       type: 'text',
//       required: true,
//       fullLabel: "Organization's City",
//       readOnly: true,
//     },
//     {
//       name: 'state',
//       label: 'State',
//       type: 'text',
//       required: true,
//       fullLabel: "Organization's State",
//       readOnly: true,
//     },
//     {
//       name: 'zip',
//       label: 'Zip',
//       type: 'text',
//       required: true,
//       fullLabel: "Organization's Zip Code",
//       readOnly: true,
//     },
//   ],
//   SMM: [
//     {
//       name: 'SMM1',
//       label: 'Is their Google Business profile up to date?',
//       type: 'text',
//       required: false,
//       fullLabel: 'Is their Google Business profile up to date?',
//     },
//   ],
// }
