import { Typography, Box, Button, Popover, useTheme, Backdrop } from '@mui/material'
import PublicIcon from '@mui/icons-material/Public'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import WarningIcon from '@mui/icons-material/Warning'

import {
  popoverStyles,
  boxContainerStyles,
  boxStyles,
  deleteButtonStyles,
  textStyles,
  buttonContainerStyles,
  overlayTextStyles,
} from './style'
import { useEffect } from 'react'

const ContactsPopover = ({ id, open, anchorEl, onClose, handleConfirmDelete, selectedData }) => {
  const theme = useTheme()

  return (
    // <Backdrop open={open} sx={{ zIndex: theme.zIndex.drawer + 1 }}>
    <>
      <Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          sx={popoverStyles(theme)}
        >
          <Box sx={boxContainerStyles(theme)}>
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                <WarningIcon sx={{ color: 'crimson' }} />
                <Typography sx={{ fontWeight: 'bold', color: 'crimson' }}>Confirm Deletion</Typography>
              </Box>
              <Typography sx={{ mb: 2 }}>
                Are you sure you want to permanently delete the following item(s) from Grobotic?
              </Typography>
              {selectedData && selectedData.name && (
                <Box sx={boxStyles(theme)}>
                  <CorporateFareIcon
                    sx={{
                      color: 'rgb(114, 229, 218)',
                      fontSize: 35,
                    }}
                  />
                  <Typography style={textStyles(theme)}>{selectedData.name}</Typography>
                </Box>
              )}
            </>

            <Box sx={buttonContainerStyles(theme)}>
              <Button sx={deleteButtonStyles(theme)} variant='outlined' onClick={handleConfirmDelete} color='secondary'>
                Confirm
              </Button>
              <Button variant='contained' onClick={onClose}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Popover>
      </Box>
    </>
  )
}
export default ContactsPopover
