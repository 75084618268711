import ConnectedUsersList from '../../components/UpdateComponents/ConnectedUsersList'
import { WebsocketProvider } from './WebsocketProvider'

const ConnectedUsers = () => {
  return (
    <WebsocketProvider>
      <ConnectedUsersList />
    </WebsocketProvider>
  )
}
export default ConnectedUsers
