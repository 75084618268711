import React from 'react'
import {
  Autocomplete,
  ListItem,
  TextField,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Paper,
  CardActions,
  Button,
  Link,
} from '@mui/material'
import { CustomCard, CustomNcoHeader } from '../../style/styleElements'
import { useLocation } from 'react-router-dom'
import { useGetDocuments } from '../../api/PandaDoc/useGetDocuments'
import Loading from '../Loading/Loading'
import { useGetProductCatalog } from '../../api/PandaDoc/useGetProductCatalog'

const DocumentDetails = () => {
  const location = useLocation()
  const { document } = location.state || {}
  const { data: documentDetails, isLoading: documentDetailsLoading, isError: documentDetailsError } = useGetDocuments(
    document.id
  )

  console.log('Document in Details: ', document)

  if (documentDetailsLoading) {
    return <Loading />
  }
  if (documentDetailsError) {
    return <div>Document unavailable at the moment...</div>
  }
  console.log('[DocumentDetails] documentDetails: ', documentDetails)
  const extractItems = () => {
    return documentDetails && documentDetails.pricing && documentDetails.pricing.tables.length > 0
      ? documentDetails.pricing.tables.reduce(
          /*(acc,table) callback function */ (acc, table) => {
            return acc.concat(table.items)
          },
          []
        )
      : null
  }

  const items = extractItems(documentDetails)
  console.log('ITEMS: ', items)

  const productCard = item => (
    <Card
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CardContent>
        <Typography variant='h5' component='div'>
          {item.name}
        </Typography>
        {item.name === 'Payment Schedule' && (
          <>
            <Typography variant='body2'>
              {item && item.merged_data ? `${item.merged_data['month']} - ${item.merged_data['year']}` : null}
            </Typography>
            <Typography variant='body2'>
              {item && item.merged_data ? item.merged_data['productsAssociated'] : null}
            </Typography>
          </>
        )}

        <Typography sx={{ fontSize: 14 }} color='lightgreen' gutterBottom>
          {`${
            item.name !== 'Payment Schedule'
              ? `$${item.price}`
              : item.name === 'Payment Schedule' && item.merged_data
              ? item.merged_data['paymentAmount']
              : null
          }`}
        </Typography>
        <Typography variant='body2' sx={{ mb: 1.5 }} color='text.secondary'>
          {item.description}
        </Typography>

        <Typography variant='body2'>{item && item.merged_data ? item.merged_data['Start Date'] : null}</Typography>
        <Typography variant='body2'>{item && item.merged_data ? item.merged_data['End Date'] : null}</Typography>
      </CardContent>
      {/* <CardActions>
        <Button size='small'>Learn More</Button>
      </CardActions> */}
    </Card>
  )

  return (
    // <Grid
    //   container
    //   sx={{
    //     display: 'flex',
    //     width: '100%',
    //     alignItems: 'flex-start',
    //   }}
    // >
    <Box
      sx={{
        margin: '0 auto',
        marginTop: '5px',
        width: '75%', // Change from 50% to 100%
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '15px',
        padding: '10px',
        '& > .MuiGrid-item': {
          padding: '10px',
        },
        transition: 'ease-in background-color 0.2s',
      }}
    >
      <Grid
        container
        sx={{
          margin: '0 auto',
          marginTop: '5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between', // This helps space out the items evenly
          // backgroundColor: rowBackgroundColor,
          borderRadius: '15px',
          padding: '10px',
          '& > .MuiGrid-item': {
            padding: '10px',
          },
          transition: 'ease-in background-color 0.2s',
        }}
      >
        <Grid item xs={12}>
          <CustomNcoHeader>Document Details Page</CustomNcoHeader>
        </Grid>
        <Grid item xs={12}>
          <CustomNcoHeader>Products</CustomNcoHeader>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
        {items
          ? items.map(item => {
              return (
                <Grid key={item.id} container spacing={1}>
                  {/* Adjusted marginLeft and marginRight to match the inner Grid items */}

                  <Grid item xs={12}>
                    {/* {items
          ? items.map(item => ( */}
                    {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={2}> */}
                    <Grid>{productCard(item)}</Grid>
                    {/* ))
          : null} */}
                  </Grid>
                </Grid>
              )
            })
          : null}
      </Grid>
    </Box>
  )
}

export default DocumentDetails
