import { useGetQueryByIds } from './useGetQueryByIds'

export const useDealsByIds = dealIds => {
  const validIds = Array.isArray(dealIds) ? dealIds.filter(id => !!id) : []
  const deals = useGetQueryByIds('/aws/deals', 'deals', validIds || [])
    .filter(result => result.isSuccess && result.data) // Only include successful results with data
    .map(result => result.data) // Extract the `data` property
  // console.log('Deals: ', deals)
  return deals
}
// import { useQuery } from '@tanstack/react-query'
// import axios from 'axios'
// import { baseUrl } from '../queryMutations/queryMutations'

// const fetchDealsByIds = async dealIds => {
//   const responses = await Promise.all(dealIds.map(id => axios.get(`${baseUrl}/aws/deals/${id}`)))
//   return responses.map(response => response.data)
// }

// export const useDealsByIds = dealIds => {
//   return useQuery({
//     queryKey: ['deals', dealIds],
//     queryFn: () => fetchDealsByIds(dealIds),
//     enabled: dealIds.length > 0, // Only query if there are deals
//     staleTime: 1000 * 60 * 5, // Consider data fresh for 5 minutes
//   })
// }
