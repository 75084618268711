// Loading.js
import React from 'react'
import { Box, CircularProgress, LinearProgress, Stack, Typography, useTheme, Backdrop } from '@mui/material'
import { styled, keyframes } from '@mui/material/styles'
import GroboticPng from '../../images/Grobotic png.png'
import GroboticLight from '../../images/Grobotic png-lightmode.png'

const fadeInOut = keyframes`
  20%, 80% {
    opacity: .2;
  }
  50% {
    opacity: 1;
  }
`

const StyledGroLoadingComponent = styled('div')(({ theme }) => ({
  position: 'fixed',
  zIndex: 1800,
  top: -425,
  left: 700,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'transparent',
}))

const LoadingLogoContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const LoadingLogo = styled('img')({
  width: 35,
  height: 35,
  borderRadius: '50%',
  animation: `${fadeInOut} 5s ease-in-out infinite`,
})

const GridLoading = ({ componentName, progress }) => {
  const theme = useTheme()
  return (
    <StyledGroLoadingComponent>
      <Stack direction='column' alignItems='center' justifyContent='center'>
        <LoadingLogoContainer>
          <LoadingLogo src={theme.palette.mode === 'dark' ? GroboticPng : GroboticLight} alt='Loading...' />
          <CircularProgress
            size={45}
            style={{ position: 'absolute', color: 'rgba(83, 228, 169, 0.5)' }}
            thickness={2}
          />
        </LoadingLogoContainer>
      </Stack>
    </StyledGroLoadingComponent>
  )
}

export default GridLoading
