import React from 'react'
import { Box, DialogTitle, Typography, useTheme } from '@mui/material'
import { DialogIcon, boxStyles, dialogTitleStyles } from '../../style/dialogStyles'
import GroboticPng from '../../images/Grobotic png.png'
import GroboticLight from '../../images/Grobotic png-lightmode.png'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import InfoIcon from '@mui/icons-material/Info'

const CreateOrganizationHeader = ({ context, onToggleInfo }) => {
  const theme = useTheme()
  return (
    <Box sx={boxStyles}>
      <DialogIcon src={theme.palette.mode === 'dark' ? GroboticPng : GroboticLight} />
      <Box sx={boxStyles}>
        <DialogTitle sx={dialogTitleStyles(theme)}>{context}</DialogTitle>
        <CorporateFareIcon
          sx={{
            color: 'rgb(114, 229, 218)',
          }}
        />
      </Box>
    </Box>
  )
}

export default CreateOrganizationHeader
