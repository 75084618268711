import { baseUrl } from '../queryMutations/queryMutations'
import { useQuery } from '@tanstack/react-query'
import { simpleFetch } from '../databaseQueries/databaseFunctions'
import { useGetQuery } from './useGetQuery'

const fetchWebhookData = async () => {
  return simpleFetch(`${baseUrl}/aws/pandahookdata`)
}

export const useFetchWebhookData = () => {
  const { data, isWebhookDataLoading, isWebhookDataError } = useGetQuery('/aws/pandahookdata', 'pandadoc_webhooks')
  const webhookData = data ?? []
  return { webhookData, isWebhookDataLoading, isWebhookDataError }
}
