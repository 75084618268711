import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useGetQueryByIds } from '../aws/useGetQueryByIds'
import { baseUrl } from '../queryMutations/queryMutations'

const fetchOrgsById = async (orgId) => {
  const { data } = await axios.get(`${baseUrl}/aws/organizations/${orgId}`)
  return data
}

// export const useOrganizationsById = orgIds => {
//   return useQuery({
//     queryKey: ['organizations', orgIds],
//     queryFn: () => Promise.all(orgIds.map(fetchOrgsById)),
//     enabled: orgIds ? orgIds.length > 0 : false,
//   })
// }
export const useOrganizationsById = (orgIds) => {
  const organizations = useGetQueryByIds(
    '/aws/organizations',
    'organizations',
    orgIds
  )
    .filter((result) => result.isSuccess && result.data) // Only include successful results with data
    .map((result) => result.data) // Extract the `data` property
  // console.log('Organizations: ', organizations)
  return organizations
}
