import { useGetQueryByIds } from '../aws/useGetQueryByIds'

export const useNewContactsById = contactIds => {
  const validNewContactIds = Array.isArray(contactIds) ? contactIds.filter(id => !!id) : []
  // console.log('validNewContactIds:', validNewContactIds)
  const newContacts = useGetQueryByIds('/aws/contacts', 'contacts', validNewContactIds || [])
    .filter(result => result.isSuccess && result.data) // Only include successful results with data
    .map(result => result.data) // Extract the `data` property
  // console.log('New Contacts: ', newContacts)
  return newContacts
}
// import { useQuery } from '@tanstack/react-query'
// import axios from 'axios'
// import { baseUrl } from '../queryMutations/queryMutations'

// const fetchNewContactsById = async contactIds => {
//   if (!contactIds || !Array.isArray(contactIds) || contactIds.length === 0) {
//     return null // Return null or an empty array based on what you want to display
//   }

//   try {
//     const { data } = await axios.get(`${baseUrl}/aws/contacts/${contactIds}`)
//     return data || {} // Ensure an empty object is returned if data is null
//   } catch (error) {
//     console.error(`Error fetching contact with ID ${contactIds}:`, error)
//     return null // Explicitly return null on error or no data
//   }
// }

// export const useNewContactsById = contactIds => {
//   return useQuery({
//     queryKey: ['contacts', contactIds],
//     queryFn: () => {
//       if (!contactIds || contactIds.length === 0) {
//         return Promise.resolve([]) // Return an empty array to avoid unnecessary API call
//       }
//       return Promise.all(contactIds.map(fetchNewContactsById))
//     },
//     enabled: contactIds.length > 0 && contactIds.every(id => id), // Query is only enabled if there are valid IDs
//     onSuccess: data => {
//       console.log('Fetched Contacts:', data) // Log the fetched data for debugging
//     },
//     onError: error => {
//       console.error('Error fetching contacts:', error)
//     },
//     select: data => {
//       return data.filter(contact => contact !== null && contact !== undefined) // Filter out null or undefined contacts
//     },
//   })
// }
