import { Chip, Typography, Box, useTheme } from '@mui/material'
import { chipStyle } from './style'

const RenderCompanyChip = ({ option, height, width, size, fontsize }) => {
  const type =
    option && option.type ? option.type : option && option.type && Array.isArray(option.type) ? option.type[0] : 'NT'
  const theme = useTheme()
  const themeColor = theme.palette.mode === 'dark' ? 'white' : 'black'
  let label = ''
  let color = ''

  switch (type) {
    case 'Property Management Company':
      label = 'PMC'
      color = 'rgb(93, 79, 251)'
      break
    case 'Ownership Group':
      label = 'OG'
      color = 'rgb(92, 179, 253)'
      break
    case 'Housing Property':
      label = 'HP'
      color = 'rgb(152, 214, 129)'
      break
    case 'Parent Client':
      label = 'PC'
      color = 'rgb(93, 79, 251)'
      break
    case 'Agency Whitelabel':
      label = 'AW'
      color = 'rgb(92, 179, 253)'
      break
    case 'Organization':
      label = 'ORG'
      color = 'rgb(242, 140, 40)'
      break
    case 'NT':
      label = 'NT'
      color = 'lightgrey'
      break

    default:
      label = 'NT'
      color = 'grey'
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        margin: 0,
        gap: 1,
        maxWidth: '100%',
        overflow: 'hidden',
      }}
    >
      <Chip size={size ? size : 'small'} label={label} style={chipStyle(theme, color, height, width)} />
      <Typography
        sx={{
          color: themeColor,
          fontSize: fontsize ? fontsize : 14,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '75%',
        }}
        variant='subtitle1'
      >
        {option && option.name ? option.name : null}
      </Typography>
    </Box>
  )
}

export default RenderCompanyChip
