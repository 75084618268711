import { useMemo } from 'react'
import { calculateRangeForProducts, calculateTotalMonthsForProducts } from '../../utility/calculateTotalMonths'

// const calculateTotalForRow = (row, months) => parseFloat(row.price || 0) * parseFloat(row.quantity || 1) * months

// const calculateMediaSpend = (price, marginPercentage) => {
//   const marginValue = parseFloat(price) * (parseFloat(marginPercentage) / 100)
//   return (parseFloat(price) - marginValue).toFixed(2)
// }
const calculateTotalForRow = (row, months) => parseFloat(row.price || 0) * parseFloat(row.quantity || 1) * months

const calculateMediaSpend = (price, marginPercentage) => {
  const marginValue = parseFloat(price) * (parseFloat(marginPercentage) / 100)
  return (parseFloat(price) - marginValue).toFixed(2)
}

const useTotals = (productRows, initialImplementationFee, type) => {
  const totals = useMemo(() => {
    let contractTotal = 0
    let grossProfit = 0
    let monthlyTotal = 0
    let ioTotal = 0
    let ioMonthlyTotal = 0
    // Get products with their respective total months
    const productsWithMonths = productRows ? calculateRangeForProducts(productRows) : []
    console.log('productsWithMonths: ', productsWithMonths)

    const { totalMonths } = calculateTotalMonthsForProducts(productsWithMonths)
    console.log('totalMonths: ', totalMonths)
    productsWithMonths.forEach(row => {
      const totalForRow = type === 'IO' ? calculateTotalForRow(row, row.months) : calculateTotalForRow(row, 1)
      const contractTotalRows = calculateTotalForRow(row, row.months)
      console.log('TOTAL FOR ROW: ', contractTotal)
      const mediaSpend = calculateMediaSpend(row.price, row.margin)

      // Accumulate gross profit and monthly total
      grossProfit += parseFloat(row.price) - parseFloat(mediaSpend)
      monthlyTotal += totalForRow // Monthly total is now per product
      contractTotal += contractTotalRows
    })

    // For IO-type deals, we accumulate the total from each product based on its individual months
    if (type === 'IO') {
      ioTotal = contractTotal
      ioMonthlyTotal = totalMonths ? ioTotal / totalMonths : 0
    }

    // Return totals based on the deal type
    return {
      grossProfit,
      implementationFee: parseFloat(initialImplementationFee),
      monthlyTotal: type === 'IO' ? ioMonthlyTotal : monthlyTotal,
      total: type === 'IO' ? ioTotal : contractTotal, // Use ioTotal for IO deals, monthlyTotal for others
    }
  }, [productRows, initialImplementationFee, type])

  return totals
}

export default useTotals

// import { useMemo } from 'react'
// import { calculateRangeForProducts, calculateTotalMonthsForProducts } from '../../utility/calculateTotalMonths'

// const calculateTotalForRow = row => parseFloat(row.price || 0) * parseFloat(row.quantity || 1)

// const calculateMediaSpend = (price, marginPercentage) => {
//   const marginValue = parseFloat(price) * (parseFloat(marginPercentage) / 100)
//   return (parseFloat(price) - marginValue).toFixed(2)
// }

// const useTotals = (productRows, initialImplementationFee, type) => {
//   console.log('Product Rows: ', productRows)
//   const totals = useMemo(() => {
//     let grossProfit = 0
//     let monthlyTotal = 0

//     // Get total months for IO-type deals
//     const { totalMonths } = productRows ? calculateTotalMonthsForProducts(productRows) : { totalMonths: 1 }
//     const test = productRows ? calculateRangeForProducts(productRows) : { totalMonths: 1 }
//     console.log('TEST: ', test)

//     productRows.forEach(row => {
//       const totalForRow = calculateTotalForRow(row)
//       const mediaSpend = calculateMediaSpend(row.price, row.margin)

//       // Accumulate gross profit and monthly total
//       grossProfit += parseFloat(row.price) - parseFloat(mediaSpend)
//       monthlyTotal += totalForRow
//     })

//     // For IO-type deals, multiply the monthly total by the totalMonths only once
//     const ioTotal = monthlyTotal * totalMonths
//     console.log('IO TOTAL: ', ioTotal)
//     // Return totals based on the deal type
//     return {
//       grossProfit,
//       implementationFee: parseFloat(initialImplementationFee),
//       monthlyTotal,
//       total: type === 'IO' ? ioTotal : monthlyTotal, // Use ioTotal for IO deals, monthlyTotal for others
//     }
//   }, [productRows, initialImplementationFee, type])

//   return totals
// }

// export default useTotals
