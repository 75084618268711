// src/components/Headers/Headers.js
import React, { useContext, useState } from 'react'
import { Box, Typography, Button, Tooltip, IconButton, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction'
import MoreTimeIcon from '@mui/icons-material/MoreTime'
import PersonIcon from '@mui/icons-material/Person'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import AppsIcon from '@mui/icons-material/Apps'
import GridViewIcon from '@mui/icons-material/GridView'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import Grid4x4Icon from '@mui/icons-material/Grid4x4'
import PandaDocImageComponent from '../AvatarImageComponents/PandaDocImageComponent'
import ViewKanbanIcon from '@mui/icons-material/ViewKanban'
import { HeaderContext } from '../../context/HeaderProvider/HeaderProvider'
import { headerStyle, HeaderButton } from './style'
import { useNavigate } from 'react-router-dom'

const Headers = ({ boardName, handleOpenCreateDeal, handleOpenCreateOrganization, handleOpenCreateContact }) => {
  const theme = useTheme()
  const textColor = theme.palette.mode === 'dark' ? 'white' : 'black'
  const navigate = useNavigate()
  const [extraStages, setExtraStages] = useState()
  const { organizationInfo, dealInfo, contactInfo } = useContext(HeaderContext)

  const handleNavigateToDeals = () => {
    navigate('/deals')
    setExtraStages(false)
  }
  const handleNavigateToKanban = () => {
    navigate('/deals/kanban')
  }
  const handleToggleChange = (event, value) => {
    if (value !== null) {
      setExtraStages(value === 'all')
    }
  }
  const renderHeaderContent = boardName => {
    switch (boardName) {
      case 'Dashboard':
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <GridViewIcon sx={{ color: 'rgb(5, 190, 113)' }} />
            <Typography sx={headerStyle(theme)}>{boardName}</Typography>
          </Box>
        )
      case 'Leads':
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <OnlinePredictionIcon sx={{ color: 'rgb(0, 134, 192)' }} />
            <Typography sx={headerStyle(theme)}>{boardName}</Typography>
          </Box>
        )
      case 'Prospects':
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <MoreTimeIcon sx={{ color: 'rgb(224, 118, 82)' }} />
            <Typography sx={headerStyle(theme)}>{boardName}</Typography>
          </Box>
        )
      case 'Proposals':
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <ContentPasteIcon
              sx={{
                color: theme.palette.mode === 'dark' ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
              }}
            />
            <Typography sx={headerStyle(theme)}>{boardName}</Typography>
          </Box>
        )
      case 'Contacts':
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <PersonIcon sx={{ color: 'rgb(255, 123, 187)' }} />
              <Typography sx={headerStyle(theme)}>{boardName}</Typography>
            </Box>
            <HeaderButton variant='contained' onClick={handleOpenCreateContact}>
              Create Contact
            </HeaderButton>
          </Box>
        )
      case 'Organizations':
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <CorporateFareIcon sx={{ color: 'rgb(114, 229, 218)' }} />
              <Typography sx={headerStyle(theme)}>{boardName}</Typography>
            </Box>
            <HeaderButton variant='contained' onClick={handleOpenCreateOrganization}>
              Create Organization
            </HeaderButton>
          </Box>
        )
      case 'Deals':
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <AttachMoneyIcon sx={{ color: '#fbc11e' }} />
              <Typography sx={headerStyle(theme)}>{boardName}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography sx={{ color: textColor }}>{'Grid View'}</Typography>
              <Tooltip title='View Kanban'>
                <IconButton onClick={handleNavigateToKanban}>
                  <AppsIcon sx={{ height: 30, width: 30 }} />
                </IconButton>
              </Tooltip>

              <HeaderButton variant='contained' sx={{ ml: 5 }} onClick={handleOpenCreateDeal}>
                Create Deal
              </HeaderButton>
            </Box>
          </Box>
        )
      case 'Deals Kanban':
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <Grid4x4Icon
                sx={{
                  color: '#fbc11e',
                }}
              />
              <Typography sx={headerStyle(theme)}>{boardName}</Typography>
            </Box>
            <Box>
              <ToggleButtonGroup value={extraStages ? 'all' : 'default'} exclusive onChange={handleToggleChange}>
                <ToggleButton value='default'>Default Stages</ToggleButton>
                <ToggleButton value='all'>All Stages</ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Typography sx={{ color: textColor }}>{'Kanban View'}</Typography>
              <Tooltip title='View Grid'>
                <IconButton onClick={handleNavigateToDeals}>
                  <ViewKanbanIcon sx={{ height: 30, width: 30 }} />
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <HeaderButton sx={{ ml: 5 }} onClick={handleOpenCreateDeal}>
                Create Deal
              </HeaderButton>
            </Box>
          </Box>
        )
      case 'PandaDoc Documents':
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <PandaDocImageComponent sx={{ color: 'rgba(255,255,255,0.3)' }} />
            <Typography sx={headerStyle(theme)}>{boardName}</Typography>
          </Box>
        )
      default:
        return null
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: 1,
        position: 'sticky',
        zIndex: 901,
      }}
    >
      {renderHeaderContent(boardName)}
    </Box>
  )
}

export default Headers
