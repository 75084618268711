import React, { useState } from 'react'
import {
  Autocomplete,
  TextField,
  Chip,
  Box,
  List,
  ListItem,
  Tooltip,
  useTheme,
  Avatar,
  Stack,
  CircularProgress,
} from '@mui/material'
import ContactsDialog from './ContactsDialog'
import { useCreateContact } from '../../api/aws/useCreateContact'
import AddRenderOptionButton from './AddRenderOptionButton'
import { listItemStyle, stringAvatar } from './style'

const ContactsAutocomplete = ({
  contacts = [],
  selectedContacts,
  setSelectedContacts,
  label,
  onChange,
  isLoading,
}) => {
  const theme = useTheme()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [tempInput, setTempInput] = useState('')
  const [inputValue, setInputValue] = useState('')
  // const [selectedContacts, setSelectedContacts] = useState([])
  const { mutate: createContact } = useCreateContact()

  const handleOpenDialog = (inputValue) => {
    setTempInput(inputValue)
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setTempInput('')
    setInputValue('')
  }

  const handleCreateContacts = (newValue) => {
    createContact(newValue, {
      onSuccess: (data) => {
        const newContact = { ...data, contacts_uuid: data.contacts_uuid }
        const updatedContacts = [...(selectedContacts || []), newContact]
        setSelectedContacts(updatedContacts)
        onChange(updatedContacts)
        handleCloseDialog()
      },
    })
  }

  const handleSelectExistingContact = (event, newValue, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      setSelectedContacts(newValue)
      onChange(newValue)
    }
  }

  const handleRemoveContact = (contactId) => {
    const newSelectedContacts = selectedContacts.filter(
      (contact) => contact.contacts_uuid !== contactId
    )
    setSelectedContacts(newSelectedContacts)
    onChange(newSelectedContacts)
  }

  return (
    <>
      <Autocomplete
        multiple
        id="contacts-autocomplete"
        options={contacts}
        getOptionLabel={(option) => option.name || ''}
        value={selectedContacts ? selectedContacts : []}
        onChange={handleSelectExistingContact}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        filterOptions={(options, { inputValue }) => {
          let filtered = options.filter((option) =>
            option.name.toLowerCase().includes(inputValue.toLowerCase())
          )
          if (
            inputValue !== '' &&
            !filtered.some(
              (option) => option.name.toLowerCase() === inputValue.toLowerCase()
            )
          ) {
            filtered.unshift({
              inputValue,
              specialOption: true,
              id: `add-new-${inputValue}`,
            })
          }
          return filtered
        }}
        renderOption={(props, option) => {
          const { key, id, ...otherProps } = props
          return option.inputValue ? (
            <AddRenderOptionButton
              key={`${key}-${option.contacts_uuid}`}
              {...otherProps}
              option={option}
              handleOpenDialog={handleOpenDialog}
            />
          ) : (
            <Box
              key={`${key}-${option.contacts_uuid}`}
              {...otherProps}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                p: 0.5,
                gap: 2,
              }}
            >
              <Avatar
                src={option.photoUrl ? option.photoUrl : ''}
                {...stringAvatar(option.name)}
                alt={option.name}
                sx={{
                  bgcolor: 'rgb(255, 188, 220)',
                  alignContent: 'center',
                }}
              />
              <Stack
                sx={{
                  justifyContent: 'center',
                  flexGrow: 1,
                  width: '100%',
                }}
              >
                <List
                  sx={{
                    flexDirection: 'column',
                    width: '100%',
                    bgcolor: 'transparent',
                  }}
                  key={`${id}-${option.contacts_uuid}`}
                >
                  <ListItem
                    sx={{
                      ...listItemStyle(theme),
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      padding: 0,
                    }}
                  >
                    {option.name}
                  </ListItem>
                  <ListItem
                    sx={{
                      ...listItemStyle(theme, 12),
                      width: '100%', // Ensure the list item fills the width
                      display: 'flex',
                      alignItems: 'center',
                      padding: 0,
                    }}
                  >
                    {option.email}
                  </ListItem>
                </List>
              </Stack>
            </Box>
          )
        }}
        renderTags={(tagValue, getTagProps) => {
          const firstContact = tagValue[0]
          const additionalContacts = tagValue.slice(1)
          return (
            <>
              {firstContact && (
                <Chip
                  key={firstContact.contacts_uuid}
                  label={firstContact.name}
                  onDelete={() =>
                    handleRemoveContact(firstContact.contacts_uuid)
                  }
                />
              )}
              {additionalContacts.length > 0 && (
                <Tooltip
                  title={additionalContacts
                    .map((contact) => contact.name)
                    .join(', ')}
                >
                  <Chip
                    key={`extra-${additionalContacts.length}`}
                    label={`+${additionalContacts.length}`}
                    onDelete={() => {
                      const newSelectedContacts = selectedContacts.slice(0, 1)
                      setSelectedContacts(newSelectedContacts)
                      onChange(newSelectedContacts)
                    }}
                  />
                </Tooltip>
              )}
            </>
          )
        }}
        renderInput={(params) => <TextField {...params} label={label} />}
        fullWidth
        freeSolo
      />
      <ContactsDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        tempInput={tempInput}
        onSaveContact={handleCreateContacts}
      />
    </>
  )
}

export default ContactsAutocomplete
