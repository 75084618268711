// import React, { useContext } from 'react'
// import { Box, Button, Typography, Divider, useTheme } from '@mui/material'
// import CorporateFareIcon from '@mui/icons-material/CorporateFare'
// import { ContainerContext } from '../../context/ContainerProvider/ContainerProvider'

// export const renderHeaderContent = boardName => {
//   switch (boardName) {
//     case 'Organizations':
//       return (
//         <Box
//           sx={{
//             display: 'flex',
//             alignItems: 'center',
//             gap: 2,
//           }}
//         >
//           <CorporateFareIcon sx={{ color: 'rgb(114, 229, 218)' }} />
//           <Divider orientation='vertical' flexItem />
//           <Typography>{boardName}</Typography>
//         </Box>
//       )
//     default:
//       return null
//   }
// }

// const BoardHeaders = ({ boardName, data, handleOpenCreateOrganization }) => {
//   const theme = useTheme()
//   const textColor = theme.palette.mode === 'dark' ? 'white' : 'black'

//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         alignItems: 'center',
//         width: '100%',
//         gap: 1,
//       }}
//     >
//       {renderHeaderContent(boardName)}
//       {boardName === 'Organizations' ? (
//         <Box
//           sx={{
//             display: 'flex',
//             flexGrow: 1,
//             justifyContent: 'space-between',
//             alignItems: 'center',
//             gap: 2,
//           }}
//         >
//           <Typography sx={{ color: textColor, fontSize: 24, fontWeight: 'bold' }}>{data ? data.name : ''}</Typography>
//           <Divider orientation='vertical' flexItem />
//           <Typography sx={{ color: textColor }}>{data ? data.status : ''}</Typography>
//           <Button onClick={handleOpenCreateOrganization}>Create Organizations</Button>
//         </Box>
//       ) : (
//         <Typography sx={{ color: textColor, flexGrow: 1, textAlign: 'center' }}>{boardName}</Typography>
//       )}
//     </Box>
//   )
// }

// export default BoardHeaders

// BoardHeaders.js
import React, { useContext } from 'react'
import { Box, Button, Typography, Divider, useTheme } from '@mui/material'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import { ContainerContext } from './ContainerProvider'

export const renderHeaderContent = boardName => {
  switch (boardName) {
    case 'Organizations':
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <CorporateFareIcon sx={{ color: 'rgb(114, 229, 218)' }} />
          <Divider orientation='vertical' flexItem />
          <Typography>{boardName}</Typography>
        </Box>
      )
    default:
      return null
  }
}

const BoardHeaders = ({ boardName, data, handleOpenCreateOrganization }) => {
  const theme = useTheme()
  const textColor = theme.palette.mode === 'dark' ? 'white' : 'black'

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: 1,
      }}
    >
      {renderHeaderContent(boardName)}
      {boardName === 'Organizations' ? (
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Typography sx={{ color: textColor, fontSize: 24, fontWeight: 'bold' }}>{data ? data.name : ''}</Typography>
          <Divider orientation='vertical' flexItem />
          <Typography sx={{ color: textColor }}>{data ? data.status : ''}</Typography>
          <Button onClick={handleOpenCreateOrganization}>Create Organizations</Button>
        </Box>
      ) : (
        <Typography sx={{ color: textColor, flexGrow: 1, textAlign: 'center' }}>{boardName}</Typography>
      )}
    </Box>
  )
}

export default BoardHeaders

// import React from 'react'
// import { Box, Typography, Divider, useTheme } from '@mui/material'
// import GroboticPng from '../../images/Grobotic png.png'
// import TimelineIcon from '@mui/icons-material/Timeline'
// import SpeedIcon from '@mui/icons-material/Speed'
// import RefreshIcon from '@mui/icons-material/Refresh'
// import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
// import MoreTimeIcon from '@mui/icons-material/MoreTime'
// import CorporateFareIcon from '@mui/icons-material/CorporateFare'
// import PersonIcon from '@mui/icons-material/Person'
// import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction'
// import PivotTableChartIcon from '@mui/icons-material/PivotTableChart'
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
// import GroboticPngLight from '../../images/Grobotic png-lightmode.png'
// import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
// import PandaDocImageComponent from '../../components/AvatarImageComponents/PandaDocImageComponent'
// import GridViewIcon from '@mui/icons-material/GridView'
// import OrganizationTabs from '../../components/CustomTabDetails/OrganizationTabs'
// import { getStatusColor } from '../../components/DataGrid/createColumns/renderOrganizationColumns'

// export const renderHeaderContent = boardName => {
//   switch (boardName) {
//     case 'Dashboard':
//       // return <CustomDateRender objectData={content} dateField={item.field} />
//       return (
//         <>
//           <GridViewIcon
//             sx={{
//               color: 'rgb(5, 190, 113)',
//             }}
//           />
//         </>
//       )
//     case 'Leads':
//       // return <CustomDateRender objectData={content} dateField={item.field} />
//       return (
//         <>
//           <OnlinePredictionIcon
//             sx={{
//               color: 'rgb(0, 134, 192)',
//             }}
//           />
//         </>
//       )
//     case 'Prospects':
//       return (
//         <>
//           <MoreTimeIcon
//             sx={{
//               color: 'rgb(224, 118, 82)',
//             }}
//           />
//         </>
//       )
//     case 'Contacts':
//       return (
//         <>
//           <PersonIcon
//             sx={{
//               color: 'rgb(255, 123, 187)',
//             }}
//           />
//         </>
//       )
//     case 'Organizations':
//       return (
//         <>
//           <CorporateFareIcon
//             sx={{
//               color: 'rgb(114, 229, 218)',
//             }}
//           />
//         </>
//       )
//     case 'Organization Details':
//       return (
//         <>
//           <CorporateFareIcon
//             sx={{
//               color: 'rgb(114, 229, 218)',
//             }}
//           />
//         </>
//       )
//     case 'Deals':
//       return (
//         <>
//           <AttachMoneyIcon
//             sx={{
//               color: '#fbc11e',
//             }}
//           />
//         </>
//       )
//     case 'Agreements':
//       return (
//         <>
//           <ThumbUpOffAltIcon
//             sx={{
//               color: '#fbc11e',
//             }}
//           />
//         </>
//       )
//     case 'Deals':
//       return (
//         <>
//           <AttachMoneyIcon
//             sx={{
//               color: '#fbc11e',
//             }}
//           />
//         </>
//       )
//     case 'Contracts':
//       return (
//         <>
//           <ThumbUpOffAltIcon
//             sx={{
//               color: '#fbc11e',
//             }}
//           />
//         </>
//       )
//     case 'Edit Contracts':
//       return (
//         <>
//           <ThumbUpOffAltIcon
//             sx={{
//               color: '#fbc11e',
//             }}
//           />
//         </>
//       )
//     case 'PandaDoc Contracts':
//       return (
//         <>
//           <PandaDocImageComponent
//             sx={{
//               color: 'rgba(255,255,255,0.3)',
//             }}
//           />
//         </>
//       )
//     default:
//       return null
//   }
// }
// const BoardHeaders = ({ icon, boardName, data }) => {
//   console.log('[BoardHeaders] data: ', data)
//   const theme = useTheme()
//   const textColor = theme.palette.mode === 'dark' ? 'white' : 'black'
//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         flexDirection: 'row',
//         justifyContent: 'space-evenly',
//         alignItems: 'center',
//         width: '500px',
//         gap: 1,
//       }}
//     >
//       {/* <Box>
//         <img height={40} width={40} src={theme.palette.mode === 'dark' ? GroboticPng : GroboticPngLight} />
//       </Box> */}
//       {/* <Divider orientation='vertical' flexItem /> */}
//       {renderHeaderContent(boardName)}

//       {boardName === 'Organization Details' ? (
//         <Box
//           sx={{
//             display: 'flex',
//             flexDirection: 'row',
//             justifyContent: 'space-between',
//             alignItems: 'center',
//           }}
//         >
//           {/* <Typography sx={{ color: textColor }}>{`${boardName} / ${data ? data.name : null}`}</Typography> */}
//           <Box>
//             <Typography variant='h4' sx={{ color: textColor }}>{`${data ? data.name : null}`}</Typography>
//           </Box>
//           <Box>
//             <Typography sx={{ color: getStatusColor(data.status) }}>{`${data ? data.status : null}`}</Typography>
//           </Box>
//         </Box>
//       ) : (
//         <Typography sx={{ color: textColor }}>{boardName}</Typography>
//       )}
//     </Box>
//   )
// }

// export default BoardHeaders
