import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions'

const fetchActiveUser = async (userId, slackToken) => {
  const apiUrl = `${baseUrl}/aws/userinfo?user=${userId}`
  try {
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `${slackToken}` },
    })
    const currentActiveUser = response.data?.user
    console.log(`Current user is ${currentActiveUser?.real_name}, their id is ${currentActiveUser?.id}`)
    // Store the active user in localStorage
    localStorage.setItem('activeUser', JSON.stringify(response.data))
    return response.data
  } catch (error) {
    console.error('Failed to fetch user info:', error)
    throw new Error('Failed to fetch user info')
  }
}

export const useActiveUser = () => {
  const userId = localStorage.getItem('slack_user_id')
  const slackAccessToken = localStorage.getItem('slack_access_token')
  const cachedUser = localStorage.getItem('activeUser')

  const { data: activeUser, isLoading: isActiveUserLoading, isError: isActiveUserError, error } = useQuery({
    queryKey: ['activeUser', userId],
    queryFn: () => fetchActiveUser(userId, slackAccessToken),
    enabled: !!userId && !cachedUser, // Only fetch if userId is available and no cachedUser
    initialData: cachedUser ? JSON.parse(cachedUser) : undefined, // Use cached user data if available
    staleTime: 1000 * 60 * 10, // 10 minutes
    cacheTime: 1000 * 60 * 15, // 15 minutes
  })

  return { activeUser, isActiveUserLoading, isActiveUserError, error }
}

// import { useQuery } from '@tanstack/react-query'
// import axios from 'axios'
// import { baseUrl } from '../queryMutations/mutationFunctions'

// const fetchActiveUser = async (userId, slackToken) => {
//   const apiUrl = `${baseUrl}/aws/userinfo?user=${userId}`
//   try {
//     const response = await axios.get(apiUrl, {
//       headers: { Authorization: `${slackToken}` },
//     })
//     const currentActiveUser = response.data?.user
//     console.log(`Current user is ${currentActiveUser?.real_name}, their id is ${currentActiveUser?.id}`)
//     return response.data
//   } catch (error) {
//     console.error('Failed to fetch user info:', error)
//     throw new Error('Failed to fetch user info')
//   }
// }

// export const useActiveUser = () => {
//   const userId = localStorage.getItem('slack_user_id')
//   const slackAccessToken = localStorage.getItem('slack_access_token')

//   const { data: activeUser, isLoading: isActiveUserLoading, isError: isActiveUserError, error } = useQuery({
//     queryKey: ['activeUser', userId],
//     queryFn: () => fetchActiveUser(userId, slackAccessToken),
//     enabled: !!userId,
//   })

//   return { activeUser, isActiveUserLoading, isActiveUserError, error }
// }
