const determineNotificationRecipients = (deal) => {
  const businessOperationsTypes = ['CSA', 'MSA']
  const accountingTypes = ['IO', 'RMSA']
  const businessOperationsManager = 'U06DLUEKM29' // Jessica Widmayer
  // const businessOperationsManager = 'U015KLM51B6' // Patric

  const accountingManager = 'U021T0PM17B' // Jamie Lambert
  // const accountingManager = 'UHKAA49CJ' // Justin Widmayer
  let recipients = []

  // Add the requestedBy user
  if (deal && deal.requestedBy) {
    recipients.push(deal.requestedBy)
  }

  // Add appropriate manager based on deal type
  if (deal && deal.type) {
    if (businessOperationsTypes.includes(deal.type)) {
      recipients.push(businessOperationsManager)
    } else if (accountingTypes.includes(deal.type)) {
      recipients.push(accountingManager)
    }
  }

  return recipients
}
export const createDealWonPayload = (deal) => {
  const dealId = deal?.deal_uuid || ''
  const dealName = deal?.name || ''
  const url = `https://grobotic.app/deals/${dealId}`
  const invoiceUrl = `https://grobotic.app/deals/${dealId}/invoicing`
  const reviewDealUrl = `https://grobotic.app/deals/${dealId}/reviewdeal`
  const paymentScheduleUrl = `https://grobotic.app/deals/${dealId}/paymentschedule`
  const requestedById = deal?.requestedBy || ''
  const slackUserIds = determineNotificationRecipients(deal)
  const nicCageGif = 'https://media.giphy.com/media/CiTLZWskt7Fu/giphy.gif'
  const officeGif =
    'https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExeTk5ZDlldGpoNmtqcHhjNXpzNnk4aGtlNjFpZXczMnVhZjlrejRmaSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/IwAZ6dvvvaTtdI8SD5/giphy.webp'

  const blockMessage = [
    {
      type: 'divider',
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*Deal Completed*\n:100: :sunglasses: :thumbsup:\n\n*Name*: ${dealName}`,
      },
      accessory: {
        type: 'image',
        image_url: officeGif,
        alt_text: 'One of us',
      },
    },
    {
      type: 'divider',
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*<${url}|View Deal>*\n*<${reviewDealUrl}|Review Deal>*\n*<${invoiceUrl}|Invoicing>*\n*<${paymentScheduleUrl}|Payment Schedule>*`,
      },
    },
  ]

  return {
    slackUserIds,
    text: `Hi, deal (${deal?.type}) has been completed: ${dealName}. View it here: ${url}`,
    blocks: blockMessage,
    icon_emoji: ':earth_americas:',
    username: 'Grobotic',
  }
}

// export const createDealWonPayload = async deal => {
//   const dealId = deal ? deal.deal_uuid : ''
//   const dealName = deal ? deal.name : ''
//   const url = `https://grobotic.app/deals/${dealId}`
//   const requestedById = deal ? deal.requestedBy : ''
//   const accountingUserIds = determineNotificationRecipients(deal)
//   const slackUserIds = [requestedById, ...accountingUserIds]
//   const nicCageGif =
//     'https://media.giphy.com/media/CiTLZWskt7Fu/giphy.gif?cid=790b7611s5nfuigv06l0rdztt6dmk6f6r0qzt4f91losi8e6&ep=v1_gifs_search&rid=giphy.gif&ct=g'
//   const csaTypes = ['CSA', 'RCSA']
// const officeGif = 'https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExeTk5ZDlldGpoNmtqcHhjNXpzNnk4aGtlNjFpZXczMnVhZjlrejRmaSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/IwAZ6dvvvaTtdI8SD5/giphy.webp'
//   // Standard message text (optional)
//   const message = `Hi deal (${
//     deal ? deal.type : ''
//   }) has been completed:\nName: ${dealName}\nURL: <${url}|Grobotic Link>`

//   const blockMessage = [
//     {
//       type: 'divider',
//     },
//     {
//       type: 'section',
//       text: {
//         type: 'mrkdwn',
//         text: `*Deal Completed*\n:rocket: :sunglasses: :thumbsup:\n\n*Name*: ${dealName}\n*<${url}|View in Grobotic>*`,
//       },
//       accessory: {
//         type: 'image',
//         image_url: nicCageGif,
//         alt_text: 'One of us',
//       },
//     },
//     {
//       type: 'divider',
//     },
//   ]
//   // Construct the payload
//   const csaChannel = 'C07H620GSQ4'
//   const msaChannel = 'C07H3E45WTV'

//   const determineNotificationPayload = () => {
//     if (deal && deal.type && csaTypes.includes(deal.type)) {
//       return {
//         channel: slackUserIds,
//         text: message, // Fallback text for clients that don't support blocks
//         blocks: blockMessage, // Properly structured blocks array
//         icon_emoji: ':earth_americas:',
//         username: 'Grobotic',
//       }
//     } else {
//       return {
//         channel: requestedById,
//         text: message, // Fallback text for clients that don't support blocks
//         blocks: blockMessage, // Properly structured blocks array
//         icon_emoji: ':earth_americas:',
//         username: 'Grobotic',
//       }
//     }
//   }

//   const payload = determineNotificationPayload(deal)

//   return JSON.stringify(payload)
// }
