import { useState, useEffect } from 'react'
import NewParentItemForm from './NewParentItemForm'
import ParentItemList from './ParentItemList'
import { Box } from '@mui/material'
import { containerStyles } from './styles'

const CampaignList = () => {
  const [parentItems, setParentItems] = useState(() => {
    const localValue = localStorage.getItem('PARENT_ITEMS')
    if (localValue == null) return []

    return JSON.parse(localValue)
  })

  useEffect(() => {
    localStorage.setItem('PARENT_ITEMS', JSON.stringify(parentItems))
  }, [parentItems])

  const addParentItem = (platform, campaignName, type, geoTarget, description, dateRange) => {
    const newItem = {
      id: crypto.randomUUID(),
      platform,
      campaignName,
      type,
      geoTarget,
      description,
      dateRange,
      subItems: [],
    }
    setParentItems(currentItems => [...currentItems, newItem])
  }

  const addSubItem = (parentId, subItem) => {
    console.log('[addSubItem] : parentId: ', parentId, 'subItem: ', subItem)
    setParentItems(currentItems => {
      return currentItems.map(item => {
        if (item.id === parentId && item.subItems.length < 10) {
          return { ...item, subItems: [...item.subItems, subItem] }
        }
        return item
      })
    })
  }

  const deleteParentItem = id => {
    setParentItems(currentItems => {
      return currentItems.filter(item => item.id !== id)
    })
  }

  const deleteSubItem = (parentId, subItemId) => {
    setParentItems(currentItems => {
      return currentItems.map(item => {
        if (item.id === parentId) {
          return {
            ...item,
            subItems: item.subItems.filter((_, idx) => idx !== subItemId),
          }
        }
        return item
      })
    })
  }

  // console.log(parentItems)

  return (
    <Box sx={containerStyles}>
      <h1 className='header'>Campaign Lists</h1>
      <Box sx={{ border: '1px solid', borderColor: 'blue', padding: 5 }}>
        <NewParentItemForm onSubmit={addParentItem} />
      </Box>
      <Box sx={{ border: '1px solid', borderColor: 'green', padding: 5 }}>
        <ParentItemList
          parentItems={parentItems}
          addSubItem={addSubItem}
          deleteParentItem={deleteParentItem}
          deleteSubItem={deleteSubItem}
        />
      </Box>
    </Box>
  )
}

export default CampaignList
