import React, { useState } from 'react'
import { Autocomplete, Box, TextField, Typography, Chip, useTheme, Tooltip } from '@mui/material'
import { AutocompleteButton } from '../../style/styleElements'
import { useCreateOrganization } from '../../api/aws/useCreateOrganization'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import RenderCompanyChip from '../RenderChipComponents/RenderCompanyChip'
import { useOrganizations } from '../../api/aws/useOrganizations'
import DetailsLoading from '../Loading/DetailsLoading'

const AssignCompaniesAutocomplete = ({
  label,
  value,
  onChange,
  context,
  onBlur,
  multiple = true,
  disabled = false,
}) => {
  const { showSnackbar } = useSnackbarContext()
  const theme = useTheme()
  const themeColor = theme.palette.mode === 'dark' ? 'white' : 'black'
  const [dialogOpen, setDialogOpen] = useState(false)
  const [tempInput, setTempInput] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [selectedOrganizations, setSelectedOrganizations] = useState(multiple ? value || [] : value || null)
  const { mutate: createOrganization } = useCreateOrganization()
  const { organizations, isOrganizationsLoading, isOrganizationsError } = useOrganizations()

  const handleOpenDialog = inputValue => {
    setTempInput(inputValue)
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setTempInput('')
    setInputValue('')
  }

  const handleSave = newValue => {
    createOrganization(newValue, {
      onSuccess: data => {
        const newOrg = { ...data, id: data.org_uuid }
        const updatedOrganizations = multiple ? [...selectedOrganizations, newOrg] : newOrg
        setSelectedOrganizations(updatedOrganizations)
        onChange(context, multiple ? updatedOrganizations.map(org => org.id) : updatedOrganizations.id)
        handleCloseDialog()
        showSnackbar('Organization created successfully', 'success')
      },
    })
  }

  const handleSelectExistingOrganization = (event, newValue) => {
    const validOrganizations = multiple ? newValue.filter(org => org !== null) : newValue
    setSelectedOrganizations(validOrganizations)
    onChange(context, multiple ? validOrganizations.map(org => org.id) : validOrganizations.id)
    onBlur(context, multiple ? validOrganizations.map(org => org.id) : validOrganizations.id)
  }

  const handleRemoveOrganization = orgId => {
    const newSelectedOrganizations = multiple ? selectedOrganizations.filter(org => org && org.id !== orgId) : null
    setSelectedOrganizations(newSelectedOrganizations)
    onChange(context, multiple ? newSelectedOrganizations.map(org => org.id) : newSelectedOrganizations)
    onBlur(context, multiple ? newSelectedOrganizations.map(org => org.id) : newSelectedOrganizations)
  }

  return (
    <>
      {isOrganizationsLoading && <DetailsLoading />}
      <Autocomplete
        multiple={multiple}
        id='custom-company-autocomplete'
        options={organizations}
        getOptionLabel={option => option.name || ''}
        value={selectedOrganizations}
        onChange={handleSelectExistingOrganization}
        disabled={disabled}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        filterOptions={(options, { inputValue }) => {
          let filtered = options.filter(
            option => option.name && option.name.toLowerCase().includes(inputValue.toLowerCase())
          )
          if (
            inputValue !== '' &&
            !filtered.some(option => option.name && option.name.toLowerCase() === inputValue.toLowerCase())
          ) {
            filtered.unshift({
              inputValue,
              specialOption: true,
              id: `add-new-${inputValue}`,
            })
          }
          return filtered
        }}
        renderOption={(props, option) => {
          return option.specialOption ? (
            <AutocompleteButton
              key={`add-new-${option.inputValue}`} // Ensure unique key for new options
              {...props}
              onClick={() => handleOpenDialog(option.inputValue)}
            >
              <Typography>Add "{option.inputValue}"</Typography>
            </AutocompleteButton>
          ) : (
            <Box key={option.org_uuid || option.inputValue} {...props}>
              <RenderCompanyChip option={option} />
            </Box>
          )
        }}
        renderTags={(tagValue, getTagProps) => {
          const maxVisibleChips = 1
          const extraOrganizations = tagValue.slice(maxVisibleChips)

          return (
            <>
              {tagValue.slice(0, maxVisibleChips).map((option, index) => {
                const { key, ...rest } = getTagProps({ index }) // No need to destructure 'key'
                return (
                  <Box key={`chip-${option.org_uuid || option.inputValue || index}`}>
                    <Chip
                      label={<RenderCompanyChip option={option} />}
                      {...rest}
                      onDelete={() => handleRemoveOrganization(option.id || option.org_uuid)}
                      size='small'
                      style={{
                        height: 'auto',
                        width: 'auto',
                        borderColor: option.color,
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        color: themeColor,
                        borderRadius: '4px',
                        marginRight: '8px',
                      }}
                    />
                  </Box>
                )
              })}
              {extraOrganizations.length > 0 && (
                <Tooltip
                  title={
                    <Box>
                      {extraOrganizations.map(org => (
                        <RenderCompanyChip key={`extra-chip-${org.org_uuid}`} option={org} />
                      ))}
                    </Box>
                  }
                  arrow
                >
                  <Chip
                    label={`+${extraOrganizations.length}`}
                    size='small'
                    style={{
                      height: 'auto',
                      width: 'auto',
                      borderColor: themeColor,
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      color: themeColor,
                      borderRadius: '4px',
                      marginRight: '8px',
                    }}
                  />
                </Tooltip>
              )}
            </>
          )
        }}
        renderInput={params => <TextField {...params} label={label} autoComplete='off' />}
        fullWidth
        freeSolo
      />
    </>
  )
}

export default AssignCompaniesAutocomplete
