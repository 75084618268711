import React from 'react'
import { Grid, Typography, Checkbox, useTheme, Chip } from '@mui/material'
import dayjs from 'dayjs'
import {
  containerStyle,
  nameStyle,
  itemStyle,
  typographyStyle,
  typographyNameStyle,
  typographyTypeStyle,
} from './style'
import SprintCard from './SprintCard.tsx'

interface SprintProps {
  name: string
  type: string
  estimate: string
  startDate: string
  endDate: string
  description: string
  isCompleted: boolean
  onComplete: () => void
}

const formatDateString = (date: string) => {
  if (!date) return ''
  return dayjs.isDayjs(date) ? date.format('MM-DD-YYYY') : dayjs(date).format('MM-DD-YYYY')
}

const Sprint: React.FC<SprintProps> = ({
  name,
  type,
  estimate,
  startDate,
  endDate,
  description,
  isCompleted,
  onComplete,
}) => {
  const theme = useTheme()

  return (
    <Grid
      item
      xs={3}
      className='sprint'
      sx={{
        ...containerStyle,
        ...(isCompleted && { opacity: 0.5, pointerEvents: 'none' }),
      }}
    >
      <SprintCard
        name={name}
        type={type}
        description={description}
        startDate={startDate}
        endDate={endDate}
        estimate={estimate}
        formatDateString={formatDateString}
        isCompleted={isCompleted}
        onComplete={onComplete}
      />
    </Grid>
  )
}

export default Sprint
