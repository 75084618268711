import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/queryMutations'

const fetchPropMgmtById = async propertyMgmtId => {
  const { data } = await axios.get(`${baseUrl}/aws/pmgs/${propertyMgmtId}`)
  console.log('Data: ', data)
  return data
}

export const usePropMgmtCompany = propertyMgmtId => {
  return useQuery({
    queryKey: ['propertyMgmtCompany', propertyMgmtId],
    queryFn: () => fetchPropMgmtById(propertyMgmtId),
    staleTime: 1000 * 60 * 5, // Data is considered fresh for 5 minutes
    enabled: !!propertyMgmtId, // This query will not run until both propertyMgmtId and prospectName are available
  })
}
