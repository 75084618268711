import * as React from 'react'
import { useEffect } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import WelcomeHeaderThemedImage from '../../images/WelcomeHeaderThemedImage/WelcomeHeaderThemedImage'
import GroboticLogoFullDark from '../../images/GRObotic Logo-darkfull.png'
import GroboticLogoFullLight from '../../images/GRObotic Logo-full-lightmode.png'
import { useLocation, useNavigate } from 'react-router-dom'
import MainNavigationSection from './MainNavigation/MainNavigationSection'
import DevNavigationSection from './DevNavigation/DevNavigationSection'
import BoardsNavigation from './BoardsNavigation/BoardsNavigation'
import CreateMenuIcons from './CreateMenuIcons'
import { Typography } from '@mui/material'
import GroboticVersionControl, { version } from '../Version'
import { useActiveUser } from '../../api/slack/useActiveUser'
import UserImage from '../AvatarImageComponents/UserImage'
import { NotificationProvider } from '../../context/NotificationProvider/NotificationProvider'
import ShowNotifications from '../NotificationDrawer/ShowNotifications'

const drawerWidth = 260

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'white',
})

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export default function MiniDrawer({
  handleOpenCreateProject,
  handleOpenCreateTask,
  handleOpenCreateOrganization,
  handleOpenCreateLead,
  handleOpenCreateDeal,
  handleOpenCreateContact,
  isNotifyDrawerOpen,
  onOpenNotify,
  onCloseNotify,
  children,
}) {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  // const {
  //   data: slackUserProfile,
  //   isLoading: slackUserProfileLoading,
  //   isError: slackUserProfileError,
  // } = useSlackUserProfile()
  // const { data: activeUser, isLoading: activeSlackUserLoading, isError: activeSlackUserError } = useActiveUser()
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const { user } = activeUser ? activeUser : {}
  const navigate = useNavigate()
  const location = useLocation()
  // const [selectedPath, setSelectedPath] = React.useState(
  //   window.location.pathname
  // )
  const [selectedPath, setSelectedPath] = React.useState(location.pathname)

  // This useEffect will handle updating `selectedPath`
  useEffect(() => {
    console.log(`Route Endpoint: ${location.pathname}`)
    setSelectedPath(location.pathname)
  }, [location])
  const drawerBgColor = theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'white'
  const bgSelectColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'
  const bgHoverColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'
  const iconSelectColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.2)'
  const pathTextColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255)' : 'rgba(0,0,0)'

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }
  const handleNavigation = path => {
    console.log('PATH: ', path)
    navigate(`/${path}`)
  }
  return (
    <Box>
      <CssBaseline />
      <AppBar sx={{ borderRadius: 0 }} position='fixed' open={open}>
        <Box sx={{ zIndex: 800, position: 'sticky' }} />
        <Toolbar sx={{ zIndex: 900, position: 'sticky' }}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <WelcomeHeaderThemedImage srcLight={GroboticLogoFullLight} srcDark={GroboticLogoFullDark} />
              <Box>
                <GroboticVersionControl />
              </Box>
              {/* <Typography sx={{ color: pathTextColor }}>{decodeURIComponent(selectedPath)}</Typography> */}
            </Box>

            {/* <ContainerWithHeader /> */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'development' && (
                  <>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        mr: 2,
                        color: 'rgb(187, 18, 255)',
                      }}
                    >
                      SERVER
                    </Typography>

                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        mr: 2,
                        color: 'rgba(25, 181, 254, 1)',
                      }}
                    >
                      DEVELOPMENT
                    </Typography>
                  </>
                )}
                {process.env.NODE_ENV && process.env.NODE_ENV === 'development' && (
                  <>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        mr: 2,
                        color: 'rgba(205, 254, 194, 1)',
                      }}
                    >
                      LOCAL
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        mr: 2,
                        color: 'rgba(25, 181, 254, 1)',
                      }}
                    >
                      DEVELOPMENT
                    </Typography>
                  </>
                )}

                {/* <BugReportIcon /> */}
              </Box>
              <CreateMenuIcons
                handleOpenCreateProject={handleOpenCreateProject}
                handleOpenCreateTask={handleOpenCreateTask}
                handleOpenCreateOrganization={handleOpenCreateOrganization}
                handleOpenCreateLead={handleOpenCreateLead}
                handleOpenCreateDeal={handleOpenCreateDeal}
                handleOpenCreateContact={handleOpenCreateContact}
                // imageUrl={
                //   slackUserProfile ? slackUserProfile.profile.image_512 : null
                // }
                imageUrl={activeUser?.user?.profile?.image_512 || null}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                <NotificationProvider>
                  <ShowNotifications
                    open={isNotifyDrawerOpen}
                    onClose={onCloseNotify}
                    imageUrl={activeUser?.user?.profile?.image_512 || null}
                  />
                </NotificationProvider>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        open={open}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: drawerBgColor, // apply theme-dependent style here
            width: drawerWidth,
            boxSizing: 'border-box',
            ...(open && {
              width: drawerWidth,
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }),
            ...(!open && {
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              overflowX: 'hidden',
              width: `calc(${theme.spacing(7)} + 1px)`,
              [theme.breakpoints.up('sm')]: {
                width: `calc(${theme.spacing(8)} + 1px)`,
              },
            }),
          },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box>
          <MainNavigationSection handleNavigation={handleNavigation} open={open} selectedPath={selectedPath} />
          <Divider />
          <BoardsNavigation handleNavigation={handleNavigation} open={open} selectedPath={selectedPath} />
          <Divider />
          {user && user.id === 'U062JDP8R5X' && (
            <>
              <DevNavigationSection handleNavigation={handleNavigation} open={open} selectedPath={selectedPath} />
              <Divider />
            </>
          )}
        </Box>
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  )
}
