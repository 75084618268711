import React from 'react'
import { Box, List, ListItem, ListItemText, Typography, Switch } from '@mui/material'
import Popover from '@mui/material/Popover'
import IconButton from '@mui/material/IconButton'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import CreateMenu from '../CreateMenu/CreateMenu'
// import SidebarUserImage from '../Slack/UserImage/SidebarUserImage'
// import ShowNotifications from '../NotificationDrawer/ShowNotifications'
import ThemeToggleButton from '../../themeProviders/ThemeToggleButton/ThemeToggleButton'
import AddIcon from '@mui/icons-material/Add'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import UserImage from '../AvatarImageComponents/UserImage'

const InfoMessage = ({ imageUrl }) => {
  return (
    <List>
      <ListItem>
        <AddIcon />
        <Typography sx={{ fontSize: '12px' }}>Menu for creating</Typography>
      </ListItem>
      <ListItem>
        <NotificationsNoneIcon />
        <Typography sx={{ fontSize: '12px' }}>Click to view notifications</Typography>
      </ListItem>
      <ListItem>
        <Switch disabled />
        <Typography sx={{ fontSize: '12px' }}>Toggle light and dark mode</Typography>
      </ListItem>
      <ListItem>
        <UserImage imageUrl={imageUrl} />
        <Typography sx={{ fontSize: '12px' }}>Current user image from Slack</Typography>
      </ListItem>
    </List>
  )
}

const CreateMenuIcons = ({
  imageUrl,
  handleOpenCreateProject,
  handleOpenCreateTask,
  handleOpenCreateOrganization,
  handleOpenCreateLead,
  handleOpenCreateDeal,
  handleOpenCreateContact,
}) => {
  // Popover state
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <IconButton onClick={handlePopoverOpen}>
        <HelpOutlineIcon />
      </IconButton>
      <Popover
        id='help-popover'
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2 }}>
          <InfoMessage imageUrl={imageUrl} />
        </Typography>
      </Popover>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row', // This stacks children vertically
          gap: 1, // Adjusts the space between the elements
        }}
      >
        <CreateMenu
          onOpenCreateProject={handleOpenCreateProject}
          onOpenCreateTask={handleOpenCreateTask}
          onOpenCreateOrganization={handleOpenCreateOrganization}
          onOpenCreateLead={handleOpenCreateLead}
          onOpenCreateDeal={handleOpenCreateDeal}
          onOpenCreateContact={handleOpenCreateContact}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 1,
        }}
      >
        {/* <ShowNotifications /> */}
        <ThemeToggleButton />
        {/* <UserImage imageUrl={imageUrl ? imageUrl : null} /> */}
      </Box>
    </>
  )
}

export default CreateMenuIcons
