import React from 'react'
import { Box, Backdrop, CircularProgress, Typography, useTheme } from '@mui/material'

const LoadingOverlay = ({ open, message }) => {
  const theme = useTheme()
  return (
    <Backdrop
      sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'white', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {message ? (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {`${message}`.split('').map((letter, index) => (
              <Typography
                variant='h4'
                key={index}
                className='animated-letter'
                style={{
                  animationDelay: `${index * 0.1}s`, // Controls when the animation starts for each letter
                  animationDuration: '0.5s', // Controls the speed of the fade-in effect
                  animationFillMode: 'forwards', // Ensures the letter stays visible after animation
                  color: theme.palette.mode === 'dark' ? 'white' : 'white',
                }}
              >
                {letter === ' ' ? '\u00A0' : letter}
              </Typography>
            ))}
          </Box>
        ) : null}
        <Box mt={2}>
          <CircularProgress color='inherit' />
        </Box>
      </Box>
    </Backdrop>
  )
}

export default LoadingOverlay
