import dayjs from 'dayjs'

export const calculateCSAPaymentSchedule = deal => {
  if (!deal || !deal.products) return []

  // Step 1: Determine the earliest start date and the latest end date
  const startDate = dayjs.min(deal.products.map(product => dayjs(product.startDate)))
  const endDate = dayjs.max(deal.products.map(product => dayjs(product.endDate)))

  // Step 2: Calculate the total number of months between the start and end dates
  const totalPayments = deal && deal.numberOfPayments ? deal.numberOfPayments : 2

  // Step 3: Calculate the total contract amount
  const totalAmount = deal.products.reduce((sum, product) => sum + parseFloat(product.price), 0)

  // Step 4: Calculate the monthly payment amount
  const monthlyPayment = (totalAmount / totalPayments).toFixed(2)

  // Step 5: Generate the payment schedule
  const paymentSchedule = []
  let currentMonth = startDate.startOf('month')

  for (let i = 0; i < totalPayments; i++) {
    paymentSchedule.push({
      month: currentMonth.format('MMMM'),
      year: currentMonth.year(),
      paymentAmount: parseFloat(monthlyPayment),
      productsAssociated: deal.products.map(product => product.productName), // or customize as needed
    })
    currentMonth = currentMonth.add(1, 'month')
  }

  return paymentSchedule
}

// import dayjs from 'dayjs'

// export const calculateCSAPaymentSchedule = deal => {
//   if (!deal || !deal.products) return []

//   const paymentSchedule = []

//   // Iterate over each product in the deal
//   deal.products.forEach(product => {
//     const startDate = dayjs(product.startDate)
//     const endDate = dayjs(product.endDate)
//     const price = parseFloat(product.price)

//     let currentMonth = startDate.startOf('month')

//     // Loop through each month the product is active
//     while (currentMonth.isSameOrBefore(endDate.startOf('month'))) {
//       const existingMonth = paymentSchedule.find(
//         item => item.month === currentMonth.format('MMMM') && item.year === currentMonth.year()
//       )

//       if (existingMonth) {
//         existingMonth.paymentAmount += price
//         existingMonth.productsAssociated.push(product.productName)
//       } else {
//         paymentSchedule.push({
//           month: currentMonth.format('MMMM'),
//           year: currentMonth.year(),
//           paymentAmount: price,
//           productsAssociated: [product.productName],
//         })
//       }

//       currentMonth = currentMonth.add(1, 'month')
//     }
//   })

//   return paymentSchedule
// }
