import ParentItem from './ParentItem'

const ParentItemList = ({ parentItems, addSubItem, deleteParentItem, deleteSubItem }) => {
  return (
    <ul className='list'>
      {parentItems.length === 0 && 'No Items'}
      {parentItems.map(item => {
        console.log('ITEM: ', item)
        return (
          <ParentItem
            key={item.id}
            {...item}
            addSubItem={addSubItem}
            deleteParentItem={deleteParentItem}
            deleteSubItem={deleteSubItem}
          />
        )
      })}
    </ul>
  )
}

export default ParentItemList
