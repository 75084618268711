import { Chip, Typography, Box, useTheme } from '@mui/material'
import { chipStyle } from './style'

const renderVerticalColor = vertical => {
  switch (vertical) {
    case 'Real Estate - Student':
    case 'Student':
    case 'Student Housing':
      return { label: 'Student', color: 'rgb(255, 165, 93)' }
    case 'Real Estate - Senior':
    case 'Senior':
    case 'Senior Living':
      return { label: 'Senior', color: 'rgb(110, 156, 222)' }
    case 'Real Estate - MF':
    case 'MF':
    case 'Multifamily':
    case 'Multi-Family':
    case 'Multi Family':
      return { label: 'Multifamily', color: 'rgb(50, 187, 130)' }
    case 'Real Estate - Homebuilding':
    case 'Homebuilding':
    case 'Home Builder':
    case 'Home Building':
      return { label: 'Homebuilding', color: 'rgb(132, 101, 192)' }
    case 'Build to rent':
      return { label: 'Build to rent', color: 'rgb(212, 113, 91)' }
    case 'Financial Institution':
      return { label: 'Financial Institution', color: 'rgb(212, 113, 91)' }
    case 'Finance':
      return { label: 'Student', color: 'rgb(255, 155, 147)' }
    case 'Technology':
      return { label: 'Technology', color: '#89CFF0' }
    case 'Medical':
      return { label: 'Medical', color: 'rgb(248, 131, 121)' }
    case 'Construction':
      return { label: 'Construction', color: 'rgb(50, 141, 181)' }
    case 'Tourism':
      return { label: 'Tourism', color: 'rgb(212, 113, 91)' }
    case 'Marketing Agency':
      return { label: 'Marketing Agency', color: 'rgb(212, 113, 91)' }
    case 'Manufacturing':
      return { label: 'Manufacturing', color: 'rgb(248, 131, 121)' }
    case 'Entertainment':
      return { label: 'Entertainment', color: 'rgb(248, 131, 121)' }
    case 'Lawyer':
      return { label: 'Lawyer', color: 'rgb(248, 131, 121)' }
    case 'Education':
      return { label: 'Education', color: 'rgb(248, 131, 121)' }
    case 'City/Government':
      return { label: 'City/Government', color: 'rgb(137, 107, 99)' }
    case 'Restaurant (Food & Beverage)':
      return { label: 'Restaurant (Food & Beverage)', color: 'rgb(224, 188, 50)' }
    case 'Please Enter Industry':
    case 'Not Listed':
    case 'No Vertical Assigned':
      return { label: 'No Vertical Assigned', color: 'rgba(110, 114, 132,0.5)' }
    default:
      return null
  }
}

const RenderOptionsWithVertical = ({ option, height, width, size, fontsize }) => {
  const type =
    option && option.type ? option.type : option && option.type && Array.isArray(option.type) ? option.type[0] : 'NT'
  const vertical = renderVerticalColor(option && Array.isArray(option.vertical) ? option.vertical[0] : [])
  const theme = useTheme()
  const themeColor = theme.palette.mode === 'dark' ? 'white' : 'black'
  let label = ''
  let color = ''

  switch (type) {
    case 'Property Management Company':
      label = 'PMC'
      color = 'rgb(93, 79, 251)'
      break
    case 'Ownership Group':
      label = 'OG'
      color = 'rgb(92, 179, 253)'
      break
    case 'Housing Property':
      label = 'HP'
      color = 'rgb(152, 214, 129)'
      break
    case 'Parent Client':
      label = 'PC'
      color = 'rgb(93, 79, 251)'
      break
    case 'Agency Whitelabel':
      label = 'AW'
      color = 'rgb(92, 179, 253)'
      break
    case 'Organization':
      label = 'ORG'
      color = 'rgb(242, 140, 40)'
      break
    case 'NT':
      label = 'NT'
      color = 'lightgrey'
      break

    default:
      label = 'NT'
      color = 'grey'
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        margin: 0,
        gap: 2,
        width: '100%',
        maxWidth: '100%',
        overflow: 'hidden',
      }}
    >
      <Chip size={size ? size : 'small'} label={label} style={chipStyle(theme, color, height, width)} />
      <Typography
        sx={{
          color: themeColor,
          fontSize: fontsize ? fontsize : 14,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '100%',
          maxWidth: '100%',
        }}
        variant='subtitle1'
      >
        {option && option.name ? option.name : null}
      </Typography>
      <Typography
        sx={{
          fontSize: 12,
          color: vertical ? vertical.color : null,
          // overflow: 'hidden',
          // textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {vertical ? vertical.label : null}
      </Typography>
    </Box>
  )
}

export default RenderOptionsWithVertical
