import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/queryMutations'
import { useGetQuery } from './useGetQuery'
import { useGetQueryById } from './useGetQueryById'

const fetchUser = async userId => {
  const { data } = await axios.get(`${baseUrl}/aws/users/${userId}`)
  // console.log('data fetchOrgsById: ', data)
  return data
}

export const useUser = userId => {
  return useGetQueryById('/aws/users', 'users', userId)
}
