import { Typography, Box, Button, Popover, useTheme, Backdrop, CircularProgress } from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import WarningIcon from '@mui/icons-material/Warning'
import {
  popoverStyles,
  boxContainerStyles,
  boxStyles,
  deleteButtonStyles,
  textStyles,
  buttonContainerStyles,
} from './style'
import { useState } from 'react'

const DealsPopover = ({ id, open, anchorEl, onClose, handleConfirmDelete, selectedData }) => {
  const theme = useTheme()
  const [isDeleting, setIsDeleting] = useState(false)
  const handleDelete = async () => {
    setIsDeleting(true)
    try {
      await handleConfirmDelete()
      await onClose()
      setIsDeleting(false)
    } catch (error) {
      setIsDeleting(false)

      console.log('Error deleting deal: ', error)
    }
  }
  return (
    // <Backdrop open={open} sx={{ zIndex: theme.zIndex.drawer + 1 }}>
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        sx={popoverStyles(theme)}
      >
        <Box sx={boxContainerStyles(theme)}>
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <WarningIcon sx={{ color: 'crimson' }} />
              <Typography sx={{ fontWeight: 'bold', color: 'crimson' }}>Confirm Deletion</Typography>
            </Box>
            <Typography style={textStyles(theme)} sx={{ mb: 2 }}>
              Are you sure you want to permanently delete the following item(s) from Grobotic?
            </Typography>
            <Box sx={boxStyles(theme)}>
              <AttachMoneyIcon
                sx={{
                  color: '#fbc11e',
                  fontSize: 35,
                }}
              />
              <Typography style={textStyles(theme)}>{selectedData ? selectedData.name : ''}</Typography>
            </Box>
          </>

          <Box sx={buttonContainerStyles(theme)}>
            {isDeleting && <CircularProgress />}
            <Button sx={deleteButtonStyles(theme)} variant='outlined' onClick={handleDelete} color='secondary'>
              Confirm
            </Button>
            <Button variant='contained' onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  )
}
export default DealsPopover
