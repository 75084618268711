import React, { useState, useEffect } from 'react'
import { Autocomplete, TextField, Avatar, Box, IconButton, CircularProgress } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { renderUserOption } from '../Users/renderOptions'

const TeamMembersAutocomplete = ({ users, label, onChange, error, defaultValue = [], isUpdating }) => {
  const [inputValue, setInputValue] = useState('')
  const [selectedUsers, setSelectedUsers] = useState(defaultValue)

  useEffect(() => {
    setSelectedUsers(defaultValue)
  }, [defaultValue])

  const handleSelectUser = (event, newValue) => {
    setSelectedUsers(newValue)
    onChange(newValue) // Trigger update in the parent component
  }

  const handleRemoveUser = (e, userId) => {
    e.preventDefault()
    const newSelectedUsers = selectedUsers.filter(user => user.user_uuid !== userId)
    onChange(newSelectedUsers)
  }

  return (
    <Autocomplete
      multiple
      id='team-members-autocomplete'
      options={users || []}
      getOptionLabel={option =>
        option.real_name ? option.real_name : `${option.first_name} ${option.last_name} (${option.email})`
      }
      value={selectedUsers}
      onChange={handleSelectUser}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(options, { inputValue }) =>
        options.filter(option =>
          `${option.first_name} ${option.last_name}`.toLowerCase().includes(inputValue.toLowerCase())
        )
      }
      renderOption={renderUserOption}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, onDelete, ...rest } = getTagProps({ index })
          return (
            <Box
              key={option.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                margin: '2px',
              }}
              {...rest}
            >
              <Avatar
                alt={`${option.first_name} ${option.last_name}`}
                src={option.image_512}
                sx={{ width: 38, height: 38, marginRight: '4px' }}
              />
              <IconButton
                size='small'
                onClick={e => handleRemoveUser(e, option.user_uuid || option.id)}
                sx={{
                  padding: '2px',
                  color: 'rgba(0, 0, 0, 0.7)',
                  '&:hover': {
                    color: 'rgba(0, 0, 0, 0.9)',
                  },
                }}
              >
                <CancelIcon fontSize='small' />
              </IconButton>
            </Box>
          )
        })
      }
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isUpdating ? <CircularProgress color='inherit' size={20} sx={{ marginRight: '10px' }} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      fullWidth
    />
  )
}

export default TeamMembersAutocomplete
