import React from 'react'
import { Grid, Typography, Chip, Checkbox, useTheme } from '@mui/material'
import {
  nameStyle,
  itemStyle,
  typographyNameStyle,
  typographyStyle,
  typographyTypeStyle,
  containerStyle,
} from './style'

const SprintCard = ({
  name,
  type,
  description,
  startDate,
  endDate,
  estimate,
  formatDateString,
  isCompleted,
  onComplete,
}) => {
  const theme = useTheme()
  return (
    <Grid item xs={12}>
      <Grid sx={nameStyle(theme, type)} item xs={12}>
        <Typography sx={typographyNameStyle}>{name}</Typography>
      </Grid>
      <Grid sx={itemStyle} item xs={12}>
        <Chip label={type} sx={typographyTypeStyle(theme, type)} />
        <Typography sx={typographyStyle}>{estimate}</Typography>
        <Typography sx={typographyStyle}>
          {formatDateString(startDate)} - {formatDateString(endDate)}
        </Typography>
      </Grid>
      <Typography sx={typographyStyle}>{description}</Typography>
      <Checkbox checked={isCompleted} onChange={onComplete} />
    </Grid>
  )
}
export default SprintCard
