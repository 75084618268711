import { Box } from '@mui/material'
import React from 'react'
import RenderCompanyChip from '../RenderChipComponents/RenderCompanyChip'

const RenderPropertyLink = ({ organization }) => {
  if (!organization) return null

  const propertyTypes = ['Housing Property', 'Agency Whitelabel', 'Organization']
  const type = organization.type && organization.type.length > 0 ? organization.type : 'NT'

  if (!propertyTypes.includes(type) && type !== 'NT') return null

  return (
    <Box>
      <RenderCompanyChip option={{ ...organization, type }} />
    </Box>
  )
}

export default RenderPropertyLink
