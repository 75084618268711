import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Token from './authentication/Token'
import AuthCallback from './authentication/AuthCallback'
import ProtectedRoute from './authentication/ProtectedRoute'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ProtectedApp from './ProtectedApp'
import AuthProvider from './authentication/AuthContext'
// import QueryDataDebugger from './api/queryFunctions/QueryDataDebugger'

const queryClient = new QueryClient()

const Main = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          {/* <QueryDataDebugger /> */}
          <Routes>
            <Route path='/' element={<Token />} />
            <Route path='/callback' element={<AuthCallback />} />
            <Route
              path='/*'
              element={
                <ProtectedRoute>
                  <ProtectedApp />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default Main
