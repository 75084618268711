import React from 'react'
import { Button } from '@mui/material'
const ApproveButton = ({ needsApproval, isApproved, isApproving, onApprove }) => {
  return (
    <>
      <Button
        sx={{
          width: '10%',
          backgroundColor: 'rgb(5, 190, 113)',
        }}
        disabled={isApproving}
        onClick={onApprove}
        name='isApproved'
        variant='contained'
      >
        Approve
      </Button>
    </>
  )
}

export default ApproveButton
