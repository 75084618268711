// export default ContactsAutocomplete
import React, { useState } from 'react'
import {
  Avatar,
  Autocomplete,
  TextField,
  Button,
  Chip,
  List,
  ListItem,
  useTheme,
  Stack,
  CircularProgress,
  Box,
} from '@mui/material'
import ContactsDialog from './ContactsDialog'
import { useCreateContact } from '../../api/aws/useCreateContact'
import AddRenderOptionButton from './AddRenderOptionButton'
import { listItemStyle, stringAvatar } from './style'

const PrimaryContactAutocomplete = ({ contacts, label, onChange }) => {
  const theme = useTheme()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [tempInput, setTempInput] = useState('') // Temp input for dialog
  const [inputValue, setInputValue] = useState('') // Input state for Autocomplete
  const [selectedPrimaryContact, setSelectedPrimaryContact] = useState([])
  const { mutate: createContact } = useCreateContact()

  const handleOpenDialog = (inputValue) => {
    setTempInput(inputValue)
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setTempInput('')
    setInputValue('') // Clear input when dialog closes
  }

  const handleCreateContacts = (newValue) => {
    createContact(newValue, {
      onSuccess: (data) => {
        const newContact = { ...data, contacts_uuid: data.contacts_uuid }
        setSelectedPrimaryContact(newContact)
        onChange([...selectedPrimaryContact, newContact])
        handleCloseDialog() // Also clears input here
      },
    })
  }

  const handleSelectExistingContact = (event, newValue, reason) => {
    console.log(
      '[handleSelectExistingContact] event, newValue, reason: ',
      event,
      newValue,
      reason
    )
    if (reason === 'selectOption') {
      setSelectedPrimaryContact(newValue)
      onChange(newValue)
    }
  }
  const handleRemoveContact = (contactId) => {
    const newSelectedContacts = selectedPrimaryContact.filter(
      (contact) => contact.contacts_uuid !== contactId
    )
    setSelectedPrimaryContact(newSelectedContacts)
    onChange(newSelectedContacts)
  }

  return (
    <>
      <Autocomplete
        id="primary-contacts-autocomplete"
        options={contacts || []}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.name || ''
        }
        value={selectedPrimaryContact}
        onChange={(event, newValue, reason) =>
          handleSelectExistingContact(event, newValue, reason)
        }
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        filterOptions={(options, params) => {
          const filtered = options.filter((option) =>
            option.name.toLowerCase().includes(params.inputValue.toLowerCase())
          )

          // If the input doesn't match any existing contacts, add the "Add new contact" option at the top
          if (
            params.inputValue !== '' &&
            !filtered.some(
              (option) =>
                option.name.toLowerCase() === params.inputValue.toLowerCase()
            )
          ) {
            filtered.unshift({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}" as a new contact`,
              specialOption: true,
            })
          }

          return filtered
        }}
        renderOption={(props, option) => {
          // Remove the key from the props object and pass it directly
          const { key, id, ...otherProps } = props
          return option.inputValue ? (
            <AddRenderOptionButton
              key={`${key}-${option.contacts_uuid}`} // Pass key directly
              {...otherProps} // Spread other props without key
              option={option}
              handleOpenDialog={handleOpenDialog}
            />
          ) : (
            <Box
              key={`${key}-${option.contacts_uuid}`}
              {...otherProps}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                p: 0.5,
                gap: 2,
              }}
            >
              <Avatar
                src={option.photoUrl ? option.photoUrl : ''}
                {...stringAvatar(option.name)}
                alt={option.name}
                sx={{
                  bgcolor: 'rgb(255, 188, 220)',
                  alignContent: 'center',
                }}
              />
              <Stack
                sx={{
                  justifyContent: 'center',
                  flexGrow: 1,
                  width: '100%',
                }}
              >
                <List
                  sx={{
                    flexDirection: 'column',
                    width: '100%',
                    bgcolor: 'transparent',
                  }}
                  key={`${id}-${option.contacts_uuid}`}
                >
                  <ListItem
                    sx={{
                      ...listItemStyle(theme),
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      padding: 0,
                    }}
                  >
                    {option.name}
                  </ListItem>
                  <ListItem
                    sx={{
                      ...listItemStyle(theme, 12),
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      padding: 0,
                    }}
                  >
                    {option.email}
                  </ListItem>
                </List>
              </Stack>
            </Box>
          )
        }}
        renderInput={(params) => <TextField {...params} label={label} />}
        fullWidth
        freeSolo
        onBlur={() => {
          if (
            inputValue !== '' &&
            !contacts.some(
              (contact) =>
                contact.name.toLowerCase() === inputValue.toLowerCase()
            )
          ) {
            setInputValue('') // Clear the input if no valid contact was added
          }
        }}
      />

      <ContactsDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        tempInput={tempInput}
        onSaveContact={handleCreateContacts}
      />
    </>
  )
}

export default PrimaryContactAutocomplete
