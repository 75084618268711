import React from 'react'
import { Box, DialogTitle, Divider, IconButton, Chip, Typography, useTheme } from '@mui/material'
import GrboboticLight from '../../images/Grobotic png-lightmode.png'
import GrboboticDark from '../../images/Grobotic png.png'
import { renderHeaderContent } from '../../context/ContainerProvider/BoardHeaders'
import ThemedImage from '../../images/ThemedImage/ThemedImage'
import ContractInfoHeader from '../ContractDialog/ContractInfoHeader'
import PublicIcon from '@mui/icons-material/Public'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { capitalizeFirstLetter, renderTypeColor } from '../DataGrid/createColumns/renderDealsColumns'
import DetailsLoading from '../Loading/DetailsLoading'
import CreateDealInfoHeader from '../ContractDialog/CreateDealInfoHeader'

const DialogHeaderComponent = ({ name, newDeal, setNewDeal, handleChange, validationErrors, setApprovalState }) => {
  const theme = useTheme()
  const titleColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'
  const orgId =
    newDeal && newDeal.organizations
      ? Array.isArray(newDeal.organizations)
        ? newDeal.organizations[0]
        : typeof newDeal.organizations === 'object' && newDeal.organizations.id
        ? newDeal.organizations.id
        : null
      : null
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId)
  if (isOrganizationLoading) {
    return <DetailsLoading />
  }
  return (
    <Box>
      {name === 'Contracts' ? (
        <>
          <Box
            key={newDeal && newDeal.id ? newDeal.id : newDeal && newDeal.deal_uuid ? newDeal.deal_uuid : ''}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '30px',
              marginBottom: 7,
            }}
          >
            <ThemedImage srcLight={GrboboticLight} srcDark={GrboboticDark} />
            {organization ? (
              <DialogTitle
                sx={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: titleColor,
                  fontSize: '4rem',
                }}
              >
                {organization.name}
              </DialogTitle>
            ) : (
              <DialogTitle
                sx={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: titleColor,
                  fontSize: '2rem',
                }}
              >
                {'No Organization Found'}
              </DialogTitle>
            )}

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Chip
                sx={{
                  marginRight: 2,
                  backgroundColor: renderTypeColor(newDeal && newDeal.status ? newDeal.status : ''),
                }}
                size='lg'
                label={
                  <Typography>
                    {newDeal && newDeal.status ? capitalizeFirstLetter(newDeal.status) : 'Set Status'}
                  </Typography>
                }
              />
              {renderHeaderContent(name)}
            </Box>
          </Box>
          <CreateDealInfoHeader
            newDeal={newDeal}
            setNewDeal={setNewDeal}
            handleChange={handleChange}
            validationErrors={validationErrors}
            setApprovalState={setApprovalState}
            organization={organization}
          />
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '30px',
            marginBottom: 3,
          }}
        >
          <ThemedImage srcLight={GrboboticLight} srcDark={GrboboticDark} />
          <DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center', color: titleColor }}>
            {`Create ${name}`}
          </DialogTitle>
          {renderHeaderContent(name)}
        </Box>
      )}
      <Divider />
    </Box>
  )
}

export default DialogHeaderComponent

// import React from 'react'
// import { Box, DialogTitle, Divider, Typography, useTheme } from '@mui/material'
// import GrboboticLight from '../../images/Grobotic png-lightmode.png'
// import GrboboticDark from '../../images/Grobotic png.png'
// import { renderHeaderContent } from '../../context/ContainerProvider/BoardHeaders'
// import ThemedImage from '../../images/ThemedImage/ThemedImage'
// import ContractInfoHeader from '../ContractDialog/ContractInfoHeader'

// const DialogHeaderComponent = ({ name, newDeal, handleChange }) => {
//   const theme = useTheme()
//   const titleColor = theme.palette.mode === 'dark' ? 'white' : 'black'
//   return (
//     <Box>
//       {name === 'Contracts' ? (
//         <>
//           <Box
//             sx={{
//               display: 'flex',
//               flexDirection: 'row',
//               justifyContent: 'space-between',
//               alignItems: 'center',
//               height: '30px',
//               marginBottom: 3,
//             }}
//           >
//             <ThemedImage srcLight={GrboboticLight} srcDark={GrboboticDark} />
//             <DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center', color: titleColor }}>
//               {`${name} Details`}
//             </DialogTitle>
//             {renderHeaderContent(name)}
//           </Box>
//           <ContractInfoHeader dealDetails={newDeal} handleChange={handleChange} />
//         </>
//       ) : (
//         <Box
//           sx={{
//             display: 'flex',
//             flexDirection: 'row',
//             justifyContent: 'space-between',
//             alignItems: 'center',
//             height: '30px',
//             marginBottom: 3,
//           }}
//         >
//           <ThemedImage srcLight={GrboboticLight} srcDark={GrboboticDark} />
//           <DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center', color: titleColor }}>
//             {`Create ${name}`}
//           </DialogTitle>
//           {renderHeaderContent(name)}
//         </Box>
//       )}
//       <Divider />
//     </Box>
//   )
// }

// export default DialogHeaderComponent
