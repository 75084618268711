import React from 'react'
import Headers from './Headers'

const headerMap = ({
  handleOpenCreateOrganization,
  handleOpenCreateDeal,
  handleOpenCreateContact,
  organizationInfo,
  dealInfo,
  contactInfo,
}) => ({
  '/dashboard': <Headers boardName='Dashboard' />,
  '/leads': <Headers boardName='Leads' />,
  '/prospects': <Headers boardName='Prospects' />,
  '/contacts': <Headers boardName='Contacts' handleOpenCreateContact={handleOpenCreateContact} />,
  '/contacts/:contactId': <Headers boardName='Contact Details' contactInfo={contactInfo} />,
  '/organizations': <Headers boardName='Organizations' handleOpenCreateOrganization={handleOpenCreateOrganization} />,
  '/deals': <Headers boardName='Deals' handleOpenCreateDeal={handleOpenCreateDeal} />,
  '/discoveryforms': <Headers boardName='Discovery' />,
  '/contracts': <Headers boardName='Contracts' />,
  '/proposals': <Headers boardName='Proposals' />,
  '/deals/kanban': <Headers boardName='Deals Kanban' handleOpenCreateDeal={handleOpenCreateDeal} />,
  '/contacts/:contactId': <Headers boardName='Contact Details' />,
  '/organizations/:organizationId': <Headers boardName='Organization Details' organizationInfo={organizationInfo} />,
  '/deals/:dealId': <Headers boardName='Deal Details' dealInfo={dealInfo} />,
  '/pandadocs': <Headers boardName='PandaDoc Documents' />,
  // Add other paths and their respective headers
})

export default headerMap
