import React, { useEffect, useState, useRef } from 'react'
import { Box, Grid, TextField, Button, Accordion, AccordionDetails, AccordionSummary, Grow } from '@mui/material'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { accordionDetailStyle, accordionStyle, accordionSummaryStyle, boxStyles } from './style'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import WarningIcon from '@mui/icons-material/Warning'

const ValidateDiscovery = ({ organization, missingFields, setMissingFields }) => {
  const [organizationUpdates, setOrganizationUpdates] = useState(null)
  const [originalValues, setOriginalValues] = useState(null)
  const { showSnackbar } = useSnackbarContext()
  const { mutate: updateItems } = useUpdateItems()

  useEffect(() => {
    if (organization) {
      setMissingFields(validateDiscovery(organization))
      const updatedOrganization = {
        ...organization,
        website: organization.website || '',
        street: organization.street || '',
        city: organization.city || '',
        state: organization.state || '',
        zip: organization.zip || '',
        cmsUsed: organization.cmsUsed || '',
        socialProfiles: organization.socialProfiles || '',
        marketingObjective: organization.marketingObjective || '',
        geoTargeting: organization.geoTargeting || '',
      }
      setOrganizationUpdates(updatedOrganization)
      setOriginalValues(updatedOrganization)
    }
  }, [organization])

  const validateDiscovery = organization => {
    const missingFields = []
    if (!organization || !organization.website) missingFields.push('Website')
    if (!organization || !organization.street) missingFields.push('Street')
    if (!organization || !organization.city) missingFields.push('City')
    if (!organization || !organization.state) missingFields.push('State')
    if (!organization || !organization.zip) missingFields.push('Zip')
    if (!organization || !organization.cmsUsed) missingFields.push('CMS Used')
    if (!organization || !organization.socialProfiles) missingFields.push('Active Social Profiles')
    if (!organization || !organization.marketingObjective) missingFields.push('Marketing Objective')
    if (!organization || !organization.geoTargeting) missingFields.push('Geographic Targeting')
    return missingFields
  }

  const filterFieldNames = name => {
    switch (name) {
      case 'CMS Used':
        return 'cmsUsed'
      case 'Active Social Profiles':
        return 'socialProfiles'
      case 'Marketing Objective':
        return 'marketingObjective'
      case 'Geographic Targeting':
        return 'geoTargeting'

      default:
        return name.toLowerCase()
    }
  }

  const handleChange = e => {
    const { name, value } = e.target
    setOrganizationUpdates(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleUpdate = () => {
    const fieldsToUpdate = missingFields.map(field => filterFieldNames(field))
    const updates = fieldsToUpdate
      .filter(field => originalValues[field] !== organizationUpdates[field])
      .map(field => ({
        primaryKey: 'org_uuid',
        tableName: 'organizations',
        itemId: organization ? organization.org_uuid : organization.id,
        fieldName: field,
        newValue: organizationUpdates[field],
      }))
    console.log('updates: ', updates)
    // Call the updateItems mutation here with the updates array
    updateItems(
      { updates: updates },
      {
        onSuccess: data => {
          console.log('data: ', data)
          showSnackbar('Organization updated successfully', 'success')
          // Re-validate and update state
          const updatedMissingFields = validateDiscovery(organizationUpdates)
          setMissingFields(updatedMissingFields)
          // If all fields are updated, clear the organizationUpdates to hide the accordion
          if (updatedMissingFields.length === 0) {
            setOrganizationUpdates(null)
          }
        },
        onError: () => {
          showSnackbar('Error updating organization', 'error')
        },
      }
    )
  }
  return (
    <>
      {organizationUpdates && missingFields.length > 0 && (
        <>
          <Grid container spacing={2}>
            <Grow in={true} timeout={500}>
              <Grid item xs={12}>
                <Accordion sx={accordionStyle}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                    sx={accordionSummaryStyle}
                  >
                    <Box sx={boxStyles} gap={2}>
                      <WarningIcon sx={{ color: 'crimson' }} />
                      Required Fields
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={accordionDetailStyle}>
                    <Grid container spacing={1}>
                      <TransitionGroup component={null}>
                        {missingFields.map((field, index) => (
                          <CSSTransition key={index} timeout={500} classNames='grow'>
                            <Grid item xs={6}>
                              <TextField
                                name={filterFieldNames(field)}
                                label={field}
                                value={organizationUpdates[filterFieldNames(field)] || ''}
                                onChange={handleChange}
                                fullWidth
                              />
                            </Grid>
                          </CSSTransition>
                        ))}
                      </TransitionGroup>
                    </Grid>
                  </AccordionDetails>
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', padding: 1 }}>
                    <Button onClick={handleUpdate} color='primary' variant='contained'>
                      Save
                    </Button>
                  </Grid>
                </Accordion>
              </Grid>
            </Grow>
          </Grid>
        </>
      )}
    </>
  )
}

export default ValidateDiscovery
