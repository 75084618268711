import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/queryMutations'
import { pandaAccessToken } from './useGetDocuments'

const createDocument = async documentData => {
  const url = `${baseUrl}/aws/pandadocuments`
  if (!pandaAccessToken) {
    console.error('No authentication token available')
    return
  }
  try {
    const response = await axios.post(url, documentData, {
      headers: { Authorization: ` ${pandaAccessToken}` },
    })
    console.log('Response Create Document: ', response)
    return response.data
  } catch (error) {
    console.error('Error creating new doc:', error)
    throw error // Re-throw the error for useMutation's onError to catch
  }
}

export const useCreateDocument = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    // Directly pass the object to the mutation function
    mutationFn: createDocument,
    onSuccess: () => {
      console.log('Successfully created document')
    },
    onError: error => {
      console.error('[useCreateDocument] Error creating doc:', error)
    },
  })

  return mutation
}
