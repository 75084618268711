import React, { useContext } from 'react'
import {
  List,
  ListItem,
  Tooltip,
  Typography,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  useTheme,
} from '@mui/material'
import MoreTimeIcon from '@mui/icons-material/MoreTime'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import PersonIcon from '@mui/icons-material/Person'
import Grid4x4Icon from '@mui/icons-material/Grid4x4'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import PandaDocImageComponent from '../../AvatarImageComponents/PandaDocImageComponent'
import PublicIcon from '@mui/icons-material/Public'

const BoardsNavigation = ({ handleNavigation, open, selectedPath }) => {
  const theme = useTheme()
  const bgSelectColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'
  const bgHoverColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'

  return (
    <List>
      {[
        { label: 'Leads', path: 'leads' },
        { label: 'Organizations', path: 'organizations' },
        { label: 'Deals', path: 'deals' },
        // { label: 'Discovery', path: 'discoveryforms' },
        // { label: 'Contracts', path: 'contracts' },
        { label: 'Contacts', path: 'contacts' },
        { label: 'PandaDoc', path: 'pandadocs' },

        // { label: 'Team Tasks Test', path: 'teamtaskstest' },
        // Add more items here if necessary
      ].map((item, index) => (
        <ListItem key={item.label} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              backgroundColor: item.path === selectedPath.replace(/^\//, '') ? bgSelectColor : 'transparent', // Adjust color as needed
              '&:hover': {
                backgroundColor: bgHoverColor, // Adjust hover color as needed
              },
            }}
            onClick={() => handleNavigation(item.path)}
          >
            <Tooltip enterDelay={500} leaveDelay={0} title={item.label} placement='right'>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {index === 0 ? (
                  <OnlinePredictionIcon
                    sx={{
                      color: 'rgb(94, 123, 167)',
                    }}
                  />
                ) : null}
                {index === 1 ? (
                  <CorporateFareIcon
                    sx={{
                      color: 'rgb(114, 229, 218)',
                    }}
                  />
                ) : null}
                {index === 2 ? (
                  <AttachMoneyIcon
                    sx={{
                      color: '#fbc11e',
                    }}
                  />
                ) : null}
                {/* {index === 3 ? <PublicIcon sx={{ color: 'rgb(25, 117, 255)' }} /> : null} */}
                {/* {index === 4 ? (
                  <ThumbUpOffAltIcon
                    sx={{
                      color: 'rgb(229, 96, 34)',
                    }}
                  />
                ) : null} */}
                {index === 3 ? (
                  <PersonIcon
                    sx={{
                      color: 'rgb(255, 123, 187)',
                    }}
                  />
                ) : null}
                {index === 4 ? (
                  <PandaDocImageComponent
                    sx={{
                      color: 'rgba(255,255,255,0.3)',
                    }}
                  />
                ) : null}
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              sx={{
                opacity: open ? 1 : 0,
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? 'white' : 'black',
                }}
              >
                {item.label}
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}

export default BoardsNavigation
