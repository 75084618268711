import { IconButton, Badge, Drawer, Tooltip } from '@mui/material'
import PublicIcon from '@mui/icons-material/Public'
import AddIcon from '@mui/icons-material/Add'

const DiscoveryPlusIcon = ({ handleClick }) => {
  return (
    <Tooltip title='Add New Discovery'>
      <Badge badgeContent={<AddIcon sx={{ fontSize: '18px' }} />} color=''>
        <PublicIcon sx={{ fontSize: '30px', color: 'rgb(25, 117, 255)' }} />
      </Badge>
    </Tooltip>
  )
}

export default DiscoveryPlusIcon
