import React from 'react'
import { Box, DialogTitle, Typography, useTheme } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import { DialogIcon, boxStyles, dialogTitleStyles } from './style'
import GroboticPng from '../../images/Grobotic png.png'
import GroboticLight from '../../images/Grobotic png-lightmode.png'

const CreateContactHeader = ({ context }) => {
  const theme = useTheme()
  return (
    <Box sx={boxStyles}>
      <DialogIcon src={theme.palette.mode === 'dark' ? GroboticPng : GroboticLight} />
      <Box sx={boxStyles}>
        <DialogTitle sx={dialogTitleStyles(theme)}>{context}</DialogTitle>
        <PersonIcon sx={{ color: 'rgb(255, 123, 187)', width: 35, height: 35 }} />
      </Box>
    </Box>
  )
}

export default CreateContactHeader
