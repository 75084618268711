import axios from 'axios'
import { baseUrl } from '../api/queryMutations/mutationFunctions'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

// Add a response interceptor
api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const newAccessToken = await refreshAccessToken()
      if (newAccessToken) {
        api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
        return api(originalRequest)
      }
    }
    return Promise.reject(error)
  }
)

export const refreshAccessToken = async () => {
  const slackUserId = localStorage.getItem('slack_user_id')
  console.log('slackUserId interceptor: ', slackUserId)
  const { data } = await axios.get(`${baseUrl}/aws/users/${slackUserId}`)
  const refreshToken = data ? data.googleRefreshToken : null
  console.log('refresh token interceptor: ', refreshToken)

  if (!refreshToken) {
    console.error('No refresh token available')
    return null
  }

  try {
    const response = await axios.post(`${baseUrl}/aws/refreshtoken`, { refreshToken })
    console.log('Refresh Token response: ', response)
    const { newAccessToken, expiresIn } = response.data
    localStorage.setItem('google_access_token', newAccessToken)
    setTimeout(refreshAccessToken, (expiresIn - 60) * 1000) // Refresh the token 1 minute before it expires
    return newAccessToken
  } catch (error) {
    console.error('Failed to refresh token', error)
    return null
  }
}

export default api
