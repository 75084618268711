import React from 'react'
import { useTheme } from '../ThemeContext'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'

const ThemeToggleButton = () => {
  const { theme, toggleTheme } = useTheme()

  return (
    <Box display='flex' alignItems='center'>
      <Switch checked={theme === 'dark'} onChange={toggleTheme} />
    </Box>
  )
}

export default ThemeToggleButton
