import React, { useMemo, useState } from 'react'
import {
  Box,
  Badge,
  Typography,
  Fab,
  Modal,
  Avatar,
  IconButton,
  useTheme,
  Divider,
  List,
  ListItem,
} from '@mui/material'
import { useWebsocketContext } from '../../context/Websocket/WebsocketProvider'
import CloseIcon from '@mui/icons-material/Close'
import PeopleIcon from '@mui/icons-material/People'
import SendBroadcast from '../../context/Websocket/SendBroadcast'
import moment from 'moment'
import { useUsers } from '../../api/aws/useUsers'

const ConnectedUsersList = () => {
  const theme = useTheme()
  const { users, isUsersLoading, isUsersError } = useUsers()
  const { connectedUsers } = useWebsocketContext() // Accessing connectedUsers directly here
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Fab
        color='primary'
        aria-label='connected-users'
        onClick={handleOpen}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
      >
        <Badge
          badgeContent={
            <Typography
              sx={{
                fontSize: 12,
                color: 'rgba(63, 195, 128, 1)',
                fontWeight: 'bold',
              }}
            >
              {connectedUsers && Array.isArray(connectedUsers) ? connectedUsers.length : 0}
            </Typography>
          }
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <PeopleIcon />
        </Badge>
      </Fab>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: theme.palette.background.paper,
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            outline: 'none',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography variant='h6'>Connected Users</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider sx={{ mb: 2 }} />
          <ConnectedUsersListContent users={users} connectedUsers={connectedUsers} />
          <Box sx={{ marginTop: theme.spacing(9) }}>
            <SendBroadcast />
          </Box>
        </Box>
      </Modal>
    </>
  )
}

const ConnectedUsersListContent = ({ users, connectedUsers }) => {
  // const connectedUserDetails = useMemo(() => {
  //   return connectedUsers.map(connectedUser => {
  //     const userDetail = users.find(user => user.id === connectedUser.id)
  //     return { ...userDetail, ...connectedUser }
  //   })
  // }, [connectedUsers, users])
  const connectedUserDetails = useMemo(() => {
    return connectedUsers.map(connectedUser => {
      const userDetail = users.find(user => user.id === connectedUser.id)
      return { ...userDetail, ...connectedUser }
    })
    // return connectedUsers
  }, [connectedUsers])

  function formatSessionTime(ms) {
    const totalSeconds = Math.floor(ms / 1000)
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60

    return `${hours}h ${minutes}m ${seconds}s`
  }

  return (
    <List>
      {connectedUserDetails && connectedUserDetails.length > 0 ? (
        connectedUserDetails.map(user =>
          user ? (
            <ListItem key={user.id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Avatar src={user.image_512} sx={{ marginRight: 2 }} />
              <Box sx={{ flexGrow: 1 }}>
                <Typography>{user.name}</Typography>
                <Typography variant='body2' color='text.secondary'>
                  ID: {user.id}
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  Session Time: {formatSessionTime(user.sessionTime)}
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  Last Ping: {moment(user.lastPing).fromNow()}
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  Pong Count: {user.pongCount}
                </Typography>
              </Box>
            </ListItem>
          ) : (
            <ListItem key={`unknown-${Math.random()}`}>
              <Typography variant='body2'>Unknown User Connected</Typography>
            </ListItem>
          )
        )
      ) : (
        <ListItem>
          <Typography variant='body2'>No users connected</Typography>
        </ListItem>
      )}
    </List>
  )
}

export default ConnectedUsersList

// import React, { useState } from 'react'
// import {
//   Box,
//   Badge,
//   Chip,
//   List,
//   ListItem,
//   Typography,
//   Fab,
//   Modal,
//   Avatar,
//   IconButton,
//   useTheme,
//   Divider,
// } from '@mui/material'
// import { useWebsocketContext, WebsocketProvider } from '../../context/Websocket/WebsocketProvider'
// import CloseIcon from '@mui/icons-material/Close'
// import PeopleIcon from '@mui/icons-material/People'
// import SendBroadcast from '../../context/Websocket/SendBroadcast'
// import moment from 'moment'
// import { useUsers } from '../../api/aws/useUsers'

// const ConnectedUsersList = () => {
//   const { connectedUsers } = useWebsocketContext()
//   const { users, isUsersLoading, isUsersError } = useUsers()

//   const theme = useTheme()
//   const [open, setOpen] = useState(false)

//   const handleOpen = () => setOpen(true)
//   const handleClose = () => setOpen(false)

// const connectedUserDetails = connectedUsers.map(connectedUser => {
//   const userDetail = users.find(user => user.id === connectedUser.id)
//   return { ...userDetail, ...connectedUser }
// })
//   function formatSessionTime(ms) {
//     const totalSeconds = Math.floor(ms / 1000)
//     const hours = Math.floor(totalSeconds / 3600)
//     const minutes = Math.floor((totalSeconds % 3600) / 60)
//     const seconds = totalSeconds % 60

//     return `${hours}h ${minutes}m ${seconds}s`
//   }

//   return (
//     <WebsocketProvider>
//       <Fab
//         color='primary'
//         aria-label='connected-users'
//         onClick={handleOpen}
//         sx={{
//           position: 'fixed',
//           bottom: 16,
//           right: 16,
//         }}
//       >
//         <Badge
//           badgeContent={
//             <Typography sx={{ fontSize: 12, color: 'rgba(63, 195, 128, 1)', fontWeight: 'bold' }}>
//               {connectedUsers && Array.isArray(connectedUsers) ? connectedUsers.length : 0}
//             </Typography>
//           }
//           anchorOrigin={{
//             vertical: 'top',
//             horizontal: 'right',
//           }}
//         >
//           <PeopleIcon />
//         </Badge>
//       </Fab>
//       <Modal open={open} onClose={handleClose}>
//         <Box
//           sx={{
//             position: 'absolute',
//             top: '50%',
//             left: '50%',
//             transform: 'translate(-50%, -50%)',
//             width: 400,
//             bgcolor: theme.palette.background.paper,
//             boxShadow: 24,
//             p: 4,
//             borderRadius: 2,
//             outline: 'none',
//           }}
//         >
//           <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
//             <Typography variant='h6'>Connected Users</Typography>
//             <IconButton onClick={handleClose}>
//               <CloseIcon />
//             </IconButton>
//           </Box>
//           <Divider sx={{ mb: 2 }} />
//           <List>
//             {connectedUserDetails && connectedUserDetails.length > 0 ? (
//               connectedUserDetails.map(user =>
//                 user ? (
//                   <ListItem key={user.id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//                     <Avatar src={user.image_512} sx={{ marginRight: 2 }} />
//                     <Box sx={{ flexGrow: 1 }}>
//                       <Typography>{user.name}</Typography>
//                       <Typography variant='body2' color='text.secondary'>
//                         ID: {user.id}
//                       </Typography>
//                       <Typography variant='body2' color='text.secondary'>
//                         Session Time: {formatSessionTime(user.sessionTime)}
//                       </Typography>
//                       <Typography variant='body2' color='text.secondary'>
//                         Last Ping: {moment(user.lastPing).fromNow()}
//                       </Typography>
//                       <Typography variant='body2' color='text.secondary'>
//                         Pong Count: {user.pongCount}
//                       </Typography>
//                     </Box>
//                   </ListItem>
//                 ) : (
//                   <ListItem key={`unknown-${Math.random()}`}>
//                     <Typography variant='body2'>Unknown User Connected</Typography>
//                   </ListItem>
//                 )
//               )
//             ) : (
//               <ListItem>
//                 <Typography variant='body2'>No users connected</Typography>
//               </ListItem>
//             )}
//           </List>
//           <Box sx={{ marginTop: theme.spacing(9) }}>
//             <SendBroadcast />
//           </Box>
//         </Box>
//       </Modal>
//     </WebsocketProvider>
//   )
// }

// export default ConnectedUsersList
