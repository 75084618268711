import { useGetQuery } from './useGetQuery'

export const useFetchNotifications = () => {
  const { data, isLoading: isNotificationsLoading, isError: isNotificationsError } = useGetQuery(
    '/aws/notifications',
    'notifications'
  )
  const notifications = data ?? []
  return { notifications, isNotificationsLoading, isNotificationsError }
}
