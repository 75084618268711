import axios from 'axios'

// export const baseUrl =
//   process.env.NODE_ENV === 'development'
//     ? 'https://localhost:8002'
//     : 'https://grobotic.app:8000'
export const baseUrl = process.env.REACT_APP_API_URL

export const fetchRelatedAgreements = async (agreementIds) => {
  if (!agreementIds || agreementIds.length === 0) {
    return []
  }

  const apiUrl = `${baseUrl}/aws/zoho-related-agreements`
  const oauthToken = localStorage.getItem('zoho_access_token')

  if (!oauthToken) {
    console.error('No authentication token available')
    return []
  }

  try {
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Bearer ${oauthToken}` },
      params: { agreementIds: agreementIds.join(',') },
    })
    console.log('Response from fetchRelatedAgreements: ', response.data)
    // Assuming response.data is an array of arrays where each inner array represents a related agreement
    const formattedAgreements = response.data.flatMap((agreementArray) =>
      agreementArray.map((agreement) => {
        return { ...agreement }
      })
    )
    console.log('Formatted Agreements: ', formattedAgreements)
    return formattedAgreements
  } catch (error) {
    console.error('Error fetching related agreements: ', error)
    return []
  }
}
export const fetchChatMessages = async (chatId) => {
  if (!chatId || chatId.length === 0) {
    return []
  }

  const apiUrl = `${baseUrl}/aws/zoho-chat-messages?chatId=${chatId}`
  const oauthToken = localStorage.getItem('zoho_access_token')

  if (!oauthToken) {
    console.error('No authentication token available')
    return []
  }

  try {
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Bearer ${oauthToken}` },
    })
    // Assuming response.data is an array of arrays where each inner array represents a related agreement
    console.log('RESPONSE FROM FETCHCHATMESSAGES: ', response)
    return response.data.cliqResponse
  } catch (error) {
    console.error('Error fetching related agreements: ', error)
    return []
  }
}
export const fetchZohoUsers = async () => {
  const apiUrl = `${baseUrl}/aws/zoho-users`
  const oauthToken = localStorage.getItem('zoho_access_token')
  if (!oauthToken) {
    console.error('No authentication token available')
    return
  }
  try {
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Bearer ${oauthToken}` },
    })
    // console.log('Response.data from fetchZohoUsers')
    const filteredUsers =
      response.data && response.data.users
        ? response.data.users.filter(
            (user) => user.status !== 'disabled' && user.status !== 'deleted'
          )
        : null

    const formattedUsers = filteredUsers
      ? filteredUsers.map((user) => {
          return {
            id: user.id,
            zuid: user.zuid,
            Isonline: user.Isonline,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            full_name: user.full_name,
            profile: user.profile,
            role: user.role,
            mobile: user.mobile,
            phone: user.phone,
            status: user.status,
          }
        })
      : null
    console.log('FORMATTED USERS: ', formattedUsers)
    return formattedUsers // Return just the data part
  } catch (error) {
    console.error('Error fetching all users: ', error)
    return [] // Return an empty array in case of an error
  }
}
export const fetchCliqChannelThreads = async (channelId) => {
  if (!channelId || channelId.length === 0) {
    return []
  }
  const apiUrl = `${baseUrl}/aws/cliq-channel-threads?channelId=${channelId}`
  const oauthToken = localStorage.getItem('zoho_access_token')
  if (!oauthToken) {
    console.error('No authentication token available')
    return []
  }
  try {
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Bearer ${oauthToken}` },
    })
    // Assuming response.data is an array of arrays where each inner array represents a related agreement
    console.log('RESPONSE FROM fetchCliqChannelThreads: ', response)
    return response.data
  } catch (error) {
    console.error('Error fetching channel threads: ', error)
    return []
  }
}
export const fetchThreadMessages = async (threadChatId) => {
  if (!threadChatId || threadChatId.length === 0) {
    return []
  }
  const apiUrl = `${baseUrl}/aws/cliq-chat-threads?threadChatId=${threadChatId}`
  const oauthToken = localStorage.getItem('zoho_access_token')
  if (!oauthToken) {
    console.error('No authentication token available')
    return []
  }
  try {
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Bearer ${oauthToken}` },
    })
    // Assuming response.data is an array of arrays where each inner array represents a related agreement
    // console.log('RESPONSE FROM fetchThreadMessages: ', response)
    return response.data.threads
  } catch (error) {
    console.error('Error fetching channel threads: ', error)
    return []
  }
}
// export const fetchThreadMessages = async ({
//   threadChatId,
//   page = 1,
//   limit = 10,
// }) => {
//   console.log('Fetch Thread Messages: threadChatId: ', threadChatId)
//   const apiUrl = `${baseUrl}/aws/cliq-chat-threads?threadChatId=${threadChatId}&page=${page}&limit=${limit}`
//   const oauthToken = localStorage.getItem('zoho_access_token')
//   if (!oauthToken) {
//     console.error('No authentication token available')
//     return []
//   }
//   try {
//     const response = await axios.get(apiUrl, {
//       headers: { Authorization: `Bearer ${oauthToken}` },
//     })
//     console.log('Response.Data in fetchThreadMessages: ', response.data)
//     return response.data // Assuming the API returns { threads: [...], nextPage: <number|null> }
//   } catch (error) {
//     console.error('Error fetching thread messages:', error)
//     return { threads: [], nextPage: null }
//   }
// }
