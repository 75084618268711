import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../queryMutations/mutationFunctions'
import axios from 'axios'

const getQuery = async (endpoint, tableName, id) => {
  try {
    const url = id ? `${baseUrl}${endpoint}/${id}` : `${baseUrl}${endpoint}`
    const { data } = await axios.get(url, {
      params: id ? {} : { table: tableName }, // Params are only needed for fetching all items
    })
    return data || []
  } catch (error) {
    console.error('Error fetching data:', error)
    return [] // Return an empty array in case of an error
  }
}

export const useGetQuery = (endpoint, tableName, id = null) => {
  return useQuery({
    queryKey: id ? [endpoint, id] : [endpoint], // Use endpoint and id as the query key
    queryFn: () => (id ? getQuery(endpoint, tableName, id) : getQuery(endpoint, tableName, null)),
    // staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
    staleTime: Infinity, // Default to Infinity if not provided
    cacheTime: 1000 * 60 * 10, // Cache data for 10 minutes
    refetchOnWindowFocus: false, // Avoid refetching on window focus
  })
}
