import React, { createContext, useContext } from 'react'
import Loading from '../../components/Loading/Loading'
import { usePandaContext } from '../PandaProvider/PandaProvider'

const LoadingContext = createContext()

export const LoadingProvider = ({ children }) => {
  const { pandaProductsLoading, pandaProductsError, errorProducts } = usePandaContext()

  const isLoadingStates = [pandaProductsLoading]

  const errorStates = [
    {
      name: 'Panda Products',
      error: pandaProductsError,
      details: errorProducts,
    },
  ]

  const totalStates = isLoadingStates.length
  const completedStates = isLoadingStates.filter(state => !state).length
  const progress = (completedStates / totalStates) * 100

  const isLoading = isLoadingStates.some(state => state)
  const isError = errorStates.some(state => state.error)
  if (isLoading) {
    return <Loading componentName='App' progress={progress} />
  }

  if (isError) {
    console.log('ErrorStates')
    return (
      <div>
        <div>Error loading data...</div>
        {errorStates.map(
          (errorState, index) =>
            errorState.error && (
              <div key={index}>
                <strong>Error in {errorState.name}:</strong>{' '}
                {errorState.details ? errorState.details.message : 'Unknown error'}
              </div>
            )
        )}
      </div>
    )
  }

  return <LoadingContext.Provider value={{}}>{children}</LoadingContext.Provider>
}

export const useCombinedLoadingContext = () => useContext(LoadingContext)
