import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { baseUrl } from '../queryMutations/queryMutations'

const createSprint = async sprintData => {
  const url = `${baseUrl}/aws/sprints`
  console.log('New Sprint Data: ', sprintData)
  const response = await axios.post(url, sprintData)
  return response.data // Assumes the response data includes the new contact with `id`
}

export const useCreateSprint = () => {
  const { showSnackbar } = useSnackbarContext()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: createSprint,
    onMutate: async newSprint => {
      await queryClient.cancelQueries(['sprints'])
      const previousSprints = queryClient.getQueryData(['sprints'])
      return { previousSprints }
    },
    onError: (err, newSprint, context) => {
      queryClient.setQueryData(['sprints'], context.previousSprints)
      console.error('Mutation Error:', err)
      showSnackbar(err.response?.data?.message || err.message, 'error')
    },
    onSuccess: data => {
      queryClient.setQueryData(['sprints'], old => {
        if (!old) {
          return { data: [{ ...data, id: data.sprint_uuid }], total: 1 }
        }
        if (Array.isArray(old.data)) {
          return { ...old, data: [...old.data, { ...data, id: data.sprint_uuid }], total: old.total + 1 }
        }
        console.error('Unexpected query data format:', old)
        return { data: [{ ...data, id: data.sprint_uuid }], total: 1 }
      })
      showSnackbar('Sprint created successfully', 'success')
    },
    onSettled: (data, error) => {
      console.log('data: ', data, 'error: ', error)
      queryClient.invalidateQueries(['sprints'])
    },
  })

  return mutation
}
