import { Box, Tooltip, CircularProgress } from '@mui/material'
import PublicIcon from '@mui/icons-material/Public'

const DiscoveryProgress = ({ progress, completed, size }) => {
  // Calculate the alpha value based on the progress, making sure it's between 0.3 and 1
  const dimValue = Math.max(Math.min(progress / 100, 1), 0.3)
  const globeColor = completed === 9 ? 'rgba(25, 117, 255, 1)' : `rgba(114, 120, 129, ${dimValue})` // Blue for 9/9, canary color otherwise

  //   console.log('dimValue: ', dimValue)
  //   console.log('Progress: ', progress)
  //   console.log('Completed: ', completed)

  return (
    <Tooltip title={`${completed} / 9`}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: 1,
        }}
      >
        {completed < 9 && (
          <CircularProgress
            variant='determinate'
            value={progress}
            size={35}
            thickness={3}
            sx={{ color: 'rgb(37, 134, 103)' }}
          />
        )}
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <PublicIcon sx={{ fontSize: size ? size : '25px', color: globeColor }} />
        </Box>
      </Box>
    </Tooltip>
  )
}

export default DiscoveryProgress
