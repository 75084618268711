import React from 'react'
import { Box } from '@mui/material'
import { GridToolbar, useGridApiRef } from '@mui/x-data-grid-pro'
import { StyledDataGrid } from '../../style/styleElements'
import { debounce } from 'lodash'

const CustomDataGrid = ({
  data,
  columns,
  setSelectedDataIds,
  fieldId,
  selectionModel,
  filterModel,
  setFilterModel,
}) => {
  const apiRef = useGridApiRef()
  const handleSelectionModelChange = newSelectionModel => {
    setSelectedDataIds(newSelectionModel)
  }
  const handleColumnResize = debounce(() => {
    apiRef.current.updateColumns()
  }, 50) // Adjust the debounce delay as necessary

  return (
    <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
      <StyledDataGrid
        autoHeight
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25, // Set the default page size here
            },
          },
          filter: {
            filterModel: {
              items: [],
              quickFilterValues: [],
            },
          },
        }}
        rows={data}
        columns={columns}
        pageSizeOptions={[5, 10, 25]} // Customize the available page sizes here
        pagination
        paginationMode='client'
        getRowId={row => row[fieldId]}
        checkboxSelection
        disableRowSelectionOnClick
        selectionModel={selectionModel}
        onSelectionModelChange={handleSelectionModelChange}
        onRowSelectionModelChange={newSelectionModel => {
          setSelectedDataIds(newSelectionModel)
        }}
        filterModel={filterModel}
        onFilterModelChange={model => setFilterModel(model)}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        onColumnResize={handleColumnResize}
      />
    </Box>
  )
}

export default CustomDataGrid

// import React, { useEffect, useState } from 'react'
// import { DataGrid, GridToolbar } from '@mui/x-data-grid'
// import { Box } from '@mui/material'
// import { StyledDataGrid } from '../../style/styleElements'

// const CustomDataGrid = ({
//   data,
//   columns,
//   setSelectedDataIds,
//   fieldId,
//   selectionModel,
//   filterModel,
//   setFilterModel,
// }) => {
//   const handleSelectionModelChange = newSelectionModel => {
//     setSelectedDataIds(newSelectionModel)
//   }
//   return (
//     <StyledDataGrid
//       autoHeight
//       initialState={{
//         filter: {
//           filterModel: {
//             items: [],
//             quickFilterValues: [],
//           },
//         },
//       }}
//       rows={data}
//       columns={columns}
//       pageSize={5}
//       rowsPerPageOptions={[5]}
//       getRowId={row => {
//         return row[fieldId]
//       }}
//       checkboxSelection
//       disableRowSelectionOnClick
//       selectionModel={selectionModel}
//       onSelectionModelChange={handleSelectionModelChange}
//       onRowSelectionModelChange={newSelectionModel => {
//         setSelectedDataIds(newSelectionModel)
//       }}
//       filterModel={filterModel}
//       onFilterModelChange={model => setFilterModel(model)}
//       slots={{ toolbar: GridToolbar }}
//       slotProps={{
//         toolbar: {
//           showQuickFilter: true,
//         },
//       }}
//     />
//   )
// }

// export default CustomDataGrid
