import axios from 'axios'
export const baseUrl = process.env.REACT_APP_API_URL

export const sendCliqMessage = async ({ username, userEmail, message, zcliq_uuid, timestamp }) => {
  const oauthToken = localStorage.getItem('zoho_access_token')
  if (!oauthToken) {
    console.error('No authentication token available')
    return
  }

  try {
    const response = await axios.post(
      `${baseUrl}/aws/zoho-cliq-messages`,
      { username, userEmail, message, zcliq_uuid, timestamp }, // Structure the data as expected by the backend
      { headers: { Authorization: `Bearer ${oauthToken}` } }
    )
    console.log('Message sent:', response.data)
    return response.data
  } catch (error) {
    console.error('Error sending cliq chat message:', error)
    throw error // Re-throw the error for useMutation's onError to catch
  }
}
export const sendCliqChannelMessage = async formData => {
  console.log('FORM DATA FROM sendCliqChannelMessage: ', formData)
  const oauthToken = localStorage.getItem('zoho_access_token')
  if (!oauthToken) {
    console.error('No authentication token available')
    return
  }

  try {
    const response = await axios.post(
      `${baseUrl}/api/zoho-cliq-send-message`,
      formData, // Directly pass FormData here
      {
        headers: {
          Authorization: `Bearer ${oauthToken}`,
          // 'Content-Type': 'multipart/form-data', is not needed, axios sets it automatically
        },
      }
    )
    console.log('Channel Message sent:', response.data)
    return response.data
  } catch (error) {
    console.error('Error sending cliq channel message:', error)
    throw error // Re-throw the error for useMutation's onError to catch
  }
}
export const sendCliqChannelAttachment = async formData => {
  console.log('FORM DATA FROM sendCliqChannelMessage: ', formData)
  const oauthToken = localStorage.getItem('zoho_access_token')
  if (!oauthToken) {
    console.error('No authentication token available')
    return
  }

  try {
    const response = await axios.post(
      `${baseUrl}/api/zoho-cliq-upload-files`,
      formData, // Directly pass FormData here
      {
        headers: {
          Authorization: `Bearer ${oauthToken}`,
          // 'Content-Type': 'multipart/form-data', is not needed, axios sets it automatically
        },
      }
    )
    console.log('Channel Attachment sent:', response.data)
    return response.data
  } catch (error) {
    console.error('Error sending cliq channel attachment:', error)
    throw error // Re-throw the error for useMutation's onError to catch
  }
}
export const sendCliqChannelComment = async formData => {
  console.log('FORM DATA FROM sendCliqChannelMessage: ', formData)
  const oauthToken = localStorage.getItem('zoho_access_token')
  if (!oauthToken) {
    console.error('No authentication token available')
    return
  }

  try {
    const response = await axios.post(
      `${baseUrl}/aws/zoho-cliq-channels`,
      formData, // Directly pass FormData here
      {
        headers: {
          Authorization: `Bearer ${oauthToken}`,
          // 'Content-Type': 'multipart/form-data', is not needed, axios sets it automatically
        },
      }
    )
    console.log('Channel Message sent:', response.data)
    return response.data
  } catch (error) {
    console.error('Error sending cliq channel message:', error)
    throw error // Re-throw the error for useMutation's onError to catch
  }
}
export const deleteCliqMessage = async payload => {
  console.log('Payload in deleteCliqMessage: ', payload)
  const oauthToken = localStorage.getItem('zoho_access_token')
  if (!oauthToken) {
    console.error('No authentication token available')
    return
  }

  try {
    const response = await axios.delete(
      // `${baseUrl}/aws/delete-cliq-message?chatId=${chatId}&commentId=${commentId}`,
      `${baseUrl}/aws/delete-cliq-message`,
      {
        data: { payload },
        headers: {
          Authorization: `Bearer ${oauthToken}`,
        },
      }
    )
    console.log('Sending payload for deletion: ', payload)
    return response.data
  } catch (error) {
    console.error('Error deleting cliq message:', error)
    throw error // Re-throw the error for useMutation's onError to catch
  }
}
export const createCliqChannel = async payload => {
  console.log('Payload in createCliqChannel')
  const oauthToken = localStorage.getItem('zoho_access_token')
  if (!oauthToken) {
    console.error('No authentication token available')
    return
  }

  try {
    const response = await axios.post(`${baseUrl}/aws/create-cliq-channel`, {
      data: { payload },
      headers: {
        Authorization: `Bearer ${oauthToken}`,
      },
    })
    console.log('Channel Created Successfully:', response.data)
    return response.data
  } catch (error) {
    console.error('Error creating cliq channel:', error)
    throw error // Re-throw the error for useMutation's onError to catch
  }
}
