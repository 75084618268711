export const initialDealState = {
  name: '',
  originalName: '',
  dba: '',
  organizations: [],
  type: '',
  description: '',
  stage: 'Discovery',
  status: 'Draft',
  probability: '',
  contacts: [],
  primaryContact: [],
  startDate: null,
  endDate: null,
  closeDate: null,
  products: [],
  recipients: {
    contactRecipient: [],
    authorizedRecipient: [],
  },
  targetLaunchDate: null,
  dependenciesDueDate: null,
  managementFee: '',
  grossProfit: '',
  setupFee: '',
  implementationFee: '',
  monthlyTotal: '',
  total: '',
  mediaSpend: '',
  contractId: '',
  createdBy: [],
  owner: [],
  noSignature: false,
  needsApproval: false,
  isRequested: false,
  isApproved: false,
  isInternal: false,
  isCompleted: false,
  isCreated: false,
  dealStatus: 'Active',
  //strategy
  busObjective: '',
  geoTargeting: '',
  demographic: '',
  competitors: '',
  requests: '',
  productsAndServices: '',
  adCopy: '',
  creativeProvided: '',
  promotionsOffered: '',
  timeFrame: '',
  businessAge: '',
  needMoreAwareness: '',
  leasingGoal: '',
  unitsLeased: '',
  targetOccupancyDate: '',
  websiteInfo: '',
  ownBillingConfig: '',
  retainer: '',
  cmsUsed: '',
  usingOwnAccounts: '',
  clientCreatingCampaigns: '',
  clientPausingCampaigns: '',
  clientModifyingCampaigns: '',
  clientModifyingConversions: '',
  googleAdsCallExtension: '',
  differentNumberFromWebsite: '',
  willingToAddGscTag: '',
  facebookAccess: '',
  facebookPageOwner: '',
  facebookPageAdmin: '',
  facebookBusinessManager: '',
  gtm: '',
  needGtm: '',
  gtmAccess: '',
  conversionsToTrack: '',
  conversionsPriority: '',
  googleBusinessProfile: '',
  googleBusinessProfileUpdated: '',
  numberOfPayments: 2,
}
