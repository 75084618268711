import { IconButton, Badge, Drawer, CircularProgress, Box } from '@mui/material'
import { useNotify } from '../../context/NotificationProvider/NotificationProvider'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import UserImage from '../AvatarImageComponents/UserImage'
import PandaDocLogo from '../../images/pandadoc.png'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import DownloadIcon from '@mui/icons-material/Download'

const PandaDocDownload = ({ size, onDownload, isDownloading }) => {
  return (
    <>
      <IconButton color='inherit' onClick={onDownload} sx={{ ml: 2 }}>
        <Badge badgeContent={<img style={{ width: size ? size : 20, borderRadius: 25 }} src={PandaDocLogo} />}>
          <CloudDownloadIcon sx={{ width: 40, height: 40 }} />
          {/* <img style={{ width: size ? size : 40, borderRadius: 25 }} src={PandaDocLogo} /> */}
        </Badge>
        {isDownloading && <CircularProgress />}
      </IconButton>
    </>
  )
}

export default PandaDocDownload
