import { styled } from '@mui/material'

export const dialogStyles = theme => ({
  width: '100%',
  maxWidth: '800px',
  backgroundColor: theme.palette.mode === 'dark' ? '' : 'rgb(232, 246, 252)',
  color: '#fff',
  minHeight: '50vh',
  maxHeight: '95vh',
  padding: '20px',
  '& .MuiDialogContent-root': {
    width: '100%',
    padding: '20px',
  },
  '& .MuiTextField-root': {
    margin: '10px 0',
    flex: '1 1 45%', // Allows text fields to be 45% of the container width
    minWidth: '150px', // Ensures a minimum width for text fields
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(67, 70, 75)' : 'white',
    minWidth: '150px', // Ensures a minimum width for input base
    flex: '1 1 45%', // Allows select components to be 45% of the container width
  },
  '& .MuiSelect-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(67, 70, 75)' : 'white',
    flex: '1 1 45%', // Allows select components to be 45% of the container width
    minWidth: '150px', // Ensures a minimum width for select components
  },
})

export const dialogContentStyles = {
  width: 'auto',
  maxWidth: '700px',
  margin: '0 auto',
}
