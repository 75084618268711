import axios from 'axios'
export const downloadContract = async contractId => {
  const panda_access_token = localStorage.getItem('panda_access_token')
  if (!panda_access_token) {
    console.log('No Panda Access Token Found')
    return
  }
  const authHeader =
    process.env.REACT_APP_ENV === 'laptop' ? `API-KEY ${panda_access_token}` : `Bearer ${panda_access_token}`
  try {
    const response = await axios.get(`https://api.pandadoc.com/public/v1/documents/${contractId}/download`, {
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
      },
      responseType: 'blob', // Ensure the response is treated as a Blob
    })

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'document.pdf') // Set the filename you want
    document.body.appendChild(link)
    link.click()

    // Clean up the URL object after the download
    link.parentNode.removeChild(link)
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Error downloading the document:', error)
    // Handle error as needed
  }
}
