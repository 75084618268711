import { useState } from 'react'
import { TextField, Button } from '@mui/material'
import { DateRangePicker } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'

const initialSubItemState = {
  platform: '',
  campaignName: '',
  url: '',
  campaignType: '',
  geoTarget: '',
  description: '',
  startDate: null,
  endDate: null,
}

const NewParentItemForm = ({ onSubmit }) => {
  const [newPlatform, setNewPlatform] = useState('')
  const [newCampaignName, setNewCampaignName] = useState('')
  const [newUrl, setNewUrl] = useState('')
  const [newType, setNewType] = useState('')
  const [newGeoTarget, setNewGeoTarget] = useState('')
  const [newDescription, setNewDescription] = useState('')
  // const [newSubItem, setNewSubItem] = useState(initialSubItemState)
  const [dateRange, setDateRange] = useState([null, null])

  const handleNewParentItem = e => {
    e.preventDefault()
    if (newPlatform === '' || newDescription === '') return

    onSubmit(newPlatform, newCampaignName, newType, newGeoTarget, newDescription, dateRange)
    setNewPlatform('')
    setNewCampaignName('')
    setNewUrl('')
    setNewType('')
    setNewGeoTarget('')
    setNewDescription('')
    setDateRange([null, null])
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <form onSubmit={handleNewParentItem} className='new-item-form'>
        <div className='form-row'>
          <TextField
            value={newPlatform}
            onChange={e => setNewPlatform(e.target.value)}
            type='text'
            id='platform'
            name='platform'
            placeholder='Platform'
          />
        </div>
        <div className='form-row'>
          <TextField
            value={newCampaignName}
            onChange={e => setNewCampaignName(e.target.value)}
            type='text'
            id='name'
            name='name'
            placeholder='Campaign Name'
          />
        </div>
        {/* <div className='form-row'>
          <TextField
            value={newUrl}
            onChange={e => setNewUrl(e.target.value)}
            type='text'
            id='url'
            name='url'
            placeholder='Landing Page Url'
          />
        </div> */}
        <div className='form-row'>
          <TextField
            value={newType}
            onChange={e => setNewType(e.target.value)}
            type='text'
            id='type'
            name='type'
            placeholder='Campaign Type'
          />
        </div>
        <div className='form-row'>
          <TextField
            value={newGeoTarget}
            onChange={e => setNewGeoTarget(e.target.value)}
            type='text'
            id='geoTarget'
            name='geoTarget'
            placeholder='Geo Target'
          />
        </div>
        <div className='form-row'>
          <TextField
            value={newDescription}
            onChange={e => setNewDescription(e.target.value)}
            type='text'
            id='description'
            name='description'
            placeholder='Description'
          />
        </div>
        <div className='form-row'>
          <DateRangePicker
            startText='Start Date'
            endText='End Date'
            value={dateRange}
            onChange={newValue => setDateRange(newValue)}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} />
                <TextField {...endProps} />
              </>
            )}
          />
        </div>
        <Button type='submit' variant='contained' color='primary'>
          Add
        </Button>
      </form>
    </LocalizationProvider>
  )
}

export default NewParentItemForm
