import dayjs from 'dayjs'
import RenderPlatformIcon from './RenderPlatformIcon'
import RenderServiceIcon from '../RenderServiceIcons/RenderServiceIcon'
import { calculateTotalMonthsForProducts } from '../../utility/calculateTotalMonths'

const formatDayjsDate = dateStr => {
  // console.log('[renderDealsColumns][formatDayjsDate] dateStr: ', dateStr)
  if (dateStr) {
    return dayjs(dateStr).format('MM/DD/YYYY')
  } else {
    return null
  }
}

const formatCurrency = number => {
  const currencyNumnber = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number)
  return `${currencyNumnber}`
}
const currencyColor = 'rgb(92, 203, 131)'
export const addressMap = organization => [
  { type: 'string', label: 'Street', value: organization.street || '' },
  { type: 'string', label: 'City', value: organization.city || '' },
  { type: 'string', label: 'State', value: organization.state || '' },
  { type: 'string', label: 'Zip', value: organization.zip || '' },
]

export const generalInfoMap = organization => [
  {
    Street: { type: 'string', value: organization.street || '' },
    City: { type: 'string', value: organization.city || '' },
    State: { type: 'string', value: organization.state || '' },
    Zip: { type: 'string', value: organization.zip || '' },
    Status: { type: 'string', value: organization.status || '' },
    Website: { type: 'string', value: organization.website || '' },
    DBA: { type: 'string', value: organization.dba || '' },
  },
]
export const totalsMap = deal => [
  {
    'Implementation Fee': {
      type: 'number',
      label: 'Implementation Fee',
      value: formatCurrency(deal.implementationFee),
      color: currencyColor,
    },
    'Total Gross Profit': {
      type: 'number',
      label: 'Total Gross Profit',
      value: formatCurrency(deal.grossProfit),
      color: currencyColor,
    },
    'Total Contract Amount': {
      type: 'number',
      label: 'Total Contact Amount',
      value: formatCurrency(deal.total),
      color: currencyColor,
    },
  },
]
export const ioTotalsMap = (deal, total) => [
  {
    'Implementation Fee': {
      type: 'number',
      label: 'Implementation Fee',
      value: formatCurrency(deal.implementationFee),
      color: currencyColor,
    },
    'Total Gross Profit': {
      type: 'number',
      label: 'Total Gross Profit',
      value: formatCurrency(deal.grossProfit),
      color: currencyColor,
    },
    'Total Contract Amount': {
      type: 'number',
      label: 'Total Contact Amount',
      value: formatCurrency(deal.total),
      color: currencyColor,
    },
    'Total Months': {
      type: 'total',
      label: 'Total Months',
      value: `${
        calculateTotalMonthsForProducts(deal && (Array.isArray(deal.products) ? deal.products : [])).totalMonths
      } mo(s).`,
    },
  },
]
export const infoMap = organization => [
  { type: 'string', label: 'Status', value: organization.status || '' },
  { type: 'string', label: 'Website', value: organization.website || '' },
  { type: 'string', label: 'DBA', value: organization.dba || '' },
]
export const contractMap = productList => {
  return productList
    .map((item, index) => {
      return {
        Service: {
          type: 'string',
          value: <RenderServiceIcon key={`${index}-${item.productName}`} service={item.productName} />,
          description: item.description,
          xs: 2,
        },
        Price: {
          type: 'number',
          value: formatCurrency(item.price),
          color: currencyColor,
          xs: 1.5,
        },
        Margin: { type: 'string', value: `${item.margin}%`, xs: 1.5 },
        'Media Spend': {
          type: 'number',
          value: formatCurrency(item.mediaSpend),
          color: currencyColor,
          xs: 1.5,
        },
        'Start Date': {
          type: 'string',
          value: formatDayjsDate(item.startDate),
          xs: 1.5,
        },
        'End Date': { type: 'string', value: formatDayjsDate(item.endDate), xs: 1.5 },
        Payer: { type: 'string', value: item.payer, xs: 1.25 },
        'Account Used': { type: 'string', value: item.accountUsed, width: 50, xs: 1.25 },
      }
    })
    .filter(item => item !== null)
}
export const payInfoMap = productList => {
  return productList
    .map((item, index) => {
      return item && item.payer && item.accountUsed
        ? {
            Service: {
              type: 'string',
              value: <RenderPlatformIcon key={`${index}-${item.productName}`} service={item.productName} />,
            },
            Payer: { type: 'string', value: item.payer },
            'Account Used': { type: 'string', value: item.accountUsed },
          }
        : null
    })
    .filter(item => item !== null)
}
