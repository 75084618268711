import React, { useState } from 'react'
import { Box, TableRow, TableCell, TextField, Tooltip, Typography, IconButton, useTheme } from '@mui/material'
import { columns } from './contractDialogColumns'
import { csaColumns, ioColumns } from './columns'
import RenderCell from './RenderCell'

const RenderRow = ({
  row,
  index,
  handleProductChange,
  provided,
  snapshot,
  handleRemoveRow,
  validationErrors,
  type,
}) => {
  const theme = useTheme()
  const tableBgColor = theme.palette.mode === 'dark' ? 'rgb(22, 28, 42, 0.3)' : 'rgba(255,255,255,1)'
  const csaTypes = ['CSA', 'RCSA']
  const ioType = ['IO']
  const rowColumns = type && csaTypes.includes(type) ? csaColumns : ioType.includes(type) ? ioColumns : columns

  return (
    <TableRow
      sx={{
        backgroundColor:
          snapshot.isDragging && theme.palette.mode === 'dark'
            ? 'rgba(30, 39, 53,0.4)'
            : snapshot.isDragging && theme.palette.mode === 'light'
            ? 'rgba(255,255,255,1)'
            : tableBgColor,
        paddingBottom: '0px',
      }}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {rowColumns.map(column => {
        if (column.id === 'rowId') return null
        return (
          <TableCell
            key={`${row.rowId}-${column.id}`}
            sx={{ flex: 1, width: column.width, padding: '8px 8px 0px 8px' }}
          >
            <RenderCell
              row={row}
              column={column}
              index={index}
              handleProductChange={handleProductChange}
              validationErrors={validationErrors}
              handleRemoveRow={handleRemoveRow}
              snapshot={snapshot}
            />
          </TableCell>
        )
      })}
    </TableRow>
  )
}

export default RenderRow
