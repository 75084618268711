import React from 'react'
import SaveIcon from '@mui/icons-material/Save'
import { IconButton } from '@mui/material'

const SaveIconButton = ({ hasErrors, onSave }) => {
  return (
    <IconButton
      disabled={hasErrors}
      onClick={onSave}
      sx={{
        backgroundColor: 'rgb(42, 128, 200)',
        '&.Mui-disabled': {
          backgroundColor: 'rgb(52, 60, 73)', // Custom background color when disabled
        },
      }}
    >
      <SaveIcon sx={{ color: hasErrors ? 'rgba(231, 109, 137, 0.5)' : '', height: 30, width: 30 }} />
    </IconButton>
  )
}

export default SaveIconButton
