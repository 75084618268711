import { Typography, Box, Button, Popover, useTheme, Backdrop, CircularProgress, IconButton } from '@mui/material'
import PublicIcon from '@mui/icons-material/Public'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import WarningIcon from '@mui/icons-material/Warning'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'

import {
  popoverStyles,
  boxContainerStyles,
  boxStyles,
  deleteButtonStyles,
  textStyles,
  buttonContainerStyles,
  overlayTextStyles,
} from './style'
import { useEffect } from 'react'
import { useDealsByIds } from '../../api/aws/useDealByIds'
import RenderError from '../../context/UsersProvider/RenderError'
import { useOrganizationsById } from '../../api/customHooks/useOrganizationsById'
import RenderCompanyChip from '../RenderChipComponents/RenderCompanyChip'

const OrganizationsPopover = ({ id, open, anchorEl, onClose, handleConfirmDelete, selectedData }) => {
  const theme = useTheme()
  const dealIds = selectedData?.dealIds || []
  const { data: deals, isLoading: dealsLoading, isError: dealsError } = useDealsByIds(dealIds)
  const { data: contractedBy, isLoading: contractedByLoading, isError: contractedByError } = useOrganizationsById(
    selectedData?.contractedBy || []
  )
  const { data: affiliate, isLoading: affiliateLoading, isError: affiliateError } = useOrganizationsById(
    selectedData?.affiliate || []
  )

  const isLoading = dealsLoading || contractedByLoading || affiliateLoading
  const isError = dealsError || contractedByError || affiliateError
  useEffect(() => {
    if (selectedData) {
      console.log('[OrganizationsPopover] selectedData: ', selectedData)
    }
  }, [selectedData])
  if (isLoading) {
    return <CircularProgress />
  }
  if (isError) {
    return <RenderError />
  }

  return (
    // <Backdrop open={open} sx={{ zIndex: theme.zIndex.drawer + 1 }}>
    <>
      <Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          sx={popoverStyles(theme)}
        >
          <Box sx={boxContainerStyles(theme)}>
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                <WarningIcon sx={{ color: 'crimson' }} />
                <Typography sx={{ fontWeight: 'bold', color: 'crimson' }}>Confirm Deletion</Typography>
              </Box>
              <Typography sx={{ mb: 2 }}>
                Are you sure you want to permanently delete the following item(s) from Grobotic?
              </Typography>
              {selectedData && selectedData.name && (
                <>
                  <Box sx={boxStyles(theme)}>
                    <CorporateFareIcon
                      sx={{
                        color: 'rgb(114, 229, 218)',
                        fontSize: 35,
                      }}
                    />
                    <Typography style={textStyles(theme)}>{selectedData.name}</Typography>
                  </Box>
                  <Box sx={boxStyles(theme)}>
                    <Typography>Linked Data:</Typography>
                  </Box>
                  <Box sx={{ ...boxStyles(theme), flexDirection: 'column' }}>
                    {contractedBy && Array.isArray(contractedBy) && contractedBy.length > 0
                      ? contractedBy.map(org => {
                          return <RenderCompanyChip option={org} />
                        })
                      : null}
                    {affiliate && Array.isArray(affiliate) && affiliate.length > 0
                      ? affiliate.map(org => {
                          return <RenderCompanyChip option={org} />
                        })
                      : null}
                  </Box>
                  {selectedData &&
                    selectedData.dealIds &&
                    Array.isArray(selectedData.dealIds) &&
                    selectedData.dealIds.length > 0 && (
                      <>
                        <Box sx={{ ...boxStyles(theme), flexDirection: 'column', justifyContent: 'space-between' }}>
                          {deals && Array.isArray(deals)
                            ? deals.map(deal => {
                                return (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      gap: 5,
                                    }}
                                  >
                                    <Box>
                                      <AttachMoneyIcon
                                        sx={{
                                          color: '#fbc11e',
                                        }}
                                      />
                                    </Box>
                                    <Box>
                                      <Typography>{deal.name}</Typography>
                                    </Box>
                                  </Box>
                                )
                              })
                            : null}
                        </Box>
                        <Box sx={{ ...boxStyles(theme), gap: 1 }}>
                          <ReportGmailerrorredIcon sx={{ color: 'rgba(255, 193, 7)' }} />
                          <Typography sx={{ color: 'rgba(255, 193, 7)' }}>
                            {`Organization has connected deal(s) - remove before deleting organization`}
                          </Typography>
                        </Box>
                      </>
                    )}
                </>
              )}
            </>

            <Box sx={buttonContainerStyles(theme)}>
              <Button
                sx={deleteButtonStyles(theme)}
                disabled={
                  selectedData &&
                  selectedData.dealIds &&
                  Array.isArray(selectedData.dealIds) &&
                  selectedData.dealIds.length > 0
                }
                variant='outlined'
                onClick={handleConfirmDelete}
                color='secondary'
              >
                Confirm
              </Button>
              <Button variant='contained' onClick={onClose}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Popover>
      </Box>
    </>
  )
}
export default OrganizationsPopover
