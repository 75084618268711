import React, { useState } from 'react'
import { Box, Typography, Avatar, Popover, Tooltip, IconButton, CircularProgress } from '@mui/material'
import TeamMembersAutocomplete from './TeamMembersAutocomplete'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { useTeamMembers } from '../../api/aws/useTeamMembers'
import { useUsers } from '../../api/aws/useUsers'

const RenderOrgTeam = ({ organization, onUpdate, isUpdating }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const roles = ['AM', 'PM', 'DMS', 'SEO', 'BD']
  const roleIds = roles.flatMap(role => organization[role] || []) // Flatten role IDs into a single array
  const { teamMembers, isTeamMembersLoading, isTeamMembersError, teamMembersError } = useTeamMembers(roleIds)
  const { users, isUsersLoading, isUsersError } = useUsers()
  const isLoading = isTeamMembersLoading || isUsersLoading
  const isError = isTeamMembersError || isUsersError
  const handleUpdate = (role, newMembers) => {
    onUpdate(role, newMembers)
  }

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const hasAssignedMembers = roles.some(role => (organization[role] || []).length > 0)

  if (isLoading) return <CircularProgress />
  if (isError) return <Typography>Error loading team members: {teamMembersError.message}</Typography>

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Typography variant='h6' sx={{ textAlign: 'center', mb: 2 }}>
          Team
        </Typography>

        {hasAssignedMembers ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
            onClick={handlePopoverOpen}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                gap: '6px',
                maxWidth: '100%',
                flexWrap: 'wrap',
              }}
            >
              {roles.flatMap(role =>
                (organization[role] || []).map(userId => {
                  const user = teamMembers.find(member => member.id === userId)
                  return user ? (
                    <Box
                      key={`${role}-${user.id}`}
                      sx={{
                        transition: 'transform 0.3s ease-in-out',
                        cursor: 'pointer',
                        '&:hover': {
                          transform: 'scale(1.10)',
                        },
                      }}
                    >
                      <Tooltip title={user.name}>
                        <Avatar
                          alt={user.name}
                          src={user.image_512}
                          sx={{
                            height: 50,
                            width: 50,
                            cursor: 'pointer',
                          }}
                        />
                      </Tooltip>
                    </Box>
                  ) : null
                })
              )}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: 50,
            }}
            onClick={handlePopoverOpen}
          >
            <IconButton>
              <PersonAddIcon />
            </IconButton>
          </Box>
        )}
      </Box>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: {
            sx: {
              padding: '10px',
              maxHeight: '500px',
              width: '375px',
            },
          },
        }}
      >
        <Typography variant='h6' sx={{ mb: 2, textAlign: 'center' }}>
          Organization Team
        </Typography>
        {roles.map(role => (
          <Box key={role} sx={{ mb: 3 }}>
            <TeamMembersAutocomplete
              users={users}
              label={`Add ${role}`}
              onChange={newMembers => handleUpdate(role, newMembers)}
              error={false}
              defaultValue={
                organization[role]
                  ? organization[role].map(userId => teamMembers.find(member => member.id === userId)).filter(Boolean)
                  : []
              }
              isUpdating={isUpdating}
            />
          </Box>
        ))}
      </Popover>
    </>
  )
}

export default RenderOrgTeam
