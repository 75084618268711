import axios from 'axios'
import { baseUrl } from '../queryMutations/queryMutations'
import { useQuery } from '@tanstack/react-query'
import api from '../../authentication/axiosInstance'
const googleToken = localStorage.getItem('google_access_token')
const slackUserId = localStorage.getItem('slack_user_id')

export const getGoogleUserInfo = async () => {
  const apiUrl = `${baseUrl}/aws/googleuserinfo`
  if (!googleToken || !slackUserId) {
    console.error('No authentication token or Slack user ID available')
    return []
  }

  try {
    const response = await api.post(
      apiUrl,
      { slackUserId },
      {
        headers: { Authorization: `Bearer ${googleToken}` },
      }
    )
    if (response.data) {
      console.log('[Google] current user: ', response.data)
      if (response && response.status === 401) {
        return alert('Google User Info Unauthorized: ', response.status)
      }
      return response.data
    } else {
      console.warn('No user data found in response')
      return []
    }
  } catch (error) {
    console.error('Error fetching current user: ', error)
    return [] // Return an empty array in case of an error
  }
}

export const useGoogleUserInfo = (options = {}) => {
  return useQuery({
    queryKey: ['googleUserInfo'],
    queryFn: getGoogleUserInfo,
    staleTime: Infinity, // Data is never considered stale
    refetchOnWindowFocus: false, // Do not refetch on window focus
    enabled: !!slackUserId,
    ...options,
  })
}
