import { TextField } from '@mui/material'
import { useState } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

export const formatCampaignDates = dateStr => {
  if (dateStr) {
    return dayjs(dateStr).format('MM/DD/YYYY')
  } else {
    return null
  }
}

const ParentItem = ({
  id,
  platform,
  campaignName,
  type,
  geoTarget,
  description,
  subItems,
  addSubItem,
  deleteParentItem,
  deleteSubItem,
  dateRange,
}) => {
  const [newSubItem, setNewSubItem] = useState({ text: '', url: '' })

  const handleAddSubItem = e => {
    e.preventDefault()
    if (newSubItem.text === '' || newSubItem.url === '') return

    addSubItem(id, newSubItem)
    setNewSubItem({ text: '', url: '' })
  }

  return (
    <li>
      <div>
        <h3>{platform}</h3>
        <p>{campaignName}</p>
        <p>{type}</p>
        <p>{geoTarget}</p>
        <p>{description}</p>
        {dateRange && Array.isArray(dateRange)
          ? dateRange.map((date, index) => <p key={index}>{formatCampaignDates(date)}</p>)
          : null}

        <button onClick={() => deleteParentItem(id)} className='btn btn-danger'>
          Delete
        </button>
      </div>
      <form onSubmit={handleAddSubItem}>
        <TextField
          type='text'
          value={newSubItem.text}
          onChange={e => setNewSubItem({ ...newSubItem, text: e.target.value })}
          placeholder='New SubItem'
        />
        <TextField
          type='text'
          value={newSubItem.url}
          onChange={e => setNewSubItem({ ...newSubItem, url: e.target.value })}
          placeholder='Landing Page URL'
        />
        <button type='submit' className='btn btn-primary'>
          Add SubItem
        </button>
      </form>
      <ul>
        {subItems.map((subItem, index) => (
          <li key={index}>
            {subItem.text} - {subItem.url}
            <button onClick={() => deleteSubItem(id, index)} className='btn btn-danger'>
              Delete
            </button>
            <TextField multiline placeholder='Keywords' />
            <TextField multiline placeholder='Specials/Promos, Adcopy, etc.' />
          </li>
        ))}
      </ul>
    </li>
  )
}

export default ParentItem
