import { useGetQueryByIds } from '../aws/useGetQueryByIds'

export const useTeamMembers = userIds => {
  const validIds = Array.isArray(userIds) ? userIds.filter(id => !!id) : []

  // Use the custom useGetQueryByIds hook to fetch user data by IDs
  const queryResults = useGetQueryByIds('/aws/team', 'users', validIds)

  // Extract user data from successful queries
  const teamMembers = queryResults
    .filter(result => result.isSuccess && result.data) // Filter out unsuccessful queries
    .map(result => result.data) // Extract the user object from the data

  // Aggregate loading and error states
  const isTeamMembersLoading = queryResults.some(result => result.isLoading)
  const isTeamMembersError = queryResults.some(result => result.isError)
  const teamMembersError = queryResults.find(result => result.isError)?.error

  return {
    teamMembers,
    isTeamMembersLoading,
    isTeamMembersError,
    teamMembersError,
  }
}
