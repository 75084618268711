import React from 'react'
import { useSlackUserProfile } from '../../api/slack/useSlackUserProfile'
import { Box, Typography, Stack } from '@mui/material'
import { StyledGridTypography } from '../../style/styleElements'
import SlackLoading from '../Loading/SlackLoading'
import Loading from '../Loading/Loading'
import { useUsers } from '../../api/aws/useUsers'

const SlackUserList = () => {
  const {
    data: slackUserProfile,
    isLoading: slackUserProfileLoading,
    isError: slackUserProfileError,
  } = useSlackUserProfile()
  const { users, isUsersLoading, isUsersError } = useUsers()
  console.log('users: ', users)

  if (slackUserProfileLoading || isUsersLoading) return <SlackLoading />
  if (slackUserProfileError || isUsersError) return <div>Error loading Slack user data</div>

  console.log('[SlackUserList]', 'Slack Users: ', users, 'SlackUserProfile: ', slackUserProfile)

  const renderUser = (user, label) => (
    <Box
      key={user.id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 1,
        margin: 1,
        border: '1px solid #ccc',
        borderRadius: '8px',
        width: 'fit-content',
      }}
    >
      <img
        src={user?.image_48 || user?.profile?.image_48 || ''}
        alt='avatar'
        style={{ width: 48, height: 48, borderRadius: '50%' }}
      />
      <StyledGridTypography>
        {label}: {user?.first_name || user?.profile?.first_name || ''}
      </StyledGridTypography>
      <StyledGridTypography>Display Name: {user.name || user?.profile?.name || ''}</StyledGridTypography>
      <StyledGridTypography>Admin: {user.is_admin ? 'Yes' : 'No'}</StyledGridTypography>
      <StyledGridTypography>Bot: {user.is_bot ? 'Yes' : 'No'}</StyledGridTypography>
    </Box>
  )

  return (
    <Box sx={{ width: '100%', maxHeight: '80vh', overflowY: 'auto', padding: 2 }}>
      {slackUserProfile && renderUser(slackUserProfile, 'Current Logged In User')}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
        {users && users.map(user => renderUser(user, 'User'))}
      </Box>
    </Box>
  )
}

export default SlackUserList
