import React, { useEffect, useState } from 'react'
import { Autocomplete, TextField, Chip, Tooltip } from '@mui/material'
import { useCreateContact } from '../../api/aws/useCreateContact'
import ContactsDialog from '../Contacts/ContactsDialog'
import AddRenderOptionButton from '../Contacts/AddRenderOptionButton'
import { useGetQuery } from '../../api/aws/useGetQuery'

const OrgAdditionalContactsAutocomplete = ({ value, label, onChange, onBlur }) => {
  const { data: contacts, isLoading: contactsLoading, isError: contactsError } = useGetQuery(
    '/aws/contacts',
    'contacts'
  )
  const [dialogOpen, setDialogOpen] = useState(false)
  const [tempInput, setTempInput] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [selectedAdditionalContacts, setSelectedAdditionalContacts] = useState([])
  const { mutate: createContact } = useCreateContact()
  useEffect(() => {
    // Set the initial selected contacts based on the value prop
    if (value && value.length > 0) {
      const initialContacts = value.map(contactId => contacts.find(contact => contact.contacts_uuid === contactId))
      setSelectedAdditionalContacts(initialContacts)
    }
  }, [value, contacts])

  const handleOpenDialog = inputValue => {
    setTempInput(inputValue)
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setTempInput('')
    setInputValue('')
  }

  const handleCreateContacts = newValue => {
    createContact(newValue, {
      onSuccess: data => {
        const newContact = { ...data, contacts_uuid: data.contacts_uuid }
        const updatedContacts = [...(selectedAdditionalContacts || []), newContact]
        setSelectedAdditionalContacts(updatedContacts)
        onChange(updatedContacts.map(contact => contact.contacts_uuid))
        handleCloseDialog()
      },
    })
  }

  const handleSelectExistingContact = (event, newValue, reason) => {
    setSelectedAdditionalContacts(newValue)
    const contactIds = newValue.map(contact => contact.contacts_uuid)
    onChange(contactIds)
  }

  const handleBlur = () => {
    const contactIds = selectedAdditionalContacts.map(contact => contact.contacts_uuid)
    onBlur('contacts', contactIds)
  }

  const handleRemoveContact = contactId => {
    const newSelectedContacts = selectedAdditionalContacts.filter(contact => contact.contacts_uuid !== contactId)
    setSelectedAdditionalContacts(newSelectedContacts)
    onChange(newSelectedContacts.map(contact => contact.contacts_uuid))
  }

  return (
    <>
      <Autocomplete
        multiple
        id='additional-contacts-autocomplete'
        options={contacts || []}
        getOptionLabel={option => option?.name || 'Unnamed Contact'}
        value={selectedAdditionalContacts}
        onChange={handleSelectExistingContact}
        onBlur={handleBlur}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        filterOptions={(options, { inputValue }) => {
          let filtered = options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()))
          if (inputValue !== '' && !filtered.some(option => option.name.toLowerCase() === inputValue.toLowerCase())) {
            filtered.unshift({
              inputValue,
              specialOption: true,
              id: `add-new-${inputValue}`,
            })
          }
          return filtered
        }}
        renderOption={(props, option) =>
          option.inputValue ? (
            <AddRenderOptionButton key={option.id} {...props} option={option} handleOpenDialog={handleOpenDialog} />
          ) : (
            <li {...props} key={option.contacts_uuid}>
              {option.name}
            </li>
          )
        }
        renderTags={(tagValue, getTagProps) => {
          const firstContact = tagValue[0]
          const additionalContacts = tagValue.slice(1)
          const { key, onDelete, ...rest } = getTagProps({ index: 0 })
          return (
            <>
              {firstContact && (
                <Chip
                  key={firstContact?.contacts_uuid}
                  label={firstContact?.name || 'Unnamed Contact'}
                  {...rest}
                  onDelete={() => handleRemoveContact(firstContact?.contacts_uuid)}
                />
              )}
              {additionalContacts.length > 0 && (
                <Tooltip title={additionalContacts.map(contact => contact?.name || 'Unnamed Contact').join(', ')}>
                  <Chip
                    key={`extra-${additionalContacts.length}`}
                    label={`+${additionalContacts.length}`}
                    onDelete={() => {
                      const newSelectedContacts = selectedAdditionalContacts.slice(0, 1)
                      setSelectedAdditionalContacts(newSelectedContacts)
                      onChange(newSelectedContacts.map(contact => contact.contacts_uuid))
                    }}
                  />
                </Tooltip>
              )}
            </>
          )
        }}
        renderInput={params => <TextField {...params} label={label} />}
        fullWidth
        freeSolo
      />
      <ContactsDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        tempInput={tempInput}
        onSaveContact={handleCreateContacts}
      />
    </>
  )
}

export default OrgAdditionalContactsAutocomplete
