// src/AppLayout.js
import React, { useState, useContext } from 'react'
import { Box, AppBar, Toolbar, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ContainerProvider } from './context/ContainerProvider/ContainerProvider'
import CreateOrganization from './components/CreateOrganization/CreateOrganization'
import CreateDeal from './components/CreateDeal/CreateDeal'
import CreateContact from './components/CreateContact/CreateContact'
import MiniDrawer from './components/AppSidebar/MiniDrawer'
import { useLocation } from 'react-router-dom'
import headerMap from './components/Headers/headerMap'
import { HeaderContext } from './context/HeaderProvider/HeaderProvider'
import { useActiveUser } from './api/slack/useActiveUser'
import ConnectedUsers from './context/Websocket/ConnectedUsers'
import { DealDetailsProvider } from './context/DealsDetailsProvider/DealsDetailsProvider'
import { initialDealState } from './context/DealsDetailsProvider/initialDealState'

const MainContent = styled('main', {
  shouldForwardProp: prop => !['open'].includes(prop),
})(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open && {
    marginLeft: 20,
    marginRight: 20,
  }),
  marginTop: theme.spacing(9),
}))

const AppLayout = ({ children }) => {
  const [isCreateOrganizationOpen, setIsCreateOrganizationOpen] = useState(false)
  const [isCreateDealOpen, setIsCreateDealOpen] = useState(false)
  const [isCreateContactOpen, setIsCreateContactOpen] = useState(false)
  const [isNotifyDrawerOpen, setIsNotifyDrawerOpen] = useState(false)
  const adminUsers = ['U062JDP8R5X', 'UHKAA49CJ']
  const handleOpenCreateOrganization = () => setIsCreateOrganizationOpen(true)
  const handleCloseCreateOrganization = () => setIsCreateOrganizationOpen(false)

  const handleOpenCreateDeal = () => setIsCreateDealOpen(true)
  const handleCloseCreateDeal = () => setIsCreateDealOpen(false)

  const handleOpenCreateContact = () => setIsCreateContactOpen(true)
  const handleCloseCreateContact = () => setIsCreateContactOpen(false)

  const handleOpenNotifyDrawer = () => setIsNotifyDrawerOpen(true)
  const handleCloseNotifyDrawer = () => setIsNotifyDrawerOpen(false)

  const theme = useTheme()
  const location = useLocation()
  const { organizationInfo, dealInfo, contactInfo } = useContext(HeaderContext)
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const pathsWithAppBar = [
    '/dashboard',
    '/deals',
    '/deals/kanban',
    '/organizations',
    '/contacts',
    '/proposals',
    '/prospects',
    '/leads',
    '/pandadocs',
    '/sprints',
  ]

  const renderHeader = () => {
    const path = location.pathname
    const headers = headerMap({
      handleOpenCreateOrganization,
      handleOpenCreateDeal,
      handleOpenCreateContact,
      organizationInfo,
      dealInfo,
      contactInfo,
    })

    if (headers[path]) {
      return headers[path]
    }

    const dynamicPaths = Object.keys(headers).filter(p => p.includes(':'))
    for (let dynamicPath of dynamicPaths) {
      const regexPath = new RegExp(dynamicPath.replace(/:\w+/g, '\\w+'))
      if (regexPath.test(path)) {
        return headers[dynamicPath]
      }
    }

    return null
  }

  const shouldRenderAppBar = pathsWithAppBar.includes(location.pathname)

  return (
    <Box
      sx={{
        display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgb(232, 246, 252)',
        maxHeight: '100vh',
        // width: '100vw',
        // overflow: 'hidden',
      }}
    >
      <ContainerProvider>
        <MiniDrawer
          handleOpenCreateOrganization={handleOpenCreateOrganization}
          handleOpenCreateDeal={handleOpenCreateDeal}
          handleOpenCreateContact={handleOpenCreateContact}
          isNotifyDrawerOpen={isNotifyDrawerOpen}
          onOpenNotify={handleOpenCreateContact}
          onCloseNotify={handleCloseNotifyDrawer}
        />
        {activeUser && activeUser.user && adminUsers.includes(activeUser.user.id) && <ConnectedUsers />}
        <MainContent>
          {shouldRenderAppBar && (
            <AppBar position='static'>
              <Toolbar sx={{ backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'white' }}>
                {renderHeader()}
              </Toolbar>
            </AppBar>
          )}
          {children}
          <CreateOrganization open={isCreateOrganizationOpen} onClose={handleCloseCreateOrganization} />
          <DealDetailsProvider dealData={initialDealState}>
            <CreateDeal open={isCreateDealOpen} onClose={handleCloseCreateDeal} />
          </DealDetailsProvider>
          <CreateContact open={isCreateContactOpen} onClose={handleCloseCreateContact} />
        </MainContent>
      </ContainerProvider>
    </Box>
  )
}

export default AppLayout
