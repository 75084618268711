import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { baseUrl } from '../queryMutations/queryMutations'

const createContact = async contactData => {
  const url = `${baseUrl}/aws/contacts`
  console.log('New Contact Data: ', contactData)
  const response = await axios.post(url, contactData)
  return response.data // Assumes the response data includes the new contact with `id`
}

export const useCreateContact = () => {
  const { showSnackbar } = useSnackbarContext()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: createContact,
    onMutate: async newContact => {
      await queryClient.cancelQueries(['contacts'])

      // Snapshot the previous value
      const previousContacts = queryClient.getQueryData(['contacts'])

      // Do not optimistically update here because we don't have the new `id` yet
      return { previousContacts }
    },
    onError: (err, newContact, context) => {
      queryClient.setQueryData(['contacts'], context.previousContacts)
      console.error('Mutation Error:', err)
      showSnackbar(err.response?.data?.message || err.message, 'error')
    },
    onSuccess: data => {
      queryClient.setQueryData(['contacts'], old =>
        old ? [...old, { ...data, id: data.contacts_uuid }] : [{ ...data, id: data.contacts_uuid }]
      )
      showSnackbar('Contact created successfully', 'success')
    },
    onSettled: () => {
      queryClient.invalidateQueries(['contacts'])
    },
  })

  return mutation
}
