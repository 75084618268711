import React from 'react'
import { useTheme } from '@mui/material/styles'

const ThemedImage = ({ srcLight, srcDark, alt, width }) => {
  const theme = useTheme()
  const src = theme.palette.type === 'dark' ? srcDark : srcLight

  // return <img style={{ width: width || 75 }} src={src} alt={alt} />
  return <img style={{ width: 70, height: 70 }} src={src} alt={alt} />
}

export default ThemedImage
