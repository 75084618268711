import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import Main from './Main'
import ThemeProviderWrapper from './themeProviders/ThemeProviderWrapper'
import { ThemeProviderContext } from './themeProviders/ThemeContext'
import { LicenseInfo } from '@mui/x-license'

LicenseInfo.setLicenseKey(
  'c1b224b295a305fa98a7ddc8b19b8141Tz05MTg5NyxFPTE3NDkxMzgwOTAwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
)

const App = () => {
  return (
    <ThemeProviderWrapper>
      <Main />
    </ThemeProviderWrapper>
  )
}
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <ThemeProviderContext>
    <App />
  </ThemeProviderContext>
)
