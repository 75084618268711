import { fontWeight } from '@mui/system'
const renderSprintType = type => {
  switch (type) {
    case 'refactor':
      return 'rgba(241, 130, 141, 1)'
    case 'optimization':
      return 'rgba(3, 201, 169, 1)'
    case 'feature':
      return 'rgba(82, 78, 183, 1)'
  }
}

export const containerStyle = {
  border: '1px solid #ccc',
  borderRadius: '5px',
  margin: 1,
}
export const typographyStyle = {
  padding: '10px',
}
export const typographyNameStyle = {
  padding: '10px',
  color: 'black',
  fontWeight: 'bold',
}
export const itemStyle = {
  padding: 2,
}
export const nameStyle = (theme, type) => ({
  backgroundColor: renderSprintType(type),
})
export const typographyTypeStyle = (theme, type) => ({
  backgroundColor: renderSprintType(type),
  color: 'rgba(255, 255, 255, 1)',
  fontWeight: 'bold',
})
export const sprintListStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '35%',
  margin: 1,
  padding: 2,
}
export const stackStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}
