import React, { useState } from 'react'
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import PersonIcon from '@mui/icons-material/Person'
import PublicIcon from '@mui/icons-material/Public'

const CreateMenu = ({
  onOpenCreateProject,
  onOpenCreateTask,
  onOpenCreateOrganization,
  onOpenCreateLead,
  onOpenCreateDeal,
  onOpenCreateContact,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        title="Create Menu"
        sx={{
          color: (theme) => (theme.palette.mode === 'dark' ? 'white' : 'black'),
          '&:hover': {
            backgroundColor: (theme) => theme.palette.secondary.main,
          },
        }}
      >
        <AddIcon style={{ fontSize: 30 }} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {/* <MenuItem
          onClick={() => {
            onOpenCreateProject()
            handleClose()
          }}
        >
          Create Project
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            onOpenCreateOrganization()
            handleClose()
          }}
        >
          <CorporateFareIcon
            sx={{
              color: 'rgb(114, 229, 218)',
            }}
          />
          <Typography sx={{ margin: 1 }}>Create Organization</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            onOpenCreateDeal()
            handleClose()
          }}
        >
          <AttachMoneyIcon
            sx={{
              color: '#fbc11e',
            }}
          />
          <Typography sx={{ margin: 1 }}>Create Deal</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onOpenCreateContact()
            handleClose()
          }}
        >
          <PersonIcon
            sx={{
              color: 'rgb(255, 123, 187)',
            }}
          />

          <Typography sx={{ margin: 1 }}>Create Contact</Typography>
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            handleOpenDiscoveryForm('Global')
            handleClose()
          }}
        >
          <PublicIcon sx={{ color: 'rgb(25, 117, 255)' }} />
          <Typography sx={{ margin: 1 }}>Create Discovery</Typography>
        </MenuItem> */}
        {/* Create Leads & Create Tasks */}
        {/* <MenuItem
          onClick={() => {
            onOpenCreateTask()
            handleClose()
          }}
          disabled
        >
          <TaskAltIcon
            sx={{
              color: 'rgb(255, 68, 161)',
            }}
          />
          <Typography sx={{ margin: 1 }}>Create Task</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onOpenCreateLead()
            handleClose()
          }}
          disabled
        >
          <OnlinePredictionIcon
            sx={{
              color: 'rgb(94, 123, 167)',
            }}
          />
          <Typography sx={{ margin: 1 }}>Create Lead</Typography>
        </MenuItem> */}
      </Menu>
    </>
  )
}

export default CreateMenu
