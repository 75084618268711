import React from 'react'
import { Stack, Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import SlackLogo from '../../images/slack_logo.png'
import './loading.css'

const SlackLoading = () => {
  const StyledGroLoadingComponent = styled('div')(({ theme }) => ({
    alignItems: 'center',
    top: 0,
    left: 0,
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: '#1f2228',
    // ... other styles
  }))

  const LoadingLogo = styled('img')`
    width: 300px; /* Adjust as needed */
    animation: fadeInOut 2s infinite;
    backgroundcolor: transparent;

    @keyframes fadeInOut {
      0%,
      100% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
    }
  `

  return (
    <StyledGroLoadingComponent>
      <Stack sx={{ alignItems: 'center' }}>
        <LoadingLogo src={SlackLogo} alt='Loading...' />
        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 10 }}>
          {'Authenticating with Slack...'.split('').map((letter, index) => (
            <Typography
              variant='h6'
              key={index}
              className='animated-letter'
              style={{
                animationDelay: `${index * 0.1}s`, // Controls when the animation starts for each letter
                animationDuration: '0.5s', // Controls the speed of the fade-in effect
                animationFillMode: 'forwards', // Ensures the letter stays visible after animation
                color: 'white',
              }}
            >
              {letter === ' ' ? '\u00A0' : letter}
            </Typography>
          ))}
        </Box>
      </Stack>
    </StyledGroLoadingComponent>
  )
}

export default SlackLoading
