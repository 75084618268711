import React from 'react'
import PandaDocLogo from '../../images/pandadoc.png'

const PandaDocImageComponent = ({ size }) => {
  return (
    <>
      {size ? (
        <img style={{ width: size, borderRadius: 25 }} src={PandaDocLogo} />
      ) : (
        <img style={{ width: 40, borderRadius: 25 }} src={PandaDocLogo} />
      )}
    </>
  )
}

export default PandaDocImageComponent
