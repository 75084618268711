import React from 'react'
import { Chip, InputAdornment, TextField, useTheme } from '@mui/material'
import { CreateDealText, CreateProjectTextField } from '../../style/styleElements'
import { disabledTextStyles } from './style'
import { setStageStyle } from '../DealsKanban/DealsKanban'

const StageDefaultTextInput = ({ stage, handleChange }) => {
  const theme = useTheme()
  return (
    <TextField
      helperText={'Default stage for new deal'}
      label='Stage'
      placeholder='Stage'
      name='stage'
      value={stage}
      onChange={handleChange}
      fullWidth
      disabled
      autoComplete='off'
      sx={disabledTextStyles(theme)}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Chip
              size='small'
              style={{
                // backgroundColor: 'rgb(231, 209, 243)',
                backgroundColor: setStageStyle(stage),
                width: 14,
                height: 14,
                borderRadius: '50%',
                margin: '5px',
              }}
            />
          </InputAdornment>
        ),
        style: {
          color: 'purple', // This ensures the text color is purple
        },
      }}
    />
  )
}

export default StageDefaultTextInput
