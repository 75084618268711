import React, { useState } from 'react'
import { Grid, Box, useTheme, Button, Typography, Tooltip, Chip, IconButton } from '@mui/material'
import MassUpdateVertical from '../MassUpdateComponents/MassUpdateVertical'
import MassUpdateTypeSelect from '../MassUpdateComponents/MassUpdateTypeSelect'
import DeleteIcon from '@mui/icons-material/Delete'
import OrganizationsPopover from './OrganizationsPopover'
import { deleteButtonStyles } from './style'
import useUploadOrganizationPhoto from '../../api/customHooks/useUploadOrganizationPhoto'
import { GridFooterContainer } from '@mui/x-data-grid-pro'
import { EditButton } from '../Deals/style'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'

const orgTypeOptions = [
  {
    label: 'Property Management Company',
    type: 'PMC',
    color: 'rgb(93, 79, 251)',
  },
  { label: 'Ownership Group', type: 'OG', color: 'rgb(92, 179, 253)' },
  { label: 'Housing Property', type: 'HP', color: 'rgb(152, 214, 129)' },
  { label: 'Parent Client', type: 'PC', color: 'rgb(93, 79, 251)' },
  { label: 'Agency Whitelabel', type: 'AW', color: 'rgb(92, 179, 253)' },
  {
    label: 'Organization',
    type: 'ORG',
    color: 'rgb(242, 140, 40)',
  },
]
const orgStatusOptions = [
  { label: 'Prospect', type: 'Prospect', color: 'rgb(93, 79, 251)' },
  { label: 'Active', type: 'Active', color: 'rgb(152, 214, 129)' },
  { label: 'Onboarding', type: 'Onboarding', color: 'rgb(92, 179, 253)' },
  { label: 'In-Progress', type: 'In-Progress', color: 'rgb(110, 156, 222)' },
  { label: 'Active (SEO)', type: 'Active (SEO)', color: 'rgb(152, 98, 161)' },
  {
    label: 'Active (Creative)',
    type: 'Active (Creative)',
    color: 'rgb(147, 111, 218)',
  },
  {
    label: 'Active (Website)',
    type: 'Active (Website)',
    color: 'rgb(102, 69, 169)',
  },
  {
    label: 'Active (Past Due)',
    type: 'Active (Past Due)',
    color: 'rgb(255, 131, 88)',
  },
  { label: 'Active (LTP)', type: 'Active (LTP)', color: 'rgb(51, 158, 205)' },
  { label: '30 day notice', type: '30 day notice', color: 'rgb(235, 77, 77)' },
  { label: 'Cancelled', type: 'Cancelled', color: 'rgb(0,0,0)' },
  {
    label: 'Inactive / Paused',
    type: 'Inactive / Paused',
    color: 'rgb(255, 145, 145)',
  },
]
const tooltipGridFooter = title => {
  return <Typography>{title}</Typography>
}
export const OrganizationsGridFooter = ({
  massUpdateData,
  selectionModel = [],
  rowCount,
  onChange,
  onUpdate,
  organizations = [],
  anchorEl,
  handleDeleteClick,
  handleConfirmDelete,
  handleClosePopover,
  selectedData,
  setSelectedData,
  setSelectionModel,
  apiRef,
}) => {
  const theme = useTheme()
  const { mutate: uploadMutation } = useUploadOrganizationPhoto()
  const { showSnackbar } = useSnackbarContext()
  const [file, setFile] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'org-grid-footer-popover' : undefined

  const handleFileChange = event => {
    const selectedFile = event.target.files[0]
    setFile(selectedFile)

    console.log('[handleFileChange] selectedFile:', selectedFile)
    console.log('[handleFileChange] selectedData:', selectedData)

    if (selectedFile && selectedData) {
      const formData = new FormData()
      formData.append('file', selectedFile)
      formData.append('orgId', selectedData.org_uuid)

      console.log('[handleFileChange] FormData:', formData.get('file'), formData.get('orgId'))

      uploadMutation(
        { orgId: selectedData.org_uuid, formData },
        {
          onSuccess: () => {
            console.log('Photo uploaded successfully!')
            showSnackbar('Photo uploaded successfully!', 'success')
            apiRef.current.setRowSelectionModel([])
            setSelectionModel([])
            setSelectedData(null)
          },
          onError: error => {
            console.error('Error uploading photo:', error)
            showSnackbar('Failed to upload photo. Please try again.', 'error') // Handle errors gracefully
          },
        }
      )
    }
  }

  const imageContainerStyles = {
    width: '100px', // Set a fixed size or responsive size
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden', // Ensure the container does not overflow
    borderRadius: '8px',
  }

  const imageStyles = {
    width: '100%',
    height: '100%',
    objectFit: 'contain', // Ensure the entire image is visible
  }
  const orgGridStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    pb: 0.5,
    pl: 1.5,
    pr: 0.5,
    pt: 0,
    mt: 0.5,
  }
  return (
    <GridFooterContainer>
      {selectionModel.length > 0 ? (
        <Grid
          container
          sx={{
            maxHeight: 'auto',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: theme.palette.mode === 'dark' ? 'black' : 'white',
            alignItems: 'center',
            zIndex: 1,
            flexWrap: 'wrap', // Ensure items wrap if they don't fit
            overflow: 'hidden', // Prevent overflow
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={3} sx={orgGridStyles}>
              <Typography variant='h8'>{'Update'}</Typography>
              <Chip label={selectionModel.length} sx={{ color: 'rgb(24, 144, 255)', fontSize: 18 }} />
              <Typography variant='h8'>{selectionModel.length === 1 ? 'Organization' : 'Organizations'}</Typography>
            </Grid>

            <Grid item xs={12} sm={3} sx={orgGridStyles} justifyContent={'flex-end'}>
              <MassUpdateTypeSelect
                name='type'
                value={massUpdateData.type || ''}
                options={orgTypeOptions}
                displayLabel='Organization Type'
                onChange={onChange}
                sx={{ height: 40, minWidth: 150 }} // Adjust minWidth to prevent overflow
              />
            </Grid>
            <Grid item xs={12} sm={3} sx={orgGridStyles} justifyContent={'flex-end'}>
              <MassUpdateTypeSelect
                name='status'
                value={massUpdateData.status || ''}
                options={orgStatusOptions}
                displayLabel='Organization Status'
                onChange={onChange}
                sx={{ height: 40, minWidth: 150 }} // Adjust minWidth to prevent overflow
              />
            </Grid>
            <Grid item xs={12} sm={3} sx={orgGridStyles} justifyContent={'flex-end'}>
              {selectionModel.length === 1 && (
                <>
                  <input
                    type='file'
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    id='contact-photo-upload'
                  />
                  <label htmlFor='contact-photo-upload' style={{ cursor: 'pointer' }}>
                    <Tooltip title={tooltipGridFooter('Upload Photo')} placement='top'>
                      <Button color='primary' variant='outlined' component='span'>
                        Upload Photo
                      </Button>
                    </Tooltip>
                  </label>
                  <Tooltip title={tooltipGridFooter('Delete')} placement='top'>
                    <Button
                      sx={{ ...deleteButtonStyles(theme) }}
                      variant='outlined'
                      onClick={handleDeleteClick}
                      color='secondary'
                    >
                      Delete
                    </Button>
                  </Tooltip>
                </>
              )}
              <Tooltip title={selectionModel.length === 1 ? 'Apply Update' : 'Apply Mass Update'} placement='top'>
                <Button
                  color='secondary'
                  variant='contained'
                  onClick={onUpdate}
                  disabled={selectionModel.length === 0}
                  sx={{ height: 40 }}
                >
                  {selectionModel.length === 1 ? 'Update' : 'Mass Update'}
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            padding: 1,
            alignItems: 'center',
            minHeight: 50,
            bgcolor: theme.palette.mode === 'dark' ? 'black' : 'white',
            gap: 1,
          }}
          justifyContent='flex-end'
        >
          <Grid item>
            <Typography variant='body2' mr={2}>
              Total Rows:
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(89, 224, 158,0.8)' : 'rgba(0,0,0,0.6)' }}>
              {rowCount}
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)' }}>
              /
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)' }}>
              {organizations.length}
            </Typography>
          </Grid>
        </Grid>
      )}
      <OrganizationsPopover
        id={id}
        open={open}
        onClose={handleClosePopover}
        handleConfirmDelete={handleConfirmDelete}
        selectedData={selectedData}
      />
    </GridFooterContainer>
  )
}
