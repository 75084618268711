import RenderCompanyChip from '../RenderChipComponents/RenderCompanyChip'

const RenderDealInfoLink = ({ organization }) => {
  const renderChip = organization => {
    // Normalize type to be an array
    const typeArray = Array.isArray(organization.type) ? organization.type : [organization.type]
    const type = typeArray[0] || 'NT' // Assuming the first element is the main type
    // console.log('TYPE: ', type)
    switch (type) {
      case 'Housing Property':
      case 'Agency Whitelabel':
      case 'Property Management Company':
      case 'Ownership Group':
      case 'Parent Client':
      case 'Student':
      case 'Housing':
      case 'Organization':
        return <RenderCompanyChip option={organization} />
      default:
        return <RenderCompanyChip option={{ ...organization, type: 'NT' }} />
    }
  }

  return <>{organization && renderChip(organization)}</>
}

export default RenderDealInfoLink
