import { Box, IconButton, useTheme } from '@mui/material'
import GroboticDark from '../../../src/images/Grobotic png.png'
import GroboticLight from '../../../src/images/Grobotic png-lightmode.png'

const GroboticIcon = ({ height, width }) => {
  const theme = useTheme()
  return (
    <IconButton>
      <img
        src={theme.palette.mode === 'dark' ? GroboticDark : GroboticLight}
        height={height ? height : 35}
        width={width ? width : 35}
      />
    </IconButton>
  )
}

export default GroboticIcon
