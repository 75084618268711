import React, { useState } from 'react'
import { Box, TextField, Grid, Chip, InputAdornment, useTheme } from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import AuthorizedRecipientAutocomplete from '../PandaDoc/AuthorizedRecipientAutocomplete'
import ContactRecipientAutocomplete from '../PandaDoc/ContactRecipientAutocomplete'
import { useOrganizationById } from '../../api/aws/useOrganization'
import Loading from '../Loading/Loading'
import { setStageStyle } from '../DealsKanban/DealsKanban'
import { renderTypeColor } from '../DataGrid/createColumns/renderDealsColumns'
import PersonIcon from '@mui/icons-material/Person'
import { useGetQuery } from '../../api/aws/useGetQuery'
import { useUsers } from '../../api/aws/useUsers'
import { useContacts } from '../../api/aws/useContacts'
import { useContactsById } from '../../api/customHooks/useContactsById'
import DealTypeSelect from '../DealTypeSelect/DealTypeSelect'
import { gridItemStyles, fieldStyles, readOnlyFieldStyles, stageFieldStyles, typeFieldStyles } from './style'
import ReviseDealType from '../DealTypeSelect/ReviseDealType'
import { determineApprovalState } from '../DealApproval/determineApprovalState'
import DetailsLoading from '../Loading/DetailsLoading'

const verticalsRequiringApproval = [
  'Construction',
  'Tourism',
  'Marketing Agency',
  'Manufacturing',
  'Entertainment',
  'Restaurant (Food & Beverage)',
  'City/Government',
  'Financial Institution',
  'Technology',
  'Medical',
  'Lawyer',
  'Education',
  'Senior',
  'Homebuilding',
  'Build to rent',
]

const ContractInfoHeader = ({ newDeal, handleChange, validationErrors, organization }) => {
  const { contacts, isContactsLoading, isContactsError } = useContacts()
  const [dealType, setDealType] = useState(newDeal ? newDeal.type : '')
  const [csaTypes, setCsaTypes] = useState(['CSA', 'RCSA'])
  const contactUUIDs = newDeal && Array.isArray(newDeal.contacts) ? newDeal.contacts : []
  const orgId = newDeal && Array.isArray(newDeal.organizations) ? newDeal.organizations[0] : []
  // const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId)
  const fetchedContacts = useContactsById(contactUUIDs)
  const { users, isUsersLoading, isUsersError } = useUsers()
  const theme = useTheme()
  const fieldBgColor = theme.palette.mode === 'dark' ? 'rgb(22, 28, 42, 0.7)' : 'rgba(255,255,255,1)'

  const validDateValue = date => (dayjs.isDayjs(date) ? date : dayjs(date).isValid() ? dayjs(date) : null)

  return (
    <Box key={newDeal ? newDeal.id : newDeal ? newDeal.deal_uuid : ''} sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={2} sx={gridItemStyles}>
          <TextField
            label='Contract Name'
            name='name'
            value={newDeal.name || ''}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            sx={readOnlyFieldStyles(fieldBgColor)}
          />
        </Grid>
        <Grid item xs={2} sx={gridItemStyles}>
          <TextField
            label='Organization'
            name='organizations'
            value={organization && organization.name ? organization.name : ''}
            fullWidth
            InputProps={{ readOnly: true }}
            sx={readOnlyFieldStyles(fieldBgColor)}
          />
        </Grid>

        <Grid item xs={2} sx={gridItemStyles}>
          <ReviseDealType
            error={validationErrors.type}
            helperText={validationErrors.type && 'Deal type is required'}
            onChange={newValue => handleChange('type', newValue)}
          />
        </Grid>
        <Grid item xs={2} sx={gridItemStyles}>
          <AuthorizedRecipientAutocomplete
            recipients={users}
            value={newDeal && newDeal.recipients ? newDeal.recipients.authorizedRecipient : []}
            label={'GRO Authorized Recipient'}
            onChange={newValue => handleChange('authorizedRecipient', newValue)}
            error={validationErrors.authorizedRecipient}
            fullWidth
            sx={fieldStyles(fieldBgColor)}
          />
        </Grid>
        <Grid item xs={2} sx={gridItemStyles}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label='Start Date'
              value={validDateValue(newDeal ? newDeal.startDate : null)}
              onChange={newValue => handleChange('startDate', newValue)}
              slotProps={{
                textField: { InputProps: { readOnly: true }, fullWidth: true },
              }}
              sx={fieldStyles(fieldBgColor)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2} sx={gridItemStyles}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label='End Date'
              value={validDateValue(newDeal ? newDeal.endDate : null)}
              onChange={newValue => handleChange('endDate', newValue)}
              slotProps={{
                textField: { InputProps: { readOnly: true }, fullWidth: true },
              }}
              sx={fieldStyles(fieldBgColor)}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={2} sx={gridItemStyles}>
          <TextField
            label='Contacts'
            fullWidth
            disabled
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position='start'>
                  {fetchedContacts && fetchedContacts.length > 0
                    ? (() => {
                        const maxVisibleChips = 1
                        const extraCount = fetchedContacts.length - maxVisibleChips
                        const contactsBorderColor = theme.palette.mode === 'dark' ? 'transparent' : 'transparent'
                        const contactsTextColor =
                          theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'
                        const extraCountBorderColor =
                          theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'
                        return (
                          <>
                            {fetchedContacts.slice(0, maxVisibleChips).map((contact, index) => (
                              <Box
                                key={contact.contacts_uuid}
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  maxWidth: '100%',
                                  overflow: 'hidden',
                                  margin: 0,
                                  padding: 0.5,
                                }}
                              >
                                <PersonIcon
                                  sx={{
                                    color: 'rgb(255, 123, 187)',
                                  }}
                                />
                                <Chip
                                  label={contact.name}
                                  size='small'
                                  style={{
                                    height: 'auto',
                                    width: 'auto',
                                    borderColor: contactsBorderColor,
                                    borderWidth: '0.5px',
                                    borderStyle: 'solid',
                                    backgroundColor: 'transparent',
                                    color: contactsTextColor,
                                    borderRadius: '4px',
                                    marginRight: '8px',
                                  }}
                                />
                              </Box>
                            ))}
                            {extraCount > 0 && (
                              <Chip
                                label={`+${extraCount}`}
                                size='small'
                                style={{
                                  height: 'auto',
                                  width: 'auto',
                                  borderColor: extraCountBorderColor,
                                  borderWidth: '0.5px',
                                  borderStyle: 'solid',
                                  backgroundColor: 'transparent',
                                  color: contactsTextColor,
                                  borderRadius: '4px',
                                  marginRight: '8px',
                                }}
                              />
                            )}
                          </>
                        )
                      })()
                    : 'No Contacts'}
                </InputAdornment>
              ),
            }}
            sx={{
              ...readOnlyFieldStyles(fieldBgColor), // Apply your predefined readOnlyFieldStyles(fieldBgColor)
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent !important', // Force the border color to be transparent
              },
              '& .MuiOutlinedInput-root': {
                backgroundColor: `${fieldBgColor} !important`, // Ensures this color takes priority
              },
            }}
          />
        </Grid>
        <Grid item xs={2} sx={gridItemStyles}>
          <TextField
            label='DBA'
            name='dba'
            value={newDeal ? newDeal.dba : ''}
            fullWidth
            sx={readOnlyFieldStyles(fieldBgColor)}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2} sx={gridItemStyles}>
          <TextField
            label='Stage'
            name='stage'
            value={newDeal ? newDeal.stage : ''}
            fullWidth
            InputProps={{ readOnly: true }}
            sx={stageFieldStyles(newDeal ? newDeal.stage : '')}
          />
        </Grid>
        <Grid item xs={2} sx={gridItemStyles}>
          <ContactRecipientAutocomplete
            recipients={contacts}
            value={newDeal && newDeal.recipients ? newDeal.recipients.contactRecipient : []}
            label={'Contact Recipient'}
            onChange={newValue => handleChange('contactRecipient', newValue)}
            error={validationErrors.contactRecipient}
            fullWidth
            sx={fieldStyles(fieldBgColor)}
          />
        </Grid>
        <Grid item xs={2} sx={gridItemStyles}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label='Dependencies Due'
              value={validDateValue(newDeal ? newDeal.dependenciesDueDate : null)}
              onChange={newValue => handleChange('dependenciesDueDate', newValue)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: validationErrors.dependenciesDueDate,
                },
              }}
              sx={fieldStyles(fieldBgColor)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2} sx={gridItemStyles}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={dealType && csaTypes.includes(dealType) ? 'Target Completion Date' : 'Target Launch Date'}
              value={validDateValue(newDeal ? newDeal.targetLaunchDate : null)}
              onChange={newValue => handleChange('targetLaunchDate', newValue)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: validationErrors.targetLaunchDate,
                },
              }}
              sx={fieldStyles(fieldBgColor)}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ContractInfoHeader
