import React, { useState, useEffect } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableBody,
  Tooltip,
  TableContainer,
  Paper,
  useTheme,
  CircularProgress,
} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { dialogStyles } from './style'
import DialogHeaderComponent from '../DialogHeader/DialogHeader'
import RenderRow from './RenderRow'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { SaveButton, CreateButton } from './style'
import { BoxScroller } from '../../style/styleElements'
import WarningIcon from '@mui/icons-material/Warning'
import PandaDocImageComponent from '../AvatarImageComponents/PandaDocImageComponent'
import { useActiveUser } from '../../api/slack/useActiveUser'
import DetailsLoading from '../Loading/DetailsLoading'
import { determineApprovalState } from '../DealApproval/determineApprovalState'
import CreateDealContractTotals from './CreateDealContractTotals'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'

const ContractDialog = ({ open, onClose, onCancel, newDeal, setNewDeal, onSave, createContract, totals }) => {
  const theme = useTheme()
  const [isCreating, setIsCreating] = useState(false)
  const [isError, setIsError] = useState(false)
  const {
    validateFields,
    validationErrors,
    productRows,
    setProductRows,
    computeTotals,
    isValid,
    setIsValid,
    addRow,
    removeRow,
    handleProductChange,
    handleChange,
  } = useDealDetailsContext()
  const orgId = newDeal && Array.isArray(newDeal.organizations) ? newDeal.organizations[0] : null
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId)
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const csaTypes = ['CSA', 'RCSA']
  const [approvalState, setApprovalState] = useState(
    newDeal && organization ? determineApprovalState(newDeal, organization) : null
  )
  // Recalculate approval state whenever deal type or organization changes
  useEffect(() => {
    if (newDeal && organization) {
      const approvalState = determineApprovalState(newDeal, organization)
      // Merge the approval state into newDeal, but keep other fields intact
      const updatedDeal = {
        ...newDeal, // Start with the existing deal
        name: organization ? `${organization.name} - ${newDeal.type}` : '',
        needsApproval: approvalState.needsApproval, // Update the approval-related fields
        status: approvalState.status,
        stage: approvalState.stage,
        hasProducts: approvalState.hasProducts,
        owner: activeUser && activeUser.user ? [activeUser.user.id] : [],
        organizations: newDeal.organizations || [], // Ensure organizations array is preserved
      }

      console.log('UPDATED DEAL: ', updatedDeal)
      setNewDeal(updatedDeal) // Update the state with the merged deal
    }
  }, [newDeal?.type, newDeal?.noSignature, organization])

  useEffect(() => {
    const newTotals = computeTotals(newDeal, productRows)
    setNewDeal(prevDeal => ({ ...prevDeal, ...newTotals }))
  }, [productRows, newDeal.implementationFee, setNewDeal])

  useEffect(() => {
    if (open) {
      const isValid = validateFields(newDeal)
      setIsValid(isValid)
    }
  }, [open, newDeal])
  const handleSave = event => {
    event.preventDefault()
    // if (!isValid) return
    setIsCreating(true)
    const newStatus = newDeal.needsApproval ? 'Needs Approval' : 'Saved'
    const newStage = newDeal.needsApproval ? 'Pending Approval' : 'Proposal'
    try {
      onSave({
        ...newDeal,
        status: newStatus,
        owner: activeUser && activeUser.user ? [activeUser.user.id] : [],
        stage: newStage,
        lastModified: new Date(),
      })
      setIsCreating(false)
      onClose()
    } catch (error) {
      setIsCreating(false)
      setIsError(true)
    }
  }
  const handleCreate = event => {
    event.preventDefault()
    if (!isValid) return

    setIsCreating(true)
    const ownerId = activeUser && activeUser.user ? activeUser.user.id : null

    console.log('Creating deal with owner ID:', ownerId)
    console.log('Current newDeal:', newDeal)
    try {
      createContract(
        {
          ...newDeal,
          owner: ownerId ? [ownerId] : [],
          creation_timestamp: new Date(),
        },
        organization,
        activeUser
      )
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      setIsCreating(false)
      setIsError(true)
    }
    setIsCreating(false)
  }

  const onDragEnd = result => {
    console.log('[onDragEnd] result: ', result)
    if (!result.destination) return
    const items = Array.from(productRows)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setProductRows(items)
  }

  useEffect(() => {
    setNewDeal(prevDetails => ({
      ...prevDetails,
      totals,
    }))
  }, [totals])
  const handleCancel = () => {
    setNewDeal(prev => ({
      ...prev,
      recipients: [{ authorizedRecipient: {} }, { contactRecipient: {} }],
    }))
    onCancel()
  }
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return
    onClose()
  }
  const isLoading = isActiveUserLoading || isOrganizationLoading
  if (isLoading) {
    return <DetailsLoading />
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth='xl'
      PaperProps={{
        sx: dialogStyles(theme),
      }}
      disableEscapeKeyDown
    >
      <DialogHeaderComponent
        name='Contracts'
        newDeal={newDeal}
        setNewDeal={setNewDeal}
        handleChange={handleChange}
        validationErrors={validationErrors}
        setApprovalState={setApprovalState}
      />
      <BoxScroller
        sx={{
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '6px',
          },
          height: '100%',
        }}
      >
        <DialogContent sx={{ height: '100%' }}>
          <TableContainer component={Paper}>
            <Table>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable'>
                  {provided => (
                    <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                      {productRows.map((row, index) => (
                        <Draggable key={row.rowId} draggableId={row.rowId} index={index}>
                          {(provided, snapshot) => (
                            <RenderRow
                              key={row.rowId}
                              row={row}
                              index={index}
                              handleProductChange={handleProductChange}
                              provided={provided}
                              snapshot={snapshot}
                              handleRemoveRow={removeRow}
                              validationErrors={validationErrors}
                              type={newDeal ? newDeal.type : ''}
                            />
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>
          <Button
            sx={{
              display: 'flex',
              '&:hover': {
                opacity: '80%',
              },
            }}
            startIcon={<AddCircleOutlineIcon />}
            onClick={addRow}
          >
            Add Service
          </Button>
          {validationErrors.products && (
            <Typography color='error' variant='body2'>
              {validationErrors.products}
            </Typography>
          )}
        </DialogContent>
      </BoxScroller>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <CreateDealContractTotals deal={newDeal} setDeal={setNewDeal} totals={totals} onChange={handleChange} />
        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }} gap={1}>
          {isCreating && <CircularProgress sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }} />}
          {isError && <Typography color='error'>Error creating deal</Typography>}

          <Button onClick={handleCancel} color='primary' variant='outlined'>
            Back
          </Button>
          <SaveButton onClick={handleSave} disabled={isCreating} color='secondary' variant='contained'>
            {!newDeal.noSignature ? 'Save' : 'Save Existing Deal'}
          </SaveButton>
          {newDeal && !newDeal.noSignature && (
            <>
              <Tooltip
                title={
                  newDeal.needsApproval && !newDeal.isApproved ? 'Deal needs approval before creating contract' : ''
                }
              >
                <Box>
                  <CreateButton
                    onClick={handleCreate}
                    // onClick={() => console.log('NEW DEAL: ', newDeal)}
                    disabled={
                      (newDeal.needsApproval && !newDeal.isApproved && newDeal.type !== 'IO') ||
                      csaTypes.includes(newDeal.type) ||
                      !isValid ||
                      isCreating
                    }
                    color='primary'
                    variant='contained'
                    startIcon={
                      (newDeal.needsApproval && !newDeal.isApproved && newDeal.type !== 'IO') ||
                      csaTypes.includes(newDeal.type) ? (
                        <WarningIcon sx={{ color: 'crimson' }} />
                      ) : (
                        <PandaDocImageComponent size={30} />
                      )
                    }
                  >
                    Create Contract
                  </CreateButton>
                </Box>
              </Tooltip>
            </>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default ContractDialog
