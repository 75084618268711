export const getTabColor = label => {
  switch (label) {
    case 'Agreement Signed':
      return 'rgb(0, 200, 117)' // Color when not selected
    case 'Handoff Call':
      return 'rgb(120, 75, 209)' // Color when not selected
    case 'Awaiting Dependencies':
      return 'rgb(87, 155, 252)'
    case 'Dependencies Received':
      return 'rgb(255, 131, 88)'
    case 'Dependencies Verified':
      return 'rgb(226, 68, 92)'
    case 'Ready To Create':
      return 'rgb(250, 200, 50)'
    case 'Launched':
      return 'rgb(3, 127, 76)'
    case 'Inactive - Temp Pause':
      return '#f6a96d'
    case 'Inactive - Past Due':
      return '#f56b62'

    case 'Churned':
      return '#f56b62'

    case 'On Hold':
      return '#fbc11e'

    case 'Active':
      return '#74cb80'

    case 'Approved':
      return '#a593ff'

    case 'On Track':
      return '#74cb80'

    case 'Invoiced':
      return '#b99897'

    case 'Planning':
      return '#08aeea'

    case 'Active - 30 Day Notice':
      return '#ff7bd7'

    case 'Completed':
      return '#4fd3e5'
    default:
      return 'grey' // Default unselected color
  }
}
