import React from 'react'
import { Box, useTheme } from '@mui/material'
import GroIconDark from '../../images/gro logo dark font.png'
import GroIconLight from '../../images/GRO-logo-white-01.png'

const GroMarketingIcon = ({ width, radius, bgColor, disabled }) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        borderRadius: radius ? radius : '',
        backgroundColor: bgColor ? bgColor : '',
        opacity: disabled ? '0.5' : '',
      }}
    >
      {theme.palette.mode === 'dark' ? (
        <img src={GroIconLight} style={{ width: width ? width : 40, padding: 2 }} />
      ) : (
        <img src={GroIconDark} style={{ width: width ? width : 40, padding: 2 }} />
      )}
    </Box>
  )
}

export default GroMarketingIcon
