import React, { useState, useEffect } from 'react'
import { Box, FormControl, MenuItem, Select, Typography, useTheme } from '@mui/material'
import RenderChip from '../RenderChipComponents/RenderChip'

const CustomTypeFilter = ({
  options,
  setterFn,
  filterModel,
  value,
  onChange,
  context,
  shouldClear, // Pass the clear trigger from the parent component
  setShouldClear, // Function to reset the clear trigger after it’s handled
}) => {
  const [selectedType, setSelectedType] = useState('')
  const theme = useTheme()

  useEffect(() => {
    if (filterModel) {
      const currentFilter = filterModel.items.find(item => item.field === 'type')

      if (currentFilter && currentFilter.value) {
        setterFn(currentFilter.value) // Correctly set the status value from filterModel
      }
    }
  }, [filterModel])

  useEffect(() => {
    if (shouldClear) {
      setSelectedType('') // Clear the selection
      setterFn('') // Reset the parent state
      if (onChange) {
        onChange(context, '') // Ensure that the filter is updated as cleared
      }
      setShouldClear(false) // Reset the clear trigger
    }
  }, [shouldClear, context, onChange, setterFn, setShouldClear])

  const handleTypeChange = event => {
    const newType = event.target.value
    const selectedTypeDetail = options.find(type => type.label === newType)
    setSelectedType(newType)
    setterFn(selectedTypeDetail ? selectedTypeDetail.label : '')

    if (onChange) {
      onChange(context, selectedTypeDetail ? selectedTypeDetail.label : '')
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: 100,
      }}
    >
      <FormControl sx={{ display: 'flex', height: 70 }} fullWidth>
        <Select
          sx={{ height: 30, pt: 2.5, pb: 2.5, borderRadius: '4px' }}
          name={context || ''}
          labelId='orgType-label'
          id='orgType-select'
          value={value || ''}
          onChange={handleTypeChange}
          displayEmpty
          renderValue={selected => {
            const typeDetail = options.find(type => type.label === selected)
            return (
              <>
                {typeDetail ? (
                  <Box>
                    <RenderChip type={selected} />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)',
                    }}
                    variant='subtitle1'
                  >
                    {'type'}
                  </Typography>
                )}
              </>
            )
          }}
        >
          {options.map(type => (
            <MenuItem key={type.label} value={type.label}>
              <RenderChip type={type.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default CustomTypeFilter
