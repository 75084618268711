import React, { useEffect, useState } from 'react'
import { Grid, Box, Button, useTheme } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useUpdateItem } from '../../api/aws/useUpdateItem'
import ViewStrategyForm from './ViewStrategyForm'
import { useDeal } from '../../api/aws/useDeal'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { editFieldConfig } from './editFieldConfig'
import SelectStrategyTemplate from './SelectStrategyTemplate'

const templates = {
  'Search Platform - Google': {
    platform: 'Google',
    demographicTargeting: 'Typical MF',
    landingPageUrl: 'See spreadsheet',
    targetingSpecifics: '',
    campaigns: [
      {
        name: 'Campaign One: Property Name - Search - Branded',
        location: 'City, 5-10 miles around address',
        adGroup: 'Property Name - Branded',
        keywords: [
          '"property name"',
          '[property name]',
          '"property name city"',
          '[property name city]',
          '"property name apartments"',
          '[property name apartments]',
        ],
        negativeKeywords: [],
      },
      {
        name: 'Campaign Two: Property Name - Search - City',
        location: 'City, 5-10 miles around address',
        adGroup: 'City',
        keywords: [
          '"**city** apartments"',
          '"**city** *state* apartments"',
          '"**city** apartments for rent"',
          '"Apartments near **city**"',
          '"Apartments for rent **city**"',
          '"Apartments in **city** *state*"',
        ],
        negativeKeywords: ['property name'],
      },
      {
        name: 'Campaign Three: Property Name - Search - Near Me',
        location: 'City, 5-10 miles around address',
        adGroup: 'Near Me',
        keywords: [
          '“Studio apartments near me”',
          '“Apartments near me”',
          '“Apartments for rent near me”',
          '“One bedroom apartments near me”',
          '“Apartment complexes near me”',
          '“Apartment communities near me”',
          '“1 bedroom apartments near me”',
          '“2 bedroom apartments near me”',
          '“3 bedroom apartments near me”',
        ],
        negativeKeywords: [],
      },
      {
        name: 'Campaign Two: Property Name - Search - City',
        location: 'City, 5-10 miles around address',
        adGroup: 'City',
        keywords: [
          '"**city** apartments"',
          '"**city** *state* apartments"',
          '"**city** apartments for rent"',
          '"Apartments near **city**"',
          '"Apartments for rent **city**"',
          '"Apartments in **city** *state*"',
        ],
        negativeKeywords: [],
      },
    ],
  },
  'Social Targeting - FB, IG': {
    platform: 'Facebook, Instagram',
    demographicTargeting: 'Typical MF',
    landingPageUrl: 'See spreadsheet',
    targetingSpecifics: '',
    geoTargeting: '15 miles around city - radius size can be increased',
    campaigns: [
      {
        name: 'Campaign One: Property Name - Leads',
        location: 'City, 5-10 miles around address',
        adGroup: '', // Add missing adGroup
        keywords: [], // Add empty keywords
        negativeKeywords: [], // Add empty negative keywords
      },
    ],
    ads: [
      {
        'Set 1': {
          name: 'Leads - Universal Conversion - Interest Targeting',
          conversionLocation: 'Website',
          performanceGoal: 'Maximize number of conversions',
          conversionEvent: 'Universal Conversion',
          audience: [
            'Renting (real estate)',
            'Rent.com (real estate)',
            'Penthouse apartment (real estate)',
            'Apartments.com (real estate)',
            'Apartment List (real estate)',
            'Apartment Guide (real estate)',
            'Apartment (real estate)',
            'Zillow (real estate)',
            'Trulia (real estate)',
            'Property finder (real estate)',
            'Real estate (industry)',
            'Residential area (real estate)',
            'Premier Agent - Zillow & Trulia (real estate)',
          ],
        },
        'Set 2': {
          name: 'Open Targeting',
          placements: 'Advantage+ Placements',
        },
        exclusions: ['FormFill', 'Account Creation'],
      },
    ],
  },
}

const Strategy = () => {
  const { dealId } = useParams()
  const { deal } = useDeal(dealId)
  const orgId = deal && Array.isArray(deal.organizations) ? deal.organizations[0] : []
  const { organization } = useOrganizationById(orgId)
  const { showSnackbar } = useSnackbarContext()
  const { mutate: updateItem } = useUpdateItem()
  const [formState, setFormState] = useState({})
  const [selectedTemplate, setSelectedTemplate] = useState('')
  const [isEditStrategyMode, setIsEditStrategyMode] = useState(false)
  const [initialFormState, setInitialFormState] = useState(null)
  const [campaigns, setCampaigns] = useState([])
  const [ads, setAds] = useState([])
  const theme = useTheme()
  console.log('[Strategy] deal: ', deal)
  useEffect(() => {
    if (deal) {
      const initialStrategyState = {
        ...deal,
        organizationName: organization ? organization.name : '',
        type: deal ? deal.type : '',
        website: organization ? organization.website : '',
        street: organization ? organization.street : '',
        city: organization ? organization.city : '',
        state: organization ? organization.state : '',
        zip: organization ? organization.zip : '',
        vertical: organization && Array.isArray(organization.vertical) ? organization.vertical[0] : '',
        cmsUsed: organization ? organization.cmsUsed : '',
      }
      setFormState(initialStrategyState)
      setInitialFormState(initialStrategyState)
    }
  }, [deal, organization])

  const handleTemplateSelect = event => {
    const selectedTemplate = templates[event.target.value]
    setSelectedTemplate(event.target.value)

    const mappedTemplate = {}

    // Map fields from editFieldConfig for campaigns
    Object.keys(editFieldConfig).forEach(section => {
      editFieldConfig[section].forEach(field => {
        if (selectedTemplate[field.name] !== undefined) {
          mappedTemplate[field.name] = selectedTemplate[field.name]
        }
      })
    })

    setFormState(prevState => ({
      ...prevState,
      ...mappedTemplate,
    }))

    // Set campaigns
    if (selectedTemplate.campaigns) {
      setCampaigns(selectedTemplate.campaigns)
    }

    // Set ads if present
    if (selectedTemplate.ads) {
      setAds(selectedTemplate.ads)
    }
  }

  const handleChange = event => {
    const { name, value } = event.target
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleBlur = event => {
    const { name, value } = event.target
    if (initialFormState[name] !== value && (value !== '' || initialFormState[name] !== '')) {
      updateItem({
        primaryKey: 'deal_uuid',
        tableName: 'deals',
        itemId: dealId,
        fieldName: name,
        newValue: value,
      })
    }
  }

  return (
    <Grid container sx={{ padding: '20px', maxWidth: '1400px', margin: 'auto' }}>
      {isEditStrategyMode ? (
        <Button onClick={() => setIsEditStrategyMode(false)}>View Mode</Button>
      ) : (
        <Button onClick={() => setIsEditStrategyMode(true)}>Edit Mode</Button>
      )}

      <Box>
        {isEditStrategyMode ? (
          <>
            <SelectStrategyTemplate
              templates={templates}
              selectedTemplate={selectedTemplate}
              onChange={handleTemplateSelect}
            />
            {formState && (
              <ViewStrategyForm
                formState={formState}
                isEditMode={isEditStrategyMode}
                onChange={handleChange}
                onBlur={handleBlur}
                campaigns={campaigns} // Pass campaigns to ViewStrategyForm
                ads={ads}
              />
            )}
          </>
        ) : (
          <>
            {formState && (
              <ViewStrategyForm
                formState={formState}
                isEditMode={isEditStrategyMode}
                onChange={handleChange}
                onBlur={handleBlur}
                campaigns={campaigns} // Pass campaigns to ViewStrategyForm
                ads={ads}
              />
            )}
          </>
        )}
      </Box>
    </Grid>
  )
}

export default Strategy
