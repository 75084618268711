import React from 'react'
import { Avatar } from '@mui/material'
import { styled } from '@mui/material/styles'

const UserImage = ({ zohoId, users, imageUrl }) => {
  const CustomAvatar = styled(Avatar)({
    width: '40px',
    height: '40px',
    backgroundColor: 'transparent',
  })

  // Placeholder image URL
  const placeholderImageUrl =
    'https://media.giphy.com/media/bn0zlGb4LOyo8/giphy.gif?cid=790b7611d6vf97l3e5n9b0ydnpjus7ijhxwvjmjqyj6rcjwo&ep=v1_gifs_search&rid=giphy.gif&ct=g' // Replace with your placeholder image path

  // Function to check if the image URL is valid
  const isValidImageUrl = url => {
    return url && url.startsWith('https://')
  }

  // Determine the image URL to use
  const imageSrc = isValidImageUrl(imageUrl) ? imageUrl : placeholderImageUrl

  return <CustomAvatar src={imageSrc} alt='User' />
}

export default UserImage
